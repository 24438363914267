import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { OKTA_NONCE_KEY } from 'declic-app/app.tokens';
import { Coerce } from 'declic-app/common';
import { OktaAuthService } from 'declic-app/services/okta-auth';
import { take } from 'rxjs';

export const nonceResolver: ResolveFn<void> = () => {
  inject(OktaAuthService)
    .selectApiNonce()
    .pipe(take(1))
    .subscribe((nonce) => {
      sessionStorage.setItem(OKTA_NONCE_KEY, Coerce.toString(nonce));
    });
};

import {
  BasicEquipment,
  HasAssetEfficiency,
  HasCapacity,
  HasInputs,
  HasYearlyProduction,
} from './equipment.types';

export type Boiler = BasicEquipment &
  HasAssetEfficiency &
  HasYearlyProduction &
  HasInputs &
  HasCapacity;

export type BoilerForm = Pick<
  Boiler,
  'name' | 'assetEfficiency' | 'yearlyProduction' | 'inputTypes' | 'capacity'
>;

import { ToggleOption } from 'declic-app/components/toggle';

export enum calculationApproachType {
  IN = 'With energy inputs',
  OUT = 'With energy output',
}

export enum MobilityApproach {
  CHARGING_POINTS = 'Based on Charging Points',
  VEHICLES = 'Based on Vehicles',
}

export const CALC_APPROACH_OPTIONS: ToggleOption[] = [
  {
    label: calculationApproachType.IN,
    source: undefined /*'/assets/icons/approach_input.svg'*/,
  },
  {
    label: calculationApproachType.OUT,
    source: undefined /*'/assets/icons/approach_output.svg'*/,
  },
];

export enum OAndMApproach {
  EFFICIENCY_RATIO = 'Based on energy efficiency ratio',
  ENERGIES_CONSUMED = 'Based on energies consumed',
}

export const OANDM_CALC_APPROACH_OPTIONS: ToggleOption[] = [
  {
    label: OAndMApproach.EFFICIENCY_RATIO,
    source: undefined /*'/assets/icons/sale_label.svg'*/,
  },
  {
    label: OAndMApproach.ENERGIES_CONSUMED,
    source: undefined /*'/assets/icons/ten_plus.svg'*/,
  },
];

import { Coerce } from 'declic-app/common';
import { AnnualFactor, EnergySource } from 'declic-app/models';
import { PlCalcApproachType } from 'declic-pl/common/';
import {
  PublicLighting,
  PowerDetails,
  LampDetails,
  PlEmissions,
} from 'declic-pl/models';
import { ProductType } from 'declic-project/models';
import { AnnualFactorValidator } from './annual-factor.validator';
import { ProductValidator } from './product.validator';

export class PlValidator implements ProductValidator<PublicLighting> {
  getType(): ProductType {
    return ProductType.PL;
  }
  isProjectValid(product: PublicLighting): boolean {
    return this.arePlEmissionsValid(product.projectEmissions);
  }
  isBaselineValid(product: PublicLighting): boolean {
    return this.arePlEmissionsValid(product.baselineEmissions);
  }
  isHistoricalValid(product: PublicLighting): boolean {
    return this.arePlEmissionsValid(product.historicalEmissions);
  }

  private arePlEmissionsValid(emissions: PlEmissions): boolean {
    return [
      this.validDetails(emissions),
      this.hasElectricity(emissions),
      this.validElectricity(emissions),
    ].every(Boolean);
  }

  private validDetails(emissions: PlEmissions) {
    if (emissions.calcApproach === PlCalcApproachType.LAMP) {
      return this.validLampDetails(emissions.lampDetails);
    }
    return this.validPowerDetails(emissions.powerDetails);
  }

  private hasElectricity(emissions: PlEmissions): boolean {
    return Boolean(this.coerceEnergySources(emissions).length);
  }

  private validElectricity(emissions: PlEmissions): boolean {
    return this.coerceEnergySources(emissions).every((source) =>
      this.validEnergySource(source),
    );
  }

  private coerceEnergySources(emissions: PlEmissions): EnergySource[] {
    return Coerce.toArr(Coerce.toObj(emissions).electricity);
  }

  private validEnergySource(source: EnergySource): boolean {
    return this.validAnnualFactors([source.emissionFactor]);
  }

  private validAnnualFactors(factors: AnnualFactor[]): boolean {
    return factors.every((factor) => AnnualFactorValidator.isValid(factor));
  }

  private validLampDetails(lampDetails: LampDetails): boolean {
    if (!!!lampDetails) {
      return false;
    }

    return [
      AnnualFactorValidator.isValid(lampDetails.numOfLamps),
      AnnualFactorValidator.isValid(lampDetails.lampConsumption),
    ].every(Boolean);
  }

  private validPowerDetails(powerDetails: PowerDetails): boolean {
    if (!!!powerDetails) {
      return false;
    }

    return [
      AnnualFactorValidator.isValid(powerDetails.powerLoad),
      AnnualFactorValidator.isValid(powerDetails.functioningHrs),
    ].every(Boolean);
  }
}

import { Coerce } from 'declic-app/common';
import {
  AnnualFactor,
  FactorNature,
  InputNature,
  YearlyEmission,
} from 'declic-app/models';
import { CerberusRepository } from 'declic-app/services/cerberus';
import { AnnualFactorValidator } from 'declic-product/validators';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

/* eslint-disable max-lines */
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormControl,
  ValidationErrors,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CustomUnit } from '../auxiliary-value';

type Fn = (_: unknown) => void;
type YearlyInputGroup = AnnualFactor & { isAnnual: boolean };

@UntilDestroy()
@Component({
  selector: 'declic-yearly-input',
  templateUrl: './yearly-input.component.html',
  styleUrls: ['./yearly-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => YearlyInputComponent),
      multi: true,
    },
  ],
})
export class YearlyInputComponent implements ControlValueAccessor, OnInit {
  private focusedAverageValue = 0;
  private readonly valuesCache = new BehaviorSubject<YearlyEmission[]>([]);
  private readonly hasError = new BehaviorSubject<boolean>(true);
  private readonly cascading = new BehaviorSubject<boolean>(true);
  private get isAnnualNatureMap(): Record<string, FactorNature> {
    return {
      true: 'annual',
      false: 'average',
    };
  }
  readonly yearlyInputGroup = this.bob.group({
    averageValue: '',
    nature: 'average',
    values: [],
    isAnnual: null,
    inputNature: InputNature.DEFAULT,
    isCascading: false,
  });

  readonly hasErr$ = this.hasError.asObservable();
  readonly readonly$ = this.cerberus.selectIsActiveReadonly();
  readonly sparklinePoints$ = this.selectValuesForSparkline();
  readonly averageControl = this.yearlyInputGroup.get('averageValue');
  readonly isAnnualControl = this.yearlyInputGroup.get('isAnnual');
  shouldHideAve = false;

  @Input() set isCascading(value: boolean) {
    this.cascading.next(value);
  }
  @Input() averageOnly = false;
  @Input() yearlyOnly = false;
  @Input() disableToggle = false;
  @Input() label = '';
  @Input() unit = '';
  @Input() customUnit: CustomUnit = undefined;
  @Input() set values(values: YearlyEmission[]) {
    this.valuesCache.next(values);
  }
  @Output() yearlyClick = new EventEmitter();
  @Output() userInput = new EventEmitter();

  private onChange: Fn = (_: unknown) => {};

  constructor(
    private readonly cerberus: CerberusRepository,
    private readonly bob: UntypedFormBuilder,
  ) {
    this.subscribeToBothValuesAndGroupForInputValiditySetting();
    this.subscribeToGroupNatureForIsAnnualControlPatching();
    this.subscribeToIsAnnualChangesForHidingOfAverage();
    this.subscribeToCerberusForAnnualToggleDisabling();
    this.subscribeForCVAPropagation();
    this.touchAverageControl();
  }

  ngOnInit(): void {
    this.disableToggleIfApplicable();
  }

  onAverageValueFocus(): void {
    this.focusedAverageValue = this.averageControl.value;
  }

  onAverageValueBlur(): void {
    if (this.hasUserChangedTheValueSinceFocus()) {
      this.userInput.emit();
    }
  }

  onYearlyClick(): void {
    this.yearlyClick.emit();
  }

  /* CVA Methods */
  writeValue(value: AnnualFactor): void {
    this.yearlyInputGroup.patchValue(this.annualFactorToGroup(value));
  }

  registerOnChange(fn: Fn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: unknown): void {
    // do nothing
  }

  private disableToggleIfApplicable(): void {
    if (this.disableToggle) {
      this.yearlyInputGroup.get('isAnnual').disable();
    }
  }

  private hasUserChangedTheValueSinceFocus(): boolean {
    return this.focusedAverageValue !== this.averageControl.value;
  }

  private subscribeToBothValuesAndGroupForInputValiditySetting(): void {
    this.combineBothValuesAndGroupChanges()
      .pipe(
        map(() => this.buildAnnualFactorFromLatestValues()),
        map((factor) => AnnualFactorValidator.isValid(factor)),
        map((isValid) => this.validErrorsMap[String(isValid)]),
        untilDestroyed(this),
      )
      .subscribe((errors) => this.setAverageControlErrs(errors));
  }

  private setAverageControlErrs(errs: ValidationErrors): void {
    this.hasError.next(!!errs);
  }

  private buildAnnualFactorFromLatestValues(): AnnualFactor {
    return {
      ...this.yearlyInputGroup.value,
      values: this.valuesCache.value,
      isCascading: this.cascading.value,
      nature:
        this.isAnnualNatureMap[
          String(this.yearlyInputGroup.getRawValue().isAnnual)
        ],
    };
  }

  private get validErrorsMap(): Record<string, unknown> {
    return {
      true: null,
      false: { invalid: true },
    };
  }

  private combineBothValuesAndGroupChanges(): Observable<unknown> {
    return combineLatest([
      this.valuesCache,
      this.yearlyInputGroup.valueChanges,
      this.cascading,
    ]);
  }

  private subscribeToIsAnnualChangesForHidingOfAverage(): void {
    this.selectIsAnnualControlChanges()
      .pipe(untilDestroyed(this))
      .subscribe((is) => {
        this.shouldHideAve = is;
      });
  }

  private touchAverageControl(): void {
    this.averageControl.setErrors({ mom: 'dad' });
    setTimeout(() => {
      this.averageControl.markAllAsTouched();
    }, 1000);
  }

  private selectValuesForSparkline(): Observable<number[]> {
    return this.valuesCache.pipe(
      map((values) => this.yearlyEmissionsToValues(values)),
    );
  }

  private yearlyEmissionsToValues(emissions: YearlyEmission[]): number[] {
    return Coerce.toArr(emissions).map((e) => e.emissions);
  }

  // private shouldEmitYearly(): boolean {
  //   return !this.getIsAnnualControl().value;
  // }

  private annualFactorToGroup(factor: AnnualFactor): YearlyInputGroup {
    const nature = this.yearlyOnly ? 'annual' : factor.nature;
    return {
      ...factor,
      nature,
      isAnnual: this.isAnnual(nature),
      isCascading: this.cascading.value,
    };
  }

  private subscribeToCerberusForAnnualToggleDisabling(): void {
    this.cerberus
      .selectIsActiveReadonly()
      .pipe(untilDestroyed(this))
      .subscribe((readonly) =>
        readonly
          ? this.getIsAnnualControl().disable()
          : this.getIsAnnualControl().enable(),
      );
  }

  private getIsAnnualControl(): UntypedFormControl {
    return this.yearlyInputGroup.get('isAnnual') as UntypedFormControl;
  }

  private selectIsAnnualControlChanges(): Observable<boolean> {
    return this.getIsAnnualControl().valueChanges;
  }

  private subscribeForCVAPropagation(): void {
    this.selectGroupValueChanges()
      .pipe(
        map((group) => this.groupToAnnualFactor(group)),
        untilDestroyed(this),
      )
      .subscribe((factor) => this.onChange(factor));
  }

  private groupToAnnualFactor(group: YearlyInputGroup): AnnualFactor {
    return {
      ...group,
      nature: this.isAnnualNatureMap[String(group.isAnnual)],
      isCascading: this.cascading.value,
    };
  }

  private selectGroupValueChanges(): Observable<YearlyInputGroup> {
    return this.yearlyInputGroup.valueChanges.pipe(
      map(() => {
        let averageValue = undefined;
        const rawAverage = this.yearlyInputGroup.getRawValue().averageValue;
        if (!!rawAverage) {
          averageValue = Number(String(rawAverage).replace(/ /g, ''));
        }
        return { ...this.yearlyInputGroup.getRawValue(), averageValue };
      }),
    );
  }

  private subscribeToGroupNatureForIsAnnualControlPatching(): void {
    this.selectGroupNatureValueChanges()
      .pipe(untilDestroyed(this))
      .subscribe((nature) =>
        this.yearlyInputGroup.patchValue({ isAnnual: this.isAnnual(nature) }),
      );
  }

  private selectGroupNatureValueChanges(): Observable<FactorNature> {
    return this.yearlyInputGroup.get('nature').valueChanges;
  }

  private isAnnual(nature: FactorNature): boolean {
    return nature === 'annual';
  }
}

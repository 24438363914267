import { Coerce } from 'declic-app/common';
import {
  EnergyNature,
  HasEnergyNature,
} from 'declic-app/components/nature-determiner';
import { ProductEditorModel } from 'declic-product/components/product-editor';
import { ProductType } from 'declic-project/models';
import { GeneralEmission, Storage, StorageEmissions } from 'declic-st/models';

import { ProductEditorMapper } from './product.mapper';

export class StorageMapper extends ProductEditorMapper<Storage> {
  mapProductToEditorHistorical(product: Storage): unknown {
    return;
  }
  getType(): ProductType {
    return ProductType.ST;
  }
  mapEditorModelToProduct(editorModel: ProductEditorModel): Storage {
    return {
      ...editorModel,
      id: undefined,
      type: editorModel.type as ProductType,
      general: this.mapGeneralToProduct(editorModel),
      baselineEmissions: this.mapBaselineToProduct(editorModel),
      projectEmissions: this.mapProjectToProduct(editorModel),
    };
  }
  mapProductToEditorGeneral(_: Storage): GeneralEmission {
    return {} as GeneralEmission;
  }
  mapProductToEditorProjectEmissions(product: Storage): StorageEmissions {
    return this.toEditorEmis(product.projectEmissions) as StorageEmissions;
  }
  mapProductToEditorBaselineEmissions(product: Storage): StorageEmissions {
    return this.toEditorEmis(product.baselineEmissions) as StorageEmissions;
  }

  private toEditorEmis(emissions: StorageEmissions): HasEnergyNature {
    const editorEmis = {
      ...emissions,
      nature: this.mapNatureToForm(emissions),
    };
    const { natureOfEnergy, natureOfHeat, ...restOfEmissions } = editorEmis;
    return restOfEmissions;
  }

  private mapBaselineToProduct(editor: ProductEditorModel): StorageEmissions {
    const baseline = editor.baselineEmissions as StorageEmissions;
    return this.toPersistedEmiss(baseline);
  }

  private mapProjectToProduct(editor: ProductEditorModel): StorageEmissions {
    const baseline = editor.baselineEmissions as StorageEmissions;
    const project = editor.projectEmissions as StorageEmissions;

    project.powerProduced = Coerce.toObj(baseline).powerProduced;
    return this.toPersistedEmiss(project);
  }

  private toPersistedEmiss(emissions: StorageEmissions): StorageEmissions {
    return {
      ...emissions,
      ...this.mapNatureToModel(Coerce.toObj(this.coerceToNature(emissions))),
    };
  }

  private coerceToNature(obj: HasEnergyNature): EnergyNature {
    return Coerce.toObj(obj).nature;
  }

  private mapGeneralToProduct(editor: ProductEditorModel): GeneralEmission {
    return editor.general as GeneralEmission;
  }
}

import { Entity } from 'declic-app/models/entity.model';
import { Product } from 'declic-project/models';

export interface ProductState
  extends Produkto,
    ProductUI,
    Omit<Entity, 'id' | 'loaded' | 'name'> {}

export interface ProductUI {
  readonly gettingOne: boolean;
  readonly deletingOne: boolean;
  readonly updatingOne: boolean;
  readonly addingOne: boolean;
  readonly loaded: boolean;
  readonly errorGettingOne: boolean;
  readonly gettingAllFor: Record<string, boolean>;
}

export interface Produkto extends Product {
  readonly projectId: string;
  readonly loaded: boolean;
  readonly valid?: boolean;
}

import amplitudePlugin from '@analytics/amplitude';
import { Inject, Injectable, Provider } from '@angular/core';
import { Analytics, AnalyticsInstance } from 'analytics';
import { Observable, from, of } from 'rxjs';
import { AnalyticsService } from './analytics.base';
import {
  ANALYTICS_CONFIG,
  ANALYTICS_SERVICE,
  AnalyticsConfig,
  AnalyticsEvent,
  LoggedInProps,
  ProductAddedProps,
  ProjectCreatedProps,
  ProjectRanProps,
} from './analytics.tokens';

export function provideAnalyticsTesting(): Provider {
  return { provide: ANALYTICS_SERVICE, useClass: MockedAnalytics };
}

@Injectable()
export class MockedAnalytics implements AnalyticsService {
  trackProjectCreated(properties?: unknown): Observable<void> {
    return of();
  }
  trackProjectRan(properties?: unknown): Observable<void> {
    return of();
  }
  trackProductAdded(properties?: unknown): Observable<void> {
    return of();
  }
  trackProjectAccessed(properties?: unknown): Observable<void> {
    return of();
  }
}

@Injectable()
export class AmplitudeAnalytics implements AnalyticsService {
  private analytics: AnalyticsInstance;

  constructor(@Inject(ANALYTICS_CONFIG) config: AnalyticsConfig) {
    this.analytics = Analytics({
      app: 'declic',
      plugins: [amplitudePlugin({ apiKey: config.amplitudeKey })],
    });
  }

  trackProjectCreated(properties: ProjectCreatedProps): Observable<void> {
    return from(
      this.analytics.track(AnalyticsEvent.PROJECT_CREATED, properties),
    );
  }
  trackProjectRan(properties: ProjectRanProps): Observable<void> {
    return from(this.analytics.track(AnalyticsEvent.PROJECT_RAN, properties));
  }
  trackProductAdded(properties: ProductAddedProps): Observable<void> {
    return from(this.analytics.track(AnalyticsEvent.PRODUCT_USED, properties));
  }
  trackProjectAccessed(properties: LoggedInProps): Observable<void> {
    return from(
      this.analytics.track(AnalyticsEvent.PROJECT_ACCESSED, properties),
    );
  }
}

import {
  AuxiliaryProps,
  ProductAuxiliary,
} from 'declic-app/components/product-auxiliaries';
import {
  AnnualFactor,
  AnnualFactorUtils,
} from 'declic-app/models/annual-factor.model';

import { Coerce } from '../coercion.utils';

type Aux = ProductAuxiliary;
type Props = AuxiliaryProps;
type Factor = AnnualFactor;
export class AuxiliariesPatcher {
  static patch(current: Aux[], next: Aux[]): Aux[] {
    const reducedNext = AuxiliariesPatcher.reduceAuxsByType(next);
    return current.map((aux) =>
      this.applyPatch(aux, reducedNext[String(aux.type)]),
    );
  }

  static applyPatch(curr: Aux, next: Aux): Aux {
    return {
      ...curr,
      props: this.patchProps(curr.props, Coerce.toObj(next).props),
    };
  }

  private static reduceAuxsByType(auxs: Aux[]): Record<string, Aux> {
    return Coerce.toArr(auxs).reduce((acc, curr) => {
      acc[String(curr.type)] = curr;
      return acc;
    }, {});
  }

  private static patchProps(curr: Props, next: Props): Props {
    return Object.entries(Coerce.toObj(curr)).reduce((acc, [key, value]) => {
      acc[key] = this.patchProp(value, Coerce.toObj(next)[key]);
      return acc;
    }, {} as Props);
  }

  private static patchProp(curr: Factor, next: Factor): Factor {
    return this.shouldPatch(curr, next)
      ? { ...next, nature: Coerce.toObj(curr).nature }
      : curr;
  }

  private static shouldPatch(curr: Factor, next: Factor): boolean {
    return [
      next,
      AnnualFactorUtils.shouldPatch(
        Coerce.toObj(curr).inputNature,
        Coerce.toObj(next).inputNature,
      ),
    ].every(Boolean);
  }
}

import { createStore, withProps } from '@ngneat/elf';
import { FeatureFlags } from 'declic-app/types';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

export const featureFlagStore = createStore(
  { name: 'featureFlags' },
  withProps<FeatureFlags>({}),
);

function selectIsAvailable(featureKey: string): Observable<boolean> {
  return featureFlagStore.pipe(
    map((flags) => flags[featureKey]),
    tap((isAvailable) => logMissingFeatureFlag(isAvailable, featureKey)),
  );
}

function isAvailable(featureKey: string): boolean {
  const isAvailable = featureFlagStore.getValue()[featureKey];
  logMissingFeatureFlag(isAvailable, featureKey);
  return isAvailable;
}

function logMissingFeatureFlag(isAvailable: boolean, featureKey: string): void {
  if (isAvailable === undefined) {
    /* eslint-disable-next-line no-console */
    console.error(`Feature (${featureKey}) not found on json.`);
  }
}

export const FeatureFlagQueries = {
  selectIsAvailable,
  isAvailable,
};

import { Coerce } from 'declic-app/common';
import { AnnualFactorValidator } from 'declic-product/validators';

import {
  BaselineEmissions,
  Biomethane,
  CategoryType,
} from '../biomethane-infrastructures/models';
import { BiogasSources, NetworkProjectAlias, ScopeOne } from '../models';
import {
  BiomethaneProjectValidator,
  NetworksValidators,
} from './network-project.validator';
import { NetworksSourcesValidator } from './networks-sources.validator.util';
import { ProjectEmission } from 'declic-app/models';

export class BiomethaneValidator
  implements BiomethaneProjectValidator<Biomethane>
{
  isScope1Valid(project: Biomethane): boolean {
    const scope1 = Coerce.toObj(project.scopeOne as ScopeOne);
    const conditions = [
      NetworksSourcesValidator.validateCombustions(
        Coerce.toObj(scope1.stationaryCombustion).sources,
      ),
      NetworksSourcesValidator.validateFluorinated(
        Coerce.toObj(scope1.mobileCombustion).sources,
      ),
      this.validateBiogas(Coerce.toObj(scope1.ch4GasFlaring).sources),
      NetworksValidators.validateFugidenso(NetworkProjectAlias.BI, scope1),
      this.validateBiogas(Coerce.toObj(scope1.ch4DirectVenting).sources),
      NetworksSourcesValidator.validateCh4(
        Coerce.toObj(scope1.fluorinatedGases).sources,
      ),
    ];

    return conditions.every(Boolean);
  }

  isScope2Valid(project: Biomethane): boolean {
    const conditions = [
      NetworksSourcesValidator.validateElec(
        Coerce.toObj(project.scopeTwo.electricityConsumption).sources,
      ),
    ];

    return conditions.every(Boolean);
  }

  isScope3Valid(project: Biomethane): boolean {
    const scopeThree = Coerce.toObj(project.scopeThree);
    const conditions = [
      NetworksSourcesValidator.validateCombustions(
        Coerce.toObj(scopeThree.stationaryCombustion).sources,
      ),
      NetworksSourcesValidator.validateFluorinated(
        Coerce.toObj(scopeThree.mobileCombustion).sources,
      ),
      NetworksSourcesValidator.validateElec(
        Coerce.toObj(scopeThree.electricityConsumption).sources,
      ),
      NetworksSourcesValidator.validateFluorinated(
        Coerce.toObj(scopeThree.transpoOfSoldProds).sources,
      ),
      NetworksSourcesValidator.validateFluorinated(
        Coerce.toObj(scopeThree.useOfSoldProds).sources,
      ),
      NetworksSourcesValidator.validateFluorinated(
        Coerce.toObj(scopeThree.emissionsOfFeedstock).sources,
      ),
      NetworksSourcesValidator.validateFluorinated(
        Coerce.toObj(scopeThree.upstreamOfSold).sources,
      ),
    ];

    return conditions.every(Boolean);
  }

  isAvoidedEmissionsValid(project: Biomethane): boolean {
    const avoidedEmissions = Coerce.toObj(project.avoidedEmissions);
    const conditions = [
      NetworksSourcesValidator.validateProjectEmissions(
        avoidedEmissions.projectEmissions as ProjectEmission[],
      ),
      NetworksSourcesValidator.validateBaselineEmissions(
        avoidedEmissions.baselineEmissions as BaselineEmissions,
      ),
    ];
    return conditions.every(Boolean);
  }

  validateBiogas(sources: BiogasSources[]) {
    if (!!!Coerce.toArr(sources).length) {
      return true;
    }
    const conditions = [];
    sources.forEach((source) => {
      conditions.push(AnnualFactorValidator.isValid(source.gwp));
      conditions.push(AnnualFactorValidator.isValid(source.volume));
      conditions.push(AnnualFactorValidator.isValid(source.methane));
    });
    return conditions.every(Boolean);
  }

  get validatorMapper(): Record<string, any> {
    return {
      [CategoryType.COMBUSTION]:
        NetworksSourcesValidator.validateCombustions.bind(this),
      [CategoryType.CH4]: this.validateBiogas.bind(this),
      [CategoryType.FLUORINATED]:
        NetworksSourcesValidator.validateCh4.bind(this),
      [CategoryType.MOBILE]:
        NetworksSourcesValidator.validateFluorinated.bind(this),
    };
  }

  displayScope1Icon(project: Biomethane): boolean {
    const {
      stationaryCombustion,
      mobileCombustion,
      ch4GasFlaring,
      ch4DirectFugitive,
      fluorinatedGases,
      ch4DirectVenting,
    } = project.scopeOne;
    const conditions = [
      Coerce.toArr(Coerce.toObj(stationaryCombustion).sources).length > 0,
      Coerce.toArr(Coerce.toObj(mobileCombustion).sources).length > 0,
      Coerce.toArr(Coerce.toObj(ch4GasFlaring).sources).length > 0,
      Coerce.toArr(Coerce.toObj(ch4DirectFugitive).sources).length > 0,
      Coerce.toArr(Coerce.toObj(fluorinatedGases).sources).length > 0,
      Coerce.toArr(Coerce.toObj(ch4DirectVenting).sources).length > 0,
    ];

    return !this.isScope1Valid(project) || conditions.every(Boolean);
  }

  displayScope2Icon(project: Biomethane): boolean {
    const { electricityConsumption } = project.scopeTwo;
    const conditions = [
      Coerce.toArr(Coerce.toObj(electricityConsumption).sources).length > 0,
    ];

    return !this.isScope2Valid(project) || conditions.every(Boolean);
  }

  displayScope3Icon(project: Biomethane): boolean {
    const scopeThree = Coerce.toObj(project).scopeThree;
    const conditions = [
      Coerce.toArr(Coerce.toObj(scopeThree.stationaryCombustion).sources)
        .length > 0,
      Coerce.toArr(Coerce.toObj(scopeThree.mobileCombustion).sources).length >
        0,
      Coerce.toArr(Coerce.toObj(scopeThree.electricityConsumption).sources)
        .length > 0,
      Coerce.toArr(Coerce.toObj(scopeThree.transpoOfSoldProds).sources).length >
        0,
      Coerce.toArr(Coerce.toObj(scopeThree.useOfSoldProds).sources).length > 0,
      Coerce.toArr(Coerce.toObj(scopeThree.emissionsOfFeedstock).sources)
        .length > 0,
    ];

    return !this.isScope3Valid(project) || conditions.every(Boolean);
  }
}

import { HasEnergySoldType } from './energy.types';
import { HasYearlySold } from './equipment.types';
import { TableEntity } from './generic.types';

export type EnergySold = TableEntity & HasYearlySold & HasEnergySoldType;

export type EnergySoldForm = Pick<
  EnergySold,
  'name' | 'yearlySold' | 'energySoldType'
>;

export type GetEnergySoldsRsp = { data: EnergySold[] };

import { NetworkProjectType } from '../models';
import { BiomethaneValidator } from './biomethane.validator';
import { ElectricityValidator } from './elec.validator';
import { GasValidator } from './gas.validator';
import { NetworkProjectValidator } from './network-project.validator';

export class NetworkProjectValidatorFactory {
  static get(type: NetworkProjectType): NetworkProjectValidator {
    return this.validatorMap[type];
  }

  private static get validatorMap(): Record<
    NetworkProjectType,
    NetworkProjectValidator
  > {
    return {
      [NetworkProjectType.BI]: new BiomethaneValidator(),
      [NetworkProjectType.EI]: new ElectricityValidator(),
      [NetworkProjectType.GI]: new GasValidator(),
    };
  }
}

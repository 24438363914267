import { FeatureFlags, GBU_N_YEARS } from 'declic-app/app.tokens';
import { Coerce, DeclicUtils } from 'declic-app/common';
import { FeatureFlagQueries } from 'declic-app/stores';

import { GlobalBusinessUnit } from './project-base.model';
import { Project } from './project.model';

const gbuYearsFn: Partial<
  Record<GlobalBusinessUnit, (p: Project) => number[]>
> = {
  [GlobalBusinessUnit.NETWORKS]: forNetworks,
};

function forNetworks(project: Project): number[] {
  return FeatureFlagQueries.isAvailable(FeatureFlags.NETWORKS_FEATURES)
    ? [getStartYear(), ...GBU_N_YEARS]
    : buildYearsFromPeriod(project);
}

function getStartYear(): number {
  return new Date().getFullYear();
}

function buildYearsFromPeriod(project: Project): number[] {
  const safeProject = Coerce.toObj(project);
  const [from, to] = Coerce.toArr(safeProject.period);
  return DeclicUtils.arrayFromRange(from, to);
}

function getYearsFn(project: Project): (p: Project) => number[] {
  const safeProject = Coerce.toObj(project);
  return gbuYearsFn[safeProject.globalBusinessUnit] || buildYearsFromPeriod;
}

function whatYears(project: Project): number[] {
  return getYearsFn(project)(project);
}

export const ProjectYearsDeterminer = {
  whatYears,
  buildYearsFromPeriod,
};

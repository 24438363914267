import { Coerce } from 'declic-app/common';

export type JustDiff = {
  value: unknown;
  op: 'replace' | 'remove' | 'add';
  path: (string | number)[];
  prevValue?: unknown;
};

function isFalseAlarm(diff: JustDiff): boolean {
  return [
    isReplacedNullOrUndefined(diff),
    isRemovedNullOrUndefined(diff),
    isAddedNullOrUndefined(diff),
    isPatchedYearlyNull(diff),
    concernsAnyOfThePaths(diff),
    directlyConcernsAvoidedBaseline(diff),
  ].some(Boolean);
}

function directlyConcernsAvoidedBaseline(diff: JustDiff): boolean {
  return ['avoidedEmissions', 'baselineEmissions'].every(
    (path, idx) => diff.path[idx] === path,
  );
}

function isReplacedNullOrUndefined(diff: JustDiff): boolean {
  return (
    diff.op === 'replace' &&
    isNullUndefinedNan(diff.value) &&
    isNullUndefinedNan(diff.prevValue)
  );
}

function isRemovedNullOrUndefined(diff: JustDiff): boolean {
  return diff.op === 'remove' && isNullUndefinedNan(diff.prevValue);
}

function isAddedNullOrUndefined(diff: JustDiff): boolean {
  return diff.op === 'add' && isNullUndefinedNan(diff.value);
}

function isPatchedYearlyNull(diff: JustDiff): boolean {
  const values = Coerce.toArr(Coerce.toObj(diff.value)['values']);
  return (
    diff.op === 'replace' &&
    isNullUndefinedNan(Coerce.toObj(values[0])['emissions']) &&
    !isNullUndefinedNan(Coerce.toObj(values[0])['year'])
  );
}

function isNullUndefinedNan(value: unknown): boolean {
  return [undefined, null].includes(value) || Number.isNaN(value);
}

function concernsAnyOfThePaths(diff: JustDiff): boolean {
  return getConcerningPaths().some((path) => diff.path.includes(path));
}

function getConcerningPaths(): string[] {
  return ['refrigerantToggle', 'isAnnual', 'choice', 'isCascading'];
}

export const EditorDiffEngine = {
  isFalseAlarm,
  concernsAnyOfThePaths,
};

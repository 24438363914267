<declic-annual-factor
  [averageOnly]="averageOnly"
  [yearlyOnly]="yearlyOnly"
  [disableToggle]="disableToggle"
  [property]="property"
  [formControl]="control"
  [contractYears]="contractYears"
  (yearlyBlur)="inputBlur.emit()"
  (avgBlur)="inputBlur.emit()"
></declic-annual-factor>

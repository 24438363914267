import { NgModule } from '@angular/core';
import { Route, RouterModule, Routes } from '@angular/router';

import { nonceResolver } from '@declic-shared/resolvers';
import { OKTA_CALLBACK_PATH } from '@declic/env';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { RouteFragments } from './app.tokens';
import { FourOhFourComponent } from './shared/containers/four-oh-four';
import { LoginPageComponent } from './shared/containers/login-page';

const redirectToProjects = (path: string): Route => ({
  path,
  redirectTo: RouteFragments.PROJECTS,
  pathMatch: 'full',
});
const routes: Routes = [
  {
    path: RouteFragments.PROJECTS,
    loadChildren: () =>
      import('./project/project.module').then((module) => module.ProjectModule),
    resolve: [nonceResolver],
    canActivate: [OktaAuthGuard],
  },
  {
    path: 'legacy',
    loadChildren: () =>
      import('./legacy/legacy.module').then((module) => module.LegacyModule),
    resolve: [nonceResolver],
    canActivate: [OktaAuthGuard],
  },
  {
    path: 'documentation',
    loadChildren: () =>
      import('./documentation/documentation.module').then(
        (module) => module.DocumentationModule,
      ),
    resolve: [nonceResolver],
    canActivate: [OktaAuthGuard],
  },
  {
    path: 'assumptions',
    loadChildren: () =>
      import('./assumption/assumption.module').then((m) => m.AssumptionModule),
    resolve: [nonceResolver],
    canActivate: [OktaAuthGuard],
  },
  {
    path: RouteFragments.LOGIN,
    component: LoginPageComponent,
  },
  redirectToProjects('dashboard'),
  redirectToProjects('home'),
  {
    path: '',
    pathMatch: 'full',
    loadComponent: () =>
      import('./components/app-landing').then((m) => m.AppLandingComponent),
  },
  { path: OKTA_CALLBACK_PATH, component: OktaCallbackComponent },
  {
    path: '**',
    component: FourOhFourComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import {
  emissionApproach,
  NatureEnergyProducedType,
  NatureHeatProductionType,
} from 'declic-app/common';
import {
  NetworkProjectType,
  NetworksProject,
} from 'declic-app/networks-project-emissions/models/network-projects.models';
import { Produkto } from 'declic-product/state';

export interface WithHistoricalEmissions<T> {
  readonly historicalEmissions: T;
}

export interface HasNatureOfEnergyAndHeat {
  readonly natureOfEnergy?:
    | NatureEnergyProducedType
    | NatureEnergyProducedType[];
  readonly natureOfHeat?: NatureHeatProductionType;
}

export type GlobalProduct = Produkto | NetworksProject;

export interface Product {
  id: string;
  type: ProductsType;
  emissionsApproach: emissionApproach;
  name?: string;
  baselineJustification?: string;
  projectType?: ProjectType;
}

export type ProductsType = ProductType | NetworkProjectType;

export enum ProductType {
  GPP = 'Green Power Generation',
  GG = 'Green Gas Generation',
  OGSO = 'Onsite Generation (Single Output)',
  CT = 'Cogen & Trigen',
  DHC = 'DHC',
  PL = 'Public Lighting',
  MO = 'Mobility',
  ST = 'Storage',
  OANDM = 'O&M Contract',
  SALES = 'Green Sales',
}

export enum ProjectType {
  BROWN = 'Brownfield',
  GREEN = 'Greenfield',
}

export const ProductTypeAliasMapping = {
  [ProductType.GPP]: 'gpp',
  [ProductType.GG]: 'ggg',
  [ProductType.OGSO]: 'ogso',
  [ProductType.CT]: 'ct',
  [ProductType.DHC]: 'dhc',
  [ProductType.PL]: 'pl',
  [ProductType.MO]: 'mo',
  [ProductType.ST]: 'st',
  [ProductType.OANDM]: 'oandm',
  [ProductType.SALES]: 'sales',
  [NetworkProjectType.BI]: 'bi',
  [NetworkProjectType.GI]: 'gi',
  [NetworkProjectType.EI]: 'ei',
};

export interface ProductDescription {
  name: ProductType;
  description: string;
}

export const AVAILABLE_PRODUCTS_INSTALLATION: ProductDescription[] = [
  {
    name: ProductType.GPP,
    description: ProductTypeAliasMapping[ProductType.GPP],
  },
  {
    name: ProductType.GG,
    description: ProductTypeAliasMapping[ProductType.GG],
  },
  {
    name: ProductType.OGSO,
    description: ProductTypeAliasMapping[ProductType.OGSO],
  },
  {
    name: ProductType.CT,
    description: ProductTypeAliasMapping[ProductType.CT],
  },
  {
    name: ProductType.DHC,
    description: ProductTypeAliasMapping[ProductType.DHC],
  },
  {
    name: ProductType.PL,
    description: ProductTypeAliasMapping[ProductType.PL],
  },
  {
    name: ProductType.MO,
    description: ProductTypeAliasMapping[ProductType.MO],
  },
  {
    name: ProductType.ST,
    description: ProductTypeAliasMapping[ProductType.ST],
  },
];

export const AVAILABLE_PRODUCTS: ProductDescription[] = [
  ...AVAILABLE_PRODUCTS_INSTALLATION,
  {
    name: ProductType.OANDM,
    description: ProductTypeAliasMapping[ProductType.OANDM],
  },
  ...getNewProducts(),
];

function getNewProducts(): ProductDescription[] {
  return [
    {
      name: ProductType.SALES,
      description: ProductTypeAliasMapping[ProductType.SALES],
    },
  ];
}

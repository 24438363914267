import {
  Coerce,
  DeclicUtils,
  mapToEnergySourceBE,
  take1Subscription,
  times100,
} from 'declic-app/common';
import { YearlyEmission } from 'declic-app/models/';
import { AssumptionConstants } from 'declic-assumptions/assumption-constants';
import { Efficiency, EmissionFactor } from 'declic-assumptions/models';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { AssumptionStore } from 'declic-app/assumption/state/assumption.store';
import { filterNil } from '@ngneat/elf';

import { Assumption } from './assumption-model';

export abstract class AssumptionService<T extends Assumption> {
  private store: AssumptionStore;
  private assumptionsManifest: { [name: string]: T } = {};

  constructor(assumptionStore: AssumptionStore) {
    this.store = assumptionStore;
  }

  abstract getAssumptionsJSONFileName(): string;
  abstract getAssumptionsJSONKey(): string;

  loadData(): void {
    const httpGetStream = this.getHttpGetStream();
    httpGetStream.pipe(filterNil(), take(1)).subscribe((data) => {
      this.assumptionsManifest = this.reduceToManifest(
        data[this.getAssumptionsJSONKey()],
      );
    });
  }

  getAssumption(name: string): T {
    return this.assumptionsManifest[name];
  }

  getHttpGetStream(): Observable<T | { [key: string]: T }> {
    return this.store.select(this.getAssumptionsJSONFileName()).pipe(
      filterNil(),
      map((data) => ({ [this.getAssumptionsJSONKey()]: data })),
    ) as Observable<{
      [key: string]: T;
    }>;
  }

  reduceToManifest(assumptions: T[]) {
    return assumptions.reduce((acc, curr) => {
      acc[curr.name] = curr;
      return acc;
    }, {});
  }
}

export abstract class EmissionFactorAssumption extends AssumptionService<EmissionFactor> {
  private getDuration(period: [number, number]) {
    const duration = [];
    for (let i = period[0]; i <= period[1]; i++) {
      duration.push(i);
    }
    return duration;
  }

  computeDirectEmissions(assumptionName: string): number {
    const assumptions = this.getAssumption(assumptionName);
    if (!assumptions) {
      return 0;
    }
    const { co2, ch4, n2o } = assumptions;
    const ratedCo2 = co2 * AssumptionConstants.globalWarmingImpact.co2;
    const ratedCh4 = ch4 * AssumptionConstants.globalWarmingImpact.ch4;
    const ratedN2o = n2o * AssumptionConstants.globalWarmingImpact.n2o;
    const ratedImpact = ratedCh4 + ratedCo2 + ratedN2o;
    const result =
      ratedImpact *
      AssumptionConstants.kjToKwh *
      AssumptionConstants.getReductionLevel();

    return Number(result.toFixed(3));
  }

  getDirectEmissionsForYear(name: string, year: number): number {
    return Coerce.toObj(this.getYearlyDirectEmissions([year, year], name)[0])
      .emissions;
  }

  getTotalEmissionsForYear(name: string, year: number): number {
    return Coerce.toObj(this.getYearlyTotalEmissions([year, year], name)[0])
      .emissions;
  }

  getYearlyDirectEmissions(
    period: [number, number],
    name: string,
  ): YearlyEmission[] {
    const emissions = this.computeDirectEmissions(name);
    return this.getDuration(period).map((year) => ({
      year,
      emissions,
    }));
  }

  getNetworksYearlyDirectEmissions(
    period: number[],
    name: string,
  ): YearlyEmission[] {
    const emissions = this.getDirectEmission(name);
    return period.map((year) => ({
      year,
      emissions,
    }));
  }

  getDirectEmission(name: string) {
    return Coerce.toObj(this.getAssumption(name)).directEmissions;
  }

  getYearlyIndirectEmissions(period: number[], name: string): YearlyEmission[] {
    const assumptions = Coerce.toObj(this.getAssumption(name));
    return period.map((year) => ({
      year,
      emissions: assumptions.indirectEmissions,
    }));
  }

  computeNetworkDirectEmissions(name: string) {
    const assumptions = Coerce.toObj(this.getAssumption(name));
    return assumptions.directEmissions;
  }

  computeTotalEmissions(assumptionName: string): number {
    if (!this.hasIndirectEmissions(assumptionName)) {
      return null;
    }

    const directEmissions = this.computeDirectEmissions(assumptionName);
    const indirectEmissions =
      this.getAssumption(assumptionName).indirectEmissions;
    return DeclicUtils.fixTo3Places(directEmissions + indirectEmissions);
  }

  getYearlyTotalEmissions(
    period: [number, number],
    name: string,
  ): YearlyEmission[] {
    const emissions = this.computeTotalEmissions(name);
    return this.getDuration(period).map((year) => ({
      year,
      emissions,
    }));
  }

  hasIndirectEmissions(assumptionName: string): boolean {
    return !!Coerce.toObj(this.getAssumption(assumptionName)).indirectEmissions;
  }

  getAssumption(name: string): EmissionFactor {
    return super.getAssumption(mapToEnergySourceBE(name));
  }
}

export abstract class EfficiencyAssumption<
  T extends Efficiency,
> extends AssumptionService<T> {
  getConversionEfficiency(name: string): number {
    return times100(
      Coerce.toObj(this.getAssumption(mapToEnergySourceBE(name))).efficiency,
    );
  }

  getYearlyConversionEff(name: string, years: number[]): YearlyEmission[] {
    const efficiency = this.getConversionEfficiency(name);
    return years.map((year) => ({ year, emissions: efficiency }));
  }
}

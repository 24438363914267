/* eslint-disable max-lines */
export enum EnergySourceType {
  CUSTOM = 'Custom',
  POWER_ELECTRIC_MIX = 'Electricity-From power grid',
  LOCAL_GAS_MIX = 'Gas - From the gas grid (natural and green gas depending on geography)',
  CRUDE_OIL = 'Liquid-Crude Oil',
  NATURAL_GAS_LIQUIDS = 'Liquid-Natural Gas Liquids',
  MOTOR_GASOLINE = 'Liquid-Motor Gasoline',
  JET_KEROSENE = 'Liquid-Jet Kerosene',
  OTHER_KEROSENE = 'Liquid-Other Kerosene',
  SHALE_OIL = 'Liquid-Shale Oil',
  GAS_DIESEL_OIL = 'Liquid-Gas/Diesel Oil',
  RESIDUAL_FUEL_OIL = 'Liquid-Residual Oil',
  LIQUEFIED_PETROLEUM_GASES = 'Liquid-Liquefied Petroleum Gases',
  PETROLEUM_COKE = 'Liquid-Petroleum Coke',
  REFINERY_FEEDSTOCKS = 'Liquid-Refinery Feedstocks',
  REFINERY_GAS = 'Liquid-Refinery Gas',
  OTHER_PETROLEUM_PRODUCTS = 'Liquid-Other Petroleum Products',
  ANTHRACITE = 'Solid-Anthracite',
  COKING_COAL = 'Solid-Coking Coal',
  OTHER_BITUMINOUS_COAL = 'Solid-Other Bituminous Coal',
  SUB_BITUMINOUS_COAL = 'Solid-Sub-Bituminous Coal',
  LIGNITE = 'Solid-Lignite',
  OIL_SHALE_AND_TAR_SANDS = 'Solid-Oil Shale and Tar Sands',
  BROWN_COAL_BRIQUETTES = 'Solid-Brown Coal Briquettes',
  GAS_COKE = 'Gas-Gas Coke',
  PEAT = 'Solid-Peat',
  WASTE_OILS = 'Liquid - Waste Oils',
  INDUSTRIAL_WASTES = 'Solid-Waste-Industrial',
  MUNICIPAL_WASTES_NBF = 'Solid-Waste-Municipal (non-biomass fraction)',
  MUNICIPAL_WASTES_BIOMASS_FRACTION_CN = 'Solid-Waste-Municipal (biomass fraction) (sustainable)',
  MUNICIPAL_WASTES_BIOMASS_FRACTION_NCN = 'Solid-Waste-Municipal (biomass fraction) (not sustainable)',
  SOLID_BIOFUELS_WOOD_WOOD_WASTE_CN = 'Solid-Biomass-Wood / Wood Waste (sustainable)',
  SOLID_BIOFUELS_WOOD_WOOD_WASTE_NCN = 'Solid-Biomass-Wood / Wood Waste (not sustainable)',
  SOLID_BIOFUELS_OTHER_PRIMARY_SOLID_BIOMASS_NCN = 'Solid-Biomass-Other Primary Solid Biomass (not sustainable)',
  LIQUID_BIOFUELS_BIOGASOLINE_CN = 'Liquid-Biofuel-Biogasoline (sustainable)',
  LIQUID_BIOFUELS_BIOGASOLINE_NCN = 'Liquid-Biofuel-Biogasoline (not sustainable)',
  LIQUID_BIOFUELS_BIODIESELS_CN = 'Liquid-Biofuel-Biodiesels (sustainable)',
  LIQUID_BIOFUELS_BIODIESELS_NCN = 'Liquid-Biofuel-Biodiesels (not sustainable)',
  LIQUID_BIOFUELS_OTHER_LIQUID_BIOFUELS_CN = 'Liquid-Biofuel-Other Liquid Biomass (sustainable)',
  LIQUID_BIOFUELS_OTHER_LIQUID_BIOFUELS_NCN = 'Liquid-Biofuel-Other Liquid Biomass (not sustainable)',
  GAS_BIOMASS_LANDFILL_GAS_CN = 'Gas-Landfill biogas (sustainable)',
  GAS_BIOMASS_LANDFILL_GAS_NCN = 'Gas-Landfill biogas (not sustainable)',
  GAS_BIOMASS_SLUDGE_GAS_CN = 'Gas-Sludge biogas (sustainable)',
  GAS_BIOMASS_SLUDGE_GAS_NCN = 'Gas-Sludge biogas (not sustainable)',
  GAS_BIOMASS_OTHER_BIOGAS_CN = 'Gas-Other Biogas (sustainable)',
  GAS_BIOMASS_OTHER_BIOGAS_NCN = 'Gas-Other Biogas (not sustainable)',
  GEOTHERMAL = 'Heat-Geothermal',
  SOLAR_THERMAL = 'Heat-Solar Thermal',
  WIND_OFFSHORE = 'Electricity-Wind offshore',
  WIND_ONSHORE = 'Electricity-Wind onshore',
  HYDROELECTRIC = 'Green-Hydroelectric',
  GREEN_HYDROGEN_FROM_WIND = 'Gas-hydrogen (from wind)',
  GREEN_HYDROGEN_FROM_SOLAR = 'Gas-hydrogen (from solar)',
  GREEN_HYDROGEN_FROM_OTHER_REN_SOURCE = 'Gas-hydrogen (from other REN source)',
  SOLAR_PV = 'Electricity-Solar PV',
  NUCLEAR = 'Electricity-Nuclear',
  NATURAL_GAS = 'Natural Gas',
  DIESEL = 'Diesel',
  SULFUR_HEXAFLUORIDE = 'Sulfur hexafluoride',
  BIOMETHANE_PRODUCED = 'Biomethane - Produced',
  BIOMETHANE_NOT_PRODUCED = 'Biomethane - Not Produced',
}

export enum EnergySourceTypeBE {
  CUSTOM = 'Custom',
  POWER_ELECTRIC_MIX = 'Power grid',
  LOCAL_GAS_MIX = 'Local gas mix',
  CRUDE_OIL = 'Liquid FF - Crude Oil',
  NATURAL_GAS_LIQUIDS = 'Liquid FF - Natural Gas Liquids',
  MOTOR_GASOLINE = 'Liquid FF - Motor Gasoline',
  JET_KEROSENE = 'Liquid FF - Jet Kerosene',
  OTHER_KEROSENE = 'Liquid FF - Other Kerosene',
  SHALE_OIL = 'Liquid FF - Shale Oil',
  GAS_DIESEL_OIL = 'Liquid FF - Gas/Diesel Oil',
  RESIDUAL_FUEL_OIL = 'Liquid FF - Residual Fuel Oil',
  LIQUEFIED_PETROLEUM_GASES = 'Liquid FF - Liquefied Petroleum Gases',
  PETROLEUM_COKE = 'Liquid FF - Petroleum Coke',
  REFINERY_FEEDSTOCKS = 'Liquid FF - Refinery Feedstocks',
  REFINERY_GAS = 'Liquid FF - Refinery Gas',
  OTHER_PETROLEUM_PRODUCTS = 'Liquid FF - Other Petroleum Products',
  ANTHRACITE = 'Solid FF - Anthracite',
  COKING_COAL = 'Solid FF - Coking Coal',
  OTHER_BITUMINOUS_COAL = 'Solid FF - Other Bituminous Coal',
  SUB_BITUMINOUS_COAL = 'Solid FF - Sub-Bituminous Coal',
  LIGNITE = 'Solid FF - Lignite',
  OIL_SHALE_AND_TAR_SANDS = 'Solid FF - Oil Shale and Tar Sands',
  BROWN_COAL_BRIQUETTES = 'Solid FF - Brown Coal Briquettes',
  GAS_COKE = 'Gas FF - Gas Coke',
  PEAT = 'Peat',
  WASTE_OILS = 'Waste Oils',
  INDUSTRIAL_WASTES = 'Waste - Industrial',
  MUNICIPAL_WASTES_NBF = 'Waste - Municipal (non-biomass fraction)',
  MUNICIPAL_WASTES_BIOMASS_FRACTION_CN = 'Waste - Municipal (biomass fraction) (carbon neutral)',
  MUNICIPAL_WASTES_BIOMASS_FRACTION_NCN = 'Waste - Municipal (biomass fraction) (not carbon neutral)',
  SOLID_BIOFUELS_WOOD_WOOD_WASTE_CN = 'Solid Biomass - Wood / Wood Waste (carbon neutral)',
  SOLID_BIOFUELS_WOOD_WOOD_WASTE_NCN = 'Solid Biomass - Wood / Wood Waste (not carbon neutral)',
  SOLID_BIOFUELS_OTHER_PRIMARY_SOLID_BIOMASS_NCN = 'Solid Biomass - Other Primary Solid Biomass (not carbon neutral)',
  LIQUID_BIOFUELS_BIOGASOLINE_CN = 'Liquid Biomass - Biogasoline (carbon neutral)',
  LIQUID_BIOFUELS_BIOGASOLINE_NCN = 'Liquid Biomass - Biogasoline (not carbon neutral)',
  LIQUID_BIOFUELS_BIODIESELS_CN = 'Liquid Biomass - Biodiesels (carbon neutral)',
  LIQUID_BIOFUELS_BIODIESELS_NCN = 'Liquid Biomass - Biodiesels (not carbon neutral)',
  LIQUID_BIOFUELS_OTHER_LIQUID_BIOFUELS_CN = 'Liquid Biomass - Other Liquid Biomass (carbon neutral)',
  LIQUID_BIOFUELS_OTHER_LIQUID_BIOFUELS_NCN = 'Liquid Biomass - Other Liquid Biomass (not carbon neutral)',
  GAS_BIOMASS_LANDFILL_GAS_CN = 'Gas Biomass - Landfill Gas (carbon neutral)',
  GAS_BIOMASS_LANDFILL_GAS_NCN = 'Gas Biomass - Landfill Gas (not carbon neutral)',
  GAS_BIOMASS_SLUDGE_GAS_CN = 'Gas Biomass - Sludge Gas (carbon neutral)',
  GAS_BIOMASS_SLUDGE_GAS_NCN = 'Gas Biomass - Sludge Gas (not carbon neutral)',
  GAS_BIOMASS_OTHER_BIOGAS_CN = 'Gas Biomass - Other Biogas (carbon neutral)',
  GAS_BIOMASS_OTHER_BIOGAS_NCN = 'Gas Biomass - Other Biogas (not carbon neutral)',
  GEOTHERMAL = 'Green - Geothermal',
  SOLAR_THERMAL = 'Green - Solar Thermal',
  WIND_OFFSHORE = 'Green - Wind offshore',
  WIND_ONSHORE = 'Green - Wind onshore',
  HYDROELECTRIC = 'Green - Hydroelectric',
  GREEN_HYDROGEN_FROM_WIND = 'Green - hydrogen (from wind)',
  GREEN_HYDROGEN_FROM_SOLAR = 'Green - hydrogen (from solar)',
  GREEN_HYDROGEN_FROM_OTHER_REN_SOURCE = 'Green - hydrogen (from other REN source)',
  SOLAR_PV = 'Green - Solar PV',
  NUCLEAR = 'Nuclear',
  NATURAL_GAS = 'Natural Gas',
  DIESEL = 'Diesel',
  BIOMETHANE_PRODUCED = 'Biomethane - Produced',
  BIOMETHANE_NOT_PRODUCED = 'Biomethane - Not Produced',
}

export type EnergySourceManifest = {
  [productId: string]: EnergySourceType[];
};

export type EnergySourceWithID = { energySource: EnergySourceType; id: string };

export const ENERGYSOURCE = {
  gpp: [
    EnergySourceType.CUSTOM,
    EnergySourceType.SOLID_BIOFUELS_WOOD_WOOD_WASTE_CN,
    EnergySourceType.LIQUID_BIOFUELS_BIOGASOLINE_CN,
    EnergySourceType.LIQUID_BIOFUELS_BIODIESELS_CN,
    EnergySourceType.LIQUID_BIOFUELS_OTHER_LIQUID_BIOFUELS_CN,
    EnergySourceType.GAS_BIOMASS_LANDFILL_GAS_CN,
    EnergySourceType.GAS_BIOMASS_SLUDGE_GAS_CN,
    EnergySourceType.GAS_BIOMASS_OTHER_BIOGAS_CN,
    EnergySourceType.MUNICIPAL_WASTES_BIOMASS_FRACTION_CN,
    EnergySourceType.GEOTHERMAL,
    EnergySourceType.SOLAR_THERMAL,
    EnergySourceType.WIND_OFFSHORE,
    EnergySourceType.WIND_ONSHORE,
    EnergySourceType.HYDROELECTRIC,
    EnergySourceType.GREEN_HYDROGEN_FROM_WIND,
    EnergySourceType.GREEN_HYDROGEN_FROM_SOLAR,
    EnergySourceType.GREEN_HYDROGEN_FROM_OTHER_REN_SOURCE,
    EnergySourceType.SOLAR_PV,
  ],
  gppBaseline: [
    EnergySourceType.CUSTOM,
    EnergySourceType.CRUDE_OIL,
    EnergySourceType.NATURAL_GAS_LIQUIDS,
    EnergySourceType.MOTOR_GASOLINE,
    EnergySourceType.JET_KEROSENE,
    EnergySourceType.OTHER_KEROSENE,
    EnergySourceType.SHALE_OIL,
    EnergySourceType.GAS_DIESEL_OIL,
    EnergySourceType.RESIDUAL_FUEL_OIL,
    EnergySourceType.LIQUEFIED_PETROLEUM_GASES,
    EnergySourceType.PETROLEUM_COKE,
    EnergySourceType.REFINERY_FEEDSTOCKS,
    EnergySourceType.REFINERY_GAS,
    EnergySourceType.OTHER_PETROLEUM_PRODUCTS,
    EnergySourceType.ANTHRACITE,
    EnergySourceType.COKING_COAL,
    EnergySourceType.OTHER_BITUMINOUS_COAL,
    EnergySourceType.SUB_BITUMINOUS_COAL,
    EnergySourceType.LIGNITE,
    EnergySourceType.OIL_SHALE_AND_TAR_SANDS,
    EnergySourceType.BROWN_COAL_BRIQUETTES,
    EnergySourceType.GAS_COKE,
    EnergySourceType.MUNICIPAL_WASTES_NBF,
    EnergySourceType.INDUSTRIAL_WASTES,
    EnergySourceType.WASTE_OILS,
    EnergySourceType.PEAT,
    EnergySourceType.SOLID_BIOFUELS_WOOD_WOOD_WASTE_CN,
    EnergySourceType.SOLID_BIOFUELS_WOOD_WOOD_WASTE_NCN,
    EnergySourceType.SOLID_BIOFUELS_OTHER_PRIMARY_SOLID_BIOMASS_NCN,
    EnergySourceType.LIQUID_BIOFUELS_BIOGASOLINE_CN,
    EnergySourceType.LIQUID_BIOFUELS_BIOGASOLINE_NCN,
    EnergySourceType.LIQUID_BIOFUELS_BIODIESELS_CN,
    EnergySourceType.LIQUID_BIOFUELS_BIODIESELS_NCN,
    EnergySourceType.LIQUID_BIOFUELS_OTHER_LIQUID_BIOFUELS_CN,
    EnergySourceType.LIQUID_BIOFUELS_OTHER_LIQUID_BIOFUELS_NCN,
    EnergySourceType.GAS_BIOMASS_LANDFILL_GAS_CN,
    EnergySourceType.GAS_BIOMASS_LANDFILL_GAS_NCN,
    EnergySourceType.GAS_BIOMASS_SLUDGE_GAS_CN,
    EnergySourceType.GAS_BIOMASS_SLUDGE_GAS_NCN,
    EnergySourceType.GAS_BIOMASS_OTHER_BIOGAS_CN,
    EnergySourceType.GAS_BIOMASS_OTHER_BIOGAS_NCN,
    EnergySourceType.MUNICIPAL_WASTES_BIOMASS_FRACTION_CN,
    EnergySourceType.MUNICIPAL_WASTES_BIOMASS_FRACTION_NCN,
    EnergySourceType.GEOTHERMAL,
    EnergySourceType.SOLAR_THERMAL,
    EnergySourceType.WIND_OFFSHORE,
    EnergySourceType.WIND_ONSHORE,
    EnergySourceType.HYDROELECTRIC,
    EnergySourceType.GREEN_HYDROGEN_FROM_WIND,
    EnergySourceType.GREEN_HYDROGEN_FROM_SOLAR,
    EnergySourceType.GREEN_HYDROGEN_FROM_OTHER_REN_SOURCE,
    EnergySourceType.SOLAR_PV,
    EnergySourceType.NUCLEAR,
  ],
  ggg: [
    EnergySourceType.CUSTOM,
    EnergySourceType.GAS_BIOMASS_LANDFILL_GAS_CN,
    EnergySourceType.GAS_BIOMASS_LANDFILL_GAS_NCN,
    EnergySourceType.GAS_BIOMASS_SLUDGE_GAS_CN,
    EnergySourceType.GAS_BIOMASS_SLUDGE_GAS_NCN,
    EnergySourceType.GAS_BIOMASS_OTHER_BIOGAS_CN,
    EnergySourceType.GAS_BIOMASS_OTHER_BIOGAS_NCN,
    EnergySourceType.GREEN_HYDROGEN_FROM_SOLAR,
    EnergySourceType.GREEN_HYDROGEN_FROM_WIND,
    EnergySourceType.GREEN_HYDROGEN_FROM_OTHER_REN_SOURCE,
  ],
  ogso_ct_oandm_st: [
    EnergySourceType.CUSTOM,
    EnergySourceType.POWER_ELECTRIC_MIX,
    EnergySourceType.LOCAL_GAS_MIX,
    EnergySourceType.CRUDE_OIL,
    EnergySourceType.NATURAL_GAS_LIQUIDS,
    EnergySourceType.MOTOR_GASOLINE,
    EnergySourceType.JET_KEROSENE,
    EnergySourceType.OTHER_KEROSENE,
    EnergySourceType.SHALE_OIL,
    EnergySourceType.GAS_DIESEL_OIL,
    EnergySourceType.RESIDUAL_FUEL_OIL,
    EnergySourceType.LIQUEFIED_PETROLEUM_GASES,
    EnergySourceType.PETROLEUM_COKE,
    EnergySourceType.REFINERY_FEEDSTOCKS,
    EnergySourceType.REFINERY_GAS,
    EnergySourceType.OTHER_PETROLEUM_PRODUCTS,
    EnergySourceType.ANTHRACITE,
    EnergySourceType.COKING_COAL,
    EnergySourceType.OTHER_BITUMINOUS_COAL,
    EnergySourceType.SUB_BITUMINOUS_COAL,
    EnergySourceType.LIGNITE,
    EnergySourceType.OIL_SHALE_AND_TAR_SANDS,
    EnergySourceType.BROWN_COAL_BRIQUETTES,
    EnergySourceType.GAS_COKE,
    EnergySourceType.MUNICIPAL_WASTES_NBF,
    EnergySourceType.INDUSTRIAL_WASTES,
    EnergySourceType.WASTE_OILS,
    EnergySourceType.PEAT,
    EnergySourceType.SOLID_BIOFUELS_WOOD_WOOD_WASTE_CN,
    EnergySourceType.SOLID_BIOFUELS_WOOD_WOOD_WASTE_NCN,
    EnergySourceType.SOLID_BIOFUELS_OTHER_PRIMARY_SOLID_BIOMASS_NCN,
    EnergySourceType.LIQUID_BIOFUELS_BIOGASOLINE_CN,
    EnergySourceType.LIQUID_BIOFUELS_BIOGASOLINE_NCN,
    EnergySourceType.LIQUID_BIOFUELS_BIODIESELS_CN,
    EnergySourceType.LIQUID_BIOFUELS_BIODIESELS_NCN,
    EnergySourceType.LIQUID_BIOFUELS_OTHER_LIQUID_BIOFUELS_CN,
    EnergySourceType.LIQUID_BIOFUELS_OTHER_LIQUID_BIOFUELS_NCN,
    EnergySourceType.GAS_BIOMASS_LANDFILL_GAS_CN,
    EnergySourceType.GAS_BIOMASS_LANDFILL_GAS_NCN,
    EnergySourceType.GAS_BIOMASS_SLUDGE_GAS_CN,
    EnergySourceType.GAS_BIOMASS_SLUDGE_GAS_NCN,
    EnergySourceType.GAS_BIOMASS_OTHER_BIOGAS_CN,
    EnergySourceType.GAS_BIOMASS_OTHER_BIOGAS_NCN,
    EnergySourceType.MUNICIPAL_WASTES_BIOMASS_FRACTION_CN,
    EnergySourceType.MUNICIPAL_WASTES_BIOMASS_FRACTION_NCN,
    EnergySourceType.GEOTHERMAL,
    EnergySourceType.SOLAR_THERMAL,
    EnergySourceType.WIND_OFFSHORE,
    EnergySourceType.WIND_ONSHORE,
    EnergySourceType.HYDROELECTRIC,
    EnergySourceType.GREEN_HYDROGEN_FROM_WIND,
    EnergySourceType.GREEN_HYDROGEN_FROM_SOLAR,
    EnergySourceType.GREEN_HYDROGEN_FROM_OTHER_REN_SOURCE,
    EnergySourceType.SOLAR_PV,
    EnergySourceType.NUCLEAR,
  ],
  pl: [
    EnergySourceType.CUSTOM,
    EnergySourceType.POWER_ELECTRIC_MIX,
    EnergySourceType.MUNICIPAL_WASTES_BIOMASS_FRACTION_CN,
    EnergySourceType.GEOTHERMAL,
    EnergySourceType.SOLAR_THERMAL,
    EnergySourceType.WIND_OFFSHORE,
    EnergySourceType.WIND_ONSHORE,
    EnergySourceType.HYDROELECTRIC,
    EnergySourceType.GREEN_HYDROGEN_FROM_WIND,
    EnergySourceType.GREEN_HYDROGEN_FROM_SOLAR,
    EnergySourceType.GREEN_HYDROGEN_FROM_OTHER_REN_SOURCE,
    EnergySourceType.SOLAR_PV,
  ],
  st: [
    EnergySourceType.CUSTOM,
    EnergySourceType.POWER_ELECTRIC_MIX,
    EnergySourceType.SOLID_BIOFUELS_WOOD_WOOD_WASTE_CN,
    EnergySourceType.LIQUID_BIOFUELS_BIOGASOLINE_CN,
    EnergySourceType.LIQUID_BIOFUELS_BIODIESELS_CN,
    EnergySourceType.LIQUID_BIOFUELS_OTHER_LIQUID_BIOFUELS_CN,
    EnergySourceType.GAS_BIOMASS_LANDFILL_GAS_CN,
    EnergySourceType.GAS_BIOMASS_SLUDGE_GAS_CN,
    EnergySourceType.GAS_BIOMASS_OTHER_BIOGAS_CN,
    EnergySourceType.MUNICIPAL_WASTES_BIOMASS_FRACTION_CN,
    EnergySourceType.GEOTHERMAL,
    EnergySourceType.SOLAR_THERMAL,
    EnergySourceType.WIND_OFFSHORE,
    EnergySourceType.WIND_ONSHORE,
    EnergySourceType.HYDROELECTRIC,
    EnergySourceType.GREEN_HYDROGEN_FROM_WIND,
    EnergySourceType.GREEN_HYDROGEN_FROM_SOLAR,
    EnergySourceType.GREEN_HYDROGEN_FROM_OTHER_REN_SOURCE,
    EnergySourceType.SOLAR_PV,
  ],
  greenSales: [
    EnergySourceType.CUSTOM,
    EnergySourceType.HYDROELECTRIC,
    EnergySourceType.SOLAR_PV,
    EnergySourceType.WIND_OFFSHORE,
    EnergySourceType.WIND_ONSHORE,
    EnergySourceType.GREEN_HYDROGEN_FROM_OTHER_REN_SOURCE,
    EnergySourceType.GREEN_HYDROGEN_FROM_WIND,
    EnergySourceType.GREEN_HYDROGEN_FROM_SOLAR,
    EnergySourceType.GAS_BIOMASS_LANDFILL_GAS_CN,
    EnergySourceType.GAS_BIOMASS_OTHER_BIOGAS_CN,
    EnergySourceType.GAS_BIOMASS_SLUDGE_GAS_CN,
    EnergySourceType.GEOTHERMAL,
    EnergySourceType.SOLAR_THERMAL,
    EnergySourceType.LIQUID_BIOFUELS_BIODIESELS_CN,
    EnergySourceType.LIQUID_BIOFUELS_BIOGASOLINE_CN,
    EnergySourceType.LIQUID_BIOFUELS_OTHER_LIQUID_BIOFUELS_CN,
    EnergySourceType.SOLID_BIOFUELS_WOOD_WOOD_WASTE_CN,
    EnergySourceType.MUNICIPAL_WASTES_BIOMASS_FRACTION_CN,
  ],
};

export enum Volumes {
  ELEC_GRID = 'Electricity Grid',
  ELEC_GREY = 'Electricity Grey',
  ELEC_GREEN = 'Electricity Green',
  ELEC_CUSTOM = 'Electricity Custom',
}

export enum FeedstocksType {
  CUSTOM = 'Custom',
  CC = 'Cover crops',
  CS = 'Corn Sillage',
}

export const CUSTOM_TYPES = [Volumes.ELEC_CUSTOM, EnergySourceType.CUSTOM];

import { Coerce } from 'declic-app/common';
import { getNetworkProjectEditorMapper } from 'declic-app/networks-project-emissions/mappers';
import { Product, ProductType } from 'declic-project/models';

import { CogenTrigenMapper } from './cogen-trigen.mapper';
import { DhcMapper } from './dhc.mapper';
import { GreenGasMapper } from './green-gas.mapper';
import { GreenPowerMapper } from './green-power.mapper';
import { GreenSalesMapper } from './green-sales.mapper';
import { MobilityMapper } from './mobility.mapper';
import { OAndMMapper } from './oandm.mapper';
import { OnsiteGenerationMapper } from './onsite-generation.mapper';
import { FakeProductEditorMapper, ProductEditorMapper } from './product.mapper';
import { PublicLightingMapper } from './public-lighting.mapper';
import { StorageMapper } from './storage.mapper';

const ALL_PRODUCT_EDITOR_MAPPERS: ProductEditorMapper<Product>[] = [
  new GreenPowerMapper(),
  new GreenGasMapper(),
  new OnsiteGenerationMapper(),
  new CogenTrigenMapper(),
  new DhcMapper(),
  new PublicLightingMapper(),
  new OAndMMapper(),
  new MobilityMapper(),
  new StorageMapper(),
  new GreenSalesMapper(),
];

function getEditorMapper<T extends Product>(
  type: ProductType,
): ProductEditorMapper<T> {
  const correctMapper = ALL_PRODUCT_EDITOR_MAPPERS.find(
    (mapper) => mapper.getType() === type,
  ) as ProductEditorMapper<T>;

  return Coerce.toObj(
    correctMapper,
    new FakeProductEditorMapper(),
  ) as ProductEditorMapper<T>;
}

export const ProductMapper = {
  getEditorMapper,
  getNetworkProjectEditorMapper,
};

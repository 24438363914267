import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'declic-info-panel',
  templateUrl: './info-panel.component.html',
  styleUrls: ['./info-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoPanelComponent {
  @Input() severity: InfoSeverity = 'none';

  get severityIconMap(): Record<string, string> {
    return {
      info: 'help_outline',
      warning: 'warning_amber',
      error: 'error_outline',
      none: 'help_outline',
    };
  }
}

export type InfoSeverity = 'info' | 'warning' | 'error' | 'none';

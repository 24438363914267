import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { DataCypressDirectiveModule } from '@declic/directives/data-cypress-directive';
import { DeclicTooltipModule } from '@declic/directives/tooltip';
import { TranslocoModule } from '@ngneat/transloco';

import { OmnibarLayoutComponent } from './omnibar-layout.component';

@NgModule({
  declarations: [OmnibarLayoutComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    MatDividerModule,
    TranslocoModule,
    MatMenuModule,
    MatDividerModule,
    DataCypressDirectiveModule,
    DeclicTooltipModule,
  ],
  exports: [OmnibarLayoutComponent],
})
export class OmnibarLayoutModule {}

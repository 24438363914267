import { Product, ProductType } from 'declic-project/models';
import { GreenPowerValidator } from './green-power.validator';
import { GreenGasValidator } from './green-gas.validator';
import { GreenSalesValidator } from './green-sales.validator';
import { OandMValidator } from './oandm-validator';
import { ProductValidator } from './product.validator';
import { StorageValidator } from './storage.validator';
import { PlValidator } from './pl-validator';
import { OnsiteGenerationSingleOutputValidator } from './ogso-validator';
import { CogenTrigenValidator } from './cogen-trigen.validator';
import { DhdcValidator } from './dhdc.validator';
import { MobilityValidator } from './mobility-validator';

export class NPValidator implements ProductValidator<Product> {
  getType(): ProductType {
    return;
  }
  isProjectValid(): boolean {
    return false;
  }
  isBaselineValid(): boolean {
    return false;
  }
  isHistoricalValid(): boolean {
    return false;
  }
}

export class ProductValidatorFactory {
  static get(type: ProductType): ProductValidator {
    return this.validatorMap[type];
  }

  private static get validatorMap(): Record<ProductType, ProductValidator> {
    return {
      [ProductType.GPP]: new GreenPowerValidator(),
      [ProductType.GG]: new GreenGasValidator(),
      [ProductType.CT]: new CogenTrigenValidator(),
      [ProductType.DHC]: new DhdcValidator(),
      [ProductType.SALES]: new GreenSalesValidator(),
      [ProductType.MO]: new MobilityValidator(),
      [ProductType.OANDM]: new OandMValidator(),
      [ProductType.OGSO]: new OnsiteGenerationSingleOutputValidator(),
      [ProductType.PL]: new PlValidator(),
      [ProductType.ST]: new StorageValidator(),
    };
  }
}

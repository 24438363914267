import { FeatureFlags } from 'declic-app/app.tokens';
import {
  calculationApproachType,
  Coerce,
  NatureEnergyProducedType,
} from 'declic-app/common';
import {
  AnnualFactor,
  AssetRefrigerant,
  EnergySource,
} from 'declic-app/models';
import { FeatureFlagQueries } from 'declic-app/stores';
import { BaselineSituationType } from 'declic-ct-dhc/common';
import { CtDhcEmission } from 'declic-ct-dhc/models';

import { AnnualFactorValidator } from './annual-factor.validator';

export class CtDhcBaselineValidator {
  static isValid(
    ctDhcEmission: CtDhcEmission,
    refrigerant: AssetRefrigerant,
  ): boolean {
    return [
      this.hasNaturesOfEnergy(ctDhcEmission),
      this.hasSources(ctDhcEmission),
      this.isColdValid(ctDhcEmission, refrigerant),
      this.validateBasedOnApproach(ctDhcEmission),
    ].every(Boolean);
  }

  static isColdValid(
    ctDhcEmission: CtDhcEmission,
    refrigerant: AssetRefrigerant,
  ): boolean {
    return [
      !this.isRefRelevant(ctDhcEmission, refrigerant),
      this.isRefValid(refrigerant),
    ].some(Boolean);
  }

  private static isRefValid(refrigerant: AssetRefrigerant): boolean {
    return [
      this.isRefFactorValid(refrigerant),
      this.isLeaksValid(refrigerant),
    ].every(Boolean);
  }

  private static isRefFactorValid(refrigerant: AssetRefrigerant): boolean {
    return AnnualFactorValidator.isValid(
      Coerce.toObj(refrigerant).emissionsFactor,
    );
  }

  private static isLeaksValid(refrigerant: AssetRefrigerant): boolean {
    return [undefined, null].every(
      (value) => value !== Coerce.toObj(refrigerant).leaks,
    );
  }

  private static isRefRelevant(
    ctDhcEmission: CtDhcEmission,
    refrigerant: AssetRefrigerant,
  ): boolean {
    return [
      this.usesCold(ctDhcEmission),
      this.usesRefrigerant(refrigerant),
    ].every(Boolean);
  }

  private static usesCold(emissions: CtDhcEmission): boolean {
    return this.coerceNatures(emissions).includes(
      NatureEnergyProducedType.COLD,
    );
  }

  private static usesRefrigerant(refrigerant: AssetRefrigerant): boolean {
    return Coerce.toObj(refrigerant).useRefrigerant;
  }

  private static coerceNatures(
    emissions: CtDhcEmission,
  ): NatureEnergyProducedType[] {
    return Coerce.toArr(emissions.natureOfEnergy as NatureEnergyProducedType[]);
  }

  private static hasNaturesOfEnergy(ctDhc: CtDhcEmission): boolean {
    return !!this.coerceNatures(ctDhc).length;
  }

  private static validateBasedOnApproach(emissions: CtDhcEmission): boolean {
    return this.isApproachInput(emissions.calculationApproachType)
      ? this.areInputSourcesValid(emissions.energySources)
      : this.validateOutput(emissions);
  }

  private static validateOutput(emissions: CtDhcEmission): boolean {
    return [
      this.areAnnualEnergiesValid(emissions),
      this.validateBasedOnSitch(emissions),
    ].every(Boolean);
  }

  private static areAnnualEnergiesValid(emissions: CtDhcEmission): boolean {
    return this.areFactorsValid(emissions.elecProduced);
  }

  private static validateBasedOnSitch(emissions: CtDhcEmission): boolean {
    return this.isCTDHCSituation(emissions.situation)
      ? this.isBaselineEffValid(emissions)
      : true;
  }

  private static isCTDHCSituation(situation: BaselineSituationType): boolean {
    return [BaselineSituationType.ACT, BaselineSituationType.ADHC].includes(
      situation,
    );
  }

  private static isApproachInput(approach: calculationApproachType): boolean {
    return approach === calculationApproachType.IN;
  }

  private static areInputSourcesValid(sources: EnergySource[]): boolean {
    return sources.every((source) =>
      this.areFactorsValid([source.elecProduced, source.emissionFactor]),
    );
  }

  private static areFactorsValid(factors: AnnualFactor[]): boolean {
    return Coerce.toArr(factors).every((factor) =>
      AnnualFactorValidator.isValid(factor),
    );
  }

  private static hasSources(ctDhc: CtDhcEmission): boolean {
    return [
      !!Coerce.toArr<EnergySource>(ctDhc.energySources).length,
      this.isSourcesValid(ctDhc),
    ].every(Boolean);
  }

  private static isSourcesValid(ctDhc: CtDhcEmission): boolean {
    return Coerce.toArr(ctDhc.energySources)
      .map((energySource) =>
        this.isSourceValid(
          energySource,
          ctDhc.situation,
          ctDhc.calculationApproachType,
        ),
      )
      .every(Boolean);
  }

  private static isSourceValid(
    energySource: EnergySource,
    situation: BaselineSituationType,
    calApproachType: calculationApproachType,
  ): boolean {
    return this.isApproachInput(calApproachType)
      ? this.isSourceInputValid(energySource)
      : this.isSourceOutputValid(energySource, situation);
  }

  private static isSourceInputValid(energySource: EnergySource): boolean {
    return [
      AnnualFactorValidator.isValid(energySource.elecProduced),
      AnnualFactorValidator.isValid(energySource.emissionFactor),
    ].every(Boolean);
  }
  private static isSourceOutputValid(
    energySource: EnergySource,
    situation: BaselineSituationType,
  ): boolean {
    return this.isCTDHCSituation(situation)
      ? this.isACTDHCEnergySourceValid(energySource)
      : this.isSPFEnergySourceValid(energySource);
  }
  private static isACTDHCEnergySourceValid(energySource: EnergySource) {
    return [
      AnnualFactorValidator.isValid(energySource.emissionFactor),
      CtDhcBaselineValidator.isWeightValid(energySource),
    ].every(Boolean);
  }
  private static isWeightValid(energySource: EnergySource): boolean {
    return FeatureFlagQueries.isAvailable(FeatureFlags.WEIGHT_FOR_STORAGE)
      ? AnnualFactorValidator.isValid(energySource.weight)
      : true;
  }
  private static isSPFEnergySourceValid(energySource: EnergySource) {
    return [
      !!energySource.outputEnergy,
      AnnualFactorValidator.isValid(energySource.conversionEfficiency),
      AnnualFactorValidator.isValid(energySource.emissionFactor),
      CtDhcBaselineValidator.isWeightValid(energySource),
    ].every(Boolean);
  }
  private static isBaselineEffValid(emissions: CtDhcEmission): boolean {
    return AnnualFactorValidator.isValid(emissions.conversionEfficiency);
  }
}

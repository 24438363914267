import {
  ProjectForm,
  ProjectInfo,
  ProjectPayload,
  ToLegacyGBUMap,
} from '@declic/types';
import { Coerce } from 'declic-app/common';

function toPayload(form: ProjectForm): ProjectPayload {
  return {
    ...form,
    ...form.info,
    globalBusinessUnit: ToLegacyGBUMap[form.gbu],
    period: buildPeriod(Coerce.toObj(form.info)),
  };
}

function buildPeriod(from: ProjectInfo): [number, number] {
  return [from.startYear, from.startYear + from.duration - 1];
}

export const ProjectMapper = {
  toPayload,
};

/* eslint-disable max-lines*/
import { Coerce, DeclicUtils } from 'declic-app/common';
import { Observable, iif, of } from 'rxjs';
import { finalize, map, mergeMap, take, tap } from 'rxjs/operators';

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createStore, filterNil, withProps } from '@ngneat/elf';
import { withActiveId, withEntities } from '@ngneat/elf-entities';

import { EntityStateStore } from 'declic-app/services/entity-state/entity-state.store';
import { withExporting, withGeneratingReport } from 'declic-app/stores';
import { ProductType } from 'declic-project/models';
import { FootprintSummary } from 'declic-results/models';
import { CalculationService } from './calculation.service';
import {
  AggregatedResults,
  BaselineJustifications,
  CalculationResults,
  CalculationState,
  HasBaselineJustification,
  Result,
} from './calculation.state';

export const calculationStore = createStore(
  { name: 'results' },
  withProps<CalculationState>({} as CalculationState),
  withEntities<CalculationResults>(),
  withActiveId(),
  withExporting(),
  withGeneratingReport(),
);
calculationStore.getValue();
export type CalculationStoreState = typeof calculationStore.state;

@Injectable({
  providedIn: 'root',
})
export class CalculationRepository extends EntityStateStore<
  CalculationStoreState,
  CalculationResults
> {
  constructor(private readonly calculationService: CalculationService) {
    super(calculationStore);
  }

  generateReport(id: string): Observable<unknown> {
    this.setState({ generatingReport: true });
    return this.calculationService
      .generateReport(id)
      .pipe(finalize(() => this.setState({ generatingReport: false })));
  }

  getResults(id: string): void {
    this.asyncGetResults(id)
      .pipe(take(1))
      .subscribe({
        error: (error: HttpErrorResponse) => this.onErrorResponse(error),
      });
  }

  exportResults(id: string): Observable<unknown> {
    this.setState({ exporting: true });
    return this.calculationService
      .exportResults(id)
      .pipe(finalize(() => this.setState({ exporting: false })));
  }

  asyncGetResults(id: string): Observable<CalculationResults> {
    this.startGetResults();
    return this.doGetResultsRequest(id).pipe(
      tap((results) => this.upsertEntity(id, results)),
      tap(() => this.setState({ successGet: true })),
      finalize(() => this.setLoading(false)),
    );
  }

  selectHasResults(projectId: string): Observable<boolean> {
    return this.selectEntity(projectId).pipe(map((results) => !!results));
  }

  selectAggregatedResults(projectId: string): Observable<AggregatedResults> {
    return this.selectNamedResults(projectId).pipe(
      map((results) => DeclicUtils.reduceToDict(results, 'name')),
    );
  }

  selectAggregatedAvoidedResults(
    projectId: string,
  ): Observable<AggregatedResults> {
    return this.selectAvoidedResults(projectId).pipe(
      map((results) => DeclicUtils.reduceToDict(results, 'name')),
    );
  }

  selectEnergyGenerators(projectId: string): Observable<ProductType[]> {
    return this.selectEntity(projectId).pipe(
      map((results) => results.energyGeneratingProducts),
    );
  }

  getEnergyGenerators(projectId: string): ProductType[] {
    return this.getEntity(projectId).energyGeneratingProducts;
  }

  selectActiveJustifications(): Observable<BaselineJustifications> {
    return this.selectTruthyActive().pipe(
      map((results) => results.results),
      map((results) => this.onlyTruthy(results)),
      mergeMap((results) => this.selectAltJusts(results)),
      map((results: HasBaselineJustification[]) =>
        this.reduceBaselineJust(results),
      ),
    );
  }

  selectAltJusts(alleged: unknown[]): Observable<unknown[]> {
    return iif(
      () => !!alleged.length,
      of(alleged),
      this.selectJustificationsFromRootAvoided(),
    );
  }

  private selectJustificationsFromRootAvoided(): Observable<unknown[]> {
    return this.selectTruthyActive().pipe(
      map((results) => results.avoidedEmissions),
      map((results) => this.onlyTruthy(results)),
    );
  }

  selectSummary(projectId: string): Observable<FootprintSummary> {
    return this.selectEntity(projectId).pipe(
      filterNil(),
      map((calc) => calc.summary),
    );
  }

  selectConsolidation(projectId: string): Observable<FootprintSummary> {
    return this.selectEntity(projectId).pipe(
      filterNil(),
      map((calc) => calc.consolidation),
    );
  }

  private onlyTruthy(
    hases: HasBaselineJustification[],
  ): HasBaselineJustification[] {
    return hases.filter((has) => !!has.baselineJustification);
  }

  private reduceBaselineJust(
    hases: HasBaselineJustification[],
  ): BaselineJustifications {
    return hases.reduce((acc, curr) => {
      acc[curr.type] = [
        ...Coerce.toArr(acc[curr.type]),
        curr.baselineJustification,
      ];
      return acc;
    }, {});
  }

  selectTruthyActive(): Observable<CalculationResults> {
    return this.selectActive().pipe(filterNil());
  }

  private selectNamedResults(projectId: string): Observable<Result[]> {
    return this.selectEntity(projectId).pipe(
      filterNil(),
      map((results) => this.calcResultsToNamedResults(results)),
    );
  }

  private selectAvoidedResults(projectId: string): Observable<Result[]> {
    return this.selectEntity(projectId).pipe(
      filterNil(),
      map((results) => this.calcResultsToAvoidedResults(results)),
    );
  }

  // @transaction()
  private startGetResults(): void {
    this.setLoading(true);
    this.setState({ successGet: false });
  }

  private onErrorResponse(err: HttpErrorResponse): void {
    this.setState({ error: DeclicUtils.resolveToEmptyObj(err.error)['error'] });
    this.setState({ error: undefined });
  }

  private doGetResultsRequest(id: string): Observable<CalculationResults> {
    return this.calculationService.doGetResultsRequest(id);
  }

  private calcResultsToNamedResults(results: CalculationResults): Result[] {
    return this.reduceResultsWithNameCount(results.results);
  }

  private calcResultsToAvoidedResults(results: CalculationResults): Result[] {
    return this.reduceResultsWithNameCount(results.avoidedEmissions);
  }

  private reduceResultsWithNameCount(results: Result[]): Result[] {
    const runningCount = {};
    return results.reduce((acc, curr) => {
      acc.push(this.rebuildResultWithNameCount(curr, runningCount[curr.type]));
      this.incrementCount(curr.type, runningCount);
      return acc;
    }, []);
  }

  private rebuildResultWithNameCount(result: Result, count: number): Result {
    return { ...result, name: this.buildNameCount(result.type, count) };
  }

  private buildNameCount(name: string, count: number): string {
    return `${name} ${this.resolveCount(count)}`.trim();
  }

  private resolveCount(count: number): string {
    return count > 0 ? `(${count})` : '';
  }

  private incrementCount(key: string, running: Record<string, number>): void {
    const count = DeclicUtils.resolveToZero(running[key]);
    running[key] = count + 1;
  }
}

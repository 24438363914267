import { EnergySourceType } from './energy-source';

export enum VehicleType {
  SMALL_CAR_DIESEL = 'Small Car - Diesel',
  SMALL_CAR_GASOLINE = 'Small Car - Gasoline',
  SMALL_CAR_HYBRID = 'Small Car - Hybrid',
  SMALL_CAR_PHEV = 'Small Car - PHEV',
  SMALL_CAR_BEV = 'Small Car - BEV',
  MEDIUM_CAR_DIESEL = 'Medium Car - Diesel',
  MED_CAR_GASOLINE = 'Medium Car - Gasoline',
  MED_CAR_HYBRID = 'Medium Car - Hybrid',
  MED_CAR_PHEV = 'Medium Car - PHEV',
  MED_CAR_BEV = 'Medium Car - BEV',
  LARGE_CAR_DIESEL = 'Large Car - Diesel',
  LARGE_CAR_GASOLINE = 'Large Car - Gasoline',
  LARGE_CAR_HYBRID = 'Large Car - Hybrid',
  LARGE_CAR_PHEV = 'Large Car - PHEV',
  LARGE_CAR_BEV = 'Large Car - BEV',
  AVE_CAR_DIESEL = 'Average Car - Diesel',
  AVE_CAR_GASOLINE = 'Average Car - Gasoline',
  AVE_CAR_HYBRID = 'Average Car - Hybrid',
  AVE_CAR_PHEV = 'Average Car - PHEV',
  AVE_CAR_BEV = 'Average Car - BEV',
  MOTORBIKE_SMALL = 'Motorbike - Small',
  MOTORBIKE_MED = 'Motorbike - Medium',
  MOTORBIKE_LARGE = 'Motorbike - Large',
  MOTORBIKE_AVE = 'Motorbike - Average',
  BUS_CNG = 'Bus - CNG',
  BUS_ETHANOL = 'Bus - Ethanol',
  BUS_DIESEL = 'Bus - Diesel',
  BUS_GASOLINE = 'Bus - Gasoline',
  LGV_I_UP_TO_1305_DIESEL = 'LGV / Class I (up to 1.305 tonnes) - Diesel',
  LGV_I_UP_TO_1305_GAS = 'LGV / Class I (up to 1.305 tonnes) - Gasoline',
  LGV_I_UP_TO_1305_BEV = 'LGV / Class I (up to 1.305 tonnes) - BEV',
  LGV_II_UP_TO_174_DIESEL = 'LGV / Class II (1.305 to 1.74 tonnes) - Diesel',
  LGV_II_UP_TO_174_GAS = 'LGV / Class II (1.305 to 1.74 tonnes) - Gasoline',
  LGV_II_UP_TO_174_BEV = 'LGV / Class II (1.305 to 1.74 tonnes) - BEV',
  LGV_III_UP_TO_35_DIESEL = 'LGV / Class III (1.74 to 3.5 tonnes) - Diesel',
  LGV_III_UP_TO_35_GAS = 'LGV / Class III (1.74 to 3.5 tonnes) - Gasoline',
  LGV_III_UP_TO_35_BEV = 'LGV / Class III (1.74 to 3.5 tonnes) - BEV',
  LGV_AVERAGE_DIESEL = 'LGV / Average (up to 3.5 tonnes) - Diesel',
  LGV_AVERAGE_GASOLINE = 'LGV / Average (up to 3.5 tonnes) - Gasoline',
  LGV_AVERAGE_BEV = 'LGV / Average (up to 3.5 tonnes) - BEV',
  HDV_RIGID_UP_TO_75 = 'HDV / Rigid (>3.5 - 7.5 tonnes) - Diesel',
  HDV_RIGID_UP_TO_17 = 'HDV / Rigid (>7.5 tonnes-17 tonnes) - Diesel',
  HDV_RIGID_MORE_THAN_17 = 'HDV / Rigid (>17 tonnes) - Diesel',
  HDV_ALL_RIGIDS = 'HDV / All rigids - Diesel',
  HDV_ARTICULATED_UP_TO_33 = 'HDV / Articulated (>3.5 - 33t) - Diesel',
  HDV_ARTICULATED_MORE_THAN_33 = 'HDV / Articulated (>33t) - Diesel',
  HDV_ALL_ARTICS = 'HDV / All artics - Diesel',
  HDV_ALL_HGVS = 'HDV / All HGVs - Diesel',
  DIESEL = 'Diesel',

  CUSTOM = 'Custom',
}

export enum FuelCategory {
  THERMAL = 'Thermal vehicle',
  BEV = 'BEV',
  PHEV = 'PHEV',
  CUSTOM = 'Custom',
}

export type FuelType =
  | FuelCategory.THERMAL
  | EnergySourceType.POWER_ELECTRIC_MIX
  | EnergySourceType.SOLAR_PV
  | EnergySourceType.WIND_ONSHORE
  | FuelCategory.CUSTOM;

export const CP_FUEL_TYPES: FuelType[] = [
  EnergySourceType.POWER_ELECTRIC_MIX,
  EnergySourceType.SOLAR_PV,
  EnergySourceType.WIND_ONSHORE,
  FuelCategory.CUSTOM,
];

export const FUEL_TYPES: FuelType[] = [FuelCategory.THERMAL, ...CP_FUEL_TYPES];

function isVehicleThermal(vehicle: VehicleType): boolean {
  return !isVehicleAlternative(vehicle) && !isVehicleCustom(vehicle);
}

function isVehicleAlternative(vehicle: VehicleType): boolean {
  const alternativeSources = [FuelCategory.BEV, FuelCategory.PHEV];
  return (
    alternativeSources.some((alt) => vehicle.toString().includes(alt)) &&
    !isVehicleCustom(vehicle)
  );
}

function isVehicleCustom(vehicle: VehicleType): boolean {
  return vehicle === VehicleType.CUSTOM;
}

function isFuelAlternative(fuel: FuelType): boolean {
  const alternativeFuels = [
    EnergySourceType.POWER_ELECTRIC_MIX,
    EnergySourceType.SOLAR_PV,
    EnergySourceType.WIND_ONSHORE,
  ];
  return alternativeFuels.some((alt) => alt === fuel) && !isFuelCustom(fuel);
}

function isFuelThermal(fuel: FuelType): boolean {
  return fuel === FuelCategory.THERMAL;
}

function isFuelCustom(fuel: FuelType): boolean {
  return fuel === FuelCategory.CUSTOM;
}

function isVehicleFuelForThermal(v: VehicleType, f: FuelType): boolean {
  return isVehicleThermal(v) && isFuelThermal(f);
}

export const VehicleAndFuelTypeUtils = {
  isVehicleAlternative,
  isVehicleCustom,
  isVehicleThermal,
  isFuelAlternative,
  isFuelThermal,
  isFuelCustom,
  isVehicleFuelForThermal,
};

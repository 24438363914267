function toObj<T>(obj: T | undefined | null, to: T = {} as T): T {
  return obj || to;
}

function toArr<T>(arr: T[] | undefined | null): T[] {
  return arr || ([] as T[]);
}

function toVoidFn<T>(alleged: ((_: T) => void) | undefined): (_: T) => void {
  return alleged || ((_: T) => {});
}

function toReturningFn<T, U>(
  alleged: ((_: T) => U) | undefined,
  toReturn: U,
): (_: T) => U {
  return alleged || (() => toReturn);
}

function toFn<T, U>(
  alleged: ((_: T) => U) | undefined,
  newFn: ((_: T) => U) | undefined,
): (_: T) => U {
  return alleged || newFn;
}

function toZero(num: number | undefined | null): number {
  return num || 0;
}

function toString(
  str: string | unknown | undefined | null,
  to: string = '',
): string {
  const resolved = str || to;
  return String(resolved);
}

function toNum(
  num: number | unknown | undefined | null,
  to: number = 0,
): number {
  const resolved = num || to;
  return Number(resolved);
}

export const Coerce = {
  toObj,
  toArr,
  toVoidFn,
  toReturningFn,
  toString,
  toNum,
  toZero,
  toFn,
};

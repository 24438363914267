import { Coerce } from 'declic-app/common';
import { EnergyNature } from 'declic-app/components/nature-determiner';
import { ProductEditorModel } from 'declic-product/components/product-editor';
import {
  HasNatureOfEnergyAndHeat,
  Product,
  ProductType,
} from 'declic-project/models';

export abstract class ProductEditorMapper<T extends Product> {
  abstract getType(): ProductType;
  abstract mapProductToEditorProjectEmissions(product: T): unknown;
  abstract mapProductToEditorBaselineEmissions(product: T): unknown;
  abstract mapProductToEditorHistorical(product: T): unknown;

  mapEditorModelToProduct(editorModel: ProductEditorModel): T {
    return {
      ...this.mapToGeneralProps(editorModel),
      ...this.mapToExclusiveProps(editorModel),
    } as T;
  }

  mapToExclusiveProps(editor: ProductEditorModel): Partial<T> {
    return {};
  }

  mapProductToEditorGeneral(product: T): unknown {
    return;
  }

  mapProductToEditorModel(product: T): ProductEditorModel {
    return {
      type: product.type,
      emissionsApproach: product.emissionsApproach,
      baselineJustification: product.baselineJustification,
      projectType: product.projectType,
      general: this.mapProductToEditorGeneral(product),
      projectEmissions: this.mapProductToEditorProjectEmissions(product),
      baselineEmissions: this.mapProductToEditorBaselineEmissions(product),
      historicalEmissions: this.mapProductToEditorHistorical(product),
    };
  }

  mapNatureToModel(energyNature: EnergyNature): HasNatureOfEnergyAndHeat {
    return {
      natureOfEnergy: energyNature.energy,
      natureOfHeat: energyNature.heat,
    };
  }

  mapNatureToForm(model: HasNatureOfEnergyAndHeat): EnergyNature {
    const data = Coerce.toObj(model);
    return {
      energy: data.natureOfEnergy,
      heat: data.natureOfHeat,
    };
  }

  private mapToGeneralProps(editor: ProductEditorModel): Partial<Product> {
    const {
      baselineJustification: baseline,
      type,
      emissionsApproach,
      projectType,
    } = Coerce.toObj(editor);
    return {
      type,
      baselineJustification: baseline,
      emissionsApproach,
      projectType,
    };
  }
}

export class FakeProductEditorMapper extends ProductEditorMapper<Product> {
  getType(): ProductType {
    return;
  }

  mapProductToEditorProjectEmissions(product: Product): unknown {
    return 'emission impossible';
  }
  mapProductToEditorBaselineEmissions(product: Product): unknown {
    return 'emission accomplished';
  }
  mapProductToEditorHistorical(product: Product): unknown {
    return 'emission accomplished';
  }
}

import { GlobalBusinessUnit } from 'declic-project/state/project-base.model';

import { Activity, ESActivity, RActivity, NActivity } from './activity.types';
import { HasType } from './emission-factor.types';
import { HasName, HasNameId, ValueByYear } from './generic.types';

export enum GBU {
  ES = 'es',
  N = 'n',
  R = 'r',
  FLEX_GEN = 'flexGen',
}

export const GBU_WITH_SELECTABLE_KPIS: GBU[] = [GBU.N, GBU.R, GBU.FLEX_GEN];

export const ToLegacyGBUMap: Partial<Record<GBU, GlobalBusinessUnit>> = {
  [GBU.ES]: GlobalBusinessUnit.ENERGY_SOLUTIONS,
  [GBU.N]: GlobalBusinessUnit.NETWORKS,
  [GBU.R]: GlobalBusinessUnit.RENEWABLES,
  [GBU.FLEX_GEN]: GlobalBusinessUnit.FLEX_GEN,
};

export const FromLegacyGBUMap: Partial<Record<GlobalBusinessUnit, GBU>> = {
  [GlobalBusinessUnit.ENERGY_SOLUTIONS]: GBU.ES,
  [GlobalBusinessUnit.NETWORKS]: GBU.N,
  [GlobalBusinessUnit.RENEWABLES]: GBU.R,
};

export const GBU_ACTIVITIES: Record<GBU, Activity[]> = {
  [GBU.ES]: Object.values(ESActivity),
  [GBU.N]: Object.values(NActivity),
  [GBU.R]: Object.values(RActivity),
  [GBU.FLEX_GEN]: [],
};

export type GBUNSourcesRsp = {
  scope1: Record<string, string[]>;
  scope2: Record<string, string[]>;
  scope3: Record<string, string[]>;
};

export type GBUNSource = HasName &
  Readonly<{
    defaultValues: Record<string, ValueByYear>;
  }>;

export type GBUNSourcesWithValuesRsp = {
  scope1: Record<string, GBUNSource[]>;
  scope2: Record<string, GBUNSource[]>;
  scope3: Record<string, GBUNSource[]>;
};

export type GBUNState = {
  sources: GBUNSourcesWithValuesRsp | GBUNSourcesRsp | null;
};

export type GBUESSource = HasType &
  HasNameId &
  Readonly<{
    ef: ValueByYear;
    variant?: string;
  }>;

export type GBUESRefrigerant = HasNameId &
  HasType &
  Readonly<{
    gwp: ValueByYear;
    variant?: string;
  }>;

export type GBUESSourcesRsp = {
  electricities: GBUESSource[];
  inputTypes: GBUESSource[];
  fuels: GBUESSource[];
  refrigerants: GBUESRefrigerant[];
};

export type GBUESSourceKind = GBUESSource & GBUESRefrigerant;
export type GBUESSourceOneKind = GBUESSource | GBUESRefrigerant;

export type GBUESSourceKey = keyof GBUESSourcesRsp;

export type GBUESState = {
  sources: GBUESSourcesRsp | null;
};

import { LoggerService } from 'declic-app/services/logger/logger.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class TimingInterceptor implements HttpInterceptor {
  constructor(private logger: LoggerService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const started = Date.now();
    return next.handle(request).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          const elapsed = Date.now() - started;
          this.logger.debug(
            `Request for ${request.urlWithParams} took ${elapsed} ms.`,
          );
        }
      }),
    );
  }
}

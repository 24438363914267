import { EnergyType, HasEnergyProducedType } from './energy.types';
import {
  BasicEquipment,
  HasAssetEfficiency,
  HasCapacity,
  HasFuels,
  HasYearlyProduction,
  WithMultipleEnergyDetails,
} from './equipment.types';

export type EquipmentEnergy = HasCapacity &
  HasYearlyProduction &
  HasEnergyProducedType;

export type HasEquipmentEnergies = { energies: EquipmentEnergy[] };

export type Cogen = Omit<BasicEquipment, 'energyProducedType'> &
  HasAssetEfficiency &
  HasFuels &
  HasEquipmentEnergies;

export type CogenForm = Pick<
  Cogen,
  'name' | 'assetEfficiency' | 'fuels' | 'energies'
>;

export const COGEN_ENERGY_TYPES: EnergyType[] = [
  EnergyType.HEAT,
  EnergyType.POWER,
];

export type CogenTableRow = Cogen & WithMultipleEnergyDetails;

import { projectLocation } from 'declic-project/components/common';
import { BaseProject } from 'declic-project/state';

import { Activity, HasActivities } from './activity.types';
import { GBU } from './gbu.types';
import { HasId } from './generic.types';

export type HasGlobalBusinessUnit = {
  globalBusinessUnit: GBU;
};

export type HasKPIs = {
  kpies: KPI[];
};

export type ProjectPayload = Pick<
  BaseProject,
  | 'name'
  | 'description'
  | 'location'
  | 'period'
  | 'globalBusinessUnit'
  | 'kpies'
>;

export type ProjectInfo = Readonly<{
  name: string;
  location: projectLocation | null;
  startYear: number | null;
  duration: number | null;
  description: string;
}>;

export enum KPI {
  CO2_FOOTPRINT = 'footprint',
  AVOIDED_EMISSIONS = 'avoided',
  REDUCED_EMISSIONS = 'reduced',
  DIRECT_EMISSIONS = 'direct',
}

export enum SecondaryKPI {
  CARBON_INTENSITY = 'intensity',
  RNR_RATIO = 'rnr',
  GREEN_ENERGY = 'green',
  PERF_SUMMARY = 'performanceSummary',
}

export type DeclicKPI = KPI | SecondaryKPI;

export type KPIPreference = Record<KPI, boolean>;

export type ProjectForm = Readonly<{
  info: ProjectInfo | null;
  gbu: GBU | null;
  activity: Activity | null;
}> &
  HasKPIs;

export const DISABLED_KPIS: Record<GBU, KPI[]> = {
  [GBU.ES]: Object.values(KPI),
  [GBU.FLEX_GEN]: [],
  [GBU.N]: [KPI.DIRECT_EMISSIONS, KPI.REDUCED_EMISSIONS],
  [GBU.R]: [KPI.CO2_FOOTPRINT, KPI.DIRECT_EMISSIONS, KPI.REDUCED_EMISSIONS],
};

export const FIXED_KPIS: Record<GBU, boolean> = {
  [GBU.ES]: true,
  [GBU.FLEX_GEN]: false,
  [GBU.N]: false,
  [GBU.R]: false,
};

export const PREFERRED_KPIS: Record<GBU, KPI[]> = {
  [GBU.ES]: [
    KPI.DIRECT_EMISSIONS,
    KPI.AVOIDED_EMISSIONS,
    KPI.REDUCED_EMISSIONS,
  ],
  [GBU.FLEX_GEN]: [],
  [GBU.N]: [KPI.CO2_FOOTPRINT],
  [GBU.R]: [KPI.AVOIDED_EMISSIONS],
};

export const DisplayedKPIs: Record<GBU, DeclicKPI[]> = {
  [GBU.ES]: [
    SecondaryKPI.PERF_SUMMARY,
    KPI.DIRECT_EMISSIONS,
    SecondaryKPI.CARBON_INTENSITY,
    SecondaryKPI.GREEN_ENERGY,
    SecondaryKPI.RNR_RATIO,
    KPI.AVOIDED_EMISSIONS,
    KPI.REDUCED_EMISSIONS,
  ],
  [GBU.FLEX_GEN]: [],
  [GBU.N]: [KPI.CO2_FOOTPRINT],
  [GBU.R]: [KPI.AVOIDED_EMISSIONS],
};

export const SummaryKPIs: Record<GBU, DeclicKPI[]> = {
  [GBU.ES]: [
    KPI.DIRECT_EMISSIONS,
    SecondaryKPI.CARBON_INTENSITY,
    SecondaryKPI.GREEN_ENERGY,
    SecondaryKPI.RNR_RATIO,
    KPI.AVOIDED_EMISSIONS,
    KPI.REDUCED_EMISSIONS,
  ],
  [GBU.FLEX_GEN]: [],
  [GBU.N]: [KPI.CO2_FOOTPRINT],
  [GBU.R]: [KPI.AVOIDED_EMISSIONS],
};

export type CreatedProject = HasId & HasActivities;

export type WebSocketConfig = Readonly<{
  url: string;
  /* Keep Alive */
  keepAlive?: boolean;
  keepAliveIntervalMs?: number;
  /* Retry */
  retryOnError?: boolean;
  retryOnClose?: boolean;
  maxRetryAttempts?: number;
  retryScalingDuration?: number;
}>;
type Fn = (_: unknown) => void;

export type WebSocketServiceConfig = Readonly<
  Partial<{
    params: { [key: string]: string };
    /* Handlers */
    handler: Fn;
    errorHandler: Fn;
    closeHandler: () => void;
    /* Keep Alive */
    keepAlive: boolean;
    keepAliveIntervalMs: number;
    /* Retry */
    retryOnError: boolean;
    retryOnClose: boolean;
    maxRetryAttempts: number;
    retryScalingDuration: number;
  }>
>;

export enum WebsocketType {
  COMPUTATION_UPDATE = 'COMPUTATION_UPDATE',
  REPORT_UPDATE = 'REPORT_UPDATE',
  CALIBRATION_STATUS = 'CALIBRATION_STATUS',
}

<div fxLayout="row" fxLayoutAlign="start start" fxFlex>
  <declic-yearly-input
    fxFlex
    [formControl]="yearlyInputCtrl"
    [averageOnly]="averageOnly"
    [yearlyOnly]="yearlyOnly"
    [disableToggle]="disableToggle"
    [label]="propertyLabel | transloco: { type: property?.extraLabel }"
    [unit]="property?.unit"
    [customUnit]="property?.customUnit"
    [values]="annualForecastCtrl.value.emission"
    [isCascading]="annualForecastCtrl.value.isCascading"
    (yearlyClick)="onYearlyClick()"
    (userInput)="onUserInput()"
  ></declic-yearly-input>
  <div
    data-test="trigger"
    [matMenuTriggerFor]="menu"
    (menuOpened)="onOpen()"
  ></div>
  <mat-menu
    #menu="matMenu"
    overlapTrigger
    xPosition="before"
    (closed)="onYearlyClosed()"
  >
    <declic-annual-forecast
      [formControl]="annualForecastCtrl"
      [opened]="opened$ | async"
      [years]="contractYears || (projectYears$ | async)"
      [readOnly]="isForceDisabled$ | async"
      (userInput)="onUserInput()"
      [isCascading]="annualForecastCtrl.value.isCascading"
      [helpMessageKey]="property?.helpMessageKey"
    ></declic-annual-forecast>
  </mat-menu>
</div>

import { Observable, Subject, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { ComponentType } from '@angular/cdk/portal';
import { AfterViewInit, Directive, OnDestroy, Provider } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  ValidationErrors,
  Validator,
} from '@angular/forms';

import { createCVAProvider, createValidatorProvider } from './utils';

@Directive()
export abstract class BaseControl<T>
  implements ControlValueAccessor, Validator, AfterViewInit, OnDestroy
{
  private hasWrittenValueSubject: Subject<unknown> = new Subject();
  private onChangeFn: (formValue: unknown) => void;
  private propagationSubscription: Subscription;
  hasWrittenValue$: Observable<unknown>;
  isFormEnabled$: Observable<boolean>;

  static createProviders(component: ComponentType<unknown>): Provider[] {
    return [createCVAProvider(component), createValidatorProvider(component)];
  }

  abstract getControl(): AbstractControl;
  abstract auxiliaryOnInit(): void;
  abstract formToParentModel(formVal: T): unknown;

  ngAfterViewInit(): void {
    this.hasWrittenValue$ = this.hasWrittenValueSubject.asObservable();
    this.propagationSubscription = this.subscribeForPropagation();
    this.isFormEnabled$ = this.isFormEnabledStream();

    this.auxiliaryOnInit();
  }
  ngOnDestroy(): void {
    if (this.propagationSubscription) {
      this.propagationSubscription.unsubscribe();
    }
  }

  getControlValueStream(): Observable<T> {
    const control = this.getControl();
    return control.valueChanges.pipe(startWith(control.value));
  }

  validate(_: AbstractControl): ValidationErrors {
    return this.getControl().valid ? null : { invalid: true };
  }
  writeValue(obj: unknown): void {
    obj
      ? this.getControl().patchValue(obj, { emitEvent: this.shouldEmitEvent() })
      : this.resetControl();
    this.hasWrittenValueSubject.next('');
  }
  resetControl(): void {
    this.getControl().reset();
  }
  registerOnChange(fn: () => {}): void {
    this.onChangeFn = fn;
  }
  registerOnTouched(fn: unknown): void {}

  setDisabledState(disabled: boolean): void {
    const control = this.getControl();
    disabled ? control.disable() : control.enable();
  }

  shouldEmitEvent(): boolean {
    return true;
  }

  getChildControlValueChangesStream(name: string): Observable<unknown> {
    const control = this.getControl().get(name);
    return control.valueChanges.pipe(startWith(control.value));
  }

  private subscribeForPropagation(): Subscription {
    return this.getControlValueStream().subscribe((value) =>
      this.onChangeFn ? this.onChangeFn(this.formToParentModel(value)) : {},
    );
  }

  private isFormEnabledStream(): Observable<boolean> {
    return this.getControlValueStream().pipe(
      map(() => this.getControl().enabled),
    );
  }
}

import { AssumptionService } from 'declic-app/assumptions/common';
import { Coerce, DeclicUtils } from 'declic-app/common';
import { YearlyEmission } from 'declic-app/models';
import { ElecEmission } from 'declic-assumptions/models';

import { Injectable } from '@angular/core';
import { AssumptionStore, Electricity } from 'declic-app/assumption/state';

@Injectable()
export class ElectricityService extends AssumptionService<Electricity> {
  constructor(private assumptionStore: AssumptionStore) {
    super(assumptionStore);
  }
  getAssumptionsJSONFileName(): string {
    return 'electricity';
  }
  getAssumptionsJSONKey(): string {
    return 'electricity';
  }

  reduceToManifest(assumptions): Electricity {
    const assumptionObj = {} as Electricity;
    assumptions.forEach((assumption) => {
      const key = this.constructKey(assumption);
      if (!assumptionObj[key]) {
        assumptionObj[key] = {};
      }
      assumptionObj[key] = assumption;
    });
    return assumptionObj;
  }

  getElecEmission(
    country: string,
    elecName: string,
    source: string,
  ): Record<number, number> {
    country = country.toLowerCase();
    const electricityType = elecName.toLowerCase().split(' ')[1];
    const assumption = DeclicUtils.resolveToEmptyObj(
      this.getAssumption(
        this.constructKey({ country, electricityType, source }),
      ),
    );
    return Coerce.toObj(assumption.values);
  }

  private constructKey(obj: {
    country: string;
    electricityType: string;
    source: string;
  }): string {
    const country = obj.country.toLowerCase();
    const electricity = obj.electricityType.toLowerCase();
    const source = obj.source.replace(/ /g, '').toLowerCase();
    return `${country}_${electricity}_${source}`;
  }
}

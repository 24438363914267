import { Coerce } from 'declic-app/common';
import { EnergySource } from 'declic-app/models';
import { GggBaselineFormModel } from 'declic-ggg/components/ggg-baseline';
import { GreenGas, GreenGasBaselineEmissions } from 'declic-ggg/models';
import { ProductEditorModel } from 'declic-product/components/product-editor';
import { ProductType } from 'declic-project/models';
import { ProductEditorMapper } from './product.mapper';

export class GreenGasMapper extends ProductEditorMapper<GreenGas> {
  mapProductToEditorHistorical(product: GreenGas): unknown {
    return;
  }
  getType(): ProductType {
    return ProductType.GG;
  }

  mapEditorModelToProduct(editorModel: ProductEditorModel): GreenGas {
    return {
      ...editorModel,
      id: undefined,
      baselineEmissions: this.mapBaselineToProduct(editorModel),
      projectEmissions: this.mapProjectToProduct(editorModel),
    };
  }

  mapProductToEditorGeneral(product: GreenGas): unknown {
    return undefined;
  }

  mapProductToEditorProjectEmissions(product: GreenGas): EnergySource[] {
    return product.projectEmissions;
  }

  mapProductToEditorBaselineEmissions(product: GreenGas): GggBaselineFormModel {
    const { baselineEmissions } = product;
    const { emissionFactor } = Coerce.toObj(baselineEmissions);
    return {
      emissionFactor,
    };
  }

  private mapBaselineToProduct(
    editor: ProductEditorModel,
  ): GreenGasBaselineEmissions {
    const { baselineEmissions } = editor;
    const { emissionFactor } = baselineEmissions as GggBaselineFormModel;

    return {
      emissionFactor,
    };
  }

  private mapProjectToProduct(editor: ProductEditorModel): EnergySource[] {
    return editor.projectEmissions as EnergySource[];
  }
}

import { Coerce } from 'declic-app/common';
import { DeclicUtils } from 'declic-app/common/utils';
import { AccessRight, CerberusClient } from 'declic-app/services/cerberus';
import { EndpointProvider } from 'declic-app/services/endpoints/endpoint.provider';
import {
  projectUIStore,
  setCreatedProject,
  setCreatedProjectId,
  setLoading,
} from 'declic-app/stores';
import { ProjectUtils } from 'declic-project/services/project.utils';
import { Observable } from 'rxjs';
import { finalize, map, pluck, take, tap } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { JSONifiedProject, JSONifiedShareInfo } from './be-project.model';
import {
  BaseProject,
  EditProjectResponse,
  GetProjectsRsp,
} from './project-base.model';

@Injectable({
  providedIn: 'root',
})
export class ProyektoService {
  constructor(
    private readonly endpoints: EndpointProvider,
    private readonly cerberus: CerberusClient,
    private readonly http: HttpClient,
  ) {}

  create(project: BaseProject): Observable<BaseProject> {
    projectUIStore.update(setLoading(true));
    return this.http
      .post<JSONifiedProject>(this.endpoints.forProjects(), project)
      .pipe(
        take(1),
        map((jsonProj) => this.parseProject(jsonProj)),
        tap((proj) => this.onSuccessfulCreate(proj)),
        finalize(() => projectUIStore.update(setLoading(false))),
      );
  }

  edit(project: BaseProject): Observable<BaseProject> {
    return this.cerberus
      .put<EditProjectResponse>(
        project.id,
        this.endpoints.forProject(project.id),
        project,
      )
      .pipe(
        take(1),
        pluck('updatedProject'),
        map((proj) => this.parseProject(proj)),
      );
  }

  copy(id: string, name: string): Observable<BaseProject> {
    return this.requestCopy(id, name);
  }

  getAll(): Observable<BaseProject[]> {
    return this.http
      .get<GetProjectsRsp>(this.endpoints.forProjects())
      .pipe(map((rsp) => this.parseProjectStrings(rsp.projects)));
  }

  compute(id: string): Observable<BaseProject> {
    return this.http
      .post(this.endpoints.forComputation(id), {})
      .pipe(map((project: JSONifiedProject) => this.parseProject(project)));
  }

  getOne(id: string): Observable<BaseProject> {
    return this.http
      .get<JSONifiedProject>(this.endpoints.forProject(id))
      .pipe(map((rsp) => this.parseProject(rsp)));
  }

  delete(id: string): Observable<unknown> {
    return this.http.delete(this.endpoints.forProject(id));
  }

  share(project: BaseProject): Observable<BaseProject> {
    return this.http
      .put<JSONifiedProject>(this.endpoints.forShareProject(project.id), {
        usersAccess: DeclicUtils.resolveToEmptyArray(project.shareInfo),
      })
      .pipe(map((jsonProj) => this.parseProject(jsonProj)));
  }

  private onSuccessfulCreate(project: BaseProject): void {
    projectUIStore.update(
      setCreatedProjectId(project.id),
      setCreatedProject(project),
    );
  }

  private mapPermission(permission: number): AccessRight {
    const mapper = {
      65535: AccessRight.READ_WRITE,
      1: AccessRight.READ,
    };
    return mapper[permission];
  }

  private parseProject(parsed: JSONifiedProject): any {
    return {
      ...parsed,
      salesforceData: Coerce.toObj(parsed.salesforceData),
      permissions: this.mapPermission(parsed.permissions),
      shareInfo: ProjectUtils.toShareInfo(
        DeclicUtils.resolveToEmptyArray<JSONifiedShareInfo>(
          parsed.shareInfo as JSONifiedShareInfo[],
        ),
      ),
    };
  }

  private requestCopy(id: string, name: string): Observable<BaseProject> {
    return this.http
      .post<BaseProject>(this.endpoints.forDuplicateProject(id), {
        name,
      })
      .pipe(take(1));
  }

  private parseProjectStrings(
    projectStrings: JSONifiedProject[],
  ): BaseProject[] {
    return projectStrings.map((parsed) => {
      return this.parseProject(parsed);
    });
  }
}

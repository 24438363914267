import { createStore } from '@ngneat/elf';
import {
  withCreatedProject,
  withCreatedProjectId,
  withLoading,
} from './factories';

export const projectUIStore = createStore(
  { name: 'projectUI' },
  withLoading(),
  withCreatedProjectId(),
  withCreatedProject(),
);

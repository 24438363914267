/* eslint-disable max-lines*/
import { Coerce } from 'declic-app/common';
import {
  NetworkEditorModel,
  NetworksProject,
} from 'declic-app/networks-project-emissions/models';
import {
  ProductEditorMapper,
  ProductMapper,
} from 'declic-product/services/product-mappers';
import { ProductValidator } from 'declic-product/validators';
import { ProductValidatorFactory } from 'declic-product/validators/product-validator.factory';
import { Product, ProductType, ProjectType } from 'declic-project/models';
import { diff as justDiff } from 'just-diff';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { select } from '@ngneat/elf';

import { EditorDiffEngine, JustDiff } from './editor-diff.engine';
import { EditorRepoUtils } from './editor.repo.utils';
import { AssumptionsTrigger, EditorForm, EditorState } from './editor.state';
import { editorStore } from './editor.store';

editorStore.getValue();
export type EditorStoreState = typeof editorStore.state;

@Injectable({
  providedIn: 'root',
})
export class EditorRepository {
  setState(newState: Partial<EditorState>) {
    editorStore.update((state) => ({ ...state, ...newState }));
  }

  resetEntities() {
    editorStore.reset();
  }

  select(key: string) {
    return editorStore.pipe(select((state) => state[key]));
  }

  updateForm(form: EditorForm): void {
    this.setState({ current: form });
  }

  updateSaved(saved): void {
    this.setState({ saved });
  }

  refreshAssumptions(where: AssumptionsTrigger = AssumptionsTrigger.ALL): void {
    this.setState({ refreshAssumptions: where });
    this.setState({ refreshAssumptions: null });
  }

  hardRefresh(): void {
    this.setState({ hardRefresh: true });
    this.setState({ hardRefresh: false });
  }

  selectHardRefreshRequested(): Observable<unknown> {
    return this.select('hardRefresh').pipe(filter((hard) => hard));
  }

  selectRefreshAssumptions(where: AssumptionsTrigger): Observable<unknown> {
    return this.select('refreshAssumptions').pipe(
      filter((trigger) => trigger === where),
    );
  }

  selectHasUnsavedChanges(): Observable<boolean> {
    return combineLatest([this.select('current'), this.select('saved')]).pipe(
      map(([current, saved]) => [Coerce.toObj(current), Coerce.toObj(saved)]),
      map(([current, saved]) => this.hasUnsavedChanges(saved, current)),
    );
  }

  getValue(): EditorState {
    return editorStore.getValue();
  }

  getHasUnsaved(): boolean {
    return this.hasUnsavedChanges(
      this.getValue().saved,
      this.getValue().current,
    );
  }

  selectCurrentProjectValid(): Observable<boolean> {
    return this.selectCurrentMappedToProduct().pipe(
      map((product) => this.validateProject(product)),
    );
  }

  selectCurrentBaselineValid(): Observable<boolean> {
    return this.selectCurrentMappedToProduct().pipe(
      map((product) => this.validateBaseline(product)),
    );
  }

  selectCurrentHistoricalValid(): Observable<boolean> {
    return this.selectCurrentMappedToProduct().pipe(
      map((product) => this.validateHistorical(product)),
    );
  }

  selectIsCurrentFormValid(): Observable<boolean> {
    return combineLatest([
      this.selectCurrentMappedToProduct().pipe(
        map((product) => this.isValid(product)),
        startWith(false),
      ),
      this.selectCurrentMappedToNetworksProject().pipe(
        map((project) => EditorRepoUtils.isNetworksProjectValid(project)),
        startWith(false),
      ),
    ]).pipe(
      map(
        ([productFormValid, netwroksFormValid]) =>
          productFormValid || netwroksFormValid,
      ),
    );
  }

  selectCurrentMappedToProduct(): Observable<Product> {
    return this.select('current').pipe(
      filter((form) => this.onlyWithTruthyType(form)),
      filter((form) => this.onlyWithTruthyMandatoryDescendants(form)),
      map((form) => this.formModelToProduct(form)),
    );
  }

  selectCurrentMappedToNetworksProject(): Observable<NetworksProject> {
    return this.select('current').pipe(
      filter((form) => this.onlyWithTruthyType(form)),
      filter((form) => this.onlyWithTruthyMandatoryDescendantsNetworks(form)),
    );
  }

  selectCurrentScopeOneValid(): Observable<boolean> {
    return this.selectCurrentMappedToNetworksProject().pipe(
      map((project) => EditorRepoUtils.validateScopeOne(project)),
    );
  }

  selectCurrentScopeTwoValid(): Observable<boolean> {
    return this.selectCurrentMappedToNetworksProject().pipe(
      map((project) => EditorRepoUtils.validateScopeTwo(project)),
    );
  }

  selectCurrentScopeThreeValid(): Observable<boolean> {
    return this.selectCurrentMappedToNetworksProject().pipe(
      map((project) => EditorRepoUtils.validateScopeThree(project)),
    );
  }

  selectDisplayScopeOneIcon(): Observable<boolean> {
    return this.selectCurrentMappedToNetworksProject().pipe(
      map((product) => EditorRepoUtils.displayScopeOneIcon(product)),
    );
  }

  selectDisplayScopeTwoIcon(): Observable<boolean> {
    return this.selectCurrentMappedToNetworksProject().pipe(
      map((product) => EditorRepoUtils.displayScopeTwoIcon(product)),
    );
  }

  selectDisplayScopeThreeIcon(): Observable<boolean> {
    return this.selectCurrentMappedToNetworksProject().pipe(
      map((product) => EditorRepoUtils.displayScopeThreeIcon(product)),
    );
  }

  getCurrentMappedToProduct(): Product {
    return this.formModelToProduct(this.getValue().current);
  }

  private hasUnsavedChanges(prev: EditorForm, next: EditorForm): boolean {
    let diffs = this.getDiffs(prev, next).map((diff) =>
      this.mapWithPrevValue(diff, prev),
    );
    diffs = this.removeFalseAlarms(diffs);
    return this.hasDiffs(diffs);
  }

  private mapWithPrevValue(diff: JustDiff, prev: EditorForm): JustDiff {
    return {
      ...diff,
      prevValue: this.getDiffValue(diff, prev),
    };
  }

  private getDiffValue(diff: JustDiff, form: EditorForm): unknown {
    return diff.path.reduce((acc, key) => acc[key], form);
  }

  private onlyWithTruthyType(form: EditorForm): boolean {
    return !!Coerce.toObj(form).type;
  }

  private onlyWithTruthyMandatoryDescendants(form: EditorForm): boolean {
    return this.getMandatoryDescendants().every((key) => form[key]);
  }

  private getMandatoryDescendants(): (keyof EditorForm)[] {
    return ['projectEmissions', 'baselineEmissions'];
  }

  private onlyWithTruthyMandatoryDescendantsNetworks(
    form: NetworkEditorModel,
  ): boolean {
    return this.getMandatoryDescendantsNetworks().every((key) => form[key]);
  }

  private getMandatoryDescendantsNetworks(): (keyof NetworkEditorModel)[] {
    return ['scopeOne', 'scopeTwo'];
  }

  private formModelToProduct(form: EditorForm): Product {
    return this.getMapper(form).mapEditorModelToProduct(form);
  }

  private validateBaseline(product: Product): boolean {
    return this.getValidator(product).isBaselineValid(product);
  }

  private validateHistorical(product: Product): boolean {
    return [
      !this.isHistoricalRelevant(product),
      this.getValidator(product).isHistoricalValid(product),
    ].some(Boolean);
  }

  private isHistoricalRelevant(product: Product): boolean {
    return product.projectType === ProjectType.BROWN;
  }

  private validateProject(product: Product): boolean {
    return this.getValidator(product).isProjectValid(product);
  }

  private isValid(product: Product): boolean {
    return [
      this.validateProject(product),
      this.validateBaseline(product),
      this.validateHistorical(product),
    ].every(Boolean);
  }

  private getValidator(product: Product): ProductValidator {
    return ProductValidatorFactory.get(product.type as ProductType);
  }

  private getMapper(form: EditorForm): ProductEditorMapper<Product> {
    return ProductMapper.getEditorMapper(form.type as ProductType);
  }

  private getDiffs(prev: EditorForm, next: EditorForm): JustDiff[] {
    return justDiff(prev, next);
  }

  private removeFalseAlarms(diffs: JustDiff[]): JustDiff[] {
    return diffs.filter((diff) => !EditorDiffEngine.isFalseAlarm(diff));
  }

  private hasDiffs(diffs: unknown[]): boolean {
    return diffs.length > 0;
  }
}

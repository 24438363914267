import { EnergySourceType, FeedstocksType, Volumes } from './energy-source';

export enum GasType {
  CH4 = 'CH4',
  CH4_METHANE = 'CH4 (Methane)',
  BIO_CH4 = 'BioCH4',
  Biogas = 'Biogas',
  NATURAL_GAS = 'Natural Gas (CH4)',
  BIOMETHANE = 'Biomethane (BioCH4)',
}

export const NETWORKS_ENERGY_SOURCES = {
  biomethanes: {
    scope1: {
      stationaryCombustion: [
        EnergySourceType.CUSTOM,
        EnergySourceType.NATURAL_GAS,
      ],
      ch4GasFlaring: [GasType.CH4],
      ch4DirectFugitive: [GasType.Biogas],
      ch4DirectVenting: [GasType.CH4],
      fluorinatedGases: [
        EnergySourceType.CUSTOM,
        EnergySourceType.SULFUR_HEXAFLUORIDE,
      ],
      mobileCombustion: [EnergySourceType.CUSTOM, EnergySourceType.DIESEL],
    },
    scope2: {
      electricityConsumption: [
        Volumes.ELEC_GRID,
        Volumes.ELEC_GREY,
        Volumes.ELEC_GREEN,
        Volumes.ELEC_CUSTOM,
      ],
    },
    scope3: {
      useOfSoldProds: [
        EnergySourceType.NATURAL_GAS,
        EnergySourceType.BIOMETHANE_PRODUCED,
      ],
      emissionsFeedstock: [
        FeedstocksType.CUSTOM,
        FeedstocksType.CC,
        FeedstocksType.CS,
      ],
      upstreamOfSold: [EnergySourceType.NATURAL_GAS],
    },
  },
  gasInfra: {
    scope3: {
      useOfSoldProds: [
        EnergySourceType.NATURAL_GAS,
        EnergySourceType.BIOMETHANE_NOT_PRODUCED,
      ],
      upstreamFVF: [GasType.NATURAL_GAS, GasType.BIOMETHANE],
    },
  },
};

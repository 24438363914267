import { AuxiliaryDialogData } from 'declic-app/components/auxiliary-dialog';
import { AuxiliaryProperty } from 'declic-app/components/auxiliary-value';
import { ProductAuxiliariesConfig } from 'declic-app/components/product-auxiliaries/product-auxiliaries.token';

function getTypeLabelKey(
  config: ProductAuxiliariesConfig | AuxiliaryDialogData,
): string {
  return config.typeTranslationKey ?? `labels.type`;
}

function getPropLabelKey(prop: AuxiliaryProperty): string {
  return prop.labelTranslationKey ?? `labels.${prop.name}`;
}

export const AuxUtils = {
  getTypeLabelKey,
  getPropLabelKey,
};

import { HasActivities } from '@declic/types';

import { BaseProject } from './project-base.model';

export type EnergySolution = BaseProject &
  Readonly<{
    salesRef?: string;
    salesforceData?: SalesForceData;
  }> &
  HasActivities;

export interface SalesForceData {
  readonly grow: SalesForceDetail;
}

export interface SalesForceDetail {
  readonly id: string;
  readonly lastSync?: string;
  readonly status: string;
}

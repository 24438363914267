import { Coerce } from 'declic-app/common/coercion.utils';
import { Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';

import {
  ACTIVITY_EQUIPMENTS,
  ActivityInfo,
  ActivitySummary,
  EnergySold,
  Equipment,
  ESActivity,
} from '@declic/types';
import { createStore, filterNil, propsFactory } from '@ngneat/elf';
import {
  entitiesPropsFactory,
  getActiveEntity,
  selectActiveEntity,
  selectEntity,
  withActiveId,
  withEntities,
} from '@ngneat/elf-entities';

import {
  withEditingHistoricalYear,
  withError,
  withLoaded,
  withLoading,
  withRecentlyProcessed,
  withSavingOne,
} from './factories';

export const {
  withSummary,
  selectSummary,
  setSummary,
  resetSummary,
  getSummary,
} = propsFactory('summary', {
  initialValue: null as ActivitySummary,
});

export const { withEnergySoldEntities, energySoldEntitiesRef } =
  entitiesPropsFactory('energySold');

export const {
  withHistoricalEquipmentEntities,
  historicalEquipmentEntitiesRef,
} = entitiesPropsFactory('historicalEquipment');

/* stores */
export const activityEntityStore = createStore(
  { name: 'activityEntity' },
  withEnergySoldEntities<EnergySold>(),
  withLoading(),
  withSavingOne(),
  withError(),
  withRecentlyProcessed(),
);

export const activitySummaryStore = createStore(
  { name: 'projectSummary' },
  withLoading(),
  withSummary({ totalProduction: 12345, heatLosses: 0, coldLosses: 0 }),
  withError(),
);

export const activityStore = createStore(
  { name: 'activity' },
  withEntities<ActivityInfo>(),
  withActiveId(),
  withLoading(),
  withLoaded(),
  withError(),
  withEditingHistoricalYear(),
);

function getActiveEquipments(): Equipment[] {
  return Coerce.toArr(
    ACTIVITY_EQUIPMENTS[
      Coerce.toObj(activityStore.query(getActiveEntity())).type
    ],
  );
}

function getActiveHistoricalYear(): number {
  return activityStore.query(getActiveEntity()).historicalYear;
}

function selectActiveEquipments(): Observable<Equipment[]> {
  return activityStore.pipe(
    selectActiveEntity(),
    filterNil(),
    pluck('type'),
    map((type) => ACTIVITY_EQUIPMENTS[type]),
  );
}

function doesTypeEqual(type: ESActivity) {
  return Coerce.toObj(activityStore.query(getActiveEntity())).type === type;
}

function selectEnergySold(id: string): Observable<EnergySold> {
  return activityEntityStore.pipe(
    selectEntity(id, { ref: energySoldEntitiesRef }),
  );
}

export const ActivityQueries = {
  getActiveEquipments,
  getActiveHistoricalYear,
  selectActiveEquipments,
  doesTypeEqual,
};

export const ActivityEntityQueries = {
  selectEnergySold,
};

import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import { Coerce } from './coercion.utils';

export function isIncludedInList(list: string[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    return !Coerce.toArr(list).includes(control.value)
      ? { invalid: true }
      : null;
  };
}

export function uniquenessValidator(list: string[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    return list.includes(control.value) ? { invalid: true } : null;
  };
}

export function validateAnnualForecast(labelName: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return validateValue(labelName, control.value);
  };
}

function validateValue(labelName: string, value: number) {
  const approachAssumptionFnMap = {
    ['Conversion Efficiency']: validateConversionEfficiency(value),
    ['Emission Factor']: validateEmissionFactor(value),
  };
  return value < 0 ? { invalid: true } : approachAssumptionFnMap[labelName];
}

function validateConversionEfficiency(value: number) {
  return value <= 100 ? null : { invalid: true };
}

function validateEmissionFactor(value: number) {
  return value <= 1 ? null : { invalid: true };
}

export const ONE_TO_100: ValidatorFn[] = [
  Validators.min(1),
  Validators.max(100),
];

export const AT_LEAST_1: ValidatorFn[] = [Validators.min(1)];

export enum Constraint {
  PERCENTAGE,
  ZERO_TO_1,
  HOURS_IN_YEAR,
  INTEGER_ONLY,
}

export type AuxProp = Readonly<{
  name: string;
  unit: string;
  constraints: Constraint[];
  displayOnly: boolean;
  averageOnly: boolean;
}>;

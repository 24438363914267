export enum ProjectStatus {
  CREATE_SUCCESS,
  CREATE_FAILURE,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
  GETALL_SUCCESS,
  GETALL_FAILURE,
  DELETE_SUCCESS,
  DELETE_FAILURE,
}

import { DeclicOkta, EnvStage, environment } from '@declic/env';
import { OktaAuth } from '@okta/okta-auth-js';

export const FeatureFlags = {
  WEIGHT_FOR_STORAGE: 'DEC-1598',
  BUSINESS_REFACTOR: 'DEC-2296',
  NETWORKS_FEATURES: 'DEC-2201',
  UNSAVED_CHANGES_POPUP: 'DEC-2056',
  SALESFORCE_INTEGRATION: 'DEC-2135',
  GPP_REDUCED_EMISSION: 'DEC-1584',
  MAINTENANCE_BANNER: 'DEC-4728',
  UPDATED_FUGITIVE_EMISSION: 'DEC-3699',
};

export const RouteFragments = {
  KPIS: 'kpis',
  OLD_RESULTS: 'results',
  PROJECTS: 'projects',
  ACTIVITIES: 'activities',
  PROJ_EQUIPS: 'projectEquipments',
  HIST_EQUIPS: 'historicalEquipments',
  ENERGY_SOLD: 'energySold',
  LOGIN: 'login',
};

export const EARLIEST_PROJECT_START_YEAR = 2015;
export const LATEST_PROJECT_START_YEAR = 2050;
export const MAX_PROJECT_DURATION = 50;
export const HISTORICAL_RANGE = 5;
export const TIME_TO_WAIT_BEFORE_SAVING = 1000;

export const GBU_N_YEARS = [2025, 2030, 2045];

export const GENERIC_ERROR = 'shit';

export const PREFERRED_DATE_FORMAT = 'MM-dd-yyyy HH:mm';

export const SELECTED_EQUIP_QUERY_PARAM = 'selected';
export const SELECTED_QUERY_PARAM = 'selected';
export const ENERGY_SOLD_ENTITY_TYPE = 'energySold';

export const MODAL_MIN_WIDTH = '720px';
export const MODAL_MAX_WIDTH = '840px';

export const CONFIRM_MODAL_WIDTH = '320px';

/* OKTA */
let issuer = DeclicOkta.issuer;
switch (environment.name) {
  case EnvStage.STAGE:
    issuer = 'https://engie.okta-emea.com/oauth2/default';
    break;
  case EnvStage.PROD:
    issuer = 'https://engie.okta-emea.com/oauth2/default';
    break;
  default:
    break;
}

export const oktaAuth = new OktaAuth({
  issuer: issuer,
  clientId: environment.oktaAuth.clientId,
  scopes: ['openid', 'email', 'profile', 'offline_access'],
  redirectUri: DeclicOkta.redirectUri,
  tokenManager: {
    expireEarlySeconds: 5 * 60, // 5 minutes early
  },
});

export const EXTENDED_CACHE_STALE_TIME = 1000 * 60 * 5;
export const OKTA_NONCE_KEY = 'okta-nonce';

import { BasicActivity, KPI } from '@declic/types';
import { filterNil } from '@ngneat/elf';
import {
  getActiveEntity,
  selectActiveEntity,
  selectEntity,
} from '@ngneat/elf-entities';
import { HISTORICAL_RANGE } from 'declic-app/app.tokens';
import { Coerce } from 'declic-app/common';
import { Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import { ProjectYearsDeterminer } from './project-years.determiner';
import { Project } from './project.model';
import { projectStore } from './project.repository';
import { projectLocation } from '../components/common';

function selectActiveActivities(): Observable<BasicActivity[]> {
  return selectActiveProject().pipe(pluck('activities'));
}

function selectActiveProject(): Observable<Project> {
  return projectStore.pipe(selectActiveEntity(), filterNil());
}

function getActiveActivities(): BasicActivity[] {
  return Coerce.toArr(projectStore.query(getActiveEntity()).activities);
}

function getActiveKpis(): string[] {
  return Coerce.toArr(projectStore.query(getActiveEntity()).kpies);
}

function getActiveLocation(): projectLocation {
  return projectStore.query(getActiveEntity()).location;
}

function selectProject(id: string): Observable<Project> {
  return projectStore.pipe(selectEntity(id), filterNil());
}

function selectActiveYears(): Observable<number[]> {
  return selectActiveProject().pipe(
    map((proj) => ProjectYearsDeterminer.whatYears(proj)),
  );
}

function selectContractYears(): Observable<number[]> {
  return selectActiveProject().pipe(
    map((proj) => ProjectYearsDeterminer.buildYearsFromPeriod(proj)),
  );
}

function isLegacyProject(project: Project): boolean {
  return !Coerce.toArr(project.activities).length;
}

function selectIsActiveLegacy(): Observable<boolean> {
  return selectActiveProject().pipe(map(isLegacyProject));
}

function doesActiveHaveKPI(kpi: KPI): boolean {
  return !!Coerce.toArr(
    Coerce.toObj(projectStore.query(getActiveEntity())).kpies,
  ).includes(kpi);
}

function selectActiveHistoricalYears(): Observable<number[]> {
  return selectActiveProject().pipe(
    map((proj) => Coerce.toArr(proj.period)[0]),
    map((start) => createHistoYears(start)),
  );
}

function createHistoYears(starting: number): number[] {
  let size = HISTORICAL_RANGE;
  return Array.from({ length: size }, (_, i) => starting - i - 1);
}

export const ProjectStoreQueries = {
  selectActiveActivities,
  getActiveActivities,
  getActiveLocation,
  selectActiveYears,
  isLegacyProject,
  selectActiveProject,
  selectProject,
  selectIsActiveLegacy,
  doesActiveHaveKPI,
  selectActiveHistoricalYears,
  selectContractYears,
  getActiveKpis,
};

import { ToggleOption } from 'declic-app/components/toggle';

export enum BaselineSituationType {
  ACT = 'Already a Coge or Trige',
  ADHC = 'Already a DHC',
  SPF = 'Separate production of fluids',
}

export type BaselineSituation = {
  [productId: string]: BaselineSituationType[];
};

export const BASELINESITUATION: BaselineSituation = {
  ct: [BaselineSituationType.ACT, BaselineSituationType.SPF],
};

export const CT_SITUATION_OPTIONS: ToggleOption[] = [
  {
    label: BaselineSituationType.ACT,
    source: undefined /*'/assets/icons/ct.svg'*/,
  },
  {
    label: BaselineSituationType.SPF,
    source: undefined /*'/assets/icons/heat_water.svg'*/,
  },
];

export const DHC_SITUATION_OPTIONS: ToggleOption[] = [
  {
    label: BaselineSituationType.ADHC,
    source: undefined /*'/assets/icons/dhc.svg'*/,
  },
  {
    label: BaselineSituationType.SPF,
    source: undefined /*'/assets/icons/heat_water.svg'*/,
  },
];

export type Position = 'left' | 'center' | 'right';
export type FormFieldErrorMessageMap = Readonly<{
  [module: string]: { [errorKey: string]: string };
}>;

export type FormFieldOption<T> = Readonly<{
  name: string;
  value: T;
  tooltip?: string;
  tooltipPosition?: Position;
}>;

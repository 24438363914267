/* istanbul ignore file */
import { APP_INITIALIZER, ErrorHandler, Provider } from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';

import { environment } from './environments';

const TAG_API_URL = 'api.url';

const initialize = () =>
  Sentry.init({
    dsn: environment.sentryDsn,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,
    environment: environment.name,
  });

const provideErrHandler = (): Provider => ({
  provide: ErrorHandler,
  useValue: Sentry.createErrorHandler(),
});

const provideTracing = (): Provider[] => [
  {
    provide: Sentry.TraceService,
    deps: [Router],
  },
  {
    provide: APP_INITIALIZER,
    useFactory: () => () => {},
    deps: [Sentry.TraceService],
    multi: true,
  },
];

const intentionallyThrowError = () => {
  throw new Error('Intentionally throwing an error');
};

const setTag = (key: string, value: string) => Sentry.setTag(key, value);
const captureException = (err: Error) => Sentry.captureException(err);

export const DeclicSentry = {
  initialize,
  provideErrHandler,
  provideTracing,
  intentionallyThrowError,
  TAG_API_URL,
  setTag,
  captureException,
};

import { OktaAuthService } from 'declic-app/services/okta-auth';
import { PseudoErrService } from 'declic-app/services/pseudo-error';
import { filter, map, Observable, take } from 'rxjs';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { DEV_STAGES, environment, EnvStage } from '@declic/env';
import { filterNil } from '@ngneat/elf';
import { RouterRepository } from '@ngneat/elf-ng-router-store';
import { TranslocoService } from '@ngneat/transloco';

import { OmnibarMasterPage } from './omnibar-layout.model';

@Component({
  selector: 'declic-omnibar-layout',
  templateUrl: './omnibar-layout.component.html',
  styleUrls: ['./omnibar-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OmnibarLayoutComponent implements OnInit {
  private oktaAuth = inject(OktaAuthService);
  private router = inject(Router);
  private pseudoErr = inject(PseudoErrService);
  private transloco = inject(TranslocoService);
  private routerRepo = inject(RouterRepository);

  @Input() masterPages: OmnibarMasterPage[] = [];
  @Input() loggedIn: boolean;
  @Output() logout = new EventEmitter();
  @Output() login = new EventEmitter();
  @Output() language = new EventEmitter();

  loggedIn$: Observable<boolean>;

  get envStage(): EnvStage {
    return environment.name as EnvStage;
  }

  get appVersion(): string {
    return environment.appVersion;
  }

  get isDevEnv(): boolean {
    return DEV_STAGES.includes(this.envStage);
  }

  ngOnInit(): void {
    this.loggedIn$ = this.oktaAuth.selectIsAuthenticated();
  }

  onLogout(): void {
    this.oktaAuth.signOut();
  }

  onLinkClick(page: OmnibarMasterPage) {
    this.router.navigateByUrl(page.url);
  }

  onCatClick(): void {
    this.pseudoErr.getPseudoErr('500').pipe(take(1)).subscribe();
  }

  getTooltip(label: string): string {
    const translated = this.transloco.translate('tooltips.navigation.' + label);
    return translated === 'TBD' ? '' : translated;
  }

  isCurrentRoute(label: string): Observable<boolean> {
    return this.routerRepo.select().pipe(
      filterNil(),
      filter((router) => !!router.state),
      map((router) => router.state.url.includes(label)),
    );
  }
}

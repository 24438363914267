import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { OktaAuthService } from 'declic-app/services/okta-auth';
import { Observable, map } from 'rxjs';

@Component({
  selector: 'declic-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
  private oktaAuth = inject(OktaAuthService);
  private router = inject(Router);

  loggingIn = false;
  loggedIn$: Observable<boolean>;

  ngOnInit(): void {
    this.loggedIn$ = this.oktaAuth
      .selectAuthState()
      .pipe(map((authState) => authState.isAuthenticated));
  }

  onLogin(): void {
    this.loggingIn = true;
    this.oktaAuth.signInWithRedirect();
  }

  onHome(): void {
    this.router.navigate(['home']);
  }

  onLogout(): void {
    this.oktaAuth.signOut();
  }
}

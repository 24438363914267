<div fxLayout="column" [formGroup]="yearlyInputGroup">
  <div
    *ngIf="!averageOnly && !yearlyOnly"
    fxLayout="row"
    fxLayoutAlign="end stretch"
    data-test="modeSwitch"
    [class.yearly-input-err]="hasErr$ | async"
  >
    <div class="nj-segmented-control">
      <button
        class="nj-segmented-control-btn"
        data-test="ave"
        [class.bg-white]="!isAnnualControl.value"
        [disabled]="disableToggle"
        (click)="isAnnualControl.setValue(false)"
      >
        Average
      </button>
      <button
        class="nj-segmented-control-btn"
        data-test="yrly"
        [disabled]="disableToggle"
        [class.bg-white]="isAnnualControl.value"
        (click)="isAnnualControl.setValue(true); onYearlyClick()"
      >
        Yearly
      </button>
    </div>
  </div>
  <mat-form-field
    [class.opacity-disabled]="readonly$ | async"
    floatLabel="always"
  >
    <mat-label>{{ label }}</mat-label>
    <input
      data-test="average"
      declicCommafy
      matInput
      formControlName="averageValue"
      [class.hide-average]="(shouldHideAve && !averageOnly) || yearlyOnly"
      (focus)="onAverageValueFocus()"
      (blur)="onAverageValueBlur()"
    />

    <span matSuffix class="yearly-input-unit" data-test="unit">
      @if (customUnit) {
        <declic-label-maker
          class="yearly-input-unit"
          [parts]="customUnit.parts"
        ></declic-label-maker>
      } @else {
        {{ unit }}
      }
    </span>

    <mat-hint *ngIf="hasErr$ | async" class="missing-values-err">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
        <mat-icon class="missing-values-icon"> error_outline </mat-icon>
        <span>
          {{ 'errors.missingValues' | transloco | titlecase }}
        </span>
      </div>
    </mat-hint>
  </mat-form-field>
  <declic-sparkline
    *ngIf="(shouldHideAve && !averageOnly) || yearlyOnly"
    class="pointer"
    [ngClass]="{ 'less-margin': yearlyOnly }"
    data-test="sparky"
    [points]="sparklinePoints$ | async"
    (click)="onYearlyClick()"
  ></declic-sparkline>
</div>

import { Coerce } from 'declic-app/common';
import { AnnualFactorValidator } from 'declic-product/validators';

import { CategoryType } from '../biomethane-infrastructures/models';
import {
  Gas,
  NetworkProjectAlias,
  ScopeOne,
  UpstreamCH4Source,
} from '../models';
import {
  NetworkProjectValidator,
  NetworksValidators,
} from './network-project.validator';
import { NetworksSourcesValidator } from './networks-sources.validator.util';

export class GasValidator implements NetworkProjectValidator<Gas> {
  isScope1Valid(project: Gas): boolean {
    const scope1 = Coerce.toObj(project.scopeOne as ScopeOne);
    const conditions = [
      NetworksSourcesValidator.validateCombustions(
        Coerce.toObj(scope1.stationaryCombustion).sources,
      ),
      NetworksSourcesValidator.validateFluorinated(
        Coerce.toObj(scope1.mobileCombustion).sources,
      ),
      NetworksSourcesValidator.validateCh4(
        Coerce.toObj(scope1.ch4GasFlaring).sources,
      ),
      NetworksValidators.validateFugidenso(NetworkProjectAlias.GI, scope1),
      NetworksSourcesValidator.validateCh4(
        Coerce.toObj(scope1.ch4DirectVenting).sources,
      ),
      NetworksSourcesValidator.validateCh4(
        Coerce.toObj(scope1.fluorinatedGases).sources,
      ),
    ];

    return conditions.every(Boolean);
  }

  isScope2Valid(project: Gas): boolean {
    const conditions = [
      NetworksSourcesValidator.validateElec(
        Coerce.toObj(project.scopeTwo.electricityConsumption).sources,
      ),
    ];

    return conditions.every(Boolean);
  }

  // eslint-disable-next-line max-lines-per-function
  isScope3Valid(project: Gas): boolean {
    const scopeThree = Coerce.toObj(project.scopeThree);
    const conditions = [
      NetworksSourcesValidator.validateCombustions(
        Coerce.toObj(scopeThree.stationaryCombustion).sources,
      ),
      NetworksSourcesValidator.validateFluorinated(
        Coerce.toObj(scopeThree.mobileCombustion).sources,
      ),
      NetworksSourcesValidator.validateElec(
        Coerce.toObj(scopeThree.electricityConsumption).sources,
      ),
      NetworksSourcesValidator.validateFluorinated(
        Coerce.toObj(scopeThree.transpoOfSoldProds).sources,
      ),
      NetworksSourcesValidator.validateFluorinated(
        Coerce.toObj(scopeThree.useOfSoldProds).sources,
      ),
      NetworksSourcesValidator.validateFluorinated(
        Coerce.toObj(scopeThree.emissionsOfFeedstock).sources,
      ),
      NetworksSourcesValidator.validateFluorinated(
        Coerce.toObj(scopeThree.upstreamOfSold).sources,
      ),
      NetworksSourcesValidator.validateFluorinated(
        Coerce.toObj(scopeThree.upstreamFVF).sources,
      ),
    ];

    return conditions.every(Boolean);
  }

  validateUpstreamCH4(sources: UpstreamCH4Source[]) {
    if (!!!Coerce.toArr(sources).length) {
      return true;
    }
    const conditions = [];
    sources.forEach((source: UpstreamCH4Source) => {
      conditions.push(AnnualFactorValidator.isValid(source.emissionFactor));
      conditions.push(AnnualFactorValidator.isValid(source.volume));
      conditions.push(AnnualFactorValidator.isValid(source.energeticContent));
    });
    return conditions.every(Boolean);
  }

  get validatorMapper(): Record<string, any> {
    return {
      [CategoryType.COMBUSTION]:
        NetworksSourcesValidator.validateCombustions.bind(this),
      [CategoryType.CH4]: NetworksSourcesValidator.validateCh4.bind(this),
      [CategoryType.FLUORINATED]:
        NetworksSourcesValidator.validateCh4.bind(this),
      [CategoryType.MOBILE]:
        NetworksSourcesValidator.validateFluorinated.bind(this),
    };
  }

  displayScope1Icon(project: Gas): boolean {
    const {
      stationaryCombustion,
      mobileCombustion,
      ch4GasFlaring,
      ch4DirectFugitive,
      fluorinatedGases,
      ch4DirectVenting,
    } = project.scopeOne;
    const conditions = [
      Coerce.toArr(Coerce.toObj(stationaryCombustion).sources).length > 0,
      Coerce.toArr(Coerce.toObj(mobileCombustion).sources).length > 0,
      Coerce.toArr(Coerce.toObj(ch4GasFlaring).sources).length > 0,
      Coerce.toArr(Coerce.toObj(ch4DirectFugitive).sources).length > 0,
      Coerce.toArr(Coerce.toObj(fluorinatedGases).sources).length > 0,
      Coerce.toArr(Coerce.toObj(ch4DirectVenting).sources).length > 0,
    ];

    return !this.isScope1Valid(project) || conditions.every(Boolean);
  }

  displayScope2Icon(project: Gas): boolean {
    const { electricityConsumption } = project.scopeTwo;
    const conditions = [
      Coerce.toArr(Coerce.toObj(electricityConsumption).sources).length > 0,
    ];

    return !this.isScope2Valid(project) || conditions.every(Boolean);
  }

  displayScope3Icon(project: Gas): boolean {
    const { transpoOfSoldProds, useOfSoldProds, upstreamFVF } = Coerce.toObj(
      Coerce.toObj(project).scopeThree,
    );
    const conditions = [
      transpoOfSoldProds.sources.length,
      useOfSoldProds.sources.length,
      upstreamFVF.sources.length,
    ];

    return !this.isScope3Valid(project) || conditions.every(Boolean);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { YearlyValuesComponent } from './yearly-values.component';

@NgModule({
  declarations: [YearlyValuesComponent],
  imports: [CommonModule, FlexLayoutModule],
  exports: [YearlyValuesComponent],
})
export class YearlyValuesModule {}

export const UNITS = {
  emissionFactor: 'tCO\u2082e/MWh',
  conversionEfficiency: '%',
  efficiencyRatio: '%',
  vehicleShare: '%',
  annualEnergy: 'MWh/year',
  annualPower: 'MWh/year',
  lampConsumption: 'MWh/lamp/year',
  averagePowerLoad: 'MVA or MW',
  annualFunctioning: 'hours/year',
  distancePerYear: 'km/year',
  efficiencyRate: 'kWh/km',
  vehicleEmissionFactor: 'kgCO\u2082e/km',
  refrigerantEmissionFactor: 'kcC0\u2082e/kg',
  leaks: 'kg/year',
  volume: 'tCH4/year',
  gwp: 'tCO\u2082/tCH4',
  mwhPerYr: 'MWh/year',
  mwh: 'MWh',
  volumeM3: 'm3',
  composition: '%bioCH4',
  energeticContent: 'MWh/tCH4',
  normalCubicMeter: 'Nm3',
  percentMethaneBiogas: '%CH4',
  methLeaks: '%',
};

export const NETWORK_EF_UNITS = {
  stationaryCombustion: 'tCO\u2082e/MWh',
  mobileCombustion: 'tCO\u2082e/l',
  tsf6Volume: 'tSF6/year',
  fluorinatedGases: 'tCO\u2082/t',
  elecVolume: 'MWh/year',
  gwp: 'tC0\u2082/tCH4',
  gef: 'tCO\u2082e/MWh',
  emissionsFeedstock: 'kgCO\u2082/tRM',
  upstreamFVF: 'tCO\u2082e/MWh',
};

export const NETWORK_ENERGY_UNIT = {
  stationaryCombustion: 'MWh/year',
  mobileCombustion: 'l/year',
  fluorinatedGases: 't/year',
  emissionsFeedstock: 'tRM/year',
};

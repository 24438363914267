import { AssumptionService } from 'declic-app/assumptions/common';
import { OtherGases } from 'declic-app/assumptions/models';
import { DeclicUtils } from 'declic-app/common/utils';

import { Injectable } from '@angular/core';
import { AssumptionStore } from 'declic-app/assumption/state';

@Injectable()
export class OtherGasesService extends AssumptionService<OtherGases> {
  constructor(private assumptionStore: AssumptionStore) {
    super(assumptionStore);
  }
  getAssumptionsJSONFileName(): string {
    return 'otherGases';
  }
  getAssumptionsJSONKey(): string {
    return 'other_gases';
  }

  getGwp(name: string): number {
    const assumption = this.resolveAssumption(name);
    return DeclicUtils.resolveToZero(assumption.gwp);
  }

  getEF(name: string): number {
    const assumption = this.resolveAssumption(name);
    return DeclicUtils.resolveToZero(assumption.ef);
  }

  getEnergeticContent(name: string): number {
    const assumption = this.resolveAssumption(name);
    return DeclicUtils.resolveToZero(assumption.energeticContent);
  }

  private resolveAssumption(name: string): OtherGases {
    return DeclicUtils.resolveToEmptyObj(this.getAssumption(name));
  }
}

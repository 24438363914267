import { Coerce, MobilityApproach } from 'declic-app/common';
import { AnnualFactorUtils } from 'declic-app/models';
import { ProductEditorModel } from 'declic-product/components/product-editor';
import { Product, ProductType } from 'declic-project/models';

import { MoEmissionsEditorModel } from '@declic-products/mobility/components/mo-emissions';
import {
  ChargingPointEmissions,
  ChargingPointVehicle,
  MobilityChargingPointBased,
  MobilityProduct,
  MobilityVehiclesBased,
  TraditionalVehicle,
  Vehicle,
} from '@declic-products/mobility/mobility.models';

import { ProductEditorMapper } from './product.mapper';

export class MobilityMapper extends ProductEditorMapper<MobilityProduct> {
  getType(): ProductType {
    return ProductType.MO;
  }
  mapEditorModelToProduct(editorModel: ProductEditorModel): MobilityProduct {
    return editorModel.general === MobilityApproach.CHARGING_POINTS
      ? this.mapToChargingBased(editorModel)
      : this.mapToVehiclesBased(editorModel);
  }
  mapProductToEditorGeneral(product: MobilityProduct): MobilityApproach {
    return product.calculationApproach;
  }
  mapProductToEditorProjectEmissions(
    product: MobilityProduct,
  ): MoEmissionsEditorModel {
    const { projectEmissions } = product;
    const { vehicles, ...projectEmissionsNoVehicles } =
      Coerce.toObj(projectEmissions);
    return {
      vehicles: Coerce.toObj(projectEmissions).vehicles,
      additional: { ...(projectEmissionsNoVehicles as ChargingPointEmissions) },
    };
  }

  mapProductToEditorHistorical(
    product: MobilityProduct,
  ): MoEmissionsEditorModel {
    return product.historicalEmissions;
  }

  mapProductToEditorBaselineEmissions(
    product: MobilityProduct,
  ): MoEmissionsEditorModel {
    return {
      vehicles: Coerce.toObj(Coerce.toObj(product).baselineEmissions).vehicles,
    };
  }

  private mapToChargingBased(
    editorModel: ProductEditorModel,
  ): MobilityChargingPointBased {
    const { baselineEmissions, projectEmissions, historicalEmissions } =
      editorModel;
    const typedBaseline = baselineEmissions as MoEmissionsEditorModel;
    const typedProject = projectEmissions as MoEmissionsEditorModel;
    const typedHistorical = historicalEmissions as MoEmissionsEditorModel;
    return {
      ...this.mapCommonProperties(editorModel),
      projectEmissions: {
        ...typedProject.additional,
        vehicles: typedProject.vehicles as ChargingPointVehicle[],
      },
      baselineEmissions: {
        vehicles: typedBaseline.vehicles as ChargingPointVehicle[],
      },
      calculationApproach: MobilityApproach.CHARGING_POINTS,
      historicalEmissions: {
        vehicles: this.coerceVehiclesToAverage(
          typedHistorical.vehicles,
        ) as ChargingPointVehicle[],
      },
    };
  }

  // eslint-disable-next-line max-lines-per-function
  private mapToVehiclesBased(
    editorModel: ProductEditorModel,
  ): MobilityVehiclesBased {
    const { baselineEmissions, projectEmissions, historicalEmissions } =
      editorModel;
    const typedBaseline = Coerce.toObj(
      baselineEmissions as MoEmissionsEditorModel,
    );
    const typedProject = Coerce.toObj(
      projectEmissions as MoEmissionsEditorModel,
    );
    const typedHistorical = Coerce.toObj(
      historicalEmissions as MoEmissionsEditorModel,
    );
    return {
      ...this.mapCommonProperties(editorModel),
      projectEmissions: {
        vehicles: typedProject.vehicles as TraditionalVehicle[],
      },
      baselineEmissions: {
        vehicles: typedBaseline.vehicles as TraditionalVehicle[],
      },
      historicalEmissions: {
        vehicles: this.coerceVehiclesToAverage(
          typedHistorical.vehicles,
        ) as TraditionalVehicle[],
      },
      calculationApproach: MobilityApproach.VEHICLES,
    };
  }

  private coerceVehiclesToAverage(vehicles: Vehicle[]): Vehicle[] {
    return Coerce.toArr(vehicles).map((vehicle) =>
      this.coerceVehicleToAverage(vehicle),
    );
  }

  private coerceVehicleToAverage(vehicle: Vehicle): Vehicle {
    return {
      ...vehicle,
      efficiencyRate: AnnualFactorUtils.toAverageNature(vehicle.efficiencyRate),
      vehicleEmissionFactor: AnnualFactorUtils.toAverageNature(
        vehicle.vehicleEmissionFactor,
      ),
    };
  }

  private mapCommonProperties(editorModel: ProductEditorModel): Product {
    const { emissionsApproach, type, projectType } = editorModel;
    return {
      emissionsApproach,
      type,
      id: undefined,
      projectType,
    };
  }
}

import { ToggleOption } from 'declic-app/components/toggle';

export enum PlLampType {
  LED = 'LEDs',
  Incandescent = 'Incandescent',
}

export const PL_LAMP_TYPE_OPTIONS: ToggleOption[] = [
  {
    label: PlLampType.Incandescent,
    source: undefined /*'/assets/icons/ct.svg'*/,
  },
  { label: PlLampType.LED, source: undefined /*'/assets/icons/led.svg'*/ },
];

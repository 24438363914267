import { Coerce } from 'declic-app/common/coercion.utils';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  ActivitySummary,
  HistoricalEquipment,
  HistoricalEquipmentForm,
} from '@declic/types';
import { filterNil } from '@ngneat/elf';
import { selectActiveEntity, selectAllEntities } from '@ngneat/elf-entities';

import { activitySummaryStore, selectSummary } from './activities.store';
import { historicalEquipmentStore } from './equipment.store';

function selectActiveInForm(): Observable<HistoricalEquipmentForm> {
  return selectTruthyActive().pipe(map((e) => toForm(e)));
}

function selectTruthyActive(): Observable<HistoricalEquipment> {
  return historicalEquipmentStore.pipe(selectActiveEntity(), filterNil());
}

function toForm(equipment: HistoricalEquipment): HistoricalEquipmentForm {
  return {
    ...equipment,
    fuel: { type: equipment.fuel, lifecycleEf: equipment.emissionsFactor },
  };
}

function toPersistence(form: HistoricalEquipmentForm): HistoricalEquipment {
  const { lifecycleEf: lifecycleEF, type } = Coerce.toObj(
    Coerce.toObj(form).fuel,
  );
  return {
    ...form,
    emissionsFactor: lifecycleEF,
    fuel: type,
  } as HistoricalEquipment;
}

function selectHistoricalMoreThanActivityProd(): Observable<boolean> {
  return combineLatest([
    selectAllProductions(),
    activitySummaryStore.pipe(selectSummary()),
  ]).pipe(map(([prods, summary]) => totalExceedsTotal(prods, summary)));
}

function totalExceedsTotal(prods: number[], summary: ActivitySummary): boolean {
  return sumProds(prods) > summary.totalProduction;
}

function sumProds(prods: number[]): number {
  return prods.reduce((acc, curr) => acc + curr, 0);
}

function selectAllProductions(): Observable<number[]> {
  return historicalEquipmentStore.pipe(
    selectAllEntities(),
    map((ents) => toProductions(ents)),
  );
}

function toProductions(ents: HistoricalEquipment[]): number[] {
  return ents.map(
    (e) =>
      Coerce.toNum(e.productionHeat) +
      Coerce.toNum(e.productionCold) +
      Coerce.toNum(e.productionPower),
  );
}

export const HistoEquipQueries = {
  selectActiveInForm,
  toPersistence,
  selectHistoricalMoreThanActivityProd,
};

import {
    BasicEquipment,
    HasCapacity,
    HasCOP,
    HasInputs,
    HasFirstYearValue,
    HasRefrigerants,
    HasYearlyProduction,
  } from './equipment.types';
  import { HasActivityId } from './generic.types';
  
  export type Chiller = BasicEquipment &
    HasActivityId &
    HasFirstYearValue &
    HasYearlyProduction &
    HasCapacity &
    HasInputs &
    HasRefrigerants &
    HasCOP;
  
  export type ChillerForm = Pick<
    Chiller,
    'name' | 'cop' | 'capacity' | 'yearlyProduction' | 'inputTypes' | 'refrigerants'
  >;
  
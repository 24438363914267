import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DataCypressDirectiveModule } from '@declic/directives/data-cypress-directive';
import { LoginPageComponent } from './login-page.component';

@NgModule({
  declarations: [LoginPageComponent],
  imports: [CommonModule, FlexLayoutModule, DataCypressDirectiveModule],
  exports: [LoginPageComponent],
})
export class LoginPageModule {}

import { projectLocation } from 'declic-project/components/common';

import { ComputationStatus } from '../services';
import { ProductsType } from './product.model';

export interface Project {
  id: string;
  name?: string;
  globalBusinessUnit?: string;
  location?: projectLocation;
  period?: [number, number];
  shareInfo?: ProjectShareInfo[];
  status?: ComputationStatus;
  date?: string;
  owner?: Owner;
  products?: ProductsType[];
  description?: string;
  salesRef?: string;
  installation?: boolean;
  businessUnit?: string;
  reportStatus?: string;
}

export interface ProjectShareInfo {
  readonly userId: string;
  readonly permissions?: number;
  readonly role?: string;
  readonly firstName?: string;
  readonly lastName?: string;
  readonly email?: string;
}

export interface Owner {
  readonly userId: string;
  readonly userName?: string;
}

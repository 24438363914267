import { Coerce, MobilityApproach } from 'declic-app/common';
import {
  ChargingPointEmissions,
  ChargingPointVehicle,
  MobilityChargingPointBased,
  MobilityProduct,
  TraditionalEmissions,
} from 'declic-app/mobility/mobility.models';
import { ProductType } from 'declic-project/models';

import { AnnualFactorValidator } from './annual-factor.validator';
import { ProductValidator } from './product.validator';

export class MobilityValidator implements ProductValidator<MobilityProduct> {
  getType(): ProductType {
    return ProductType.MO;
  }

  validateChargingPointVehicle(
    emissions: ChargingPointEmissions,
    conditions: boolean[],
  ) {
    Coerce.toArr(emissions.vehicles).forEach((v: ChargingPointVehicle) => {
      conditions.push(!!v.fuelType);
      conditions.push(!!v.type);
      conditions.push(AnnualFactorValidator.isValid(v.efficiencyRate));
      conditions.push(AnnualFactorValidator.isValid(v.share));
      conditions.push(AnnualFactorValidator.isValid(v.vehicleEmissionFactor));
    });
  }

  validateMobilityVehicles(
    emissions: TraditionalEmissions,
    conditions: boolean[],
  ) {
    Coerce.toArr(emissions.vehicles).forEach((v) => {
      conditions.push(!!v.fuelType);
      conditions.push(!!v.type);
      conditions.push(AnnualFactorValidator.isValid(v.distancePerYear));
      conditions.push(AnnualFactorValidator.isValid(v.efficiencyRate));
      conditions.push(AnnualFactorValidator.isValid(v.numberOfVehicles));
      conditions.push(AnnualFactorValidator.isValid(v.vehicleEmissionFactor));
    });
  }

  isProjectValid(product): boolean {
    const projectEmissions = Coerce.toObj(product.projectEmissions);
    const conditions = [Coerce.toArr(projectEmissions.vehicles).length > 0];

    if (
      product.calculationApproach === MobilityApproach.CHARGING_POINTS.valueOf()
    ) {
      conditions.push(
        AnnualFactorValidator.isValid(projectEmissions.numberOfPoints),
      );
      conditions.push(
        AnnualFactorValidator.isValid(projectEmissions.annualEnergy),
      );
      conditions.push(
        AnnualFactorValidator.isValid(projectEmissions.emissionsFactor),
      );

      this.validateChargingPointVehicle(projectEmissions, conditions);
    } else {
      this.validateMobilityVehicles(projectEmissions, conditions);
    }

    return conditions.every(Boolean);
  }

  isBaselineValid(product): boolean {
    const conditions = [
      Coerce.toArr(product.baselineEmissions.vehicles).length > 0,
    ];

    if (
      product.calculationApproach === MobilityApproach.CHARGING_POINTS.valueOf()
    ) {
      this.validateChargingPointVehicle(product.baselineEmissions, conditions);
    } else {
      this.validateMobilityVehicles(product.baselineEmissions, conditions);
    }

    return conditions.every(Boolean);
  }

  isHistoricalValid(product): boolean {
    const conditions = [product.historicalEmissions.vehicles.length > 0];

    if (
      product.calculationApproach === MobilityApproach.CHARGING_POINTS.valueOf()
    ) {
      this.validateChargingPointVehicle(
        product.historicalEmissions,
        conditions,
      );
    } else {
      this.validateMobilityVehicles(product.historicalEmissions, conditions);
    }

    return conditions.every(Boolean);
  }
}

import { Coerce } from 'declic-app/common';
import { EnergySource, ProjectEmission } from 'declic-app/models';
import { AnnualFactorValidator } from 'declic-app/product/validators';
import { Ch4Sources, ElecSources, FluorinatedSources } from '../models';
import { BaselineEmissions } from '../biomethane-infrastructures/models';

function validateCh4(sources: Ch4Sources[]) {
  if (!!!Coerce.toArr(sources).length) {
    return true;
  }
  const conditions = [];
  sources.forEach((source) => {
    conditions.push(AnnualFactorValidator.isValid(source.gwp));
    conditions.push(AnnualFactorValidator.isValid(source.volume));
  });
  return conditions.every(Boolean);
}

function validateCombustions(sources: EnergySource[]) {
  if (!!!Coerce.toArr(sources).length) {
    return true;
  }
  const conditions = [];
  sources.forEach((source) => {
    conditions.push(AnnualFactorValidator.isValid(source.elecProduced));
    conditions.push(AnnualFactorValidator.isValid(source.emissionFactor));
  });
  return conditions.every(Boolean);
}

function validateFluorinated(sources: FluorinatedSources[]) {
  if (!!!Coerce.toArr(sources).length) {
    return true;
  }
  const conditions = [];
  sources.forEach((source: FluorinatedSources) => {
    conditions.push(AnnualFactorValidator.isValid(source.emissionFactor));
    conditions.push(AnnualFactorValidator.isValid(source.volume));
  });
  return conditions.every(Boolean);
}

function validateElec(sources: ElecSources[]) {
  if (!!!Coerce.toArr(sources).length) {
    return true;
  }
  const conditions = [];
  sources.forEach((source) => {
    conditions.push(AnnualFactorValidator.isValid(source.gef));
    conditions.push(AnnualFactorValidator.isValid(source.elecVolume));
  });
  return conditions.every(Boolean);
}

function validateProjectEmissions(emissions: ProjectEmission[]) {
  if (!!!Coerce.toArr(emissions).length) {
    return true;
  }
  const conditions = [];
  emissions.forEach((emission) => {
    conditions.push(AnnualFactorValidator.isValid(emission.energyProduced));
    conditions.push(AnnualFactorValidator.isValid(emission.emissionFactor));
  });
  return conditions.every(Boolean);
}

function validateBaselineEmissions(emissions: BaselineEmissions) {
  if (!!!Coerce.toObj(emissions).emissionFactor) {
    return true;
  }
  const conditions = [];
  conditions.push(AnnualFactorValidator.isValid(emissions.emissionFactor));
  return conditions.every(Boolean);
}

export const NetworksSourcesValidator = {
  validateCh4,
  validateCombustions,
  validateFluorinated,
  validateElec,
  validateProjectEmissions,
  validateBaselineEmissions,
};

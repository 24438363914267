import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommafyDirective } from './commafy.directive';

@NgModule({
  declarations: [CommafyDirective],
  imports: [CommonModule],
  exports: [CommafyDirective],
})
export class CommafyModule {}

/* eslint-disable */
import { Coerce } from 'declic-app/common';
import { EntityStateStore } from 'declic-app/services/entity-state';
import { withLoading } from 'declic-app/stores';
import { ProjectStatus } from 'declic-project/services/project.status';
import { CalculationRepository } from 'declic-results/state';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map, pluck, tap } from 'rxjs/operators';

import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ANALYTICS_SERVICE, AnalyticsService } from '@declic/analytics';
import { createStore, select, withProps } from '@ngneat/elf';
import {
  getAllEntities,
  withActiveId,
  withEntities,
} from '@ngneat/elf-entities';

import {
  CalibrationStatus,
  ComputationStatus,
  ProjectStateModel,
} from './project-base.model';
import { ProjectYearsDeterminer } from './project-years.determiner';
import { Project } from './project.model';
import { ProyektoService } from './proyekto.service';

export const projectStore = createStore(
  { name: 'project' },
  withProps<ProjectStateModel>({ loaded: false }),
  withEntities<Project>(),
  withActiveId(),
  withLoading(),
);
export type ProjectState = typeof projectStore.state;

@Injectable({
  providedIn: 'root',
})
export class ProjectRepository extends EntityStateStore<ProjectState, Project> {
  constructor(
    private readonly calc: CalculationRepository,
    private readonly projectService: ProyektoService,
    @Inject(ANALYTICS_SERVICE) private analytics: AnalyticsService,
  ) {
    super(projectStore);
  }
  project$ = this.selectAll().pipe(
    map((projects: Project[]) =>
      projects.sort((a, b) => {
        return Number(new Date(b.date)) - Number(new Date(a.date));
      }),
    ),
  );
  activeGbu$ = this.selectActive().pipe(
    map((project: Project) => project.globalBusinessUnit),
  );
  calibrationStatus$ = this.selectActive().pipe(
    map((project: Project) => project.calibrationStatus),
  );
  latestProject$ = this.store.pipe(select((state) => state.lastCreated));
  activeKpis$ = this.selectActive().pipe(
    map((project: Project) => project.kpies),
  );

  getProducts(id: string): Observable<unknown> {
    return this.selectEntity(id).pipe(
      map((project) => Coerce.toObj(project).products),
    );
  }

  create(project: Project): void {
    this.setState({ updating: true });
    this.projectService
      .create(project)
      .pipe(
        tap((proj: Project) => {
          this.setState({
            lastCreated: proj,
            status: ProjectStatus.CREATE_SUCCESS,
            updating: false,
          });
          this.analytics.trackProjectCreated({
            businessUnit: proj.globalBusinessUnit,
          });
        }),
      )
      .subscribe((proj: Project) => this.upsertEntity(proj.id, proj));
  }
  edit(project: Project): void {
    this.setState({ updating: true, status: undefined });
    this.projectService
      .edit(project)
      .pipe(
        tap((proj: Project) =>
          this.setState({
            lastCreated: proj,
            status: ProjectStatus.UPDATE_SUCCESS,
            updating: false,
          }),
        ),
        catchError((err) => {
          this.setState({ status: ProjectStatus.UPDATE_FAILURE });
          return this.errorCaught(err);
        }),
      )
      .subscribe((jsonProject: Project) => {
        this.upsertProjectEntity(jsonProject.id, jsonProject);
      });
  }
  copy(id: string, name: string): Observable<Project> {
    this.setState({ copying: true });
    return this.projectService.copy(id, name).pipe(
      tap((copied: Project) => this.upsertEntity(copied.id, copied)),
      tap((proj: Project) => this.setState({ lastCreated: proj })),
      finalize(() => this.setState({ copying: false })),
    );
  }
  getAll(): Observable<unknown> {
    this.setState({ loading: true, loaded: false });
    return this.projectService.getAll().pipe(
      tap((projects: Project[]) => this.setEntities(projects)),
      finalize(() => this.setState({ loading: false, loaded: true })),
    );
  }

  updateStatus(projectId: string, status: ComputationStatus): void {
    this.upsertEntity(projectId, { status });
    if (
      [ComputationStatus.ERROR, ComputationStatus.COMPLETED].includes(status)
    ) {
      this.setState({ computing: false });
    }
  }

  updateCalibrationStatus(
    projectId: string,
    calibrationStatus: CalibrationStatus,
  ): void {
    this.upsertEntity(projectId, { calibrationStatus });
  }

  updateReportStatus(projectId: string, reportStatus: string): void {
    this.upsertEntity(projectId, { reportStatus });
  }

  updateDateModifiedToNow(projectId: string): void {
    const date = new Date();
    this.upsertEntity(projectId, { date: date.toISOString() });
  }

  compute(id: string): void {
    this.calc.setLoading(true);
    this.setState({ computing: true });
    this.projectService
      .compute(id)
      .pipe(
        tap((project) => {
          this.upsertEntity(id, project);
          this.analytics.trackProjectRan({
            businessUnit: project.globalBusinessUnit,
          });
        }),
      )
      .subscribe();
  }

  getOne(id: string): Observable<Project> {
    return this.projectService.getOne(id).pipe(
      tap((project) => this.upsertEntity(id, project)),
      finalize(() => {
        this.setState({ loading: false });
      }),
    );
  }

  delete(id: string): Observable<unknown> {
    this.setState({ deletingOne: true });
    return this.projectService.delete(id).pipe(
      tap(() => this.remove(id)),
      finalize(() => this.setState({ deletingOne: false })),
    );
  }

  share(project: Project): Observable<Project> {
    this.setState({ sharing: true });
    return this.projectService.share(project).pipe(
      tap((proj) => this.upsertProjectEntity(project.id, proj)),
      finalize(() => this.setState({ sharing: false })),
      catchError((error) => throwError(error)),
    );
  }

  getProjectStatus(): Observable<ComputationStatus> {
    return this.selectActive().pipe(
      map((project: Project) => Coerce.toObj(project).status),
    );
  }

  getAllNames(): string[] {
    return projectStore
      .query(getAllEntities())
      .map((project) => project.name)
      .filter(Boolean);
  }

  getReportStatus(): Observable<string> {
    return this.selectActive().pipe(pluck('reportStatus'));
  }

  getActiveYears(): number[] {
    return ProjectYearsDeterminer.whatYears(this.getActive());
  }

  selectActiveYears(): Observable<number[]> {
    return this.selectActive().pipe(
      map((project) => ProjectYearsDeterminer.whatYears(project)),
    );
  }

  isComputing(): Observable<boolean> {
    return this.selectActive().pipe(
      map((data: Project) => data.status !== ComputationStatus.COMPLETED),
    );
  }

  private errorCaught(err: HttpErrorResponse): Observable<never> {
    this.setState({ error: err.error['error'] });
    return throwError(err);
  }

  private upsertProjectEntity(id: string, project: Partial<Project>): void {
    this.upsertEntity(id, this.removeUndefinedProperty(project, 'permissions'));
  }

  private removeUndefinedProperty(
    project: Partial<Project>,
    propName: keyof Project,
  ): Partial<Project> {
    if (project[propName] === undefined) {
      delete project[propName];
    }
    return project;
  }
}

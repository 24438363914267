import { Coerce } from 'declic-app/common';
import { EndpointProvider } from 'declic-app/services/endpoints';
import { forkJoin, map, Observable, tap } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@declic/env';
import {
  Equipments,
  FUELING_STATION_TYPES,
  GBUESSource,
  GBUESSourceKind,
  GBUESSourcesRsp,
  GBUESState,
  SourceType,
} from '@declic/types';
import { withCache } from '@ngneat/cashew';
import { createStore, select, setProp, withProps } from '@ngneat/elf';

export const gbuEsStore = createStore(
  { name: 'gbu-es' },
  withProps<GBUESState>({
    sources: {
      electricities: [
        { ef: { 2021: 1 }, name: 'hello', type: SourceType.GREEN },
      ],
      fuels: [],
      inputTypes: [],
      refrigerants: [],
    } as unknown as GBUESSourcesRsp,
  }),
);

@Injectable({ providedIn: 'root' })
export class GbuEsService {
  private http = inject(HttpClient);
  private endpoints = inject(EndpointProvider);

  getSources(
    equipment: Equipments,
    location?: string,
    years?: number[],
  ): Observable<unknown> {
    location = Coerce.toString(location).toLowerCase().replace(/\s/g, '');
    const startYear = Coerce.toArr(years).at(0);
    const endYear = Coerce.toArr(years).at(-1);

    return this.http
      .get(this.endpoints.forGbuEsSources(equipment), {
        context: withCache(),
        params: {
          location,
          startYear,
          endYear,
          v: environment.appVersion,
        },
      })
      .pipe(
        map((rsp: GBUESSourcesRsp) => this.mapRspEmptyObjectToEmptyArray(rsp)),
        tap((rsp: GBUESSourcesRsp) =>
          gbuEsStore.update(setProp('sources', rsp)),
        ),
      );
  }

  getSourcesWithVariants(
    equipment: Equipments,
    location?: string,
    years?: number[],
  ): Observable<unknown> {
    const combinedObservables = this.getCombinedVariantObservables(
      equipment,
      location,
      years,
    );

    return forkJoin(combinedObservables).pipe(
      map((responses: GBUESSourcesRsp[]) => this.combineResponses(responses)),
      tap((rsp: GBUESSourcesRsp) => gbuEsStore.update(setProp('sources', rsp))),
    );
  }

  private getCombinedVariantObservables(
    equipment: Equipments,
    location?: string,
    years?: number[],
  ): Observable<GBUESSourcesRsp>[] {
    location = Coerce.toString(location).toLowerCase().replace(/\s/g, '');
    const startYear = Coerce.toArr(years).at(0);
    const endYear = Coerce.toArr(years).at(-1);
    const variants = this.getEquipVariants(equipment);

    return variants.map((variant) =>
      this.http
        .get(this.endpoints.forGbuEsSources(equipment), {
          context: withCache(),
          params: {
            location,
            startYear,
            endYear,
            variant,
            v: environment.appVersion,
          },
        })
        .pipe(
          map((rsp: GBUESSourcesRsp) =>
            this.mapRspEmptyObjectToEmptyArray(rsp),
          ),
          map((rsp: GBUESSourcesRsp) => this.mapWithVariant(rsp, variant)),
        ),
    );
  }

  private combineResponses(responses: GBUESSourcesRsp[]): GBUESSourcesRsp {
    return responses.reduce((acc, rsp) => {
      Object.keys(rsp).forEach((key) => {
        acc[key] = [...(acc[key] || []), ...rsp[key]];
      });
      return acc;
    }, {} as GBUESSourcesRsp);
  }

  private mapRspEmptyObjectToEmptyArray(rsp: GBUESSourcesRsp): GBUESSourcesRsp {
    // set {} to []
    Object.keys(rsp).forEach((key) => {
      if (
        Object.keys(rsp[key]).length === 0 &&
        rsp[key].constructor === Object
      ) {
        rsp[key] = [];
      }
    });
    return rsp;
  }

  private mapWithVariant(
    rsp: GBUESSourcesRsp,
    variant: string,
  ): GBUESSourcesRsp {
    Object.keys(rsp).forEach((key) => {
      rsp[key].forEach((src) => (src.variant = variant));
    });
    return rsp;
  }

  private getEquipVariants(equipType: Equipments): string[] {
    // eslint-disable-next-line sonarjs/no-small-switch
    switch (equipType) {
      case Equipments.FUELING_STATION:
        return FUELING_STATION_TYPES;
      default:
        return [];
    }
  }
}

function pluck(slice: keyof GBUESSourcesRsp, id: string): GBUESSourceKind {
  return Coerce.toObj(
    gbuEsStore.value.sources[slice]?.find((src) => src.id === id),
  ) as GBUESSourceKind;
}

function selectFuels(): Observable<GBUESSource[]> {
  return gbuEsStore.pipe(
    select((state) =>
      Array.isArray(state.sources.fuels) ? state.sources.fuels : [],
    ),
  );
}

export const ESStoreQueries = {
  pluck,
  selectFuels,
};

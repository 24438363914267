import { FeatureFlags } from 'declic-app/app.tokens';
import {
  DeclicUtils,
  EnergySourceType,
  NatureEnergyProducedType,
} from 'declic-app/common';
import { UNITS } from 'declic-app/common/units';
import { AnnualFactor } from 'declic-app/models';
import { FeatureFlagQueries } from 'declic-app/stores';

import { AuxProp, Constraint, HasName } from '@declic/types';

import { AnnualFactorUtils } from './annual-factor.model';

interface WithWeight {
  readonly weight: AnnualFactor;
  readonly weightInValue: AnnualFactor;
}

export interface EnergySource extends Partial<WithWeight>, Partial<HasName> {
  id: string;
  source: EnergySourceType;
  outputEnergy?: NatureEnergyProducedType;
  elecProduced?: AnnualFactor;
  emissionFactor?: AnnualFactor;
  conversionEfficiency?: AnnualFactor;
  annualPower?: AnnualFactor;
  energyProduced?: AnnualFactor;
  efficiencyRatio?: AnnualFactor;
  volume?: AnnualFactor;
}

type EnergySourceKey = keyof EnergySource;
export const ENERGY_SOURCE_KEYS: Partial<
  Record<EnergySourceKey, EnergySourceKey>
> = {
  weight: 'weight',
  weightInValue: 'weightInValue',
  emissionFactor: 'emissionFactor',
  conversionEfficiency: 'conversionEfficiency',
  elecProduced: 'elecProduced',
  volume: 'volume',
};

function coerceToAverageEF(source: EnergySource): EnergySource {
  return {
    ...source,
    emissionFactor: AnnualFactorUtils.toAverageNature(source.emissionFactor),
    conversionEfficiency: AnnualFactorUtils.toAverageNature(
      source.conversionEfficiency,
    ),
  };
}

function coerceToAverageEFs(sources: EnergySource[]): EnergySource[] {
  return DeclicUtils.resolveToEmptyArray(sources).map((source) =>
    coerceToAverageEF(source),
  );
}

function createWeightAuxProps(): AuxProp[] {
  if (!!!FeatureFlagQueries.isAvailable(FeatureFlags.WEIGHT_FOR_STORAGE)) {
    return [];
  }
  return [
    {
      name: ENERGY_SOURCE_KEYS.weight,
      unit: UNITS.vehicleShare,
      constraints: [Constraint.PERCENTAGE],
      averageOnly: true,
      displayOnly: false,
    },
    {
      name: ENERGY_SOURCE_KEYS.weightInValue,
      unit: UNITS.annualEnergy,
      constraints: [],
      displayOnly: true,
      averageOnly: true,
    },
  ];
}

export const EnergySourceUtils = {
  coerceToAverageEF,
  coerceToAverageEFs,
  createWeightAuxProps,
};

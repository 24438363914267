export enum AssetType {
  CUSTOM = 'Custom',
  SOLID_BIOFUELS_WOOD = 'Solid Biofuels - Wood / Wood Waste (sustainable)',
  LIQUID_BIOFUELS_BIOGASOLINE = 'Liquid Biofuels - Biogasoline (sustainable)',
  LIQUID_BIOFUELS_BIODIESELS = 'Liquid Biofuels - Biodiesels (sustainable)',
  LIQUID_BIOFUELS_OTHER = 'Liquid Biofuels - Other Liquid Biofuels (sustainable)',
  GAS_BIOMASS_LANDFILL = 'Gas Biomass - Landfill Gas (sustainable)',
  GAS_BIOMASS_LF_NCN = 'Gas Biomass - Landfill Gas (not sustainable)',
  GAS_BIOMASS_SLUDGE = 'Gas Biomass - Sludge Gas (sustainable)',
  GAS_BIOMASS_SG_NCN = 'Gas Biomass - Sludge Gas (not sustainable)',
  GAS_BIOMASS_OTHER = 'Gas Biomass - Other Biogas (sustainable)',
  GAS_BIOMASS_OB_NCN = 'Gas Biomass - Other Biogas (not sustainable)',
  MUNICIPAL_WASTES = 'Municipal Wastes (biomass fraction) (sustainable)',
  GEOTHERMAL = 'Geothermal',
  SOLAR_THERMAL = 'Solar Thermal',
  WIND_OFFSHORE = 'Wind offshore',
  WIND_ONSHORE = 'Wind onshore',
  HYDROELECTRIC = 'Hydroelectric',
  GREEN_HYDROGEN_WIND = 'Green hydrogen (from wind)',
  GREEN_HYDROGEN_SOLAR = 'Green hydrogen (from solar)',
  GREEN_HYDROGEN_OTHER = 'Green hydrogen (from other REN source)',
  GREEN_HYRDOGEN_REN = 'Green hydrogen (from other REN source)',
  SOLAR_PV = 'Solar PV',
}

export type Assets = {
  [productId: string]: AssetType[];
};

export type AssetWithID = { asset: AssetType; id: string };

export const ASSETS: Assets = {
  gpp: [
    AssetType.CUSTOM,
    AssetType.SOLID_BIOFUELS_WOOD,
    AssetType.LIQUID_BIOFUELS_BIOGASOLINE,
    AssetType.LIQUID_BIOFUELS_BIODIESELS,
    AssetType.LIQUID_BIOFUELS_OTHER,
    AssetType.GAS_BIOMASS_LANDFILL,
    AssetType.GAS_BIOMASS_SLUDGE,
    AssetType.GAS_BIOMASS_OTHER,
    AssetType.MUNICIPAL_WASTES,
    AssetType.GEOTHERMAL,
    AssetType.SOLAR_THERMAL,
    AssetType.WIND_OFFSHORE,
    AssetType.WIND_ONSHORE,
    AssetType.HYDROELECTRIC,
    AssetType.GREEN_HYDROGEN_WIND,
    AssetType.GREEN_HYDROGEN_SOLAR,
    AssetType.GREEN_HYDROGEN_OTHER,
    AssetType.SOLAR_PV,
  ],
  ggg: [
    AssetType.CUSTOM,
    AssetType.GAS_BIOMASS_LANDFILL,
    AssetType.GAS_BIOMASS_LF_NCN,
    AssetType.GAS_BIOMASS_SLUDGE,
    AssetType.GAS_BIOMASS_SG_NCN,
    AssetType.GAS_BIOMASS_OTHER,
    AssetType.GAS_BIOMASS_OB_NCN,
    AssetType.GREEN_HYDROGEN_SOLAR,
    AssetType.GREEN_HYDROGEN_WIND,
    AssetType.GREEN_HYRDOGEN_REN,
  ],
};

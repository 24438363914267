import { Coerce } from 'declic-app/common';

import {
  Biomethane,
  BiomethaneBE,
  ScopeThree,
  ScopeThreeBE,
} from '../biomethane-infrastructures/models';
import {
  NetworkEditorModel,
  NetworkProjectType,
  ScopeOne,
  ScopeOneBE,
} from '../models';
import { ScopeTwo } from '../models/scope2.models';
import { NetworksProjectEditorMapper } from './networks-project.mapper';

export class BiomethaneMapper extends NetworksProjectEditorMapper<BiomethaneBE> {
  getType(): NetworkProjectType {
    return NetworkProjectType.BI;
  }

  mapEditorModelToProduct(editorModel: NetworkEditorModel): BiomethaneBE {
    return {
      ...editorModel,
      id: undefined,
      scopeOne: this.mapScopeOneToProduct(editorModel),
      scopeTwo: this.mapScopeTwoToProduct(editorModel),
      scopeThree: this.mapScopeThreeToProduct(editorModel),
    };
  }

  mapProductToEditorModel(product: Biomethane): Biomethane {
    return {
      type: product.type,
      scopeOne: this.mapProductToEditorScope1(product),
      scopeTwo: this.mapProductToEditorScope2(product),
      scopeThree: this.mapProductToEditorScope3(product),
      avoidedEmissions: product.avoidedEmissions,
    } as Biomethane;
  }

  mapProductToEditorScope1(product: BiomethaneBE): ScopeOneBE {
    return {
      ...product.scopeOne,
      mobileCombustion: this.mapMobileCombustionToEditor(
        Coerce.toObj(product.scopeOne).mobileCombustion,
      ),
    };
  }
  mapProductToEditorScope2(product: BiomethaneBE): ScopeTwo {
    return product.scopeTwo;
  }
  mapProductToEditorScope3(product: BiomethaneBE): ScopeThree {
    return {
      ...product.scopeThree,
      mobileCombustion: this.mapMobileCombustionToEditor(
        Coerce.toObj(product.scopeThree).mobileCombustion,
      ),
    };
  }

  private mapScopeOneToProduct(editor): ScopeOneBE {
    return {
      ...(editor.scopeOne as ScopeOne),
      mobileCombustion: this.mapEditorToMobileCombustion(
        Coerce.toObj(editor.scopeOne).mobileCombustion,
      ),
    };
  }

  private mapScopeThreeToProduct(editor: NetworkEditorModel): ScopeThreeBE {
    return {
      ...(editor.scopeThree as ScopeThree),
      mobileCombustion: this.mapEditorToMobileCombustion(
        Coerce.toObj(editor.scopeThree as ScopeThree).mobileCombustion,
      ),
    };
  }

  private mapScopeTwoToProduct(editor: NetworkEditorModel): ScopeTwo {
    return {
      ...(editor.scopeTwo as ScopeTwo),
    };
  }
}

/* eslint-disable */
import { AuxUtils, Coerce, createCVAProvider } from 'declic-app/common';
import { AnnualFactor, InputNature, YearlyEmission } from 'declic-app/models';
import { CerberusRepository } from 'declic-app/services/cerberus';
import { AnnualFactorValidator } from 'declic-product/validators/annual-factor.validator';
import { ProjectStoreQueries } from 'declic-project/state';
import { BehaviorSubject, Subject } from 'rxjs';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AuxiliaryProperty } from '../auxiliary-value';

type YearlyEmissionsWithIsCascading = {
  emission: YearlyEmission[];
  isCascading: boolean;
};

type AnnualFactorGroup = {
  yearlyInput: AnnualFactor;
  annualForecast: YearlyEmissionsWithIsCascading;
};

@UntilDestroy()
@Component({
  selector: 'declic-annual-factor',
  templateUrl: './annual-factor.component.html',
  styleUrls: ['./annual-factor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [createCVAProvider(AnnualFactorComponent)],
})
export class AnnualFactorComponent implements ControlValueAccessor {
  private readonly inputNature = new BehaviorSubject<InputNature>(
    InputNature.DEFAULT,
  );
  private readonly isForceDisabled = new BehaviorSubject<boolean>(false);
  private readonly userInputted = new Subject();
  private readonly opened = new Subject<boolean>();
  private readonly annualFactorGroup = new UntypedFormGroup({
    yearlyInput: new UntypedFormControl({} as AnnualFactor),
    annualForecast: new UntypedFormControl(
      {} as YearlyEmissionsWithIsCascading,
    ),
  });

  readonly projectYears$ = ProjectStoreQueries.selectActiveYears();
  readonly opened$ = this.opened.asObservable();
  readonly isForceDisabled$ = this.isForceDisabled.asObservable();
  readonly yearlyInputCtrl = this.annualFactorGroup.get('yearlyInput');
  readonly annualForecastCtrl = this.annualFactorGroup.get('annualForecast');

  @Input() contractYears: number[] | undefined;
  @Input() disableToggle: boolean;
  @Input() averageOnly: boolean;
  @Input() yearlyOnly: boolean;
  @Input() property: AuxiliaryProperty | undefined;
  @Output() yearlyBlur = new EventEmitter();
  @Output() avgBlur = new EventEmitter();

  get propertyLabel(): string {
    return AuxUtils.getPropLabelKey(Coerce.toObj(this.property));
  }

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  private onChange = (_: unknown) => {};

  constructor(private readonly cerberus: CerberusRepository) {
    this.subscribeForCVAPropagation();
    this.subscribeToUserInputtedForRepropagationToCVAParent();
  }

  onYearlyClosed(): void {
    this.yearlyBlur.emit();
  }

  onUserInput(): void {
    this.avgBlur.emit();
    this.userInputted.next('');
    this.updateInputNature(InputNature.USER);
  }

  onOpen(): void {
    this.opened.next(true);
  }

  onYearlyClick(): void {
    if (!this.cerberus.isActiveReadonly()) {
      this.trigger.openMenu();
    }
  }

  registerOnChange(fn: (_: unknown) => void): void {
    this.onChange = fn;
  }

  writeValue(value: AnnualFactor): void {
    if (this.shouldPatch(value)) {
      this.annualFactorGroup.patchValue({
        yearlyInput: Coerce.toObj(value),
        annualForecast: {
          emission: Coerce.toArr(Coerce.toObj(value).values),
          isCascading: Coerce.toObj(value).isCascading,
        },
      });
      this.updateInputNature(Coerce.toObj(value).inputNature);
    }
  }

  private updateInputNature(nature: InputNature): void {
    this.isForceDisabled.next(this.shouldForceDisable(nature));
    this.inputNature.next(nature);
  }

  private shouldForceDisable(nature: InputNature): boolean {
    return [
      this.propDisabledAndNotUser(nature),
      this.emissionsValid(),
      this.notCustom(nature),
    ].every(Boolean);
  }

  private notCustom(nature: InputNature): boolean {
    return nature !== InputNature.CUSTOM;
  }

  private propDisabledAndNotUser(nature: InputNature): boolean {
    return [this.property.disabled, nature !== InputNature.USER].every(Boolean);
  }

  private emissionsValid(): boolean {
    return AnnualFactorValidator.areEmissionsValid(
      this.annualForecastCtrl.value.emission,
    );
  }

  private subscribeToUserInputtedForRepropagationToCVAParent(): void {
    this.userInputted
      .pipe(untilDestroyed(this))
      .subscribe(() =>
        this.onChange(this.buildUserInputttedAnnualFactorFromLatest()),
      );
  }

  private buildUserInputttedAnnualFactorFromLatest(): AnnualFactor {
    return {
      ...this.buildAnnualFactorFromGroup(this.annualFactorGroup.value),
      inputNature: InputNature.USER,
    };
  }

  private shouldPatch(upcoming: AnnualFactor): boolean {
    return ![
      this.isInputNatureDefault(upcoming),
      this.isCurrentInputNatureUser(),
    ].every(Boolean);
  }

  private isCurrentInputNatureUser(): boolean {
    return this.inputNature.value === InputNature.USER;
  }

  private isInputNatureDefault(factor: AnnualFactor): boolean {
    return Coerce.toObj(factor).inputNature === InputNature.DEFAULT;
  }

  private subscribeForCVAPropagation(): void {
    this.annualFactorGroup.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((group) =>
        this.onChange(this.buildAnnualFactorFromGroup(group)),
      );
  }

  private buildAnnualFactorFromGroup(group: AnnualFactorGroup): AnnualFactor {
    return {
      ...group.yearlyInput,
      values: group.annualForecast.emission,
      isCascading: group.annualForecast.isCascading,
    };
  }

  registerOnTouched(fn: unknown): void {
    // do nothing
  }
}

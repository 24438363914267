import { ToggleOption } from 'declic-app/components/toggle';

export enum gppProjectType {
  GRID_CONNECTED = 'Grid-Connected',
  OFF_GRID = 'Off-Grid',
}
export const PROJ_TYPE_OPTIONS: ToggleOption[] = [
  {
    label: gppProjectType.GRID_CONNECTED,
    source: undefined /*'/assets/icons/approach_d.svg'*/,
  },
  {
    label: gppProjectType.OFF_GRID,
    source: undefined /*'/assets/icons/approach_output.svg'*/,
  },
];

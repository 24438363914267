<div [ngSwitch]="status" fxLayoutAlign="start center">
  <mat-icon
    *ngSwitchCase="'complete'"
    class="green-icon"
    data-test="complete-icon"
    >check_circle</mat-icon
  >
  <mat-icon
    *ngSwitchCase="'incomplete'"
    class="red-icon"
    data-test="incomplete-icon"
    >error</mat-icon
  >
</div>

import { Coerce } from 'declic-app/common/coercion.utils';

import { NetworkProjectType, NetworksProject } from '../models';
import { BiomethaneMapper } from './biomethane.mapper';
import { ElecInfraMapper } from './elec.mapper';
import { GasMapper } from './gas.mapper';
import {
  FakeNetworkProjectEditorMapper,
  NetworksProjectEditorMapper,
} from './networks-project.mapper';

const ALL_PRODUCT_EDITOR_MAPPERS: NetworksProjectEditorMapper<NetworksProject>[] =
  [new BiomethaneMapper(), new GasMapper(), new ElecInfraMapper()];

export function getNetworkProjectEditorMapper<T extends NetworksProject>(
  type: NetworkProjectType,
): NetworksProjectEditorMapper<T> {
  const correctMapper = ALL_PRODUCT_EDITOR_MAPPERS.find(
    (mapper) => mapper.getType() === type,
  ) as NetworksProjectEditorMapper<T>;

  return Coerce.toObj(
    correctMapper,
    new FakeNetworkProjectEditorMapper(),
  ) as NetworksProjectEditorMapper<T>;
}

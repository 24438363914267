<div
  fxLayout="row"
  fxLayoutAlign="start stretch"
  fxLayoutGap="32px"
  style="width: 480px"
>
  <div
    class="four-oh-four fg-engie-gradient"
    fxLayout="column"
    fxLayoutAlign="start end"
  >
    <span style="height: 128px">404</span>
    <span>:(</span>
  </div>
  <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="32px">
    <div fxLayout="column" fxLayoutAlign="start stretch">
      <span class="four-title">{{ 'messages.404Title' | transloco }}</span>
      <span class="four-subtitle">
        {{ 'messages.404Subtitle' | transloco }}</span
      >
    </div>

    <button
      type="button"
      class="nj-btn nj-btn--primary"
      (click)="onGoBackHome()"
    >
      {{ 'buttons.goBackHome' | transloco | uppercase }}
    </button>
  </div>
</div>

import { Directive, HostBinding, Input, OnInit } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[dataCy]',
})
export class DataCypressDirective implements OnInit {
  @HostBinding('attr.data-cy') dataCySelector: string;
  @Input() dataCy: string;

  ngOnInit(): void {
    this.dataCySelector = this.dataCy;
  }
}

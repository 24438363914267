import { Coerce } from 'declic-app/common';
import { AssetRefrigerant } from 'declic-app/models';
import { AnnualFactorValidator } from './annual-factor.validator';

function isValid(refrigerant: AssetRefrigerant): boolean {
  return [
    hasRefrigerant(refrigerant),
    hasLeaks(refrigerant),
    hasValidEmissionsFactor(refrigerant),
  ].every(Boolean);
}

function hasRefrigerant(refrigerant: AssetRefrigerant): boolean {
  return !!Coerce.toObj(refrigerant).refrigerant;
}

function hasLeaks(refrigerant: AssetRefrigerant): boolean {
  return refrigerant.leaks !== null && refrigerant.leaks !== undefined;
}

function hasValidEmissionsFactor(refrigerant: AssetRefrigerant): boolean {
  return AnnualFactorValidator.isValid(refrigerant.emissionsFactor);
}

export const AssetRefrigerantValidator = {
  isValid,
};

import { Coerce } from 'declic-app/common';

import { CategoryType } from '../biomethane-infrastructures/models';
import { ElecScopeOne, ElectricityInfra } from '../models/elec.model';
import { NetworkProjectValidator } from './network-project.validator';
import { NetworksSourcesValidator } from './networks-sources.validator.util';

export class ElectricityValidator
  implements NetworkProjectValidator<ElectricityInfra>
{
  isScope1Valid(project: ElectricityInfra): boolean {
    const scope1 = Coerce.toObj(project.scopeOne as ElecScopeOne);
    const conditions = [
      NetworksSourcesValidator.validateCombustions(
        Coerce.toObj(scope1.stationaryCombustion).sources,
      ),
      NetworksSourcesValidator.validateFluorinated(
        Coerce.toObj(scope1.mobileCombustion).sources,
      ),
      NetworksSourcesValidator.validateCh4(
        Coerce.toObj(scope1.fluorinatedGases).sources,
      ),
    ];

    return conditions.every(Boolean);
  }

  isScope2Valid(project: ElectricityInfra): boolean {
    const conditions = [
      NetworksSourcesValidator.validateElec(
        Coerce.toObj(project.scopeTwo.electricityConsumption).sources,
      ),
      NetworksSourcesValidator.validateElec(
        Coerce.toObj(project.scopeTwo.electricityLosses).sources,
      ),
      NetworksSourcesValidator.validateElec(
        Coerce.toObj(project.scopeTwo.electricityTransported).sources,
      ),
    ];

    return conditions.every(Boolean);
  }

  isScope3Valid(project: ElectricityInfra): boolean {
    const scopeThree = Coerce.toObj(project.scopeThree);
    const conditions = [
      NetworksSourcesValidator.validateCombustions(
        Coerce.toObj(scopeThree.stationaryCombustion).sources,
      ),
      NetworksSourcesValidator.validateFluorinated(
        Coerce.toObj(scopeThree.mobileCombustion).sources,
      ),
      NetworksSourcesValidator.validateElec(
        Coerce.toObj(scopeThree.electricityConsumption).sources,
      ),
      NetworksSourcesValidator.validateElec(
        Coerce.toObj(scopeThree.electricityLosses).sources,
      ),
    ];

    return conditions.every(Boolean);
  }

  get validatorMapper(): Record<string, any> {
    return {
      [CategoryType.COMBUSTION]:
        NetworksSourcesValidator.validateCombustions.bind(this),
      [CategoryType.FLUORINATED]:
        NetworksSourcesValidator.validateCh4.bind(this),
      [CategoryType.MOBILE]:
        NetworksSourcesValidator.validateFluorinated.bind(this),
    };
  }

  displayScope1Icon(project: ElectricityInfra): boolean {
    const { stationaryCombustion, mobileCombustion, fluorinatedGases } =
      project.scopeOne;
    const conditions = [
      Coerce.toArr(Coerce.toObj(stationaryCombustion).sources).length > 0,
      Coerce.toArr(Coerce.toObj(mobileCombustion).sources).length > 0,
      Coerce.toArr(Coerce.toObj(fluorinatedGases).sources).length > 0,
    ];

    return !this.isScope1Valid(project) || conditions.every(Boolean);
  }

  displayScope2Icon(project: ElectricityInfra): boolean {
    const {
      electricityConsumption,
      electricityLosses,
      electricityTransported,
    } = project.scopeTwo;
    const isElecLossesValid =
      Coerce.toArr(Coerce.toObj(electricityLosses).sources).length > 0 ||
      Coerce.toArr(Coerce.toObj(electricityTransported).sources).length > 0;
    const conditions = [
      Coerce.toArr(Coerce.toObj(electricityConsumption).sources).length > 0,
      isElecLossesValid,
    ];

    return !this.isScope2Valid(project) || conditions.every(Boolean);
  }

  displayScope3Icon(project: ElectricityInfra): boolean {
    const scopeThree = Coerce.toObj(project).scopeThree;
    const conditions = [
      Coerce.toArr(Coerce.toObj(scopeThree.stationaryCombustion).sources)
        .length > 0,
      Coerce.toArr(Coerce.toObj(scopeThree.mobileCombustion).sources).length >
        0,
      Coerce.toArr(Coerce.toObj(scopeThree.electricityConsumption).sources)
        .length > 0,
    ];

    return !this.isScope3Valid(project) || conditions.every(Boolean);
  }
}

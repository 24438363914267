import { createStore, propsFactory, withProps } from '@ngneat/elf';
import { withActiveId } from '@ngneat/elf-entities';
import { withSavingOne } from 'declic-app/stores';
import { EditorState } from './editor.state';

export const { setInteracted, resetInteracted, getInteracted, withInteracted } =
  propsFactory('interacted', {
    initialValue: false,
  });

export const {
  withSaveTriggerSeed,
  selectSaveTriggerSeed,
  setSaveTriggerSeed,
} = propsFactory('saveTriggerSeed', {
  initialValue: null as string,
});

export const editorStore = createStore(
  { name: 'editor' },
  withProps<EditorState>({
    current: undefined,
    saved: undefined,
    refreshAssumptions: undefined,
    hardRefresh: false,
  }),
  withActiveId(),
  withInteracted(),
  withSavingOne(),
  withSaveTriggerSeed(),
);

function triggerSaving(): void {
  editorStore.update(setSaveTriggerSeed(Date.now().toString()));
}

export const EditorStoreMutators = {
  triggerSaving,
};

export type HasType = { type: string };
export type HasEF = { ef: number };
export type HasGWP = { gwp: number };

export type EquipmentFuel = HasType & {
  lifecycleEf: number;
  name?: string;
};

export type ProjectFuel = HasType &
  HasEF & {
    fuelType: string;
  };

export type Refrigerant = HasType & HasGWP;

export type ProjectElec = HasType & {
  ef: Record<number, number>;
};

export type GetFuelsRsp = { fuels: EquipmentFuel[] };
export type GetProjFuelsRsp = { fuels: ProjectFuel[] };
export type GetRefrigerantsRsp = { refrigerants: Refrigerant[] };
export type GetProjElecsRsp = ProjectElec[];

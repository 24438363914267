import { InjectionToken, Type } from '@angular/core';
import { AnalyticsService } from './analytics.base';

export interface AnalyticsConfig {
  readonly serviceClass: Type<AnalyticsService>;
  readonly amplitudeKey: string;
}

export const ANALYTICS_CONFIG = new InjectionToken('analytics-config');

export const ANALYTICS_SERVICE = new InjectionToken('analytics-service');

export enum AnalyticsEvent {
  PROJECT_ACCESSED = 'PROJECT_ACCESSED',
  PROJECT_CREATED = 'PROJECT_CREATED',
  PROJECT_RAN = 'PROJECT_RAN',
  PRODUCT_USED = 'PRODUCT_USED',
}

interface EventProps {
  readonly businessUnit: string;
}

export type LoggedInProps = EventProps;
export type ProjectCreatedProps = EventProps;
export type ProjectRanProps = EventProps;
export interface ProductAddedProps extends EventProps {
  readonly type: string;
}

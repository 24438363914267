import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DataCypressDirective } from './data-cypress.directive';

@NgModule({
  declarations: [DataCypressDirective],
  imports: [CommonModule],
  exports: [DataCypressDirective],
})
export class DataCypressDirectiveModule {}

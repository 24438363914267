import { Coerce } from 'declic-app/common';
import { AnnualFactor, EnergySource } from 'declic-app/models';
import { StCalcApproachType } from 'declic-st/common';
import { Storage, StorageEmissions } from 'declic-st/models';

import { AnnualFactorValidator } from './annual-factor.validator';
import { ProductValidator } from './product.validator';

type EmissionsValidatorFn = (emissions: StorageEmissions) => boolean;

export class StorageValidator implements ProductValidator<Storage> {
  isProjectValid(product: Storage): boolean {
    return this.validateProjectEmissions(product.projectEmissions);
  }
  isBaselineValid(product: Storage): boolean {
    return [
      this.isAnnualHeatDischargedValid(product.baselineEmissions),
      this.hasPowerSources(product.baselineEmissions),
      this.areBaselineSourcesValid(product.baselineEmissions),
    ].every(Boolean);
  }
  isHistoricalValid(): boolean {
    return true;
  }

  private areBaselineSourcesValid(emissions: StorageEmissions): boolean {
    return Coerce.toArr(emissions.powerSource).every((source) =>
      this.isDischargedOrBaselineSourceValid(source),
    );
  }

  private isAnnualHeatDischargedValid(emissions: StorageEmissions): boolean {
    return AnnualFactorValidator.isValid(emissions.powerProduced);
  }

  private validateProjectEmissions(emissions: StorageEmissions): boolean {
    return this.getEmissionsValidatorFn(emissions)(emissions);
  }

  private getEmissionsValidatorFn(
    emissions: StorageEmissions,
  ): EmissionsValidatorFn {
    const defaultFn = (_: unknown) => false;
    return (
      this.calcApproachValidator[Coerce.toObj(emissions).calcApproach] ||
      defaultFn
    );
  }

  private get calcApproachValidator(): Record<
    StCalcApproachType,
    EmissionsValidatorFn
  > {
    return {
      [StCalcApproachType.CHARGED]: this.isValidCharged.bind(this),
      [StCalcApproachType.DISCHARGED]: this.isValidDischarged.bind(this),
    };
  }

  private isValidCharged(emissions: StorageEmissions): boolean {
    return [
      this.hasPowerSources(emissions),
      this.areChargedSourcesValid(emissions.powerSource),
    ].every(Boolean);
  }

  private hasPowerSources(emissions: StorageEmissions): boolean {
    return !!Coerce.toArr(emissions.powerSource).length;
  }

  private areChargedSourcesValid(sources: EnergySource[]): boolean {
    return sources.every((source) => this.isChargedSourceValid(source));
  }

  private isChargedSourceValid(source: EnergySource): boolean {
    return this.areFactorsValid([
      source.annualPower,
      source.conversionEfficiency,
      source.emissionFactor,
    ]);
  }

  private areFactorsValid(factors: AnnualFactor[]): boolean {
    return factors.every((factor) => AnnualFactorValidator.isValid(factor));
  }

  private isValidDischarged(emissions: StorageEmissions): boolean {
    return [
      this.isRoundTripEffValid(emissions),
      this.hasPowerSources(emissions),
      this.areDischargedSourcesValid(emissions.powerSource),
    ].every(Boolean);
  }

  private areDischargedSourcesValid(sources: EnergySource[]): boolean {
    return Coerce.toArr(sources).every((source) =>
      this.isDischargedOrBaselineSourceValid(source),
    );
  }

  private isDischargedOrBaselineSourceValid(source: EnergySource): boolean {
    return this.areFactorsValid([
      source.conversionEfficiency,
      source.emissionFactor,
    ]);
  }

  private isRoundTripEffValid(emissions: StorageEmissions): boolean {
    return AnnualFactorValidator.isValid(emissions.roundTripEff);
  }
}

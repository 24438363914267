<div
  fxLayout="column"
  fxLayoutAlign="start stretch"
  (click)="$event.stopPropagation()"
  (keydown.Tab)="$event.stopPropagation()"
  class="forecast-menu"
  (declicDrop)="patchPastedListFull($event)"
  *transloco="let t"
>
  <div
    data-testid="cascade"
    class="forecast-cascade-control"
    *ngIf="(isReadOnly$ | async) === false"
  >
    <mat-slide-toggle [formControl]="shouldCascadeControl" color="primary">
      {{ 'labels.cascadeToSucceedingYears' | transloco }}</mat-slide-toggle
    >
  </div>
  <div
    fxLayout="column"
    fxLayoutAlign="start stretch"
    fxLayoutGap="8px"
    class="annual-forecast-menu"
  >
    <div class="overflow annual-forecast-inputs">
      <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="8px">
        <mat-form-field
          fxFlex="45"
          *ngFor="let emissionControl of forecastControl.controls"
          (declicPaste)="patchPastedList(emissionControl, $event)"
        >
          <mat-label>{{ emissionControl?.value?.year }}</mat-label>
          <input
            *ngIf="emissionControl.get('emissions') as control"
            matInput
            data-testid="input-field"
            type="number"
            autocomplete="off"
            [ngModel]="getValue(control.value)"
            (ngModelChange)="control.setValue($event)"
            [readonly]="isReadOnly$ | async"
            (focus)="onFieldFocus(control.value)"
            (blur)="onFieldBlur(control.value)"
          />
          <span matSuffix class="annual-forecast-unit">{{ unit }}</span>
          <mat-error
            *ngIf="
              emissionControl.get('emissions')?.getError('min') as minError
            "
          >
            {{ 'errors.min' | transloco: { number: minError?.min } }}
          </mat-error>
          <mat-error
            *ngIf="emissionControl.get('emissions')?.getError('max') as error"
          >
            {{ 'errors.max' | transloco: { number: error?.max } }}
          </mat-error>
          <mat-error
            *ngIf="emissionControl.get('emissions')?.getError('required')"
          >
            {{ 'errors.required' | transloco }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
  @if (helpMessageKey) {
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      <mat-icon class="help-icon" inline>help</mat-icon>
      <span class="forecast-hint">{{
        t('helpMessage.' + helpMessageKey)
      }}</span>
    </div>
  }
</div>

import { Coerce } from './coercion.utils';

/**
 * @param baseUrl - the domain url
 * @param endpoint - the structure of the path after the domain url
 * @param parameters - the ids for the path
 * For better understanding, refer to the spec file for this util.
 */
export const generateEndpoint = (
  baseUrl: string,
  endpoint: string,
  ...parameters: Array<string>
): string => {
  let index = 0;
  const generated = endpoint.replace(/:[A-Za-z0-9]+/g, (match: string) => {
    const newValue =
      typeof parameters[index] !== undefined ? parameters[index] : match;
    index++;
    return newValue;
  });
  return `${baseUrl}${generated}`;
};

/**
 * @param key - the query param value
 * @param values - the parameters to set for each key
 * For better understanding, refer to the spec file for this util.
 */
export const generateMultivalueQueryStringParams = (
  key: string,
  values: string[],
): string => {
  if (Coerce.toArr(values).length === 0) {
    return '';
  }
  let result = `?${key}=${values[0]}`;
  for (let i = 1; i < values.length; ++i) {
    result = `${result}&${key}=${values[i]}`;
  }
  return result;
};

import { Coerce } from 'declic-app/common';
import { Entity } from 'declic-app/models/entity.model';
import { ProjectRepository } from 'declic-app/project/state';
import { EntityStateStore } from 'declic-app/services/entity-state/entity-state.store';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { createStore, withProps } from '@ngneat/elf';
import { withActiveId, withEntities } from '@ngneat/elf-entities';

import { CerberusService } from './cerberus.service';
import {
  AccessRight,
  CerberusState,
  initialState,
  RightsBag,
} from './cerberus.state';

interface HasID {
  readonly id: string;
}

const cerberusStore = createStore(
  { name: 'cerberus' },
  withProps<CerberusState>(initialState()),
  withEntities<Entity>(),
  withActiveId(),
);
cerberusStore.getValue();
export type CerberusStoreState = typeof cerberusStore.state;

@Injectable({ providedIn: 'root' })
export class CerberusRepository extends EntityStateStore<
  CerberusStoreState,
  Entity
> {
  constructor(private readonly service: CerberusService) {
    super(cerberusStore);
  }

  #projectRepo = inject(ProjectRepository);

  getProjectAccessRights(projectId: string): Observable<AccessRight> {
    return this.service.getProjectAccessRights(projectId).pipe(
      tap((right: AccessRight) => this.handleAccessRight(projectId, right)),
      tap((right: AccessRight) => {
        this.#projectRepo.upsertEntity(projectId, { permissions: right });
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      }),
    );
  }

  // increase count of active users
  open(project: HasID): void {
    this.setActive(Coerce.toObj(project).id);
    this.service.open(Coerce.toObj(project).id);
  }

  // decrease count of active users
  close(project: HasID): void {
    this.service.close(Coerce.toObj(project).id);
  }

  count(project: HasID): Observable<number> {
    return this.service.count(Coerce.toObj(project).id);
  }

  toggleActive(): void {
    this.upsertRight(
      this.getActiveId(),
      this.inverseRight(this.getActiveRight()),
    );
  }

  upsertRight(key: string, right: AccessRight): void {
    this.setState({
      rights: {
        ...this.getRights(),
        [key]: right,
      },
    });
  }

  setActive(key: string): void {
    this.setState({
      active: key,
    });
  }
  selectGettingRights(id: string): Observable<boolean> {
    return this.select('rightsing').pipe(map((rightsing) => !!rightsing[id]));
  }

  selectIsActiveReadonly(): Observable<boolean> {
    return this.state$.pipe(
      map((state) => this.isReadonly(this.pluckActiveRights(state))),
    );
  }

  isActiveReadonly(): boolean {
    return this.isReadonly(
      this.resolveToReadWrite(this.pluckActiveRights(this.state$.getValue())),
    );
  }

  selectIsProjectReadonly(projectId: string): Observable<boolean> {
    return this.state$.pipe(
      map((state) =>
        this.isReadonly(this.pluckProjectRights(state, projectId)),
      ),
    );
  }

  selectRightsOfProject(projectId: string): Observable<AccessRight> {
    return this.state$.pipe(
      map((state) => this.pluckProjectRights(state, projectId)),
    );
  }

  selectRightsOfActiveProject(): AccessRight {
    return this.pluckActiveRights(this.state$.getValue());
  }

  private isReadonly(alleged: AccessRight): boolean {
    return alleged === AccessRight.READ;
  }

  private pluckActiveRights(from: CerberusState): AccessRight {
    return from.rights[from.active];
  }

  private pluckProjectRights(
    from: CerberusState,
    projectId: string,
  ): AccessRight {
    return from.rights[projectId];
  }

  private resolveToReadWrite(from: AccessRight): AccessRight {
    return from || AccessRight.READ_WRITE;
  }

  private handleAccessRight(projectId: string, right: AccessRight): void {
    this.upsertRight(projectId, right);
    this.setActive(projectId);
  }

  private getActiveRight(): AccessRight {
    return this.getRights()[this.getActiveId()];
  }

  private inverseRight(right: AccessRight): AccessRight {
    return right === AccessRight.READ
      ? AccessRight.READ_WRITE
      : AccessRight.READ;
  }

  private getRights(): RightsBag {
    return this.state$.getValue().rights;
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@declic/env';
import { FeatureFlags } from '@declic/types';
import { withCache } from '@ngneat/cashew';
import { featureFlagStore } from 'declic-app/stores';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { EndpointProvider } from '../endpoints';

@Injectable({ providedIn: 'root' })
export class FeatureFlagger {
  constructor(
    private readonly endpoints: EndpointProvider,
    private readonly http: HttpClient,
  ) {}

  getFlags(): Observable<unknown> {
    return this.http
      .get(this.endpoints.forFeatureFlags(environment.name), {
        context: withCache(),
        params: { v: environment.appVersion },
      })
      .pipe(tap((flags: FeatureFlags) => featureFlagStore.update(() => flags)));
  }
}

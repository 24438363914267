import { EnvConfig } from 'declic-app/models/authentication/env-config.model';

import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';

import { AuthConfig } from '../../models/authentication/auth-config.model';
import { HttpConfig } from '../../models/authentication/http-config.model';

export const AUTH_CONFIG = new InjectionToken<AuthConfig>('auth-config');
export const ENV_CONFIG = new InjectionToken<EnvConfig>('env-config');
export const HTTP_CONFIG = new InjectionToken<HttpConfig>('http-config');

@Injectable({ providedIn: 'root' })
export class AuthConfigService {
  constructor(
    @Optional() @Inject(AUTH_CONFIG) public auth: AuthConfig,
    @Optional() @Inject(ENV_CONFIG) public env: EnvConfig,
    @Optional() @Inject(HTTP_CONFIG) public http: HttpConfig,
  ) {}
}

import { SnackbarService } from 'declic-app/services/snackbar';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DeclicSentry } from 'src/sentry';

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Coerce } from '../coercion.utils';

class HTTPRequestErr extends Error {
  name: string = `HTTPRequestErr`;
}

@Injectable({
  providedIn: 'root',
})
export class ErrNotifierInterceptor implements HttpInterceptor {
  constructor(private snacks: SnackbarService) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(catchError((err) => this.onError(req, err)));
  }

  onError(
    req: HttpRequest<unknown>,
    err: HttpErrorResponse,
  ): Observable<HttpEvent<unknown>> {
    this.snacks.openNOK(this.buildWholeMsg(err));
    this.tellSentry(req, err);
    return throwError(() => err);
  }

  buildSentryErrMsg(req: HttpRequest<unknown>, err: HttpErrorResponse) {
    return `${req.method} (${err.status}) - "${this.extractError(err)}"`;
  }

  private tellSentry(req: HttpRequest<unknown>, err: HttpErrorResponse): void {
    DeclicSentry.setTag(DeclicSentry.TAG_API_URL, req.urlWithParams);
    DeclicSentry.captureException(
      new HTTPRequestErr(this.buildSentryErrMsg(req, err)),
    );
  }

  private buildWholeMsg(error: HttpErrorResponse): string {
    const statusText = error.statusText != 'OK' ? `(${error.statusText}) ` : '';
    return `HTTP${error.status} ${statusText}- ${this.getErrorMsg(error)}`;
  }

  private getErrorMsg(error: HttpErrorResponse): string {
    return Coerce.toString(
      this.extractError(error),
      'Something went wrong while processing this action',
    );
  }

  private extractError(from: HttpErrorResponse): string {
    return from.error.error;
  }
}

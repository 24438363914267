import { AnnualFactor, FactorNature } from 'declic-app/models';
import { map } from 'rxjs/operators';

import {
  Component,
  EventEmitter,
  Input,
  Output,
  forwardRef,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { filterNil } from '@ngneat/elf';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AuxiliaryProperty } from './auxiliary-value.model';

@UntilDestroy()
@Component({
  selector: 'declic-auxiliary-value',
  templateUrl: './auxiliary-value.component.html',
  styleUrls: ['./auxiliary-value.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AuxiliaryValueComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AuxiliaryValueComponent),
      multi: true,
    },
  ],
})
export class AuxiliaryValueComponent
  implements ControlValueAccessor, Validator
{
  @Input() averageOnly: boolean;
  @Input() yearlyOnly: boolean;
  @Input() disableToggle: boolean;
  @Input() property: AuxiliaryProperty;
  @Input() contractYears: number[] | undefined;
  @Output() inputBlur = new EventEmitter();

  readonly control = new UntypedFormControl();
  private onChange = (_: unknown) => {};

  constructor() {
    this.subscribeForCVAPropagation();
  }

  writeValue(parentValue: AnnualFactor): void {
    this.control.patchValue(parentValue);
  }

  registerOnChange(fn): void {
    this.onChange = fn;
  }

  validate(_: AbstractControl): ValidationErrors {
    return this.control.errors;
  }

  private subscribeForCVAPropagation(): void {
    this.control.valueChanges
      .pipe(
        filterNil(),
        map((factor) => this.forceAverageIfApplicable(factor)),
        untilDestroyed(this),
      )
      .subscribe((value) => this.onChange(value));
  }

  private forceAverageIfApplicable(factor: AnnualFactor): AnnualFactor {
    return { ...factor, nature: this.resolveNatureBasedOnFlag(factor) };
  }

  private resolveNatureBasedOnFlag(factor: AnnualFactor): FactorNature {
    return this.averageOnly ? 'average' : factor.nature;
  }

  registerOnTouched(fn: () => {}): void {
    // do nothing
  }
}

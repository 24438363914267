import { Coerce } from 'declic-app/common';
import { generateEndpoint } from 'declic-app/common/api.util';
import { EnvConfig } from 'declic-app/models/authentication/env-config.model';
import { EndpointProvider } from 'declic-app/services/endpoints/endpoint.provider';
import { Observable, Observer, throwError } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DECLICONFIG } from '@declic/env';

import { ExportResultsResponse, Result, ResultsFromBE } from './';
import {
  CalculationResults,
  GenerateReportRsp,
  ResultsJSON,
} from './calculation.state';

@Injectable({
  providedIn: 'root',
})
export class CalculationService {
  constructor(
    private readonly http: HttpClient,
    private readonly endpoints: EndpointProvider,
    @Inject(DECLICONFIG) private readonly env: EnvConfig,
  ) {}

  generateReport(id: string): Observable<unknown> {
    return this.http
      .get<GenerateReportRsp>(this.endpoints.forGenerateReport(id))
      .pipe(tap((rsp: GenerateReportRsp) => this.implicitDownload(rsp.url)));
  }

  exportResults(id: string): Observable<unknown> {
    return this.http
      .get<ExportResultsResponse>(this.createExportResultsEndpoint(id))
      .pipe(
        tap((rsp: ExportResultsResponse) =>
          this.implicitDownload(rsp.resultsUrl),
        ),
      );
  }

  doGetResultsRequest(id: string): Observable<CalculationResults> {
    return this.getResultsReqest<{ results: unknown }>(id).pipe(
      map((json) => json.results as unknown as ResultsJSON),
      map((json) => this.jsonToCalculationResults(id, json)),
    );
  }

  getResultsReqest<T>(projectId: string): Observable<T> {
    return this.http
      .get(this.createGetResultsEndpoint(projectId), { responseType: 'blob' })
      .pipe(
        mergeMap((blob: Blob) => this.readBlobAsT<T>(blob)),
        catchError((rsp: HttpErrorResponse) => {
          if (rsp.status === 422) {
            return this.readBlobAsT<T>(rsp.error);
          }
          return throwError(() => rsp);
        }),
      );
  }

  private implicitDownload(url: string): void {
    window.open(url);
  }

  private createExportResultsEndpoint(id: string): string {
    const { baseUrl, endpoints } = this.env.api;
    return generateEndpoint(baseUrl, endpoints['project']['export'], id);
  }

  private jsonToCalculationResults(
    id: string,
    json: ResultsJSON,
  ): CalculationResults {
    return { id, ...this.spreadJSON(json) };
  }

  private spreadJSON(json: ResultsJSON): Omit<CalculationResults, 'id'> {
    return {
      ...json,
      results: this.resultsFromBEToResults(json.results),
    };
  }

  private resultsFromBEToResults(results: ResultsFromBE[]): Result[] {
    return results.map((result) => this.resultFromBEtoResult(result));
  }

  private resultFromBEtoResult(result: ResultsFromBE): Result {
    return {
      ...result,
      annualReducedEmissions: Coerce.toObj(result.reducedCalculation)
        .annualReducedEmissions,
    };
  }

  private readBlobAsT<T>(blob: Blob): Observable<T> {
    return new Observable((observer: Observer<T>) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        observer.next(JSON.parse(reader.result.toString()));
        observer.complete();
      };
      reader.readAsText(blob);
    });
  }

  private createGetResultsEndpoint(id: string): string {
    return this.createResultsEndpoint(id);
  }

  private createResultsEndpoint(id: string): string {
    const { baseUrl, endpoints } = this.env.api;
    return generateEndpoint(baseUrl, endpoints['project']['results'], id);
  }
}

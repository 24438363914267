import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

const localStorage = require('local-storage');
const AGREED_VERSION_KEY = 'agreed_version';

@Injectable()
export class AgreementService {
  constructor(private transloco: TranslocoService) {}

  agree(version: string): void {
    localStorage(AGREED_VERSION_KEY, version);
  }

  getAgreedVersion(): string {
    return localStorage(AGREED_VERSION_KEY);
  }

  getLatestGDPRVersionStream(): Observable<string> {
    return of(this.transloco.translate('gdprVersion'));
  }

  getLatestDoCVersionStream(): Observable<string> {
    return of(this.transloco.translate('methodologyVersion'));
  }

  getConcatenatedVersionStream(): Observable<string> {
    return combineLatest([
      this.getLatestGDPRVersionStream(),
      this.getLatestDoCVersionStream(),
    ]).pipe(map(([gdpr, doc]) => `${gdpr}_${doc}`));
  }
}

import { FeatureFlags } from 'declic-app/app.tokens';
import { Coerce } from 'declic-app/common';
import { FeatureFlagQueries } from 'declic-app/stores';
import { gppProjectType } from 'declic-gpp/components/common';
import { BaselineEmissions, GreenPower } from 'declic-gpp/models';
import { ProductType } from 'declic-project/models';

import { AnnualFactorValidator } from './annual-factor.validator';
import { ProductValidator } from './product.validator';

export class GreenPowerValidator implements ProductValidator<GreenPower> {
  getType(): ProductType {
    return ProductType.GPP;
  }

  isProjectValid(product: GreenPower): boolean {
    const assets = Coerce.toArr(product.projectEmissions.assets);
    // there must be more than 1 asset
    const conditions = [assets.length > 0];

    // all assets must have a valid annual factor
    assets.forEach((a) => {
      conditions.push(AnnualFactorValidator.isValid(a.energyProduced));
      conditions.push(AnnualFactorValidator.isValid(a.conversionEfficiency));
      conditions.push(AnnualFactorValidator.isValid(a.emissionFactor));
    });
    return conditions.every(Boolean);
  }

  isBaselineValid(product: GreenPower): boolean {
    return this.isBaselineHistoricalValid(
      product.projectEmissions.projectType,
      product.baselineEmissions,
    );
  }

  // GPP doesn't have historical emissions
  isHistoricalValid(product: GreenPower): boolean {
    if (!!!FeatureFlagQueries.isAvailable(FeatureFlags.GPP_REDUCED_EMISSION)) {
      return true;
    }
    return this.isBaselineHistoricalValid(
      product.projectEmissions.projectType,
      product.historicalEmissions,
    );
  }

  private isBaselineHistoricalValid(
    projectType: gppProjectType,
    emission: BaselineEmissions,
  ): boolean {
    // check the off grid sources only if the project type is off grid
    if (projectType === gppProjectType.OFF_GRID) {
      return [Coerce.toArr(emission.offGrid).length].every(Boolean);
      // otherwise, check if the emission factor is valid
    } else if (projectType === gppProjectType.GRID_CONNECTED) {
      return AnnualFactorValidator.isValid(emission.emissionFactor);
    }
  }
}

import { ToggleOption } from 'declic-app/components/toggle';

export enum PlCalcApproachType {
  POWERLOAD = 'Power load (kVA or kW)',
  LAMP = 'Lamp electric usage (kWh per lamp)',
}

export const PL_CALC_APPROACH_OPTIONS: ToggleOption[] = [
  {
    label: PlCalcApproachType.LAMP,
    source: undefined /*'/assets/icons/led.svg'*/,
  },
  {
    label: PlCalcApproachType.POWERLOAD,
    source: undefined /*'/assets/icons/nature_power.svg'*/,
  },
];

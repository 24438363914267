import { Equipment } from './equipment.types';
import { ValueByYear } from './generic.types';
import { DeclicKPI } from './project.types';

export type KPIReport = {
  summaryEmissions: SummaryEmissions;
  equipmentEmissions: EquipmentsAndTheirEmissions;
  historicalEmissions: number;
  historicalYear: number;
  years: number[];
  summaryYears: number[];
  failedKPIs: DeclicKPI[];
  renewableAndRecoveryEnergyRatio: ValueByYear;
  carbonIntensity: ValueByYear;
};

export type SummaryEmission = {
  unit: string;
  yearlyAverage: number;
  values: ValueByYear;
};

export type SummaryEmissions = Record<EqEmsKey, SummaryEmission>;

export enum KPIError {
  COMPUTATION_NOT_FINISHED = 'computationNotFinished',
}

export type EquipmentsAndTheirEmissions = Partial<
  Record<Equipment, EquipmentEmissions>
>;

export type EquipmentEmissions = {
  projectEmissions: ValueByYear;
  baselineEmissions: ValueByYear;
  avoidedEmissions: ValueByYear;
  reducedEmissions: ValueByYear;
  directEmissions: ValueByYear;
  carbonIntensity: ValueByYear;
  greenEnergyDelivered: ValueByYear;
};

export type EqEmsKey = keyof EquipmentEmissions;

export const EQ_EMS_KEYS: EqEmsKey[] = [
  'projectEmissions',
  'baselineEmissions',
  'avoidedEmissions',
  'reducedEmissions',
  'directEmissions',
  'carbonIntensity',
  'greenEnergyDelivered',
];

export type SummaryEmissionKey = keyof (EquipmentEmissions & KPIReport);

export const SUMMARY_KEYS: SummaryEmissionKey[] = [
  'directEmissions',
  'carbonIntensity',
  'greenEnergyDelivered',
  'renewableAndRecoveryEnergyRatio',
  'avoidedEmissions',
  'reducedEmissions',
];

export const AVOIDED_KEYS: EqEmsKey[] = [
  'avoidedEmissions',
  'baselineEmissions',
  'projectEmissions',
];

export const REDUCED_KEYS: (keyof (EquipmentEmissions & KPIReport))[] = [
  'projectEmissions',
  'reducedEmissions',
];

import { Coerce } from 'declic-app/common';
import { AnnualFactor } from 'declic-app/models';
import { StepStatus } from 'declic-app/product/components/editor-step-icon-switch';
import { AnnualFactorValidator } from 'declic-app/product/validators';
import {
  Fugitive,
  FugitiveKeys,
  FugitiveSources,
  NetworkProjectAlias,
  NetworksProject,
  ScopeOne,
} from '../models';

export interface NetworkProjectValidator<
  T extends NetworksProject = NetworksProject,
> {
  isScope1Valid(project: T): boolean;
  isScope2Valid(project: T): boolean;
  isScope3Valid(project: T): boolean;

  displayScope1Icon(project: T): boolean;
  displayScope2Icon(project: T): boolean;
  displayScope3Icon(project: T): boolean;

  get validatorMapper(): Record<string, any>;
}

export interface BiomethaneProjectValidator<
  T extends NetworksProject = NetworksProject,
> extends NetworkProjectValidator<T> {
  isAvoidedEmissionsValid(project: T): boolean;
}

const FugidensoValidators: Record<
  NetworkProjectAlias,
  (scope1: ScopeOne) => boolean
> = {
  [NetworkProjectAlias.BI]: biFugidenso,
  [NetworkProjectAlias.GI]: giFugidenso,
  [NetworkProjectAlias.EI]: eiFugidenso,
};

function validateFugidenso(
  alias: NetworkProjectAlias,
  scope1: Partial<ScopeOne>,
) {
  return FugidensoValidators[alias](scope1);
}

function checkFugiSrcs(alias: NetworkProjectAlias, scope1: ScopeOne) {
  return Coerce.toArr(Coerce.toObj(scope1.ch4DirectFugitive).sources).every(
    (src) => checkFugidensoProps(alias, src),
  );
}

function checkFugiProps(
  fugi: FugitiveSources,
  props: (keyof FugitiveSources)[],
): boolean {
  return props.every((prop) =>
    AnnualFactorValidator.isValid(Coerce.toObj(fugi)[prop] as AnnualFactor),
  );
}

function checkFugidensoProps(
  alias: NetworkProjectAlias,
  fugidenso: FugitiveSources,
): boolean {
  return checkFugiProps(fugidenso, FugitiveKeys[alias]);
}

function checkInjected(scope1: ScopeOne): boolean {
  return [
    !hasFugiSrcs(scope1.fugitiveInjected),
    checkInjectedSrcs(scope1),
  ].some(Boolean);
}

function checkInjectedSrcs(scope1: ScopeOne): boolean {
  return scope1.fugitiveInjected?.sources?.every((src) =>
    checkFugiProps(src, ['volume', 'methane']),
  );
}

function checkCHPSrcs(scope1: ScopeOne): boolean {
  return scope1.fugitiveCHP?.sources?.every((src) =>
    checkFugiProps(src, ['volume', 'methane', 'gwp']),
  );
}

function checkCHP(scope1: ScopeOne): boolean {
  return [!hasFugiSrcs(scope1.fugitiveCHP), checkCHPSrcs(scope1)].some(Boolean);
}

function hasFugiSrcs(fugi: Fugitive): boolean {
  return !!Coerce.toArr(Coerce.toObj(fugi).sources).length;
}

function hasBIFugi(scope1: ScopeOne): boolean {
  return [
    hasFugiSrcs(scope1.fugitiveInjected),
    hasFugiSrcs(scope1.fugitiveCHP),
  ].some(Boolean);
}

function biFugidenso(scope1: ScopeOne): boolean {
  return hasBIFugi(scope1) ? checkBIFugiSrcs(scope1) : true;
}

function checkBIFugiSrcs(scope1: ScopeOne): boolean {
  return [checkInjected(scope1), checkCHP(scope1)].every(Boolean);
}

function eiFugidenso(scope1: ScopeOne): boolean {
  return checkFugiSrcs(NetworkProjectAlias.EI, scope1);
}

function giFugidenso(scope1: ScopeOne): boolean {
  return checkFugiSrcs(NetworkProjectAlias.GI, scope1);
}

function hasNonBIFugiSrcs(scope1: ScopeOne): boolean {
  return !!Coerce.toArr(Coerce.toObj(scope1.ch4DirectFugitive).sources).length;
}

function hasFugidensoSrcs(scope1: ScopeOne): boolean {
  return [hasNonBIFugiSrcs(scope1), hasBIFugi(scope1)].some(Boolean);
}

function getFugidensoStepStatus(
  alias: NetworkProjectAlias,
  scope1: ScopeOne,
): StepStatus {
  return hasFugidensoSrcs(scope1)
    ? NetworksValidators.validateFugidenso(alias, scope1)
      ? StepStatus.COMPLETE
      : StepStatus.INCOMPLETE
    : StepStatus.HIDDEN;
}

export const NetworksValidators = {
  validateFugidenso,
  getFugidensoStepStatus,
};

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Provider } from '@angular/core';

import { ErrNotifierInterceptor, OktaAuthInterceptor } from './interceptors';
import { TimingInterceptor } from './interceptors/timing-interceptor.service';

export function getDeclicInterceptorProviders(): Provider[] {
  return [
    { provide: HTTP_INTERCEPTORS, useClass: OktaAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TimingInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrNotifierInterceptor,
      multi: true,
    },
  ];
}

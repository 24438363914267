import { Coerce } from 'declic-app/common';
import { EnergySource } from 'declic-app/models';
import { GreenGas } from 'declic-ggg/models';
import { AnnualFactorValidator } from './annual-factor.validator';
import { ProductValidator } from './product.validator';

export class GreenGasValidator implements ProductValidator<GreenGas> {
  isProjectValid(product: GreenGas): boolean {
    return [this.hasAssets(product), this.areAssetsValid(product)].every(
      Boolean,
    );
  }
  isBaselineValid(product: GreenGas): boolean {
    return AnnualFactorValidator.isValid(
      Coerce.toObj(product.baselineEmissions).emissionFactor,
    );
  }
  isHistoricalValid(_: GreenGas): boolean {
    return true;
  }

  private hasAssets(gas: GreenGas): boolean {
    return !!Coerce.toArr(gas.projectEmissions).length;
  }

  private areAssetsValid(gas: GreenGas): boolean {
    return Coerce.toArr(gas.projectEmissions).every((asset) =>
      this.isAssetValid(asset),
    );
  }

  private isAssetValid(asset: EnergySource): boolean {
    return [asset.energyProduced, asset.emissionFactor].every((factor) =>
      AnnualFactorValidator.isValid(factor),
    );
  }
}

/* eslint-disable max-lines */
import {
  Coerce,
  DeclicUtils,
  NatureEnergyProducedType,
  NatureHeatProductionType,
} from 'declic-app/common';
import { refrigirants } from 'declic-app/common/refrigirants';
import { RefrigerantForm } from 'declic-app/components/asset-refrigirant';
import { AnnualFactor, AssetRefrigerant } from 'declic-app/models';
import { EnergySourceUtils } from 'declic-app/models/energy-source.model';
import { CtBaselineFormModel } from 'declic-ct-dhc/components/ct-dhc-baseline';
import { CTGeneralForm } from 'declic-ct-dhc/components/ct-dhc-general';
import {
  CtProjectFormModel,
  NatureAnnualEnergy,
} from 'declic-ct-dhc/components/ct-dhc-project';
import { CtDhc, CtDhcEmission } from 'declic-ct-dhc/models';
import { ProductEditorModel } from 'declic-product/components/product-editor';
import { ProductType } from 'declic-project/models';

import { ProductEditorMapper } from './product.mapper';

export class DhCtMapper extends ProductEditorMapper<CtDhc> {
  getType(): ProductType {
    return ProductType.CT;
  }
  mapEditorModelToProduct(editorModel: ProductEditorModel): CtDhc {
    return {
      ...editorModel,
      id: undefined,
      natureEnergy: this.mapGeneralToNatureEnergy(editorModel),
      heatProduction: this.mapGeneralToHeatProduction(editorModel),
      baselineEmissions: this.mapBaselineToProduct(editorModel),
      baselineRefrigerant: this.mapBaselineToRefrigerant(editorModel),
      historicalEmissions: this.mapHistoricalToProduct(editorModel),
      historicalRefrigerant: this.mapHistoricalToRefrigerant(editorModel),
      projectEmissions: this.mapProjectToProduct(editorModel),
      projectRefrigerant: this.mapProjectToRefrigerant(editorModel),
    };
  }

  mapProductToEditorGeneral(): unknown {
    return {};
  }
  mapProductToEditorProjectEmissions(product: CtDhc): CtBaselineFormModel {
    const { projectEmissions, projectRefrigerant } = product;
    return this.mapEmissionsToEditorBaselineForm(
      projectEmissions,
      projectRefrigerant,
      Coerce.toObj(projectEmissions)
        .natureOfEnergy as NatureEnergyProducedType[],
    );
  }
  mapProductToEditorBaselineEmissions(product: CtDhc): CtBaselineFormModel {
    const { baselineEmissions, baselineRefrigerant } = product;
    return this.mapEmissionsToEditorBaselineForm(
      baselineEmissions,
      baselineRefrigerant,
      Coerce.toObj(baselineEmissions)
        .natureOfEnergy as NatureEnergyProducedType[],
    );
  }
  mapProductToEditorHistorical(product: CtDhc): unknown {
    const { historicalEmissions, historicalRefrigerant } = product;
    return this.mapEmissionsToEditorBaselineForm(
      historicalEmissions,
      historicalRefrigerant,
      Coerce.toObj(historicalEmissions)
        .natureOfEnergy as NatureEnergyProducedType[],
    );
  }

  private mapEmissionsToEditorBaselineForm(
    emissions: CtDhcEmission,
    refrigerant: AssetRefrigerant,
    natures: NatureEnergyProducedType[],
  ): CtBaselineFormModel {
    const resolved = DeclicUtils.resolveToEmptyObj(emissions);
    return {
      calcApproach: resolved.calculationApproachType,
      situation: resolved.situation,
      refrigerant: this.mapProductToEditorRefrigerant(refrigerant),
      annualEnergies: this.createNatureAnnualEnergies(
        natures,
        resolved.elecProduced,
      ),
      energySources: resolved.energySources,
      baselineEfficiency: resolved.conversionEfficiency,
      useRefrigerant: Coerce.toObj(refrigerant).useRefrigerant,
      nature: this.mapNatureToForm(emissions),
    };
  }

  private createNatureAnnualEnergies(
    natures: NatureEnergyProducedType[],
    produced: AnnualFactor[],
  ): NatureAnnualEnergy[] {
    return DeclicUtils.resolveToEmptyArray(produced).map((value, idx) => ({
      nature: natures[idx],
      value,
    }));
  }

  private mapBaselineToRefrigerant(
    editor: ProductEditorModel,
  ): AssetRefrigerant {
    const baseline = editor.baselineEmissions as CtBaselineFormModel;
    return this.mapBaselineHistoricalToRefrigerant(
      baseline,
      Coerce.toObj(baseline).useRefrigerant,
    );
  }

  private mapHistoricalToRefrigerant(
    editor: ProductEditorModel,
  ): AssetRefrigerant {
    const historical = Coerce.toObj<CtBaselineFormModel>(
      editor.historicalEmissions as CtBaselineFormModel,
    );
    return this.mapBaselineHistoricalToRefrigerant(
      historical,
      historical.useRefrigerant,
    );
  }

  private mapBaselineHistoricalToRefrigerant(
    emissions: CtBaselineFormModel,
    useRefrigerant: boolean,
  ): AssetRefrigerant {
    const refrigerant = Coerce.toObj(Coerce.toObj(emissions).refrigerant);
    return {
      useRefrigerant,
      leaks: refrigerant.leaks,
      refrigerant: refrigerant.refrigerant,
      emissionsFactor: {
        averageValue: refrigerant.emissionsFactor,
        nature: 'average',
        values: [],
      },
    };
  }

  private mapProjectToRefrigerant(
    editor: ProductEditorModel,
  ): AssetRefrigerant {
    const project = Coerce.toObj(editor.projectEmissions as CtProjectFormModel);
    const refrigerant = Coerce.toObj(Coerce.toObj(project).refrigerant);
    return {
      useRefrigerant: project.useRefrigerant,
      leaks: refrigerant.leaks,
      refrigerant: refrigerant.refrigerant,
      emissionsFactor: {
        averageValue: refrigerant.emissionsFactor,
        nature: 'average',
        values: [],
      },
    };
  }

  private mapGeneralToHeatProduction(
    editor: ProductEditorModel,
  ): NatureHeatProductionType {
    const general = Coerce.toObj(editor.general) as CTGeneralForm;
    return general.heatNature;
  }

  private mapProductToEditorRefrigerant(
    refrigerant: AssetRefrigerant,
  ): RefrigerantForm {
    const resolved = DeclicUtils.resolveToEmptyObj(refrigerant);
    const emissionsFactor = DeclicUtils.resolveToEmptyObj(
      resolved.emissionsFactor,
    );
    return {
      emissionsFactor: emissionsFactor.averageValue,
      leaks: resolved.leaks,
      refrigerant: resolved.refrigerant as refrigirants,
    };
  }

  private mapProjectToProduct(editor: ProductEditorModel): CtDhcEmission {
    return this.mapEmissionsEditorToProduct(
      editor.projectEmissions as CtBaselineFormModel,
    );
  }

  private mapGeneralToNatureEnergy(
    editor: ProductEditorModel,
  ): NatureEnergyProducedType[] {
    const general = Coerce.toObj(editor.general) as CTGeneralForm;
    return general.energyNatures;
  }

  private mapBaselineToProduct(editor: ProductEditorModel): CtDhcEmission {
    return this.mapEmissionsEditorToProduct(
      editor.baselineEmissions as CtBaselineFormModel,
    );
  }

  private mapHistoricalToProduct(
    editorModel: ProductEditorModel,
  ): CtDhcEmission {
    let emissions = this.mapEmissionsEditorToProduct(
      editorModel.historicalEmissions as CtBaselineFormModel,
    );
    emissions = this.coerceToHistoricalAverage(emissions);
    return emissions;
  }

  private coerceToHistoricalAverage(emissions: CtDhcEmission): CtDhcEmission {
    return {
      ...emissions,
      energySources: EnergySourceUtils.coerceToAverageEFs(
        emissions.energySources,
      ),
    };
  }

  private mapEmissionsEditorToProduct(
    emissions: CtBaselineFormModel,
  ): CtDhcEmission {
    const {
      calcApproach,
      energySources,
      situation,
      baselineEfficiency,
      nature,
    } = Coerce.toObj(emissions);
    return {
      calculationApproachType: calcApproach,
      energySources,
      situation,
      elecProduced: this.resolveBaselineElecProduced(emissions),
      conversionEfficiency: baselineEfficiency,
      ...this.mapNatureToModel(Coerce.toObj(nature)),
    };
  }

  private resolveBaselineElecProduced(
    baseline: CtBaselineFormModel,
  ): AnnualFactor[] {
    return DeclicUtils.resolveToEmptyArray(
      Coerce.toObj(baseline).annualEnergies,
    )
      .map((energy) => energy.value)
      .filter(Boolean);
  }
}

import { AssumptionService } from 'declic-app/assumptions/common';
import { Refrigirant } from 'declic-app/assumptions/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DeclicUtils } from 'declic-app/common/utils';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AssumptionStore } from 'declic-app/assumption/state';
import { YearlyEmission } from 'declic-app/models';
import { Coerce } from 'declic-app/common';

type RefrigirantWithFluid = Refrigirant & { fluid: string };

@Injectable()
export class RefrigirantService extends AssumptionService<Refrigirant> {
  constructor(private assumptionStore: AssumptionStore) {
    super(assumptionStore);
  }

  getAssumptionsJSONFileName(): string {
    return 'refrigerantFluids';
  }

  getAssumptionsJSONKey(): string {
    return 'refrigirants';
  }

  reduceToManifest(assumptions: RefrigirantWithFluid[]) {
    return assumptions.reduce((acc, curr) => {
      acc[curr.fluid.trim()] = curr;
      return acc;
    }, {});
  }

  getDirectEmission(fluid: string): number {
    return Coerce.toObj(this.getAssumption(fluid)).directEmissions;
  }
}

import { createStore, withProps } from '@ngneat/elf';

type UIProps = {
  deletedAProduct: boolean;
  currentPage?: string;
};

export const uiStore = createStore(
  { name: 'ui' },
  withProps<UIProps>({
    deletedAProduct: false,
  }),
);

import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { EndpointProvider } from '../endpoints';

@Injectable({ providedIn: 'root' })
export class PseudoErrService {
  private endpoints = inject(EndpointProvider);
  private http = inject(HttpClient);

  getPseudoErr(status: string): Observable<unknown> {
    return this.http.get(this.endpoints.forPseudoErr(), { params: { status } });
  }
}

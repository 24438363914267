import { FeatureFlags } from 'declic-app/app.tokens';
import { FeatureFlagQueries } from 'declic-app/stores';

function downloadAsJSON(object: unknown, fileName: string): void {
  removeAttributesInObj(object);

  const sJson = JSON.stringify(object);
  const element = document.createElement('a');
  element.setAttribute(
    'href',
    'data:text/json;charset=UTF-8,' + encodeURIComponent(sJson),
  );
  element.setAttribute('download', buildJSONFileName(fileName));
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

function buildJSONFileName(name: string): string {
  return `${name}.json`;
}

function removeAttributesInObj(object: unknown): void {
  const data = object as Record<string, unknown>;

  const removeAttribute = (flag: boolean, attribute: string): void => {
    if (flag && data.hasOwnProperty(attribute)) {
      delete data[attribute];
    }
  };

  const salesforceIntegrationDisabled = !FeatureFlagQueries.isAvailable(
    FeatureFlags.SALESFORCE_INTEGRATION,
  );
  const businessRefactorDisabled = !FeatureFlagQueries.isAvailable(
    FeatureFlags.BUSINESS_REFACTOR,
  );

  removeAttribute(salesforceIntegrationDisabled, 'salesforceData');
  removeAttribute(businessRefactorDisabled, 'kpies');
  removeAttribute(businessRefactorDisabled, 'activities');
}

export const ExportUtils = { downloadAsJSON };

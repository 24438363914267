import { ToggleOption } from 'declic-app/components/toggle';

export enum emissionApproach {
  DE = 'Direct',
  LA = 'Lifecycle',
}

export const EMISSION_APPROACH_OPTIONS: ToggleOption[] = [
  {
    label: emissionApproach.LA,
    source: undefined /*'/assets/icons/approach_l.svg'*/,
  },
  {
    label: emissionApproach.DE,
    source: undefined /*'/assets/icons/approach_d.svg'*/,
  },
];

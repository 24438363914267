import { Coerce } from 'declic-app/common';
import { CtDhc } from 'declic-ct-dhc/models';

import { CtDhcBaselineValidator } from './ctdhc-baseline.validator';
import { ProductValidator } from './product.validator';

export class CtDhcValidator implements ProductValidator<CtDhc> {
  isProjectValid(product: CtDhc): boolean {
    return CtDhcBaselineValidator.isValid(
      Coerce.toObj(product).projectEmissions,
      Coerce.toObj(product).projectRefrigerant,
    );
  }

  isBaselineValid(product: CtDhc): boolean {
    return CtDhcBaselineValidator.isValid(
      Coerce.toObj(product).baselineEmissions,
      Coerce.toObj(product).baselineRefrigerant,
    );
  }

  isHistoricalValid(product: CtDhc): boolean {
    return CtDhcBaselineValidator.isValid(
      Coerce.toObj(product).historicalEmissions,
      Coerce.toObj(product).historicalRefrigerant,
    );
  }
}

import { Coerce } from 'declic-app/common';
import { AnnualFactor, EnergySource } from 'declic-app/models';
import { OAndM, OAndMEmission } from 'declic-oandm/models';
import { ProductType } from 'declic-project/models';
import { AnnualFactorValidator } from './annual-factor.validator';
import { ProductValidator } from './product.validator';

export class OandMValidator implements ProductValidator<OAndM> {
  getType(): ProductType {
    return ProductType.OANDM;
  }
  isProjectValid(product: OAndM): boolean {
    return this.areOandMEmissionsValid(product.projectEmissions);
  }
  isBaselineValid(product: OAndM): boolean {
    return this.areOandMEmissionsValid(product.baselineEmissions);
  }
  isHistoricalValid(product: OAndM): boolean {
    return this.areOandMEmissionsValid(product.historicalEmissions);
  }

  private areOandMEmissionsValid(emissions: OAndMEmission): boolean {
    return [
      this.hasEnergySources(emissions),
      this.validEnergySources(emissions),
      this.validRefrigerants(emissions),
    ].every(Boolean);
  }

  private hasEnergySources(emissions: OAndMEmission): boolean {
    return !!this.coerceEnergySources(emissions).length;
  }

  private validEnergySources(emissions: OAndMEmission): boolean {
    return this.coerceEnergySources(emissions).every((source) =>
      this.validEnergySource(source),
    );
  }

  private coerceEnergySources(emissions: OAndMEmission): EnergySource[] {
    return Coerce.toArr(Coerce.toObj(emissions).energySources);
  }

  private validEnergySource(source: EnergySource): boolean {
    return this.validAnnualFactors([
      source.elecProduced,
      source.emissionFactor,
    ]);
  }

  private validAnnualFactors(factors: AnnualFactor[]): boolean {
    return factors.every((factor) => AnnualFactorValidator.isValid(factor));
  }

  private validRefrigerants(emission: OAndMEmission): boolean {
    return this.usesRefrigerant(emission)
      ? this.areRefrigerantsValid(emission)
      : true;
  }

  private usesRefrigerant(emission: OAndMEmission): boolean {
    return emission.useRefrigerant;
  }

  private areRefrigerantsValid(emission: OAndMEmission): boolean {
    return this.hasRefrigerants(emission)
      ? emission.refrigerants.every((refrigerant) =>
          this.validAnnualFactors([
            refrigerant.leaks,
            refrigerant.refrigerantEmissionFactor,
          ]),
        )
      : false;
  }

  private hasRefrigerants(emission: OAndMEmission): boolean {
    return !!Coerce.toArr(emission.refrigerants).length;
  }
}

import { Coerce } from 'declic-app/common';
import {
  ProjectRepository,
  ProjectStoreQueries,
} from 'declic-app/project/state';
import { GbuEsService } from 'declic-app/stores';
import { mergeMap, take } from 'rxjs';

import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { Equipment } from '@declic/types';

export const gbuEsSourcesResolver: ResolveFn<void> = (
  route: ActivatedRouteSnapshot,
) => {
  const gbuEsService = inject(GbuEsService);
  const projectStore = inject(ProjectRepository);

  projectStore
    .selectActiveYears()
    .pipe(
      mergeMap((years) =>
        gbuEsService.getSources(
          extractTypeFromRoute(route),
          ProjectStoreQueries.getActiveLocation(),
          years,
        ),
      ),
      take(1),
    )
    .subscribe();
};

export const gbuEsSourcesWithVariantsResolver: ResolveFn<void> = (
  route: ActivatedRouteSnapshot,
) => {
  const gbuEsService = inject(GbuEsService);
  const projectStore = inject(ProjectRepository);

  projectStore
    .selectActiveYears()
    .pipe(
      mergeMap((years) =>
        gbuEsService.getSourcesWithVariants(
          extractTypeFromRoute(route),
          ProjectStoreQueries.getActiveLocation(),
          years,
        ),
      ),
      take(1),
    )
    .subscribe();
};

function extractTypeFromRoute(route: ActivatedRouteSnapshot): Equipment {
  return Coerce.toObj(route.data).type;
}

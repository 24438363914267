import { EmissionFactorAssumption } from 'declic-app/assumptions/common';
import { EmissionFactor } from 'declic-assumptions/models';

import { Injectable } from '@angular/core';
import { AssumptionStore } from 'declic-app/assumption/state';

@Injectable()
export class FuelFactorsService extends EmissionFactorAssumption {
  /* istanbul ignore next */
  constructor(private assumptionStore: AssumptionStore) {
    super(assumptionStore);
  }
  getAssumptionsJSONFileName(): string {
    return 'fuels';
  }
  getAssumptionsJSONKey(): string {
    return 'emissionFactors';
  }

  reduceToManifest(assumptions): { [key: string]: EmissionFactor } {
    const assumptionObj = {} as { [key: string]: EmissionFactor };
    assumptions.forEach((assumption) => {
      assumptionObj[String(assumption.fuel).trim()] = {
        ...(assumption.emissionsBreakdown ?? this.emissionBreakDownZeroValues),
        name: assumption.fuel,
        indirectEmissions: assumption.indirectEmissions,
        directEmissions: assumption.directEmissions,
      };
    });
    return assumptionObj;
  }

  private get emissionBreakDownZeroValues(): Omit<
    EmissionFactor,
    'indirectEmissions' | 'name' | 'directEmissions'
  > {
    return { ch4: 0, co2: 0, n2o: 0 };
  }
}

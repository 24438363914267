import { ToggleOption } from 'declic-app/components/toggle';

export enum StCalcApproachType {
  CHARGED = 'With energy charged',
  DISCHARGED = 'With energy discharged',
}

export const ST_CALC_APPROACH_OPTIONS: ToggleOption[] = [
  {
    label: StCalcApproachType.CHARGED,
    source: undefined /*'/assets/icons/nature_power.svg'*/,
  },
  {
    label: StCalcApproachType.DISCHARGED,
    source: undefined /*'/assets/icons/led.svg'*/,
  },
];

import {
  calculationApproachType,
  Coerce,
  NatureEnergyProducedType,
} from 'declic-app/common';
import { AssetRefrigerant, EnergySource } from 'declic-app/models';
import {
  OnsiteGeneration,
  OnsiteGenerationEmission,
} from 'declic-onsite/models';
import { ProductType } from 'declic-project/models';

import { AnnualFactorValidator } from './annual-factor.validator';
import { AssetRefrigerantValidator } from './asset-refrigerant.validator';
import { ProductValidator } from './product.validator';

export class OnsiteGenerationSingleOutputValidator
  implements ProductValidator<OnsiteGeneration> {
  getType(): ProductType {
    return ProductType.OGSO;
  }

  validateEnergySources(emission: OnsiteGenerationEmission) {
    const energySources = Coerce.toArr(emission.energySources);
    const conditions = [energySources.length > 0];
    if (
      emission.calculationApproachType === calculationApproachType.OUT.valueOf()
    ) {
      conditions.push(AnnualFactorValidator.isValid(emission.elecProduced));
      energySources.forEach((s) => {
        conditions.push(AnnualFactorValidator.isValid(s.conversionEfficiency));
        conditions.push(AnnualFactorValidator.isValid(s.emissionFactor));
      });
    } else {
      energySources.forEach((s) => {
        conditions.push(AnnualFactorValidator.isValid(s.elecProduced));
        conditions.push(AnnualFactorValidator.isValid(s.emissionFactor));
      });
    }
    return conditions;
  }

  isProjectValid(product: OnsiteGeneration): boolean {
    const conditions = this.validateEnergySources(product.projectEmissions);

    // if the nature of energy is cold and a refrigerant is selected, the refrigerant must have a value
    this.validRefrigerant(
      product.natureEnergy,
      product.projectRefrigerant,
      conditions,
    );

    return conditions.every(Boolean);
  }

  isBaselineValid(product: OnsiteGeneration): boolean {
    const conditions = this.validateEnergySources(product.baselineEmissions);

    // if the nature of energy is cold and a refrigerant is selected, the refrigerant must have a value
    this.validRefrigerant(
      product.natureEnergy,
      product.baselineRefrigerant,
      conditions,
    );

    return conditions.every(Boolean);
  }

  isHistoricalValid(product: OnsiteGeneration): boolean {
    const conditions = this.validateEnergySources(product.historicalEmissions);

    // if the nature of energy is cold and a refrigerant is selected, the refrigerant must have a value
    this.validRefrigerant(
      product.natureEnergy,
      product.historicalRefrigerant,
      conditions,
    );

    return conditions.every(Boolean);
  }

  private validRefrigerant(
    natureEnergy: NatureEnergyProducedType,
    refrigerant: AssetRefrigerant,
    conditions: boolean[],
  ) {
    if (
      natureEnergy === NatureEnergyProducedType.COLD.valueOf() &&
      refrigerant.useRefrigerant
    ) {
      conditions.push(AssetRefrigerantValidator.isValid(refrigerant));
    }
  }
}

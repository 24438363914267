import { Injectable, inject } from '@angular/core';
import { filterNil } from '@ngneat/elf';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import { AccessToken, AuthState } from '@okta/okta-auth-js';
import { isJwtExpired } from 'jwt-check-expiration';
import { Observable, filter, map, tap } from 'rxjs';
import { LoggerService } from '../logger';

export const JWTCheckExpirationWrapper = {
  isJwtExpired,
};

@Injectable({ providedIn: 'root' })
export class OktaAuthService {
  private oktaState = inject(OktaAuthStateService);
  private oktaAuth = inject(OKTA_AUTH);
  private logger = inject(LoggerService);

  selectOrRenewAccessToken(): Observable<AccessToken> {
    return this.selectAuthState().pipe(
      map((state) => state.accessToken),
      filterNil(),
      tap((token) => this.renewAccessTokenIfExpired(token)),
      filter((token) => !this.isExpired(token.accessToken)),
      tap((token) =>
        this.logger.debug(`Latest access token: ${token.accessToken}`),
      ),
    );
  }

  selectIsAuthenticated(): Observable<boolean> {
    return this.selectAuthState().pipe(map((state) => state.isAuthenticated));
  }

  selectAuthState(): Observable<AuthState> {
    return this.oktaState.authState$;
  }

  signInWithRedirect(): void {
    this.oktaAuth.signInWithRedirect();
  }

  signOut(): void {
    this.oktaAuth.signOut();
  }

  getApiToken(): string {
    return this.oktaAuth.getIdToken();
  }

  selectApiNonce(): Observable<string> {
    return this.oktaState.authState$.pipe(
      map((state) => state.idToken),
      filterNil(),
      map((token) => token.claims.nonce),
    );
  }

  selectEmail(): Observable<string> {
    return this.selectAuthState().pipe(
      map((state) => state.idToken.claims.email),
    );
  }

  private isExpired(token: string): boolean {
    return JWTCheckExpirationWrapper.isJwtExpired(token);
  }

  private async renewAccessTokenIfExpired(token: AccessToken) {
    if (this.isExpired(token.accessToken)) {
      this.logger.debug('Renewing access token...');
      await this.oktaAuth.tokenManager.renew('accessToken');
    }
  }
}

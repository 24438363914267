import {
  Chart,
  ChartConfiguration,
  ChartData,
  ChartDataset,
  ChartOptions,
} from 'chart.js';
import { Coerce } from 'declic-app/common';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { uuid } from 'short-uuid';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'declic-sparkline',
  templateUrl: './sparkline.component.html',
  styleUrls: ['./sparkline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SparklineComponent implements AfterViewInit {
  private readonly pointsSubject = new BehaviorSubject<number[]>([]);
  @Input() set points(points: number[]) {
    this.pointsSubject.next(points);
  }
  chart: Chart<'line'>;
  readonly id: string = uuid();

  ngAfterViewInit(): void {
    this.subscribeToPointsForChartInstanceManagement();
  }

  private subscribeToPointsForChartInstanceManagement(): void {
    this.pointsSubject
      .pipe(
        tap(() => this.destroyInstanceIfTruthy()),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.chart = new Chart(this.id, this.getChartConfig());
      });
  }

  private getChartConfig(): ChartConfiguration<'line'> {
    return {
      type: 'line',
      data: this.getChartData(),
      options: this.getChartOptions(),
    };
  }

  private getChartOptions(): ChartOptions<'line'> {
    return {
      scales: { x: { display: false }, y: { display: false } },
      plugins: {
        title: { display: false },
        legend: { display: false },
        tooltip: { enabled: false },
      },
    };
  }

  private getChartData(): ChartData<'line'> {
    return {
      labels: this.createEmptyStringLabels(),
      datasets: [this.getChartDataset()],
    };
  }

  private createEmptyStringLabels(): string[] {
    return new Array(Coerce.toArr(this.pointsSubject.value).length).fill('');
  }

  private getChartDataset(): ChartDataset<'line'> {
    return {
      data: this.pointsSubject.value,
      // borderColor: ChartUtils.getColors().yellow,
      borderColor: '#007acd',
      pointRadius: 1,
    };
  }

  private destroyInstanceIfTruthy(): void {
    if (this.chart) {
      this.chart.destroy();
    }
  }
}

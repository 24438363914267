import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { StepStatus } from './editor-step-icon-switch.model';

@Component({
  selector: 'declic-editor-step-icon-switch',
  templateUrl: './editor-step-icon-switch.component.html',
  styleUrls: ['./editor-step-icon-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorStepIconSwitchComponent {
  @Input() status: StepStatus = StepStatus.HIDDEN;
}

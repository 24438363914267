import { Coerce, DeclicUtils } from 'declic-app/common';
import { YearlyEmission } from 'declic-app/models';
import { AssumptionService } from 'declic-assumptions/common';
import { MobilityFactor } from 'declic-assumptions/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { AssumptionStore } from 'declic-app/assumption/state';

import { MobilityFactorsBE } from './mobility-factors.model';

@Injectable()
export class MobilityFactorsService extends AssumptionService<MobilityFactor> {
  constructor(private assumptionStore: AssumptionStore) {
    super(assumptionStore);
  }

  getAssumptionsJSONFileName(): string {
    return 'mobilities';
  }
  getAssumptionsJSONKey(): string {
    return 'vehicles';
  }

  getHttpGetStream() {
    return this.assumptionStore.select(this.getAssumptionsJSONFileName()).pipe(
      map((emissions: MobilityFactorsBE[]) =>
        emissions.map((emission) => ({
          name: emission.vehicle.toLowerCase(),
          direct: emission.directEmissions,
          indirect: emission.indirectEmissions,
        })),
      ),
      map((data) => ({ [this.getAssumptionsJSONKey()]: data })),
    ) as unknown as Observable<{
      [key: string]: MobilityFactor;
    }>;
  }

  getAverageDirect(name: string): number {
    const assumption = DeclicUtils.resolveToEmptyObj(
      this.getAssumption(name.toLowerCase()),
    );
    return DeclicUtils.fixTo3Places(
      DeclicUtils.resolveToZero(assumption.direct),
    );
  }

  getAverageIndirect(name: string): number {
    const assumption = DeclicUtils.resolveToEmptyObj(
      this.getAssumption(name.toLowerCase()),
    );
    return assumption.indirect;
  }

  getAverageTotal(name: string): number {
    const emission = Coerce.toObj(this.getAssumption(name.toLowerCase()));
    return DeclicUtils.fixTo3Places(emission.direct + emission.indirect);
  }

  getYearlyDirect(name: string, years: number[]): YearlyEmission[] {
    const emissions = this.getAverageDirect(name);
    return years.map((year) => ({ year, emissions }));
  }

  getYearlyTotal(name: string, years: number[]): YearlyEmission[] {
    const emissions = this.getAverageTotal(name);
    return years.map((year) => ({ year, emissions }));
  }

  hasDirectEmissions(name: string): boolean {
    return !!Coerce.toObj(this.getAssumption(name.toLowerCase())).direct;
  }

  getDirectForYear(name: string, year: number): number {
    return this.getYearlyDirect(name, [year])[0].emissions;
  }

  getTotalForYear(name: string, year: number): number {
    return this.getYearlyTotal(name, [year])[0].emissions;
  }
}

/* eslint-disable max-lines*/
import { State } from 'declic-app/models/state.model';

import {
  ASSUMPTION_RANGE,
  ElectricityType,
  EmissionType,
} from './assumption.tokens';

export type AssumptionsState = UIState &
  State &
  Readonly<
    Partial<{
      powerGrids: PowerGridBE[];
      gasMixes: GasMix[];
      fuels: Fuel[];
      mobilities: Mobility[];
      refrigerantFluids: RefrigerantFluid[];
      energyRates: unknown[];
      mobilityRates: unknown[];
      otherGases: OtherGases[];
      feedstocks: Feedstock[];
      countries: Country[];
      assumedFrom: number;
      assumedTo: number;
    }>
  >;

export type UIState = Readonly<{
  loadedMobilities?: boolean;
  loadedPowerGrids?: boolean;
  loadedFuels?: boolean;
  loadedGasMixes?: boolean;
  loadedRefrigerant?: boolean;
  loadedOtherGases?: boolean;
  loadedFeedstocks?: boolean;
  loadedCountries?: boolean;
  loadedRates?: boolean;
  loadedElec?: boolean;
  gettingEnergyRates?: boolean;
  gettingMobilityRates?: boolean;
}>;

export type GetAssumptionsRsp = Assumption[];

export type Assumption = PowerGridBE &
  GasMix &
  Fuel &
  Mobility &
  RefrigerantFluid &
  EnergyRate &
  MobilityRate &
  OtherGases &
  Feedstock;

export type RefrigerantFluid = HasFluid & HasEmissions & HasSource;

export type Feedstock = HasName & HasType & HasUnit & Readonly<{ ef: number }>;

export type Fuel = HasFuel &
  HasEmissions &
  HasUnit &
  HasSource &
  HasEmissionsBreakdown;

export type GasMix = PowerGridBE;

export type PowerGridBE = HasCountry &
  HasEmissionType &
  HasUnit &
  HasSource &
  HasYearlyValues;

export type Mobility = HasFuel &
  HasVehicle &
  HasUnit &
  HasEmissions &
  HasSource;

export type OtherGases = HasName & HasUnit & HasGwp;
export type EnergyRate = HasFuel & HasUnit & HasProductions & HasSource;
export type MobilityRate = HasTypeOfVehicle & HasValue & HasSource;
export type Country = HasName & HasPowerGrid & HasGasMix;

export type Electricity = HasName &
  HasCountry &
  HasElectricityType &
  HasSourceOnly &
  HasUnit &
  HasYearlyValues;

export interface HasProductions {
  readonly productionOfElectricity: number;
  readonly productionOfHeat: HeatProduction;
}

export interface HasTypeOfVehicle {
  readonly typeOfVehicle: string;
}

interface HasValue {
  readonly value: number;
}

export interface HeatProduction {
  readonly hotWater: number;
  readonly steam: number;
  readonly exhaustGases: number;
}

export interface HasFluid {
  readonly fluid: string;
}

export interface HasVehicle {
  readonly vehicle: string;
}

export interface HasFuel {
  readonly fuel: string;
}

interface HasEmissionsBreakdown {
  readonly emissionsBreakdown: EmissionsBreakdown;
}

interface EmissionsBreakdown {
  readonly n2o: number;
  readonly ch4: number;
  readonly co2: number;
  readonly unit: string;
}

export interface HasEmissions {
  readonly directEmissions: number;
  readonly indirectEmissions: number;
  readonly totalEmissions: number;
}

export interface HasCountry {
  readonly country: string;
}

export interface HasEmissionType {
  readonly emissionType: EmissionType;
}

export interface HasUnit {
  readonly unit: string;
}

export interface HasYearlyValues {
  readonly values: Record<number, number>;
}

export interface HasSource {
  readonly source: string;
  readonly link: string;
}

export interface HasSourceOnly {
  readonly source: string;
}

export interface HasName {
  readonly name: string;
}

export interface HasPowerGrid {
  readonly powerGrid: string;
}

export interface HasGasMix {
  readonly gasMix: string;
}

export interface HasType {
  readonly type: string;
}

export interface HasGwp {
  readonly gwp: string;
}

export interface HasElectricityType {
  readonly electricityType: ElectricityType;
}

export function createInitialState(): AssumptionsState {
  return {
    powerGrids: [],
    gasMixes: [],
    fuels: [],
    mobilities: [],
    refrigerantFluids: [],
    energyRates: [],
    mobilityRates: [],
    otherGases: [],
    countries: [],
    feedstocks: [],
    assumedFrom: ASSUMPTION_RANGE[0],
    assumedTo: ASSUMPTION_RANGE[1],
    loading: false,
    loaded: false,
  };
}

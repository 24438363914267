import { EnergySourceType, EnergySourceTypeBE } from './energy-source';

const getEnergySourceEnumKey = (value: string) => {
  const indexOfValue = Object.values(EnergySourceType).indexOf(
    value as EnergySourceType,
  );
  return Object.keys(EnergySourceType)[indexOfValue];
};

export const mapToEnergySourceBE = (value: string) => {
  return EnergySourceTypeBE[getEnergySourceEnumKey(value)];
};

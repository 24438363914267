import {
  mapToEnergySourceBE,
  NatureHeatProductionType,
  times100,
} from 'declic-app/common';
import { YearlyEmission } from 'declic-app/models';
import { EfficiencyAssumption } from 'declic-assumptions/common';
import { HeatConversionEfficiency } from 'declic-assumptions/models';

import { Injectable } from '@angular/core';
import { AssumptionStore } from 'declic-app/assumption/state';

@Injectable()
export class HeatConversionService extends EfficiencyAssumption<HeatConversionEfficiency> {
  /* istanbul ignore next */
  constructor(private assumptionStore: AssumptionStore) {
    super(assumptionStore);
  }
  getAssumptionsJSONFileName(): string {
    return 'energyRates';
  }
  getAssumptionsJSONKey(): string {
    return 'conversionEfficiencies';
  }

  reduceToManifest(assumptions): HeatConversionEfficiency {
    return assumptions.reduce((acc, assumption) => {
      acc[String(assumption.fuel).trim()] = {
        ...assumption.productionOfHeat,
        name: assumption.fuel,
      };
      return acc;
    }, {});
  }

  getYearlyBasedOnHeatNature(
    source: string,
    nature: NatureHeatProductionType,
    years: number[],
  ): YearlyEmission[] {
    const emissions = this.getAverageBasedOnHeatNature(source, nature);
    return years.map((year) => ({ year, emissions }));
  }

  getAverageBasedOnHeatNature(
    source: string,
    nature: NatureHeatProductionType,
  ): number {
    const assumption =
      this.getAssumption(mapToEnergySourceBE(source)) ??
      this.getZeroValuesHeatConversionEfficiency();
    return times100(this.extractMetricOfHeatNature(assumption, nature));
  }

  private extractMetricOfHeatNature(
    assumption: HeatConversionEfficiency,
    metric: NatureHeatProductionType,
  ): number {
    const natureMap = {
      [NatureHeatProductionType.DIRECT]: 'exhaustGases',
      [NatureHeatProductionType.HOT_WATER]: 'hotWater',
      [NatureHeatProductionType.STEAM]: 'steam',
    };
    return assumption[natureMap[metric]];
  }

  private getZeroValuesHeatConversionEfficiency(): HeatConversionEfficiency {
    return {
      hotWater: 0,
      steam: 0,
      exhaustGases: 0,
    } as HeatConversionEfficiency;
  }
}

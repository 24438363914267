import { Coerce } from 'declic-app/common';
import { GppBaselineFormModel } from 'declic-gpp/components/gpp-baseline';
import { GppProjectFormModel } from 'declic-gpp/components/gpp-project';
import {
  BaselineEmissions,
  GppProjectEmission,
  GreenPower,
} from 'declic-gpp/models';
import { ProductEditorModel } from 'declic-product/components/product-editor';
import { ProductType } from 'declic-project/models';

import { ProductEditorMapper } from './product.mapper';

export class GreenPowerMapper extends ProductEditorMapper<GreenPower> {
  mapProductToEditorHistorical(product: GreenPower): unknown {
    const historicalEmissions = Coerce.toObj(product.historicalEmissions);

    return {
      emissionFactor: historicalEmissions.emissionFactor,
      offGridForm: historicalEmissions.offGrid,
    };
  }
  getType(): ProductType {
    return ProductType.GPP;
  }
  mapEditorModelToProduct(editorModel: ProductEditorModel): GreenPower {
    return {
      ...editorModel,
      id: undefined,
      baselineEmissions: this.mapBaselineEmissionFormToProduct(
        editorModel.baselineEmissions,
      ),
      projectEmissions: this.mapProjectToProduct(editorModel),
      historicalEmissions: this.mapBaselineEmissionFormToProduct(
        editorModel.historicalEmissions,
      ),
    };
  }
  mapProductToEditorProjectEmissions(product: GreenPower): GppProjectEmission {
    const projectEmissions = Coerce.toObj(product.projectEmissions);
    return {
      projectType: projectEmissions.projectType,
      assets: projectEmissions.assets,
    };
  }
  mapProductToEditorBaselineEmissions(
    product: GreenPower,
  ): GppBaselineFormModel {
    const baselineEmission = Coerce.toObj(product.baselineEmissions);

    return {
      emissionFactor: baselineEmission.emissionFactor,
      offGridForm: baselineEmission.offGrid,
    };
  }

  private mapBaselineEmissionFormToProduct(
    baselineEmissionForm: GppBaselineFormModel,
  ): BaselineEmissions {
    const form = Coerce.toObj(baselineEmissionForm);
    return {
      emissionFactor: form.emissionFactor,
      offGrid: form.offGridForm,
    };
  }

  private mapProjectToProduct(editor: ProductEditorModel): GppProjectEmission {
    const projectEmission = editor.projectEmissions as GppProjectFormModel;
    return {
      projectType: projectEmission.projectType,
      assets: projectEmission.assets,
    };
  }
}

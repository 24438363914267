import { Computation } from 'declic-app/models';
import { Entity } from 'declic-app/models/entity.model';
import { ProductType, ProjectType } from 'declic-project/models';
import { FootprintSummary } from 'declic-results/models';

export interface CalculationState extends CalculationResults, UIState {}

export interface UIState {
  readonly successGet?: boolean;
}

export type AggregatedResults = Record<string, Computation | Result>;

export interface CalculationResults extends Entity {
  readonly results: Result[];
  readonly energyGeneratingProducts: ProductType[];

  // FOR NETWORKS
  readonly avoidedEmissions?: Result[];
  readonly summary?: FootprintSummary;
  readonly consolidation?: FootprintSummary;
}

export type ExportResultsResponse = GetResultsResponse;

export type GetResultsResponse = {
  resultsUrl: string;
};

export type GenerateReportRsp = Readonly<{
  url: string;
}>;

export interface Result extends ResultsFromBE, NetworksResultsFromBE {
  readonly annualReducedEmissions: YearlyEmission[];
}

export type ResultsJSON = {
  results: ResultsFromBE[];
  energyGeneratingProducts: ProductType[];
  summary?: FootprintSummary;
};

export interface HasBaselineJustification {
  readonly baselineJustification: string;
  readonly type: string;
  readonly name: string;
}

export type BaselineJustifications = Record<string, string[]>;

export interface ResultsFromBE extends HasBaselineJustification {
  // Amount per year
  readonly baselineAnnualEmissions: YearlyEmission[];
  readonly projectAnnualEmissions: YearlyEmission[];
  readonly annualDecarbonizationImpact: YearlyEmission[];
  readonly annualDecarbonizationImpactRatio: YearlyEmission[];

  // Average annual amount
  readonly baselineAnnualAverageEmissions: number;
  readonly projectAnnualAverageEmissions: number;
  readonly annualAverageDecarbonizationImpact: number;
  readonly annualAverageDecarbonizationImpactRatio: number;

  // Total over contract duration
  readonly totalBaselineEmissionsOverContractDuration: number;
  readonly totalProjectEmissionsOverContractDuration: number;
  readonly totalDecarbonizationImpactOverContractDuration: number;
  readonly totalDecarbonizationImpactRatioOverContractDuration: number;

  // Installation
  readonly installationProjectEmissions?: number;
  readonly installationBaselineEmissions?: number;

  // Reduced Emissions
  readonly projectType: ProjectType;
  readonly reducedCalculation: ReducedCalculation;

  // Energy Generation
  readonly annualEnergyGenerated?: YearlyEmission[];
  readonly averageEnergyGenerated?: number;
  readonly totalEnergyGenerated?: number;
}

export interface NetworksResultsFromBE {
  readonly comprehensiveCO2?: ComprehensiveCo2Results;
}

export interface ReducedCalculation {
  readonly projectAnnualEmissions: YearlyEmission[];
  readonly historicalEmissions: YearlyEmission;
  readonly annualReducedEmissions: YearlyEmission[];
}

export type YearlyEmission = {
  year: number;
  emissions: number;
};

export type ResultYearlyEmissionKey = keyof Pick<
  Result,
  'annualEnergyGenerated' | 'annualReducedEmissions'
>;

export interface ComprehensiveCo2Results {
  readonly scopeOne: ScopeOneResults;
  readonly scopeTwo: ScopeTwoResults;
  readonly scopeThree: ScopeThreeResults;
}

export interface ScopeOneResults {
  readonly stationaryCombustion: YearlyEmission[];
  readonly mobileCombustion: YearlyEmission[];
  readonly ch4GasFlaring: YearlyEmission[];
  readonly ch4DirectFugitive: YearlyEmission[];
  readonly ch4DirectVenting: YearlyEmission[];
  readonly fluorinatedGases: YearlyEmission[];
}

export interface ScopeTwoResults {
  readonly electricityConsumption: YearlyEmission[];
}

export interface ScopeThreeResults {
  readonly upstreamStationaryCombustion: YearlyEmission[];
  readonly upstreamMobileCombustion: YearlyEmission[];
  readonly upstreamElectricityConsumption: YearlyEmission[];
  readonly transpoOfSoldProds: YearlyEmission[];
  readonly useOfSoldProds: YearlyEmission[];
}

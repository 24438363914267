export type AuthConfig = Readonly<{
  region: string;
  identityPoolId?: string;
  identityPoolRegion?: string;
  userPoolId?: string;
  userPoolWebClientId?: string;
  userPoolWebClientSecret?: string;
  oauth: AuthConfigOAuth;
}>;

export type AuthConfigOAuth = Readonly<{
  domain?: string;
  scope?: Array<string>;
  redirectSignIn?: string;
  redirectSignOut?: string;
  responseType?: string;
  options?: AuthConfigOAuthOptions;
  identityProvider?: string;
}>;

export type AuthConfigOAuthOptions = Readonly<{
  AdvancedSecurityDataCollectionFlag: boolean;
}>;

import { GreenEnergyEmission, GreenSales } from '@declic/green-sales/common';
import { ProductType } from 'declic-project/models';
import { AnnualFactorValidator } from './annual-factor.validator';
import { ProductValidator } from './product.validator';

export class GreenSalesValidator implements ProductValidator<GreenSales> {
  getType(): ProductType {
    return ProductType.SALES;
  }
  isProjectValid(product: GreenSales): boolean {
    return this.areEmissionsValid(product.projectEmissions);
  }
  isBaselineValid(product: GreenSales): boolean {
    return this.areEmissionsValid(product.baselineEmissions);
  }
  isHistoricalValid(product: GreenSales): boolean {
    return this.areEmissionsValid(product.historicalEmissions);
  }

  private areEmissionsValid(emissions: GreenEnergyEmission[]): boolean {
    return [
      emissions.length,
      this.areAllFactorsValidOfEmissions(emissions),
    ].every(Boolean);
  }

  private areAllFactorsValidOfEmissions(
    emissions: GreenEnergyEmission[],
  ): boolean {
    return emissions.every((emi) => this.areAllAnnualFactorsValid(emi));
  }

  private areAllAnnualFactorsValid(emission: GreenEnergyEmission): boolean {
    return [
      emission.conversionEfficiency,
      emission.elecProduced,
      emission.emissionFactor,
    ].every((factor) => AnnualFactorValidator.isValid(factor));
  }
}

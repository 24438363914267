/* eslint-disable max-lines */
import { DeclicUtils } from 'declic-app/common/utils';
import { Entity } from 'declic-app/models/entity.model';
import { EndpointProvider } from 'declic-app/services/endpoints/endpoint.provider';
import { EntityStateStore } from 'declic-app/services/entity-state/entity-state.store';
import { forkJoin, Observable } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@declic/env';
import { withCache } from '@ngneat/cashew';
import { createStore, select, withProps } from '@ngneat/elf';
import { withActiveId, withEntities } from '@ngneat/elf-entities';

import { withElectricity } from '../props';
import {
  Assumption,
  AssumptionsState,
  createInitialState,
  GetAssumptionsRsp,
} from './assumption.state';
import {
  ASSUMPTION_RANGE,
  AssumptionType,
  EfficiencyRateType,
} from './assumption.tokens';

export const assumptionStore = createStore(
  { name: 'assumption' },
  withProps<AssumptionsState>(createInitialState()),
  withEntities<Entity>(),
  withActiveId(),
  withElectricity(),
);
assumptionStore.getValue();
export type AState = typeof assumptionStore.state;

@Injectable({
  providedIn: 'root',
})
export class AssumptionStore extends EntityStateStore<AState, Entity> {
  constructor(
    private readonly endpoints: EndpointProvider,
    private readonly http: HttpClient,
  ) {
    super(assumptionStore);
  }

  getEfficiencyRates(): Observable<unknown> {
    this.setState({ loadedRates: false });
    return forkJoin([
      this.requestEnergyRates(),
      this.requestMobilityRates(),
    ]).pipe(finalize(() => this.setState({ loadedRates: true })));
  }

  getMobiAssumptions(): Observable<unknown> {
    return this.getAssumptions(AssumptionType.MOBILITY, 'loadedMobilities');
  }

  getFuelAssumptions(): Observable<unknown> {
    return this.getAssumptions(AssumptionType.FUEL, 'loadedFuels');
  }

  getPowerGridAssumptions(): Observable<unknown> {
    return this.getAssumptions(AssumptionType.POWER_GRID, 'loadedPowerGrids');
  }

  getGasMixAssumptions(): Observable<unknown> {
    return this.getAssumptions(AssumptionType.GAS_MIX, 'loadedGasMixes');
  }

  getFluidAssumptions(): Observable<unknown> {
    return this.getAssumptions(
      AssumptionType.REFRIGERANT_FLUID,
      'loadedRefrigerant',
    );
  }

  getCountryAssumptions(): Observable<unknown> {
    return this.getAssumptions(AssumptionType.COUNTRIES, 'loadedCountries');
  }

  getOtherGasesAssumptions(): Observable<unknown> {
    return this.getAssumptions(AssumptionType.OTHER_GASES, 'loadedOtherGases');
  }

  getElectricityAssumptions(): Observable<unknown> {
    return this.getAssumptions(AssumptionType.ELECTRICITY, 'loadedElectricity');
  }

  getFeedstockAssumptions(): Observable<unknown> {
    return this.getAssumptions(AssumptionType.FEEDSTOCK, 'loadedFeedstocks');
  }

  updateAssumedFrom(from: number): void {
    this.setState({ assumedFrom: from });
  }

  updateAssumedTo(to: number): void {
    this.setState({ assumedTo: to });
  }

  getAssumedYears(): number[] {
    return this.explodeToList(this.toAssumedRange(this.store.getValue()));
  }

  selectAssumedYears(): Observable<number[]> {
    return this.store.pipe(
      select((state) => state),
      map((from: AState) =>
        this.explodeToList([from.assumedFrom, from.assumedTo]),
      ),
    );
  }

  resetAssumedRange(): void {
    this.setState({
      assumedFrom: ASSUMPTION_RANGE[0],
      assumedTo: ASSUMPTION_RANGE[1],
    });
  }

  private requestEnergyRates(): Observable<unknown> {
    this.setState({ gettingEnergyRates: true });
    return this.requestRates(EfficiencyRateType.ENERGY).pipe(
      tap((energyRates: unknown[]) => this.setState({ energyRates })),
      finalize(() => this.setState({ gettingEnergyRates: false })),
    );
  }

  requestMobilityRates(): Observable<unknown> {
    this.setState({ gettingMobilityRates: true });
    return this.requestRates(EfficiencyRateType.MOBILITY).pipe(
      tap((mobilityRates: unknown[]) => this.setState({ mobilityRates })),
      finalize(() => this.setState({ gettingMobilityRates: false })),
    );
  }

  private requestRates(type: EfficiencyRateType): Observable<unknown> {
    return this.http.get(this.endpoints.forEfficiencyRateAssumptions(), {
      ...withCache(),
      params: { type, v: environment.appVersion },
    });
  }

  private getAssumptions(
    type: AssumptionType,
    state: string,
  ): Observable<unknown> {
    this.setState({ loading: true, [state]: false });

    return this.http
      .get<GetAssumptionsRsp>(this.getEndpoint(type), {
        context: withCache(),
        params: { v: environment.appVersion },
      })
      .pipe(
        map((rsp) => this.pluckAssumptions(rsp)),
        tap((assumptions: Assumption[]) =>
          this.assumpdate(type, assumptions, state),
        ),
      );
  }

  private assumpdate(
    type: AssumptionType,
    assumptions: Assumption[],
    state: string,
  ): void {
    this.setState({
      [this.typeStateKeyMap[type]]: assumptions,
      [state]: true,
      loading: false,
    });
  }

  private getEndpoint(type: AssumptionType): string {
    return this.assumptionTypeEndpointMap[type];
  }

  private get assumptionTypeEndpointMap(): Record<AssumptionType, string> {
    return {
      [AssumptionType.POWER_GRID]: this.endpoints.forPowerGridAssumptions(),
      [AssumptionType.GAS_MIX]: this.endpoints.forGasMixAssumptions(),
      [AssumptionType.FUEL]: this.endpoints.forFuelAssumptions(),
      [AssumptionType.MOBILITY]: this.endpoints.forMobilityAssumptions(),
      [AssumptionType.REFRIGERANT_FLUID]: this.endpoints.forFluidAssumptions(),
      [AssumptionType.EFFICIENCY_RATE]:
        this.endpoints.forEfficiencyRateAssumptions(),
      [AssumptionType.OTHER_GASES]: this.endpoints.forOtherGasesAssumptions(),
      [AssumptionType.COUNTRIES]: this.endpoints.forCountryAssumptions(),
      [AssumptionType.ELECTRICITY]: this.endpoints.forElectricityAssumptions(),
      [AssumptionType.FEEDSTOCK]: this.endpoints.forFeedstockAssumptions(),
    };
  }

  private get typeStateKeyMap(): Record<AssumptionType, keyof AState> {
    return {
      [AssumptionType.POWER_GRID]: 'powerGrids',
      [AssumptionType.GAS_MIX]: 'gasMixes',
      [AssumptionType.FUEL]: 'fuels',
      [AssumptionType.MOBILITY]: 'mobilities',
      [AssumptionType.REFRIGERANT_FLUID]: 'refrigerantFluids',
      [AssumptionType.EFFICIENCY_RATE]: 'energyRates',
      [AssumptionType.OTHER_GASES]: 'otherGases',
      [AssumptionType.COUNTRIES]: 'countries',
      [AssumptionType.ELECTRICITY]: 'electricity',
      [AssumptionType.FEEDSTOCK]: 'feedstocks',
    };
  }

  private pluckAssumptions(from: GetAssumptionsRsp): Assumption[] {
    return from;
  }

  private toAssumedRange(from: AState): [number, number] {
    return [from.assumedFrom, from.assumedTo];
  }

  private explodeToList(range: [number, number]): number[] {
    return DeclicUtils.arrayFromRange(range[0], range[1]);
  }
}

import { Cogen } from './cogen.types';
import { EnergyType } from './energy.types';
import { WithMultipleEnergyDetails } from './equipment.types';

export type Exchanger = Omit<Cogen, 'assetEfficiency'>;

export type ExchangerForm = Pick<Exchanger, 'name' | 'energies' | 'fuels'>;

export const EXCHANGER_ENERGY_TYPES: EnergyType[] = [
  EnergyType.HEAT,
  EnergyType.COLD,
];

export type ExchangerTableRow = Exchanger &
  Omit<WithMultipleEnergyDetails, 'capacities'>;

import { Coerce } from 'declic-app/common';
import {
  Combustion,
  Fluorinated,
  NetworkEditorModel,
  NetworkProjectType,
  NetworksProject,
} from '../models';

export abstract class NetworksProjectEditorMapper<T extends NetworksProject> {
  abstract getType(): NetworkProjectType;
  abstract mapProductToEditorScope1(product: T): unknown;
  abstract mapProductToEditorScope2(product: T): unknown;
  abstract mapProductToEditorScope3(product: T): unknown;

  mapMobileCombustionToEditor(mobile: Combustion): Fluorinated {
    return {
      sources: Coerce.toArr(Coerce.toObj(mobile).sources).map((source) => ({
        id: source.id,
        source: source.source,
        emissionFactor: source.emissionFactor,
        volume: source.elecProduced,
        name: source.name,
      })),
    };
  }

  mapEditorToMobileCombustion(mobile: Fluorinated): Combustion {
    return {
      sources: Coerce.toArr(Coerce.toObj(mobile).sources).map((source) => ({
        id: source.id,
        source: source.source,
        emissionFactor: source.emissionFactor,
        elecProduced: source.volume,
        name: source.name,
      })),
    };
  }

  mapEditorModelToProduct(editorModel: NetworkEditorModel): T {
    return {} as T;
  }

  mapProductToEditorModel(product: T): NetworkEditorModel {
    return {
      type: product.type,
      scopeOne: this.mapProductToEditorScope1(product),
      scopeTwo: this.mapProductToEditorScope2(product),
      scopeThree: this.mapProductToEditorScope3(product),
      avoidedEmissions: undefined,
    };
  }
}

export class FakeNetworkProjectEditorMapper extends NetworksProjectEditorMapper<NetworksProject> {
  getType(): NetworkProjectType {
    return;
  }

  mapProductToEditorScope1(product: NetworksProject): unknown {
    return 'scope 1';
  }
  mapProductToEditorScope2(product: NetworksProject): unknown {
    return 'scope 2';
  }
  mapProductToEditorScope3(product: NetworksProject): unknown {
    return 'emission accomplished';
  }
}

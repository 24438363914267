import { ChangeDetectionStrategy, Component, input } from '@angular/core';

export type CustomText = {
  text: string;
  verticality: Verticality;
};

export enum Verticality {
  NORMAL = 'normal',
  SUPER = 'super',
  SUB = 'sub',
}

@Component({
  selector: 'declic-label-maker',
  standalone: true,
  imports: [],
  templateUrl: './label-maker.component.html',
  styleUrl: './label-maker.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelMakerComponent {
  parts = input<CustomText[]>();
}

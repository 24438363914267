export interface CerberusState extends UIState {
  readonly rights: RightsBag;
  readonly active: string;
}

export interface UIState {
  readonly rightsing: Record<string, boolean>;
}

export enum AccessRight {
  READ = 'read',
  READ_WRITE = 'read_write',
}

export type RightsBag = Record<string, AccessRight>;

export function initialState(): CerberusState {
  return {
    rights: {},
    active: undefined,
    rightsing: {},
  };
}

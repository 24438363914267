import {
  BasicEquipment,
  HasCapacity,
  HasCOP,
  HasElecs,
  HasFirstYearValue,
  HasRefrigerants,
  HasYearlyProduction,
} from './equipment.types';
import { HasActivityId } from './generic.types';

export type HeatPump = BasicEquipment &
  HasActivityId &
  HasFirstYearValue &
  HasYearlyProduction &
  HasCapacity &
  HasElecs &
  HasRefrigerants &
  HasCOP;

export type HeatPumpForm = Pick<
  HeatPump,
  'name' | 'cop' | 'capacity' | 'yearlyProduction' | 'elecs' | 'refrigerants'
>;

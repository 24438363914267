import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { InfoPanelComponent } from './info-panel.component';

@NgModule({
  declarations: [InfoPanelComponent],
  imports: [CommonModule, FlexLayoutModule, MatIconModule],
  exports: [InfoPanelComponent],
})
export class InfoPanelModule {}

import { AssetRefrigerant } from 'declic-app/models';
import { Product } from 'declic-project/models';
import { NatureHeatProductionType } from './../common/nature-heat-production';

export interface OnsiteCogen extends Product {
  baselineRefrigerant?: AssetRefrigerant;
  projectRefrigerant?: AssetRefrigerant;
  historicalRefrigerant?: AssetRefrigerant;
  heatProduction?: NatureHeatProductionType;
}

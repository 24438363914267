import { Coerce } from 'declic-app/common/coercion.utils';

import { ASSUMPTION_CEILING } from 'declic-app/assumption/state';
import { YearlyEmission } from './computation.model';

export type FactorNature = 'annual' | 'average';
export enum InputNature {
  USER = 'user',
  DEFAULT = 'default',
  RESET = 'reset',
  CUSTOM = 'custom',
}

export interface AnnualFactor {
  averageValue: number | undefined;
  values: YearlyEmission[];
  nature: FactorNature;
  inputNature?: InputNature;
  isCascading?: boolean;
}

const createEmptyInstance = (years: number[] = []): AnnualFactor => ({
  averageValue: undefined,
  values: years.map((year) => ({ year, emissions: undefined })),
  nature: 'average',
  isCascading: true,
});

const resolveCorrectValueByNature = (
  instance: AnnualFactor,
  year: number,
): number => {
  const { averageValue, values, nature } = instance;
  const matchedYear = values.find((em) => em.year === year);
  return nature === 'average' ? averageValue : matchedYear.emissions;
};

function toAverageNature(instance: AnnualFactor): AnnualFactor | undefined {
  return instance ? { ...instance, nature: 'average' } : undefined;
}

function createAverage(
  averageValue: number,
  values: YearlyEmission[],
): AnnualFactor {
  return {
    ...createFactor(averageValue, values, 'average'),
    inputNature: InputNature.DEFAULT,
  };
}

function createAnnual(
  averageValue: number,
  values: YearlyEmission[],
): AnnualFactor {
  return {
    ...createFactor(averageValue, values, 'annual'),
    inputNature: InputNature.DEFAULT,
  };
}

function createFactor(
  averageValue: number,
  values: YearlyEmission[],
  nature: FactorNature,
): AnnualFactor {
  return {
    averageValue,
    values,
    nature,
    inputNature: InputNature.DEFAULT,
  };
}

function shouldPatch(current: InputNature, next: InputNature): boolean {
  return ![current === InputNature.USER, next === InputNature.DEFAULT].every(
    Boolean,
  );
}

function cascadeCeilingIfApplicable(
  emissions: YearlyEmission[],
): YearlyEmission[] {
  return !!findCeiling(emissions) ? cascadeCeiling(emissions) : emissions;
}

function cascadeCeiling(emissions: YearlyEmission[]): YearlyEmission[] {
  const ceiling = getCeilingValue(emissions);
  return emissions.map((em) => applyCeil(em, ceiling));
}

function applyCeil(emission: YearlyEmission, ceiling: number): YearlyEmission {
  return {
    ...emission,
    emissions: shouldApplyCeiling(emission) ? ceiling : emission.emissions,
  };
}

function shouldApplyCeiling(emission: YearlyEmission): boolean {
  return emission.year >= ASSUMPTION_CEILING;
}

function getCeilingValue(emissions: YearlyEmission[]): number {
  return Coerce.toObj(findCeiling(emissions)).emissions;
}

function findCeiling(emissions: YearlyEmission[]): YearlyEmission {
  return Coerce.toArr(emissions).find((em) => em.year === ASSUMPTION_CEILING);
}

export const AnnualFactorUtils = {
  createEmptyInstance,
  resolveCorrectValueByNature,
  toAverageNature,
  createAnnual,
  createAverage,
  shouldPatch,
  cascadeCeilingIfApplicable,
};

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { DEFAULT_ALLOWED_ORIGINS, environment } from '@declic/env';
import { OKTA_NONCE_KEY } from 'declic-app/app.tokens';
import { OktaAuthService } from 'declic-app/services/okta-auth';
import { Observable } from 'rxjs';
import { Coerce } from '../coercion.utils';

@Injectable()
export class OktaAuthInterceptor implements HttpInterceptor {
  private oktaAuth = inject(OktaAuthService);

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(this.addAuthHeaderToAllowedOrigins(request));
  }

  private addAuthHeaderToAllowedOrigins(
    request: HttpRequest<unknown>,
  ): HttpRequest<unknown> {
    let req = request;

    if (this.isInAllowedOrigins(request)) {
      const authToken = this.oktaAuth.getApiToken();
      req = request.clone({
        setHeaders: {
          Authorization: [`Bearer ${authToken}`, this.resolveNonce()],
        },
      });
    }

    return req;
  }

  private resolveNonce(): string {
    return Coerce.toString(sessionStorage.getItem(OKTA_NONCE_KEY));
  }

  private isInAllowedOrigins(request: HttpRequest<unknown>): boolean {
    return !!this.getAllowedOrigins().find((origin) =>
      request.url.includes(origin),
    );
  }

  private getAllowedOrigins(): string[] {
    return [
      ...DEFAULT_ALLOWED_ORIGINS,
      environment.api.baseUrl,
      environment.api.baseUrlUser,
    ];
  }
}

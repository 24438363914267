import { Coerce } from 'declic-app/common';
import { AnnualFactor, YearlyEmission } from 'declic-app/models';

function isValid(factor: AnnualFactor): boolean {
  return isAveragedNature(factor)
    ? isEmissionsValid(factor.averageValue)
    : isAnnualValid(factor);
}

function isAveragedNature(factor: AnnualFactor): boolean {
  return Coerce.toObj(factor).nature === 'average';
}

function isEmissionsValid(emissions: number): boolean {
  return !['null', 'undefined', '', 'NaN'].some(
    (val) => val === String(emissions),
  );
}

function isAnnualValid(factor: AnnualFactor): boolean {
  const safeFactor = Coerce.toObj(factor);
  return (
    Boolean(Coerce.toArr(safeFactor.values).length) &&
    areEmissionsValid(safeFactor.values)
  );
}

function areEmissionsValid(emissions: YearlyEmission[]): boolean {
  return Coerce.toArr(emissions).every((emission) =>
    isEmissionsValid(Coerce.toObj(emission).emissions),
  );
}

export const AnnualFactorValidator = {
  isValid,
  areEmissionsValid,
};

import {
  AuxiliaryMetadata,
  AuxiliaryProps,
  ProductAuxiliary,
} from 'declic-app/components/product-auxiliaries';
import { EnergySource } from 'declic-app/models';
import { ProductEditorService } from 'declic-product/services/product-editor';
import { Observable } from 'rxjs';
import { emissionApproach } from '../emissionApproach';
import { EnergySourceType } from '../energy-source';
import { AuxiliariesRegulator } from './auxiliaries-regulator';

export abstract class SourcesRegulator<
  T extends AuxiliaryMetadata = AuxiliaryMetadata
> extends AuxiliariesRegulator<EnergySource> {
  private localEditorService: ProductEditorService;

  abstract mapLocalToMeta(local: EnergySource): T;
  abstract mapMetaToLocal(meta: T): EnergySource;
  abstract nextOnInit(): void;

  constructor(editorService: ProductEditorService) {
    super();
    this.localEditorService = editorService;
  }

  mapAuxToLocalModel(aux: ProductAuxiliary): EnergySource {
    const { props, type, id } = aux;
    return {
      ...props,
      source: type as EnergySourceType,
      id,
      ...this.mapMetaToLocal(aux.meta as T),
    };
  }
  mapLocalToAuxModel(local: EnergySource): ProductAuxiliary<AuxiliaryMetadata> {
    const { id, source } = local;
    return {
      id,
      type: source,
      props: this.mapSourceToAuxProps(local),
      meta: this.mapLocalToMeta(local),
    };
  }
  getLocalType(local: EnergySource): string {
    return local.source;
  }

  auxiliaryOnInit(): void {
    this.subscribeToRefreshTriggersForAuxSyncing();
    this.nextOnInit();
  }
  getEmissionsApproachStream(): Observable<emissionApproach> {
    return this.localEditorService.emissionsApproach$;
  }

  private mapSourceToAuxProps(local: EnergySource): AuxiliaryProps {
    return ({ ...local } as unknown) as AuxiliaryProps;
  }
}

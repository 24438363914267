import { DeclicUtils } from 'declic-app/common';
import { EnvConfig } from 'declic-app/models/authentication/env-config.model';
import { Observable } from 'rxjs';
import { catchError, filter, map, pluck } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { withCache } from '@ngneat/cashew';

import { DECLICONFIG } from '@declic/env';
import { AccessRight } from './cerberus.state';

@Injectable({ providedIn: 'root' })
export class CerberusService {
  constructor(
    private readonly http: HttpClient,
    @Inject(DECLICONFIG) private readonly envConfig: EnvConfig,
  ) {}

  getProjectAccessRights(projectId: string): Observable<AccessRight> {
    return this.http.get(this.projectPermissionEndpoint(projectId)).pipe(
      filter((p) => !!p),
      pluck('permission'),
      map((rights: number) => this.mapToAccessRight(rights)),
    );
  }

  // increase count of active users
  open(id: string): void {
    this.http
      .post(this.accessStatusEndpoint(id), {
        status: 'opened',
      })
      .pipe(catchError((error) => error))
      .subscribe();
  }

  // decrease count of active users
  close(id: string): void {
    this.http
      .post(this.accessStatusEndpoint(id), {
        status: 'closed',
      })
      .subscribe();
  }

  count(id: string): Observable<number> {
    return this.http
      .get<{ count: number }>(this.countActiveUsersEndpoint(id), {
        context: withCache(),
      })
      .pipe(map((data) => data.count));
  }

  private mapToAccessRight(rights: number): AccessRight {
    // return AccessRight.READ;
    return rights === 65535 ? AccessRight.READ_WRITE : AccessRight.READ;
  }

  private countActiveUsersEndpoint(id: string): string {
    return DeclicUtils.buildEndpoint(
      this.getBaseUrl(),
      this.getAccessingProjectEndpoint(),
      id,
    );
  }

  private accessStatusEndpoint(id: string): string {
    return DeclicUtils.buildEndpoint(
      this.getBaseUrl(),
      this.getAccessStatusEndpoint(),
      id,
    );
  }

  private projectPermissionEndpoint(id: string): string {
    return DeclicUtils.buildEndpoint(
      this.getBaseUrl(),
      this.getProjectPermissionEndpoint(),
      id,
    );
  }

  private getAccessingProjectEndpoint(): string {
    return this.envConfig.api.endpoints['project']['count'];
  }

  private getAccessStatusEndpoint(): string {
    return this.envConfig.api.endpoints['project']['accessStatus'];
  }

  private getProjectPermissionEndpoint(): string {
    return this.envConfig.api.endpoints['project']['projectPermission'];
  }

  private getBaseUrl(): string {
    return this.envConfig.api.baseUrl;
  }
}

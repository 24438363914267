import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';

import { EditorStepIconSwitchComponent } from './editor-step-icon-switch.component';

@NgModule({
  declarations: [EditorStepIconSwitchComponent],
  imports: [CommonModule, FlexLayoutModule, MatIconModule],
  exports: [EditorStepIconSwitchComponent],
})
export class EditorStepIconSwitchModule {}

import { Coerce } from 'declic-app/common';

import { NetworkEditorModel, NetworkProjectType, ScopeOne } from '../models';
import {
  ElecInfraBE,
  ElecScopeOne,
  ElecScopeOneBE,
  ElecScopeThree,
  ElecScopeThreeBE,
  ElecScopeTwo,
} from '../models/elec.model';
import { NetworksProjectEditorMapper } from './networks-project.mapper';

export class ElecInfraMapper extends NetworksProjectEditorMapper<ElecInfraBE> {
  getType(): NetworkProjectType {
    return NetworkProjectType.EI;
  }

  mapEditorModelToProduct(editorModel: NetworkEditorModel): ElecInfraBE {
    return {
      ...editorModel,
      id: undefined,
      scopeOne: this.mapScopeOneToProduct(editorModel),
      scopeTwo: this.mapScopeTwoToProduct(editorModel),
      scopeThree: this.mapScopeThreeToProduct(editorModel),
    };
  }

  mapProductToEditorScope1(product: ElecInfraBE): ScopeOne {
    const ch4_filler = { sources: [] };
    return {
      ...product.scopeOne,
      mobileCombustion: this.mapMobileCombustionToEditor(
        Coerce.toObj(product.scopeOne).mobileCombustion,
      ),
      ch4DirectFugitive: ch4_filler,
      ch4GasFlaring: ch4_filler,
      ch4DirectVenting: ch4_filler,
    };
  }
  mapProductToEditorScope2(product: ElecInfraBE): ElecScopeTwo {
    return product.scopeTwo;
  }
  mapProductToEditorScope3(product: ElecInfraBE): ElecScopeThree {
    const filler = { sources: [] };
    return {
      ...product.scopeThree,
      mobileCombustion: this.mapMobileCombustionToEditor(
        Coerce.toObj(product.scopeThree).mobileCombustion,
      ),
    };
  }

  private mapScopeOneToProduct(editor: NetworkEditorModel): ElecScopeOneBE {
    return {
      ...(editor.scopeOne as ElecScopeOne),
      mobileCombustion: this.mapEditorToMobileCombustion(
        Coerce.toObj(editor.scopeOne as ElecScopeOne).mobileCombustion,
      ),
    };
  }

  private mapScopeThreeToProduct(editor: NetworkEditorModel): ElecScopeThreeBE {
    return {
      ...(editor.scopeThree as ElecScopeThree),
      mobileCombustion: this.mapEditorToMobileCombustion(
        Coerce.toObj(editor.scopeThree as ElecScopeThree).mobileCombustion,
      ),
    };
  }

  private mapScopeTwoToProduct(editor: NetworkEditorModel): ElecScopeTwo {
    return {
      ...(editor.scopeTwo as ElecScopeTwo),
    };
  }
}

import { DeclicUtils } from 'declic-app/common';
import { EnergySourceUtils } from 'declic-app/models/energy-source.model';
import { PlEmissions, PublicLighting } from 'declic-pl/models/';
import { ProductEditorModel } from 'declic-product/components/product-editor';
import { ProductType } from 'declic-project/models';
import { ProductEditorMapper } from './product.mapper';

export class PublicLightingMapper extends ProductEditorMapper<PublicLighting> {
  getType(): ProductType {
    return ProductType.PL;
  }
  mapEditorModelToProduct(editorModel: ProductEditorModel): PublicLighting {
    return {
      ...editorModel,
      id: undefined,
      baselineEmissions: this.mapBaselineToProduct(editorModel),
      projectEmissions: this.mapProjectToProduct(editorModel),
      historicalEmissions: this.mapHistoricalToProduct(editorModel),
    };
  }
  mapProductToEditorGeneral(_: PublicLighting) {}
  mapProductToEditorProjectEmissions(product: PublicLighting): PlEmissions {
    return { ...product.projectEmissions };
  }
  mapProductToEditorBaselineEmissions(product: PublicLighting): PlEmissions {
    return { ...product.baselineEmissions };
  }
  mapProductToEditorHistorical(product: PublicLighting): PlEmissions {
    return { ...product.historicalEmissions };
  }

  private mapBaselineToProduct(editor: ProductEditorModel): PlEmissions {
    return editor.baselineEmissions as PlEmissions;
  }

  private mapProjectToProduct(editor: ProductEditorModel): PlEmissions {
    return editor.projectEmissions as PlEmissions;
  }

  private mapHistoricalToProduct(editor: ProductEditorModel): PlEmissions {
    return this.coerceToHistoricalAve(
      DeclicUtils.resolveToEmptyObj(editor.historicalEmissions) as PlEmissions,
    );
  }

  private coerceToHistoricalAve(emissions: PlEmissions): PlEmissions {
    return {
      ...emissions,
      electricity: EnergySourceUtils.coerceToAverageEFs(emissions.electricity),
    };
  }
}

import { CreatedProject } from '@declic/types';
import { propsFactory } from '@ngneat/elf';

export const {
  withRecentlyProcessed,
  getRecentlyProcessed,
  selectRecentlyProcessed,
  setRecentlyProcessed,
  resetRecentlyProcessed,
} = propsFactory('recentlyProcessed', {
  initialValue: null as string,
});

export const { withHyperjump, getHyperjump, resetHyperjump, setHyperjump } =
  propsFactory('hyperjump', {
    initialValue: null as string,
  });

export const {
  getSavingOne,
  selectSavingOne,
  setSavingOne,
  withSavingOne,
  resetSavingOne,
} = propsFactory('savingOne', { initialValue: false });

export const { selectLoading, setLoading, withLoading, resetLoading } =
  propsFactory('loading', {
    initialValue: false,
  });

export const { selectLoadingOne, withLoadingOne, setLoadingOne } = propsFactory(
  'loadingOne',
  { initialValue: false },
);

export const { withLoaded, selectLoaded, getLoaded } = propsFactory('loaded', {
  initialValue: false,
});
export const { withError, selectError, getError, setError, resetError } =
  propsFactory('error', {
    initialValue: undefined,
  });
export const { withExporting, selectExporting } = propsFactory('exporting', {
  initialValue: false,
});
export const { withGeneratingReport, selectGeneratingReport } = propsFactory(
  'generatingReport',
  { initialValue: false },
);

export const {
  setCreatedProjectId,
  selectCreatedProjectId,
  withCreatedProjectId,
} = propsFactory('createdProjectId', {
  initialValue: null as string,
});

export const { setCreatedProject, selectCreatedProject, withCreatedProject } =
  propsFactory('createdProject', {
    initialValue: null as CreatedProject,
  });

export const {
  withEditingHistoricalYear,
  setEditingHistoricalYear,
  selectEditingHistoricalYear,
} = propsFactory('editingHistoricalYear', {
  initialValue: false,
});

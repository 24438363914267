import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  CategoryScale,
  Chart,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
} from 'chart.js';
import { SparklineComponent } from './sparkline.component';

@NgModule({
  declarations: [SparklineComponent],
  imports: [CommonModule, FlexLayoutModule],
  exports: [SparklineComponent],
})
export class SparklineModule {
  static forFeature(): ModuleWithProviders<SparklineModule> {
    Chart.register(
      LineController,
      CategoryScale,
      LinearScale,
      PointElement,
      LineElement,
    );
    return { ngModule: SparklineModule };
  }
}

<p>
  @for (part of parts(); track $index) {
    @switch (part.verticality) {
      @case ('sub') {
        <sub>{{ part.text }}</sub>
      }
      @case ('super') {
        <sup>{{ part.text }}</sup>
      }
      @default {
        {{ part.text }}
      }
    }
  }
  <sup></sup>
  <sub></sub>
</p>

import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import {
  NetworkProjectAlias,
  NetworkProjectAliases,
} from 'declic-app/networks-project-emissions/models';
import { ProductQueries } from 'declic-app/product/state';
import { ProjectStoreQueries } from 'declic-app/project/state';
import { GBUNService } from 'declic-app/stores';
import { Observable, map, take } from 'rxjs';

export const sourcesResolver: ResolveFn<void> = () => {
  const gbuNService = inject(GBUNService);
  selectActiveTypeAlias()
    .pipe(take(1))
    .subscribe((alias) => {
      if (alias) {
        gbuNService
          .getSources(alias, ProjectStoreQueries.getActiveLocation())
          .pipe(take(1))
          .subscribe();
      }
    });
};

function selectActiveTypeAlias(): Observable<NetworkProjectAlias> {
  return ProductQueries.selectActiveType().pipe(
    map((type) => NetworkProjectAliases[type]),
  );
}

/* eslint-disable */
import { UntypedFormControl } from '@angular/forms';

import { Activity, ESActivity, NActivity, RActivity } from './activity.types';
import { EquipmentFuel } from './emission-factor.types';
import {
  EnergyType,
  HasEnergyProducedType,
  HasEnergySoldType,
} from './energy.types';
import { HasFuelingStationType } from './fueling-station.types';
import {
  Factor,
  HasActivityId,
  HasBeingDeleted,
  HasId,
  HasName,
  HasPayload,
  HasProjectId,
  TableEntity,
} from './generic.types';

export type HasEquipmentType = { type: Equipment };
export type GetAllEquipmentParams = HasEquipmentType &
  HasProjectId &
  HasActivityId;
export type SingleEquipmentParams = HasId &
  HasProjectId &
  HasActivityId &
  HasEquipmentType;
export type CreateEquipParams = Omit<SingleEquipmentParams, 'id'> &
  HasPayload &
  HasEquipmentType;
export type EditEquipParams = SingleEquipmentParams & HasPayload;

export enum ProductionType {
  YEARLY_PROD = 'yearlyProd',
  FIRST_YEAR_AND_EFF_LOSS = 'firstYearAndEffLoss',
}
export const PROD_TYPES = Object.values(ProductionType);

export enum GHGEmissionsApproach {
  DIRECT = 'direct',
  LIFECYCLE = 'lifecycle',
}
export const GHG_EMISSIONS_APPROACHES = Object.values(GHGEmissionsApproach);

export enum BatteryType {
  CO_LOCALIZED = 'coLocalized',
  CO_OPTIMIZED = 'coOptimized',
}
export const BATTERY_TYPES = Object.values(BatteryType);

export type HasProductionType = { productionType: ProductionType };
export type HasYearlyProduction = { yearlyProduction: Factor };
export type HasYearlySold = { yearlySold: Factor };
export type HasEfficiencyLoss = { efficiencyLoss: Factor };
export type HasGHGEmissionsApproach = {
  ghgEmissionsApproach: GHGEmissionsApproach;
};
export type HasFirstYearValue = { firstYearValue: number };
export type HasAssetEfficiency = { assetEfficiency: number };
export type HasCapacity = { capacity: number };

export type HasBatteryType = { batteryType: BatteryType };
export type HasBatteryEfficiency = { batteryEfficiency: number };
export type HasBatteryCapacity = { batteryCapacity: number };

export type AssetFuel = {
  fuel: string;
  emissionFactor: number;
  percentage: number;
  fuelType?: string;
};
export type HasFuels = { fuels: AssetFuel[] };

export type AssetElec = {
  elec: string;
  emissionFactor: Record<number, number>;
  percentage: number;
};

export type AssetInputType = {
  inputType: string;
  emissionFactor: Record<number, number>;
  percentage: number;
  input: string;
};

export type HasElecs = Partial<{ elecs: AssetElec[] }>;
export type HasInputs = Partial<{ inputTypes: AssetInputType[] }>;

export type HasCOP = { cop: number };

export type AssetRefrigerant = {
  refrigerant: string;
  gwp: number;
  volume: number;
};

export type EquipmentAsset = AssetElec &
  AssetFuel &
  AssetRefrigerant &
  AssetInputType;

export type HasRefrigerants = Partial<{ refrigerants: AssetRefrigerant[] }>;

export type BasicEquipment = TableEntity &
  HasEquipmentType &
  HasEnergyProducedType;
export type IsPV = HasProductionType &
  HasYearlyProduction &
  HasEfficiencyLoss &
  HasFirstYearValue;

export type PVSystem = TableEntity &
  HasYearlyProduction &
  HasEfficiencyLoss &
  HasProductionType &
  HasCapacity &
  HasFirstYearValue;

export type EquipmentForm = HasName & HasCapacity;

export type PVSystemForm = {
  name: UntypedFormControl;
  yearlyProduction: Factor;
  efficiencyLoss: Factor;
  productionType: string;
  firstYearValue: UntypedFormControl;
} & HasCapacity;

export type UpsertPvData = { existing: PVSystem };

export enum Equipments {
  PV_SYTEM = 'pv',
  BOILER = 'boiler',
  COGEN = 'cogen',
  EV_CHARGING = 'evc',
  HEAT_PUMP = 'hpump',
  STANDALONE_CHILLER = 'standaloneChiller',
  THERMAL_ENERGY_EXCHANGER = 'thermalEnergy',
  PV_POWER_PLANT = 'pvp',
  ONSHORE_WIND_FARM = 'onshoreWindFarm',
  OFFSHORE_WIND_FARM = 'offshoreWindFarm',
  SOLAR_PV = 'solarPv',
  CONVENTIONAL_SOLAR_THERMAL = 'conventionalThermal',
  HEAT_PUMP_HC = 'hpumphc',
  TRIGENERATION = 'trigeneration',
  ABSORPTION_CHILLER = 'absorptionChiller',
  THERMAL_STORAGE = 'thermalStorage',
  INCINERATOR = 'incinerator',
  GEOTHERMAL_UNIT_EXCHANGER = 'geothermalUnit',
  THALASSO_THERMAL = 'thalassoThermal',
  WIND_TURBINE = 'windTurbine',
  POWER_STORAGE = 'powerStorage',
  FUELING_STATION = 'fuelingStation',
  LIGHT_POINT = 'lightPoint',
  HYDRO_PLANT = 'hydroPlant',
  POWER_GENERATOR = 'powerGen',
  HYDRO_RESERVOIR = 'hydroReservoir',
  HYDRO_RUNUP_RIVER = 'hydroRunUpRiver',
  ELEC_GRID = 'gridElec',
  SOLAR_PV_BATTERY = 'solarPvBattery',
}

export const SoonEquipments = [
  Equipments.SOLAR_PV,
  Equipments.CONVENTIONAL_SOLAR_THERMAL,
  Equipments.TRIGENERATION,
  Equipments.THERMAL_STORAGE,
  Equipments.INCINERATOR,
  Equipments.GEOTHERMAL_UNIT_EXCHANGER,
  Equipments.THALASSO_THERMAL,
  Equipments.WIND_TURBINE,
  Equipments.POWER_STORAGE,
  Equipments.LIGHT_POINT,
  Equipments.HYDRO_PLANT,
  Equipments.POWER_GENERATOR,
];

export const LEGEquipment = {
  PV_SYTEM: Equipments.PV_SYTEM,
  WIND_TURBINE: Equipments.WIND_TURBINE,
  HYDRO_PLANT: Equipments.HYDRO_PLANT,
  POWER_GENERATOR: Equipments.POWER_GENERATOR,
};

export const DHCEquipment = {
  BOILER: Equipments.BOILER,
  COGEN: Equipments.COGEN,
  HEAT_PUMP: Equipments.HEAT_PUMP,
  HEAT_PUMP_HC: Equipments.HEAT_PUMP_HC,
  STANDALONE_CHILLER: Equipments.STANDALONE_CHILLER,
  THERMAL_ENERGY_EXCHANGER: Equipments.THERMAL_ENERGY_EXCHANGER,
  CONVENTIONAL_SOLAR_THERMAL: Equipments.CONVENTIONAL_SOLAR_THERMAL,
  TRIGENERATION: Equipments.TRIGENERATION,
  INCINERATOR: Equipments.INCINERATOR,
  THERMAL_STORAGE: Equipments.THERMAL_STORAGE,
};

export const OUEquipment = {
  BOILER: Equipments.BOILER,
  COGEN: Equipments.COGEN,
  HEAT_PUMP: Equipments.HEAT_PUMP,
  HEAT_PUMP_HC: Equipments.HEAT_PUMP_HC,
  STANDALONE_CHILLER: Equipments.STANDALONE_CHILLER,
  THERMAL_ENERGY_EXCHANGER: Equipments.THERMAL_ENERGY_EXCHANGER,
  CONVENTIONAL_SOLAR_THERMAL: Equipments.CONVENTIONAL_SOLAR_THERMAL,
  TRIGENERATION: Equipments.TRIGENERATION,
  HYDRO_PLANT: Equipments.HYDRO_PLANT,
  INCINERATOR: Equipments.INCINERATOR,
  POWER_GENERATOR: Equipments.POWER_GENERATOR,
  THERMAL_STORAGE: Equipments.THERMAL_STORAGE,
};
export const EPSEquipment = OUEquipment;

export const DSSEquipment = {
  SOLAR_PV: Equipments.SOLAR_PV,
  SOLAR_PV_BATTERY: Equipments.SOLAR_PV_BATTERY,
  WIND_TURBINE: Equipments.WIND_TURBINE,
  POWER_STORAGE: Equipments.POWER_STORAGE,
};

export const PLEquipment = {
  LIGHT_POINT: Equipments.LIGHT_POINT,
};

export const LCMEquipment = {
  EVC: Equipments.EV_CHARGING,
  FUELING_STATION: Equipments.FUELING_STATION,
};

export const GPGEquipment = {
  PV_POWER_PLANT: Equipments.PV_POWER_PLANT,
  ONSHORE_WIND_FARM: Equipments.ONSHORE_WIND_FARM,
  OFFSHORE_WIND_FARM: Equipments.OFFSHORE_WIND_FARM,
  HYDRO_RESERVOIR: Equipments.HYDRO_RESERVOIR,
  HYDRO_RUNUP_RIVER: Equipments.HYDRO_RUNUP_RIVER,
};

export type Equipment = Equipments;

export const NON_EFFICIENCY_EQUIPS: Equipment[] = [LEGEquipment.PV_SYTEM];

export const ACTIVITY_EQUIPMENTS: Record<Activity, Equipment[]> = {
  [ESActivity.DISTRICT_HEATING_COOLING]: Object.values(DHCEquipment),
  [ESActivity.DISTRIBUTED_SOLAR_AND_STORAGE]: Object.values(DSSEquipment),
  [ESActivity.ON_SITE_UTILS]: Object.values(OUEquipment),
  [ESActivity.LOW_CARBON_MOBILITY]: Object.values(LCMEquipment),
  [ESActivity.ENERGY_PERF_SERVICES]: Object.values(EPSEquipment),
  [ESActivity.LOCAL_ENERGY_GRID]: Object.values(LEGEquipment),
  [ESActivity.PUBLIC_LIGHTING]: Object.values(PLEquipment),
  [RActivity.GREEN_POWER_GENERATION]: Object.values(GPGEquipment),
  [NActivity.BIOMETHANE_PRODUCTION]: [],
  [NActivity.ELECTRICAL_TRANSPORTATION_AND_DISTRIBUTION]: [],
  [NActivity.GAS_STORAGE]: [],
  [NActivity.GAS_TRANSPORTATION_AND_DISTRIBUTION]: [],
  [NActivity.LNG_TERMINALS]: [],
};

export type EquipmentBE = PVSystem;

export type GetEquipmentsRsp = { equipments: EquipmentBE[] };

export type HistoricalEquipment = HasId & {
  name: string;
  efficiency: number;
  equipment: Equipment;
  productionHeat: number;
  productionCold: number;
  productionPower: number;
  fuel: string;
  emissionsFactor: number;
  status?: ComputationReadiness;
  hasEfficiency?: boolean;
  hasCop?: boolean;
} & Partial<HasBeingDeleted>;

export enum ComputationReadiness {
  R = 'r',
  NR = 'nr',
}

export type HistoricalEquipmentForm = Pick<
  HistoricalEquipment,
  | 'name'
  | 'efficiency'
  | 'equipment'
  | 'productionHeat'
  | 'productionCold'
  | 'productionPower'
> & { fuel: EquipmentFuel };

export type GetHistoEquipsRsp = { historicalEquipments: HistoricalEquipment[] };

export type CreateHistoEquipArgs = {
  projectId: string;
  activityId: string;
  payload: unknown;
};

export type EditHistoEquipArgs = CreateHistoEquipArgs & HasId;

export type DeleteHistoArgs = Omit<EditHistoEquipArgs, 'payload'>;

export const EnergyProducedTypes: Partial<Record<Equipment, EnergyType[]>> = {
  pv: [EnergyType.POWER],
  boiler: [EnergyType.HEAT],
  evc: [EnergyType.POWER],
  hpump: [EnergyType.HEAT],
  standaloneChiller: [EnergyType.COLD],
  hpumphc: [EnergyType.HEAT, EnergyType.COLD],
  cogen: [EnergyType.HEAT, EnergyType.POWER],
  solarPv: [EnergyType.POWER],
  windTurbine: [EnergyType.POWER],
  hydroPlant: [EnergyType.POWER],
  incinerator: [EnergyType.HEAT, EnergyType.POWER],
  thermalEnergy: [EnergyType.HEAT, EnergyType.COLD],
  powerGen: [EnergyType.POWER],
  geothermalUnit: [EnergyType.HEAT, EnergyType.COLD],
  gridElec: [EnergyType.POWER],
  conventionalThermal: [EnergyType.HEAT],
  solarPvBattery: [EnergyType.POWER],
};

export type WithMultipleEnergyDetails = {
  energyTypes: EnergyType[];
  capacities: number[];
  yearlyProductions: Factor[];
};

export const SourcesDistributionEquipments = {
  fuels: [Equipments.COGEN, Equipments.THERMAL_ENERGY_EXCHANGER],
  inputTypes: [
    Equipments.BOILER,
    Equipments.STANDALONE_CHILLER,
    Equipments.FUELING_STATION,
  ],
  elecs: [
    Equipments.HEAT_PUMP,
    Equipments.HEAT_PUMP_HC,
    Equipments.EV_CHARGING,
  ],
  refrigerants: [
    Equipments.HEAT_PUMP,
    Equipments.HEAT_PUMP_HC,
    Equipments.STANDALONE_CHILLER,
  ],
};

export type SourcesDistributionEquipmentsKeys =
  keyof typeof SourcesDistributionEquipments;

export type EquipmentDisplayProps = HasName &
  HasYearlySold &
  HasEnergySoldType &
  HasEnergyProducedType &
  HasCapacity &
  HasProductionType &
  HasYearlyProduction &
  HasFuels &
  HasElecs &
  HasInputs &
  Pick<
    HistoricalEquipment,
    'equipment' | 'fuel' | 'emissionsFactor' | 'efficiency'
  > &
  WithMultipleEnergyDetails &
  HasRefrigerants &
  HasGHGEmissionsApproach &
  HasFuelingStationType;

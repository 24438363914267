import { AssetType } from 'declic-app/common';
import { AnnualFactor } from 'declic-app/models';

export type EmissionsType = 'project' | 'baseline' | 'historical';

export interface ProjectEmission {
  id: string;
  asset: AssetType;
  energyProduced: AnnualFactor;
  emissionFactor: AnnualFactor;
  conversionEfficiency?: AnnualFactor;
}

import { Coerce } from 'declic-app/common';
import { NetworkProjectAlias } from 'declic-app/networks-project-emissions/models';
import { EndpointProvider } from 'declic-app/services/endpoints';
import { map, Observable, tap } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@declic/env';
import {
  GBUNSource,
  GBUNSourcesRsp,
  GBUNSourcesWithValuesRsp,
  GBUNState,
} from '@declic/types';
import { withCache } from '@ngneat/cashew';
import { createStore, setProp, withProps } from '@ngneat/elf';

export const gbuNStore = createStore(
  { name: 'gbu-n' },
  withProps<GBUNState>({
    sources: { scope1: {}, scope2: {}, scope3: {} },
  }),
);

@Injectable({ providedIn: 'root' })
export class GBUNService {
  private http = inject(HttpClient);
  private endpoints = inject(EndpointProvider);

  getSources(
    projectType: NetworkProjectAlias,
    location?: string,
  ): Observable<unknown> {
    location = Coerce.toString(location).toLowerCase().replace(/\s/g, '');
    return this.http
      .get(this.endpoints.forGbuNSources(projectType), {
        context: withCache(),
        params: { location, v: environment.appVersion },
      })
      .pipe(
        tap((rsp: GBUNSourcesWithValuesRsp) =>
          gbuNStore.update(setProp('sources', rsp)),
        ),
      );
  }
}

function selectSourcesSlice<T = string>(
  scope: keyof GBUNSourcesRsp,
  category: string,
): Observable<T[]> {
  return gbuNStore.pipe(
    map((s) => s.sources as GBUNSourcesRsp),
    map((sources) => sources?.[scope]?.[category] ?? []),
  ) as Observable<T[]>;
}

function getSourcesSlice(
  scope: keyof GBUNSourcesRsp,
  category: string,
): GBUNSource[] {
  return gbuNStore.value.sources[scope][category] as GBUNSource[];
}

function selectSliceSourceNames(
  scope: keyof GBUNSourcesRsp,
  cat: string,
): Observable<string[]> {
  return gbuNStore.pipe(
    map((s) => s.sources as GBUNSourcesWithValuesRsp),
    map((s) =>
      Coerce.toArr(Coerce.toObj(Coerce.toObj(s)[scope])[cat]).map(
        (source) => source.name,
      ),
    ),
  );
}

function getSource(scope: keyof GBUNSourcesRsp, cat: string, name: string) {
  return Coerce.toArr(gbuNStore.value.sources[scope][cat] as GBUNSource[]).find(
    (source) => source.name === name,
  );
}

export const GbuNStoreQueries = {
  selectSourcesSlice,
  selectSliceSourceNames,
  getSourcesSlice,
  getSource,
};

import { HasEquipmentEnergies } from './cogen.types';
import { EnergyType } from './energy.types';
import {
  BasicEquipment,
  HasCOP,
  HasElecs,
  HasRefrigerants,
  WithMultipleEnergyDetails,
} from './equipment.types';

export type HpumpHc = Omit<BasicEquipment, 'energyProducedType'> &
  HasCOP &
  HasElecs &
  HasEquipmentEnergies &
  HasRefrigerants;

export type HpumpHcForm = Pick<
  HpumpHc,
  'name' | 'cop' | 'elecs' | 'energies' | 'refrigerants'
>;

export const HPUMP_HC_ENERGY_TYPES: EnergyType[] = [
  EnergyType.HEAT,
  EnergyType.COLD,
];

export type HpumpHcTableRow = HpumpHc & WithMultipleEnergyDetails;

import { AnnualFactor } from 'declic-app/models';

export type ScopeTwo = Readonly<
  Partial<{
    electricityConsumption: Electricity;
  }>
>;

export type Electricity = Readonly<
  Partial<{
    sources: ElecSources[];
  }>
>;

export type ElecSources = Readonly<
  Partial<{
    gef: AnnualFactor;
    elecVolume: AnnualFactor;
    source: string;
    id: string;
  }>
>;

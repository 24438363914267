import {
  BasicEquipment,
  HasCapacity,
  HasElecs,
  HasYearlyProduction,
} from './equipment.types';

export type EVCharging = BasicEquipment &
  HasYearlyProduction &
  HasElecs &
  HasCapacity;

export type EVCForm = Pick<
  EVCharging,
  'name' | 'yearlyProduction' | 'elecs' | 'capacity'
>;

import { filterNil } from '@ngneat/elf';
import { selectActiveEntity } from '@ngneat/elf-entities';
import { ProductsType } from 'declic-project/models';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { productStore } from './product.repository';

function selectActiveType(): Observable<ProductsType> {
  return productStore.pipe(selectActiveEntity(), filterNil(), pluck('type'));
}

export const ProductQueries = {
  selectActiveType,
};

/* eslint-disable max-lines */
import { parseTemplate } from 'url-template';

import { Inject, Injectable } from '@angular/core';
import { BASE_URL, ENDPOINTS } from '@declic/env';
import { HasId } from '@declic/types';

export type ActivityEntitiesParams = {
  projectId: string;
  activityId: string;
  entityType: string;
};

@Injectable({ providedIn: 'root' })
export class EndpointProvider {
  constructor(
    @Inject(BASE_URL) private readonly baseUrl: string,
    @Inject(ENDPOINTS) private readonly endpoints: Record<string, string>,
  ) {}

  forHistoricalTypes(activity: string): string {
    return parseTemplate(
      this.extractEndpointAndPrefix('gbuEsHistoricalTypes'),
    ).expand({ activity });
  }

  forRefrigerants(equipmentType: string): string {
    return parseTemplate(
      this.extractEndpointAndPrefix('assumptionRefrigerants'),
    ).expand({ equipmentType });
  }

  forGbuNSources(projectType: string): string {
    return parseTemplate(this.extractEndpointAndPrefix('gbuNSources')).expand({
      projectType,
    });
  }

  forGbuEsSources(equipmentType: string): string {
    return parseTemplate(this.extractEndpointAndPrefix('gbuEsSources')).expand({
      equipmentType,
    });
  }

  forPseudoErr(): string {
    return this.extractEndpointAndPrefix('pseudoError');
  }

  forActivitySummary(projectId: string, activityId: string): string {
    return parseTemplate(
      this.extractEndpointAndPrefix('activitySummary'),
    ).expand({
      projectId,
      activityId,
    });
  }

  forFuels(): string {
    return this.extractEndpointAndPrefix('v2Fuels');
  }

  forActivityEntities(params: ActivityEntitiesParams): string {
    return parseTemplate(
      this.extractEndpointAndPrefix('activityEntities'),
    ).expand(params);
  }

  forActivityEntity(params: ActivityEntitiesParams & HasId): string {
    return parseTemplate(
      this.extractEndpointAndPrefix('activityEntity'),
    ).expand(params);
  }

  forHistoEquip(projectId: string, activityId: string, id: string): string {
    return parseTemplate(this.extractEndpointAndPrefix('histoEquip')).expand({
      projectId,
      activityId,
      id,
    });
  }

  forActivity(projectId: string, activityId: string): string {
    return parseTemplate(this.extractEndpointAndPrefix('activity')).expand({
      projectId,
      activityId,
    });
  }

  forHistoricalEquipments(projectId: string, activityId: string): string {
    return parseTemplate(
      this.extractEndpointAndPrefix('historicalEquips'),
    ).expand({
      projectId,
      activityId,
    });
  }

  forEquipments(projectId: string, activityId: string): string {
    return parseTemplate(this.extractEndpointAndPrefix('equipments')).expand({
      projectId,
      activityId,
    });
  }

  forEquipment(projectId: string, activityId: string, id: string): string {
    return parseTemplate(this.extractEndpointAndPrefix('equipment')).expand({
      projectId,
      activityId,
      id,
    });
  }

  forFeatureFlags(env: string): string {
    return parseTemplate(this.endpoints['featureFlags']).expand({ env });
  }

  forLegacyProjects(): string {
    return this.extractEndpointAndPrefix('legacyProjects');
  }

  forProjects(): string {
    return this.extractEndpointAndPrefix('projects');
  }

  forProject(id: string): string {
    return parseTemplate(this.extractEndpointAndPrefix('project')).expand({
      id,
    });
  }

  forProducts(id: string): string {
    return parseTemplate(this.extractEndpointAndPrefix('products')).expand({
      id,
    });
  }

  forActivities(id: string): string {
    return parseTemplate(this.extractEndpointAndPrefix('activities')).expand({
      id,
    });
  }

  forProduct(project: string, id: string): string {
    return parseTemplate(this.extractEndpointAndPrefix('product')).expand({
      project,
      id,
    });
  }

  forComputation(id: string): string {
    return parseTemplate(
      this.extractEndpointAndPrefix('computeProject'),
    ).expand({
      id,
    });
  }

  forPowerGridAssumptions(): string {
    return this.extractEndpointAndPrefix('powerGrids');
  }

  forGasMixAssumptions(): string {
    return this.extractEndpointAndPrefix('gasMixes');
  }

  forFuelAssumptions(): string {
    return this.extractEndpointAndPrefix('fuels');
  }

  forMobilityAssumptions(): string {
    return this.extractEndpointAndPrefix('mobilities');
  }

  forFluidAssumptions(): string {
    return this.extractEndpointAndPrefix('fluids');
  }

  forEfficiencyRateAssumptions(): string {
    return this.extractEndpointAndPrefix('efficiencyRates');
  }

  forOtherGasesAssumptions(): string {
    return this.extractEndpointAndPrefix('otherGases');
  }

  forElectricityAssumptions(): string {
    return this.extractEndpointAndPrefix('electricity');
  }

  forFeedstockAssumptions(): string {
    return this.extractEndpointAndPrefix('feedstock');
  }

  forCountryAssumptions(): string {
    return this.extractEndpointAndPrefix('countries');
  }

  forGenerateReport(id: string): string {
    return this.parse('generateReport', { id });
  }

  forDuplicateProject(id: string): string {
    return this.parse('duplicateProject', { id });
  }

  forShareProject(id: string): string {
    return parseTemplate(this.extractEndpointAndPrefix('shareProject')).expand({
      id,
    });
  }

  forProjFuels(equipmentType: string): string {
    return parseTemplate(
      this.extractEndpointAndPrefix('assumptionProjFuels'),
    ).expand({ equipmentType });
  }

  forProjElecs(equipmentType: string): string {
    return parseTemplate(
      this.extractEndpointAndPrefix('assumptionProjElecs'),
    ).expand({ equipmentType });
  }

  private parse(key: string, params: Record<string, string>): string {
    return parseTemplate(this.extractEndpointAndPrefix(key)).expand(params);
  }

  private extractEndpointAndPrefix(key: string): string {
    return this.prefixBaseUrl(this.endpoints[key]);
  }

  private prefixBaseUrl(endpoint: string): string {
    return `${this.baseUrl}${endpoint}`;
  }
}

import { Coerce, DeclicUtils } from 'declic-app/common';
import { refrigirants } from 'declic-app/common/refrigirants';
import { RefrigerantForm } from 'declic-app/components/asset-refrigirant';
import { AssetRefrigerant } from 'declic-app/models';
import { EnergySourceUtils } from 'declic-app/models/energy-source.model';
import { OgsoEmissionsFormModel } from 'declic-onsite/components/ogso-emissions';
import {
  OnsiteGeneration,
  OnsiteGenerationEmission,
} from 'declic-onsite/models';
import { ProductEditorModel } from 'declic-product/components/product-editor';
import { ProductType } from 'declic-project/models';
import { ProductEditorMapper } from './product.mapper';

export class OnsiteGenerationMapper extends ProductEditorMapper<OnsiteGeneration> {
  getType(): ProductType {
    return ProductType.OGSO;
  }
  mapEditorModelToProduct(editorModel: ProductEditorModel): OnsiteGeneration {
    return {
      ...editorModel,
      id: undefined,
      natureEnergy: undefined,
      heatProduction: undefined,
      projectRefrigerant: this.toProjRef(editorModel),
      baselineRefrigerant: this.toBaseRef(editorModel),
      historicalRefrigerant: this.toHistoRef(editorModel),
      projectEmissions: this.toProjectEmissions(editorModel),
      baselineEmissions: this.toBaselineEmissions(editorModel),
      historicalEmissions: this.toHistoricalEmissions(editorModel),
    };
  }

  mapProductToEditorGeneral(product: OnsiteGeneration): unknown {
    return {};
  }

  mapProductToEditorProjectEmissions(
    product: OnsiteGeneration,
  ): OgsoEmissionsFormModel {
    return this.createEditorEmissions(
      product.projectEmissions,
      product.projectRefrigerant,
    );
  }

  mapProductToEditorBaselineEmissions(
    product: OnsiteGeneration,
  ): OgsoEmissionsFormModel {
    return this.createEditorEmissions(
      product.baselineEmissions,
      product.baselineRefrigerant,
    );
  }

  mapProductToEditorHistorical(product: OnsiteGeneration): unknown {
    return this.createEditorEmissions(
      product.historicalEmissions,
      product.historicalRefrigerant,
    );
  }

  toEmissions(from: OgsoEmissionsFormModel): OnsiteGenerationEmission {
    const {
      annualEnergy,
      calcApproach,
      energySources,
      nature,
    } = DeclicUtils.resolveToEmptyObj(from);
    return {
      calculationApproachType: calcApproach,
      energySources,
      elecProduced: annualEnergy,
      ...super.mapNatureToModel(Coerce.toObj(nature)),
    };
  }

  createEditorEmissions(
    from: OnsiteGenerationEmission,
    ref: AssetRefrigerant,
  ): OgsoEmissionsFormModel {
    const {
      calculationApproachType,
      energySources,
      elecProduced,
    } = DeclicUtils.resolveToEmptyObj(from);
    return {
      refrigerant: this.toEditorRefrigerant(DeclicUtils.resolveToEmptyObj(ref)),
      annualEnergy: elecProduced,
      calcApproach: calculationApproachType,
      energySources,
      useRefrigerant: Coerce.toObj(ref).useRefrigerant,
      nature: super.mapNatureToForm(Coerce.toObj(from)),
    };
  }

  private toEditorRefrigerant(refrigerant: AssetRefrigerant): RefrigerantForm {
    return {
      emissionsFactor: DeclicUtils.resolveToEmptyObj(
        refrigerant.emissionsFactor,
      ).averageValue,
      leaks: refrigerant.leaks,
      refrigerant: refrigerant.refrigerant as refrigirants,
    };
  }

  private toProjRef(editor: ProductEditorModel): AssetRefrigerant {
    return this.toAssetRef(editor.projectEmissions as OgsoEmissionsFormModel);
  }
  private toBaseRef(editor: ProductEditorModel): AssetRefrigerant {
    return this.toAssetRef(editor.baselineEmissions as OgsoEmissionsFormModel);
  }
  private toHistoRef(editor: ProductEditorModel): AssetRefrigerant {
    return this.toAssetRef(
      DeclicUtils.resolveToEmptyObj(
        editor.historicalEmissions,
      ) as OgsoEmissionsFormModel,
    );
  }

  private toAssetRef(emissions: OgsoEmissionsFormModel): AssetRefrigerant {
    const refrigerant = DeclicUtils.resolveToEmptyObj(emissions.refrigerant);
    return {
      useRefrigerant: emissions.useRefrigerant,
      leaks: refrigerant.leaks,
      refrigerant: refrigerant.refrigerant,
      emissionsFactor: {
        values: [],
        averageValue: refrigerant.emissionsFactor,
        nature: 'average',
      },
    };
  }

  private toProjectEmissions(
    editor: ProductEditorModel,
  ): OnsiteGenerationEmission {
    return this.toEmissions(editor.projectEmissions as OgsoEmissionsFormModel);
  }

  private toBaselineEmissions(
    editor: ProductEditorModel,
  ): OnsiteGenerationEmission {
    return this.toEmissions(editor.baselineEmissions as OgsoEmissionsFormModel);
  }

  private toHistoricalEmissions(
    editor: ProductEditorModel,
  ): OnsiteGenerationEmission {
    let emissions = this.toEmissions(
      editor.historicalEmissions as OgsoEmissionsFormModel,
    );
    emissions = this.coerceHistoricalToAverage(emissions);
    return emissions;
  }

  private coerceHistoricalToAverage(
    emissions: OnsiteGenerationEmission,
  ): OnsiteGenerationEmission {
    return {
      ...emissions,
      energySources: EnergySourceUtils.coerceToAverageEFs(
        emissions.energySources,
      ),
    };
  }
}

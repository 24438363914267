import { AssumptionStore, Feedstock } from 'declic-app/assumption/state';
import { AssumptionService } from 'declic-app/assumptions/common';
import { DeclicUtils } from 'declic-app/common/utils';

import { Injectable } from '@angular/core';

@Injectable()
export class FeedstockService extends AssumptionService<Feedstock> {
  constructor(private assumptionStore: AssumptionStore) {
    super(assumptionStore);
  }
  getAssumptionsJSONFileName(): string {
    return 'feedstocks';
  }
  getAssumptionsJSONKey(): string {
    return 'feedstocks';
  }

  getEmission(name: string): number {
    const assumption = DeclicUtils.resolveToEmptyObj(
      this.getAssumption(name.toLowerCase()),
    );
    return assumption.ef;
  }

  reduceToManifest(assumptions: Feedstock[]) {
    return assumptions.reduce((acc, curr) => {
      acc[curr.type.toLowerCase()] = curr;
      return acc;
    }, {});
  }
}

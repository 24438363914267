import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { AnnualFactorModule } from '../annual-factor/annual-factor-two.module';
import { AuxiliaryValueComponent } from './auxiliary-value.component';

@NgModule({
  declarations: [AuxiliaryValueComponent],
  imports: [CommonModule, AnnualFactorModule, ReactiveFormsModule],
  exports: [AuxiliaryValueComponent],
})
export class AuxiliaryValueModule {}

import { Coerce, DeclicUtils, VehicleType } from 'declic-app/common';
import { YearlyEmission } from 'declic-app/models';
import { AssumptionService } from 'declic-assumptions/common';
import { Efficiency } from 'declic-assumptions/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { AssumptionStore } from 'declic-app/assumption/state';

import { MobilityEfficiencyBE } from './mobility-efficiency.model';

@Injectable()
export class MobilityEfficiencyService extends AssumptionService<Efficiency> {
  constructor(private assumptionStore: AssumptionStore) {
    super(assumptionStore);
  }
  getAssumptionsJSONFileName(): string {
    return 'mobilityRates';
  }
  getAssumptionsJSONKey(): string {
    return 'vehicles';
  }

  getHttpGetStream() {
    return this.assumptionStore.select(this.getAssumptionsJSONFileName()).pipe(
      map((mobilities: MobilityEfficiencyBE[]) =>
        mobilities.map((mobility: MobilityEfficiencyBE) => ({
          name: mobility.typeOfVehicle.toLowerCase(),
          efficiency: DeclicUtils.fixTo3Places(mobility.value),
        })),
      ),
      map((data) => ({ [this.getAssumptionsJSONKey()]: data })),
    ) as unknown as Observable<{
      [key: string]: Efficiency;
    }>;
  }

  getConversionEfficiency(name: VehicleType): number {
    return Coerce.toObj(this.getAssumption(name.toLowerCase())).efficiency;
  }

  getYearlyConversionEff(name: VehicleType, years: number[]): YearlyEmission[] {
    const emissions = this.getConversionEfficiency(name);
    return years.map((year) => ({ year, emissions }));
  }
}

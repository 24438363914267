export type YearlyEmission = {
  year: number;
  emissions: number;
};

export interface HasAnnualDecarbImpactRatio {
  readonly annualDecarbonizationImpactRatio: YearlyEmission[];
}

export interface HasAnnualKPIs {
  readonly baselineAnnualEmissions: YearlyEmission[];
  readonly projectAnnualEmissions: YearlyEmission[];
}

export interface Computation extends HasAnnualDecarbImpactRatio, HasAnnualKPIs {
  id?: string;

  // Amount per year
  annualDecarbonizationImpact: YearlyEmission[];

  // Average annual amount
  baselineAnnualAverageEmissions: number;
  projectAnnualAverageEmissions: number;
  annualAverageDecarbonizationImpact: number;
  annualAverageDecarbonizationImpactRatio: number;

  // Total over contract duration
  totalBaselineEmissionsOverContractDuration: number;
  totalProjectEmissionsOverContractDuration: number;
  totalDecarbonizationImpactOverContractDuration: number;
  totalDecarbonizationImpactRatioOverContractDuration: number;

  // Installation
  installationProjectEmissions?: number;
  installationBaselineEmissions?: number;
}

export type IntermediaryYearAndEmissions = { [year: string]: number };

import {
  GreenEnergyEmission,
  GreenSales,
  ProjectGreenEnergyEmission,
} from '@declic/green-sales/common';
import { EnergySourceUtils } from 'declic-app/models/energy-source.model';
import { ProductEditorModel } from 'declic-product/components/product-editor';
import { ProductType } from 'declic-project/models';
import { ProductEditorMapper } from './product.mapper';

export class GreenSalesMapper extends ProductEditorMapper<GreenSales> {
  getType(): ProductType {
    return ProductType.SALES;
  }
  mapToExclusiveProps(editor: ProductEditorModel): Partial<GreenSales> {
    const { baselineEmissions, projectEmissions, historicalEmissions } = editor;
    return {
      baselineEmissions: baselineEmissions as GreenEnergyEmission[],
      projectEmissions: projectEmissions as ProjectGreenEnergyEmission[],
      historicalEmissions: this.coerceHistoricalToAverage(
        historicalEmissions as GreenEnergyEmission[],
      ),
    };
  }

  mapProductToEditorProjectEmissions(product: GreenSales): unknown {
    return product.projectEmissions;
  }
  mapProductToEditorBaselineEmissions(product: GreenSales): unknown {
    return product.baselineEmissions;
  }
  mapProductToEditorHistorical(product: GreenSales): unknown {
    return product.historicalEmissions;
  }

  private coerceHistoricalToAverage(
    emissions: GreenEnergyEmission[],
  ): GreenEnergyEmission[] {
    return EnergySourceUtils.coerceToAverageEFs(
      emissions,
    ) as GreenEnergyEmission[];
  }
}

export type HasId = { id: string };
export type HasName = { name: string };
export type HasNameId = HasId & HasName;
export type HasBeingDeleted = { beingDeleted?: boolean };
export type TableEntity = HasNameId & HasBeingDeleted;
export type ValueByYear = ValuePerYear;
export type ValuePerYear = Record<number, number>;
export type HasProjectId = { projectId: string };
export type HasActivityId = { activityId: string };
export type HasPayload = { payload: unknown };
export type UpsertMode = 'create' | 'edit';
export type HasValue = { value: number };
export type Option = { label: string; value: string; disabled?: boolean };
export type HasSelectedId = { selectedId: string };
export type ResponseWithData<T> = { data: T[] };

export type FactorMode = 'average' | 'yearly';
export const FACTOR_MODES: FactorMode[] = ['average', 'yearly'];
export type Factor = {
  values: ValuePerYear;
  isCascading: boolean;
  mode?: FactorMode;
  averageValue?: number;
};

export type FactorViewMode = 'average' | 'yearly';
export enum SourceType {
  GREEN = 'green',
  GREY = 'grey',
}

import { AssumptionService } from 'declic-app/assumptions/common';
import { Countries } from 'declic-app/assumptions/models';

import { Injectable } from '@angular/core';
import { AssumptionStore } from 'declic-app/assumption/state';

@Injectable()
export class CountriesService extends AssumptionService<Countries> {
  constructor(private assumptionStore: AssumptionStore) {
    super(assumptionStore);
  }
  getAssumptionsJSONFileName(): string {
    return 'countries';
  }
  getAssumptionsJSONKey(): string {
    return 'locations';
  }

  getPowerGrid(location: string) {
    return this.getAssumption(location).powerGrid;
  }
}

import { Project } from 'declic-project/models';
import { BaseProject, ProjectRepository } from 'declic-project/state';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { NotificationService } from './notification.service';
import { WebsocketType } from './websocket-config.model';

@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  currentAuthJwtToken: string;

  constructor(
    private notificationService: NotificationService,
    private projectStore: ProjectRepository,
    private readonly router: Router,
  ) {}

  initWebsocketConnection() {
    this.notificationService.messages$.subscribe((wsData) => {
      this.processNotification(wsData);
    });
  }

  private processNotification(wsData) {
    const functionRef = {
      [WebsocketType.COMPUTATION_UPDATE]:
        this.updateCalculationStatus.bind(this),
      [WebsocketType.REPORT_UPDATE]: this.updateGenerateReportStatus.bind(this),
      [WebsocketType.CALIBRATION_STATUS]:
        this.onCalibrationStatusNotif.bind(this),
    };
    if (wsData.type) {
      functionRef[wsData.type](wsData.data);
    }
  }

  private updateCalculationStatus(data: Project) {
    this.projectStore.updateStatus(data.id, data.status);
  }

  private updateCalibrationStatus(data: BaseProject) {
    this.projectStore.updateCalibrationStatus(data.id, data.calibrationStatus);
  }

  private updateGenerateReportStatus(data: Project) {
    this.projectStore.updateReportStatus(data.id, data.reportStatus);
  }

  private onCalibrationStatusNotif(data: BaseProject) {
    this.updateCalibrationStatus(data);
    this.router.navigate(['projects', data.id]);
  }
}

import {
  HttpErrorResponse,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { OKTA_AUTH } from '@okta/okta-angular';
import { catchError } from 'rxjs';

export function tokenRefresher(): HttpInterceptorFn {
  return (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
    const oktaAuth = inject(OKTA_AUTH);

    return next(req).pipe(
      catchError((err) => {
        if (shouldRefreshToken(err)) {
          oktaAuth.clearStorage();
          window.location.reload();
        }
        throw err;
      }),
    );
  };
}

function shouldRefreshToken(err: HttpErrorResponse): boolean {
  return err.status === 401;
}

import { Coerce } from 'declic-app/common';
import { EntityStateStore } from 'declic-app/services/entity-state/entity-state.store';
import { GlobalProduct, ProductsType } from 'declic-project/models';
import { ProjectRepository } from 'declic-project/state';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map, mergeMap, tap } from 'rxjs/operators';

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createStore, filterNil, withProps } from '@ngneat/elf';
import { withActiveId, withEntities } from '@ngneat/elf-entities';

import { ProductService } from './product.service';
import { ProductState } from './product.state';

export const productStore = createStore(
  { name: 'produkto' },
  withProps<ProductState>({} as ProductState),
  withEntities<GlobalProduct>(),
  withActiveId(),
);
productStore.getValue();

export type ProductStoreState = typeof productStore.state;
@Injectable({
  providedIn: 'root',
})
export class ProductRepository extends EntityStateStore<
  ProductStoreState,
  GlobalProduct
> {
  constructor(
    private readonly productService: ProductService,
    private readonly projectRepo: ProjectRepository,
  ) {
    super(productStore);
  }

  gettingOne$ = this.select('gettingOne');
  updatingOne$ = this.select('updatingOne');

  addOne(projectId: string, product: GlobalProduct): Observable<unknown> {
    this.setState({ addingOne: true });
    return this.productService.addOne(projectId, product).pipe(
      tap((produkto) => this.addEntity(produkto)),
      catchError((error: HttpErrorResponse) =>
        this.setAndThrowError(this.toErrMsg(error)),
      ),
      finalize(() => this.setState({ addingOne: false })),
    );
  }

  getAll(projectId: string): Observable<unknown> {
    this.projectRepo.setActiveId(projectId);
    this.updateGetAllUIStates(projectId, true);
    return this.productService.getAll(projectId).pipe(
      tap((produktos) => {
        produktos.forEach((produkto) =>
          this.upsertEntity(produkto.id, produkto),
        );
      }),
      catchError((error: HttpErrorResponse) =>
        this.setAndThrowError(this.toErrMsg(error)),
      ),
      finalize(() => this.updateGetAllUIStates(projectId, false)),
    );
  }

  getOne(project: string, id: string): Observable<unknown> {
    this.setState({ gettingOne: true, errorGettingOne: false });
    return this.productService.getOne(project, id).pipe(
      tap((produkto) => this.upsertEntity(produkto.id, produkto)),
      catchError((error) => this.onGetOneError(error)),
      finalize(() => this.setState({ gettingOne: false })),
    );
  }

  deleteOne(project: string, id: string): Observable<unknown> {
    this.setState({ deletingOne: true });
    return this.productService.deleteOne(project, id).pipe(
      tap(() => this.remove(id)),
      catchError((error) => this.setAndThrowError(this.toErrMsg(error))),
      mergeMap(() => this.projectRepo.getOne(project)),
      finalize(() => this.setState({ deletingOne: false })),
    );
  }

  updateOne(
    project: string,
    id: string,
    prod: GlobalProduct,
  ): Observable<unknown> {
    this.setState({ updatingOne: true });
    return this.productService.updateOne(project, id, prod).pipe(
      tap((updated) => this.upsertEntity(id, updated)),
      catchError((error) => this.setAndThrowError(this.toErrMsg(error))),
      mergeMap(() => this.projectRepo.getOne(project)),
      finalize(() => {
        this.setState({ updatingOne: false });
      }),
    );
  }

  selectForProject(projectId: string): Observable<GlobalProduct[]> {
    return this.selectAll().pipe(
      map((all) => this.onlyMatchingProjectId(all, projectId)),
    );
  }

  selectForActiveProject(): Observable<GlobalProduct[]> {
    return this.projectRepo
      .selectActiveId()
      .pipe(mergeMap((id: string) => this.selectForProject(id)));
  }

  getTypeOfActive(): ProductsType {
    return Coerce.toObj(this.getActive()).type;
  }

  selectTypeOfActive(): Observable<ProductsType> {
    return this.selectLoadedActive().pipe(map((p) => p.type));
  }

  selectLoadedActive(): Observable<GlobalProduct> {
    return this.selectActive().pipe(filterNil());
  }

  selectGettingAllFor(projectId: string): Observable<boolean> {
    return this.select('gettingAllFor').pipe(
      map((g) => Coerce.toObj(g)[projectId] || false),
    );
  }

  selectAreAllValid(id: string): Observable<boolean> {
    return this.selectForProject(id).pipe(
      map((prods) => this.areAllValid(prods)),
    );
  }

  selectAreAllValidForActive(): Observable<boolean> {
    return this.selectForActiveProject().pipe(
      map((prods) => !!prods.length && this.areAllValid(prods)),
    );
  }

  selectAreAllNonActiveForActive(): Observable<boolean> {
    return this.selectForActiveProject().pipe(
      map((prods) => this.onlyNonActiveProducts(prods)),
      map((nonActive) => this.areAllValid(nonActive)),
    );
  }

  // @transaction()
  private updateGetAllUIStates(id: string, value: boolean): void {
    this.setState({
      loading: value,
      loaded: !value,
      gettingAllFor: { ...this.getGettingAllFor(), [id]: !value },
    });
  }

  private getGettingAllFor(): Record<string, boolean> {
    return this.state$.getValue().gettingAllFor;
  }

  private onGetOneError(error: HttpErrorResponse): Observable<unknown> {
    this.setState({ errorGettingOne: true, error: this.toErrMsg(error) });
    return throwError(error);
  }

  private toErrMsg(error: HttpErrorResponse): string {
    return `${error.status}: ${error.statusText}`;
  }

  private setAndThrowError(error: string): Observable<unknown> {
    this.setState({ error });
    return throwError(error);
  }

  private onlyNonActiveProducts(prods: GlobalProduct[]): GlobalProduct[] {
    return prods.filter((p) => p.id !== this.getActiveId());
  }

  private areAllValid(products: GlobalProduct[]): boolean {
    return products.every((p) => this.isConsideredValid(p));
  }

  private isConsideredValid(product: GlobalProduct): boolean {
    return product.valid !== false;
  }

  private onlyMatchingProjectId(
    prods: GlobalProduct[],
    projId: string,
  ): GlobalProduct[] {
    return prods.filter((p) => p.projectId === projId);
  }
}

<div
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutGap="16px"
  [class]="'info-panel-' + severity"
>
  <mat-icon>{{ severityIconMap[severity] }}</mat-icon>
  <div data-test="content" class="info-panel-content" fxFlex>
    <ng-content></ng-content>
  </div>
</div>

import {
  BasicEquipment,
  HasBatteryCapacity,
  HasBatteryEfficiency,
  HasBatteryType,
  HasCapacity,
  HasYearlyProduction,
} from './equipment.types';

export type SolarPvBattery = BasicEquipment &
  HasCapacity &
  HasBatteryCapacity &
  HasBatteryType &
  HasBatteryEfficiency &
  HasYearlyProduction;

export type SolarPvBatteryForm = Pick<
  SolarPvBattery,
  | 'name'
  | 'capacity'
  | 'batteryCapacity'
  | 'batteryType'
  | 'batteryEfficiency'
  | 'yearlyProduction'
>;

import {
  NetworkProjectType,
  NetworksProject,
} from 'declic-app/networks-project-emissions/models';
import {
  BiomethaneValidator,
  NetworkProjectValidatorFactory,
} from 'declic-app/networks-project-emissions/validators';
import {
  BiomethaneProjectValidator,
  NetworkProjectValidator,
} from 'declic-app/networks-project-emissions/validators/network-project.validator';

function isNetworksProjectValid(project: NetworksProject): boolean {
  return [
    validateScopeOne(project),
    validateScopeTwo(project),
    validateScopeThree(project),
    validateAvoidedEmissions(project),
  ].every(Boolean);
}

function validateScopeOne(project: NetworksProject): boolean {
  return getNetworksValidator(project).isScope1Valid(project);
}

function validateScopeTwo(project: NetworksProject): boolean {
  return getNetworksValidator(project).isScope2Valid(project);
}

function validateScopeThree(project: NetworksProject): boolean {
  return getNetworksValidator(project).isScope3Valid(project);
}

function validateAvoidedEmissions(project: NetworksProject): boolean {
  return getBiomethaneValidator().isAvoidedEmissionsValid(project);
}

function displayScopeOneIcon(project: NetworksProject): boolean {
  return getNetworksValidator(project).displayScope1Icon(project);
}

function displayScopeTwoIcon(project: NetworksProject): boolean {
  return getNetworksValidator(project).displayScope2Icon(project);
}

function displayScopeThreeIcon(project: NetworksProject): boolean {
  return getNetworksValidator(project).displayScope3Icon(project);
}

function getNetworksValidator(
  project: NetworksProject,
): NetworkProjectValidator {
  return NetworkProjectValidatorFactory.get(project.type as NetworkProjectType);
}

function getBiomethaneValidator(): BiomethaneProjectValidator {
  return new BiomethaneValidator();
}

export const EditorRepoUtils = {
  isNetworksProjectValid,
  validateScopeOne,
  validateScopeTwo,
  validateScopeThree,
  validateAvoidedEmissions,
  displayScopeOneIcon,
  displayScopeTwoIcon,
  displayScopeThreeIcon,
};

import { WebSocketConfig } from 'declic-app/services/websocket/websocket-config.model';

import { InjectionToken, Provider } from '@angular/core';
import { environment } from './environment';
import { DECLICONFIG } from './environment-commons';

export const IS_ENV_PRODUCTION = new InjectionToken<boolean>(
  'is-env-production',
);

export const WEB_SOCKET_URL = new InjectionToken<string>('web-socket-url');
export const WEB_SOCKET_CONFIG = new InjectionToken<WebSocketConfig>(
  'web-socket-config',
);

export function provideDecliConfig(): Provider {
  return { provide: DECLICONFIG, useValue: environment };
}

import { ScopeOne } from 'declic-app/networks-project-emissions/models';
import { NetworksProject } from 'declic-app/networks-project-emissions/models/network-projects.models';
import { ScopeTwo } from 'declic-app/networks-project-emissions/models/scope2.models';
import { ScopeThree } from '../biomethane-infrastructures/models/biomethane.model';

export interface Gas extends NetworksProject {
  scopeOne: ScopeOne;
  scopeTwo: ScopeTwo;
  scopeThree: ScopeThree;
}

import { emissionApproach } from 'declic-app/common';
import { ProductsType, ProjectType } from 'declic-project/models';

export interface EditorState {
  readonly current: EditorForm;
  readonly saved?: EditorForm;
  readonly refreshAssumptions?: AssumptionsTrigger;
  readonly hardRefresh?: boolean;
}

export enum AssumptionsTrigger {
  ALL = 'all',
  PROJECT = 'project',
  BASELINE = 'baseline',
  HISTORICAL = 'historical',
}

export interface EditorForm {
  readonly type: ProductsType;
  readonly emissionsApproach: emissionApproach;
  readonly projectType?: ProjectType;
  readonly baselineJustification?: string;
  readonly general?: unknown;
  readonly projectEmissions?: unknown;
  readonly baselineEmissions?: unknown;
  readonly historicalEmissions?: unknown;
}

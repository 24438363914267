/* eslint-disable max-lines*/
import { EnergySourceType } from 'declic-app/common';
import { GasType } from 'declic-app/common/network-energy-source';
import { UNITS } from 'declic-app/common/units';
import { AuxiliaryProperty } from 'declic-app/components/auxiliary-value';
import { ProductAuxiliariesConfig } from 'declic-app/components/product-auxiliaries';
import { AnnualFactor, EnergySource } from 'declic-app/models';
import {
  NetworkProjectAlias,
  NetworkProjectType,
} from './network-projects.models';

export type ScopeOne = Readonly<
  Partial<{
    stationaryCombustion: Combustion;
    mobileCombustion: Fluorinated;
    fluorinatedGases: Ch4Gas;
    ch4GasFlaring: Ch4Gas;
    ch4DirectFugitive: Fugitive;
    ch4DirectVenting: Ch4Gas;

    fugitiveInjected: Fugitive;
    fugitiveCHP: Fugitive;
  }>
>;
export type ScopeOneBE = Readonly<
  Partial<{
    stationaryCombustion: Combustion;
    mobileCombustion: Combustion;
    fluorinatedGases: Ch4Gas;
    ch4GasFlaring: Ch4Gas;
    ch4DirectFugitive: Fugitive;
    ch4DirectVenting: Ch4Gas;

    fugitiveInjected: Fugitive;
    fugitiveCHP: Fugitive;
  }>
>;

export type Combustion = Readonly<
  Partial<{
    sources: EnergySource[];
  }>
>;

export type Ch4Gas = Readonly<
  Partial<{
    sources: Ch4Sources[];
  }>
>;

export type Ch4Sources = Readonly<
  Partial<{
    gwp: AnnualFactor;
    volume: AnnualFactor;
    source: string;
    id: string;
  }>
>;

export type BiogasSources = Ch4Sources &
  Readonly<
    Partial<{
      methane: AnnualFactor;
    }>
  >;

export type Fluorinated = Readonly<
  Partial<{
    sources: FluorinatedSources[];
  }>
>;

export type FluorinatedSources = Readonly<{
  emissionFactor: AnnualFactor;
  volume: AnnualFactor;
  source: EnergySourceType;
  id: string;
  name?: string;
}>;

export type UpstreamCH4 = Readonly<
  Partial<{
    sources: UpstreamCH4Source[];
  }>
>;

export type UpstreamCH4Source = FluorinatedSources &
  Readonly<{
    energeticContent: AnnualFactor;
  }>;

export type Fugitive = Readonly<
  Partial<{
    sources: FugitiveSources[];
  }>
>;

export type FugitiveSources = Readonly<
  Partial<{
    composition: AnnualFactor;
    volume: AnnualFactor;
    gwp: AnnualFactor;
    source: EnergySourceType;
    id: string;
    methane: AnnualFactor;
    leaks: AnnualFactor;
  }>
>;

export const FugitiveKeys: Record<
  NetworkProjectAlias,
  (keyof FugitiveSources)[]
> = {
  [NetworkProjectAlias.BI]: ['composition', 'gwp', 'volume'],
  [NetworkProjectAlias.GI]: ['gwp', 'volume'],
  [NetworkProjectAlias.EI]: [],
};

export const CH4_FUGITIVE_CONFIG: ProductAuxiliariesConfig = {
  name: 'bioCh4Fugitive',
  props: [
    {
      name: 'volume',
      unit: UNITS.volumeM3,
      constraints: [],
      extraLabel: 'of Biogas to be upgraded',
    },
    {
      name: 'composition',
      unit: UNITS.composition,
      constraints: [],
      extraLabel: 'of biogas',
    },
    {
      name: 'gwp',
      unit: UNITS.gwp,
      constraints: [],
      disabled: true,
    },
  ],
  averageOnly: false,
  yearlyOnly: true,
  types: [GasType.Biogas],
};

const BIO_CH4_PROP_METHANE: AuxiliaryProperty = {
  name: 'methane',
  unit: UNITS.percentMethaneBiogas,
  constraints: [],
  helpMessageKey: 'oneTo100',
};

export const FUGITIVE_INJECTED_CONFIG: ProductAuxiliariesConfig = {
  ...CH4_FUGITIVE_CONFIG,
  props: [
    {
      name: 'volume',
      labelTranslationKey: 'labels.volumeOfBiogasToBeUpgraded',
      unit: UNITS.normalCubicMeter,
      constraints: [],
    },
    {
      ...BIO_CH4_PROP_METHANE,
      labelTranslationKey: 'labels.percentOfMethaneInBiogas',
    },
    {
      name: 'leaks',
      unit: UNITS.methLeaks,
      constraints: [],
      disabled: true,
    },
  ],
  types: [],
  typeTranslationKey: 'labels.whatUpgradingTech',
};

export const CH4_CONFIG: ProductAuxiliariesConfig = {
  name: 'ch4Volume',
  props: [
    {
      name: 'volume',
      unit: UNITS.volume,
      constraints: [],
    },
    {
      name: 'gwp',
      unit: UNITS.gwp,
      constraints: [],
      disabled: true,
    },
  ],
  averageOnly: false,
  yearlyOnly: true,
  types: [GasType.CH4],
};

const BIO_CH4_PROP_VOLUME: AuxiliaryProperty = {
  name: 'volume',
  unit: UNITS.normalCubicMeter,
  constraints: [],
  extraLabel: 'of Biogas Flared',
};

const BIO_CH4_PROP_GWP: AuxiliaryProperty = {
  name: 'gwp',
  unit: UNITS.gwp,
  constraints: [],
  disabled: true,
};

export const BIO_CH4_CONFIG: ProductAuxiliariesConfig = {
  name: 'biogas',
  props: [BIO_CH4_PROP_VOLUME, BIO_CH4_PROP_METHANE, BIO_CH4_PROP_GWP],
  averageOnly: false,
  yearlyOnly: true,
  types: [],
};

export const BIO_CH4_CONFIG_VENTING: ProductAuxiliariesConfig = {
  ...BIO_CH4_CONFIG,
  props: [
    { ...BIO_CH4_PROP_VOLUME, extraLabel: 'of Biogas Vented' },
    BIO_CH4_PROP_METHANE,
    BIO_CH4_PROP_GWP,
  ],
};

export const FUGITIVE_CHP_CONFIG: ProductAuxiliariesConfig = {
  ...BIO_CH4_CONFIG,
  props: [
    { ...BIO_CH4_PROP_VOLUME, extraLabel: 'of Biogas used in CHP' },
    BIO_CH4_PROP_METHANE,
    BIO_CH4_PROP_GWP,
  ],
};

export const GAS_CH4_CONFIG: ProductAuxiliariesConfig = {
  ...CH4_CONFIG,
  types: [GasType.NATURAL_GAS, GasType.BIOMETHANE],
};

export const Ch4Configs: Record<NetworkProjectType, ProductAuxiliariesConfig> =
  {
    [NetworkProjectType.BI]: BIO_CH4_CONFIG,
    [NetworkProjectType.GI]: GAS_CH4_CONFIG,
    [NetworkProjectType.EI]: CH4_CONFIG,
  };

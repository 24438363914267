import { ProductAuxiliariesConfig } from 'declic-app/components/product-auxiliaries';

export type emissionsCategory = {
  config: ProductAuxiliariesConfig;
  control: string;
  fixedProps: string[];
  type?: CategoryType;
};

export enum CategoryType {
  COMBUSTION = 'COMBUSTION',
  MOBILE = 'MOBILE',
  CH4 = 'CH4',
  FLUORINATED = 'FLUORINATED',
  ELEC = 'ELEC',
  FUGITIVE = 'FUGITIVE',
  UPSTREAMCH4 = 'UPSTREAMCH4',
}

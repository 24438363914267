import { AnnualFactor, EnergySource } from 'declic-app/models';
import { Coerce } from './coercion.utils';

export class EnergySourceAdapter {
  static withWeightInValues(
    srcs: EnergySource[],
    factor: number,
  ): EnergySource[] {
    return srcs.map((src) =>
      EnergySourceAdapter.withWeightInValue(src, factor),
    );
  }

  static withWeightInValue(src: EnergySource, factor: number): EnergySource {
    return {
      ...src,
      weightInValue: EnergySourceAdapter.toWeightInValueIfApplicable(
        src.weight,
        factor,
      ),
    };
  }

  private static toWeightInValueIfApplicable(
    weight: AnnualFactor,
    factor: number,
  ): AnnualFactor {
    return weight
      ? {
          nature: 'average',
          averageValue: EnergySourceAdapter.calculateValue(
            weight.averageValue,
            factor,
          ),
          values: [],
        }
      : undefined;
  }

  private static calculateValue(weight: number, factor: number): number {
    return (Coerce.toNum(weight) / 100) * Coerce.toNum(factor);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import {
  CountriesService,
  ElecConversionService,
  ElectricityService,
  FuelFactorsService,
  GasMixService,
  HeatConversionService,
  MobilityFactorsService,
  OtherGasesService,
  PowerGridService,
  RefrigirantService,
} from './services';
import { FeedstockService } from './services/feedstock';
import { MobilityEfficiencyService } from './services/mobility-efficiency';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    ElecConversionService,
    HeatConversionService,
    FuelFactorsService,
    PowerGridService,
    CountriesService,
    GasMixService,
    RefrigirantService,
    MobilityFactorsService,
    MobilityEfficiencyService,
    OtherGasesService,
    ElectricityService,
    FeedstockService,
  ],
})
export class AssumptionsModule {}

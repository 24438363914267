import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MockedAnalytics } from './analytics.adapters';
import {
  AnalyticsConfig,
  ANALYTICS_CONFIG,
  ANALYTICS_SERVICE,
} from './analytics.tokens';

@NgModule({
  imports: [CommonModule],
})
export class AnalyticsModule {
  static forRoot(
    config: AnalyticsConfig,
  ): ModuleWithProviders<AnalyticsModule> {
    return {
      ngModule: AnalyticsModule,
      providers: [
        { provide: ANALYTICS_CONFIG, useValue: config },
        { provide: ANALYTICS_SERVICE, useClass: config.serviceClass },
      ],
    };
  }
}

export function getAnalyticsTestingModule(): ModuleWithProviders<AnalyticsModule> {
  return AnalyticsModule.forRoot({
    amplitudeKey: '',
    serviceClass: MockedAnalytics,
  });
}

<div
  fxLayout="column"
  fxLayoutAlign="start stretch"
  class="full-height"
  *transloco="let t"
>
  <div
    fxLayout="column"
    fxLayoutAlign="start stretch"
    fxLayoutGap="4px"
    class="omnibar-top"
  >
    <div class="bg-engie-gradient" fxFlex="3px"></div>
    <!-- engie+breadcrumbs and navigation items -->
    <div
      class="omnibar-container"
      fxLayout="row"
      fxLayoutAlign="space-between start"
    >
      <div fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="24px">
        <img class="omnibar-engie" src="/assets/engie/logo-engie-blue.svg" />
        <div
          [class.invisible]="(loggedIn$ | async) === false"
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="24px"
        >
          <mat-divider [vertical]="true" class="h-full"> </mat-divider>
          <span class="omnibar-declic-brand fg-engie-gradient">DeCliC</span>
          @if (isDevEnv) {
            <span class="omnibar-version-tag">
              {{ t('stageNames.' + envStage) + '_v' + appVersion }}
            </span>
          }
        </div>
      </div>

      @if (loggedIn$ | async) {
        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="48px">
          <!-- nav items -->
          <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="64px">
            <span
              *ngFor="let link of masterPages"
              class="omnibar-link pb-2"
              (click)="onLinkClick(link)"
              [dataCy]="link?.label"
              declicTooltip
              [content]="getTooltip(link?.label)"
              [class.selected-route]="isCurrentRoute(link?.label) | async"
            >
              {{ t('navigation.' + link?.label) }}
            </span>
          </div>
          <!-- notif and settings -->
          <button
            data-test="settings"
            mat-icon-button
            color="primary"
            [matMenuTriggerFor]="settings"
          >
            <mat-icon color="primary">settings</mat-icon>
          </button>
        </div>
      }
    </div>
  </div>

  <div fxLayout="column" fxLayoutAlign="start stretch" class="omnibar-content">
    <ng-content></ng-content>
  </div>
</div>

<mat-menu #settings="matMenu">
  <div
    fxLayout="column"
    fxLayoutAlign="start stretch"
    fxLayoutGap="16px"
    class="omnibar-menu"
    *transloco="let t"
  >
    <img
      (click)="onCatClick()"
      data-test="cat"
      class="self-center half-opacity"
      src="/assets/icons/cat.svg"
      width="64px"
    />

    <button
      type="button"
      class="nj-btn nj-btn--danger nj-btn--sm"
      (click)="onLogout()"
    >
      {{ t('buttons.logout') | uppercase }}
    </button>
    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="4px">
      <mat-divider> </mat-divider>
      <div fxLayout="column" fxLayoutAlign="start start">
        <span class="omnibar-need-help">{{ t('hints.needHelp') }}</span>
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="8px"
          class="omnibar-send-message"
        >
          <mat-icon [inline]="true" class="fg-engie-gradient">email</mat-icon>
          <a [href]="t('helpAddress')">
            {{ t('hints.clickToMsg') }}
          </a>
        </div>
        <div
          class="flex flex-row justify-content-between text-xs w-full opacity-30"
        >
          <span> Version: </span>
          <span>
            {{ appVersion }}
          </span>
        </div>
      </div>
    </div>
  </div>
</mat-menu>

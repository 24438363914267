import { ToggleOption } from 'declic-app/components/toggle';

export enum NatureEnergyProducedType {
  NA = 'N/A',
  HEAT = 'Heat',
  COLD = 'Cold',
  POWER = 'Power',
  FEEDSTOCK = 'Feedstock',
}

export type NatureEnergyProduced = {
  [productId: string]: NatureEnergyProducedType[];
};

export type NatureEnergyProducedWithID = {
  energySource: NatureEnergyProducedType;
  id: string;
};

export const NATUREENERGYPRODUCED = {
  ogso: [
    NatureEnergyProducedType.HEAT,
    NatureEnergyProducedType.COLD,
    NatureEnergyProducedType.POWER,
    NatureEnergyProducedType.FEEDSTOCK,
  ],
  ct: [
    NatureEnergyProducedType.NA,
    NatureEnergyProducedType.HEAT,
    NatureEnergyProducedType.COLD,
    NatureEnergyProducedType.POWER,
  ],
};

const NATURE_HEAT_SRC = '/assets/icons/nature_heat.svg';
const NATURE_COLD_SRC = '/assets/icons/nature_cold.svg';
const NATURE_POWER_SRC = '/assets/icons/nature_power.svg';
const NATURE_FEEDSTOCK_SRC = '/assets/icons/nature_feedstock.svg';

export const NATURE_ENERGY_DHC_OPTIONS: ToggleOption[] = [
  {
    label: NatureEnergyProducedType.HEAT,
    source: NATURE_HEAT_SRC,
  },
  {
    label: NatureEnergyProducedType.COLD,
    source: NATURE_COLD_SRC,
  },
];

export const NATURE_ENERGY_CT_OPTIONS: ToggleOption[] = [
  {
    label: NatureEnergyProducedType.HEAT,
    source: NATURE_HEAT_SRC,
  },
  {
    label: NatureEnergyProducedType.COLD,
    source: NATURE_COLD_SRC,
  },
  {
    label: NatureEnergyProducedType.POWER,
    source: NATURE_POWER_SRC,
  },
];

export const NATURE_ENERGY_OGSO_OPTIONS: ToggleOption[] = [
  {
    label: NatureEnergyProducedType.HEAT,
    source: NATURE_HEAT_SRC,
  },
  {
    label: NatureEnergyProducedType.COLD,
    source: NATURE_COLD_SRC,
  },
  {
    label: NatureEnergyProducedType.POWER,
    source: NATURE_POWER_SRC,
  },
  {
    label: NatureEnergyProducedType.FEEDSTOCK,
    source: NATURE_FEEDSTOCK_SRC,
  },
];

export const NatureEnergyAliasMapping = {
  [NatureEnergyProducedType.HEAT]: 'heat',
  [NatureEnergyProducedType.COLD]: 'cold',
  [NatureEnergyProducedType.POWER]: 'power',
  [NatureEnergyProducedType.FEEDSTOCK]: 'feedstock',
};

import { DeclicDropModule } from 'declic-app/directives/drop';
import { DeclicPasteModule } from 'declic-app/directives/paste';
import { DeclicTooltipModule } from 'declic-app/directives/tooltip';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { TranslocoModule } from '@ngneat/transloco';

import { SparklineModule } from '../sparkline';
import { AnnualForecastComponent } from './annual-forecast.component';

@NgModule({
  declarations: [AnnualForecastComponent],
  imports: [
    CommonModule,
    FormsModule,
    FlexLayoutModule,
    MatButtonModule,
    MatInputModule,
    MatSlideToggleModule,
    MatIconModule,
    TranslocoModule,
    MatMenuModule,
    ReactiveFormsModule,
    SparklineModule.forFeature(),
    DeclicTooltipModule,
    DeclicPasteModule,
    DeclicDropModule,
  ],
  exports: [AnnualForecastComponent],
})
export class AnnualForecastModule {}

export const ASSUMPTION_CEILING = 2050;
export const ASSUMPTION_RANGE: [number, number] = [2015, ASSUMPTION_CEILING];
export enum EmissionType {
  DIRECT = 'direct',
  INDIRECT = 'indirect',
  TOTAL = 'total',
}

export enum AssumptionType {
  POWER_GRID = 'powerGrid',
  GAS_MIX = 'gasMix',
  FUEL = 'fuel',
  MOBILITY = 'mobility',
  REFRIGERANT_FLUID = 'refrigerantFluid',
  EFFICIENCY_RATE = 'efficiencyRate',
  OTHER_GASES = 'otherGases',
  COUNTRIES = 'countries',
  ELECTRICITY = 'electricity',
  FEEDSTOCK = 'feedstocks',
}

export enum EfficiencyRateType {
  ENERGY = 'energy',
  MOBILITY = 'mobility',
}

export enum ElectricityType {
  GRID = 'Grid',
  GREY = 'Grey',
  GREEN = 'Green',
}

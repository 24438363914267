export const AssumptionConstants = {
  kjToKwh: 3600,
  globalWarmingImpact: {
    co2: 1,
    ch4: 34,
    n2o: 298,
  },
  discountRate: {
    default: 0.04,
    french: 0.05,
  },
  getReductionLevel: () => Math.pow(10, -9),
};

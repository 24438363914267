import { Injectable } from '@angular/core';
import { filterNil } from '@ngneat/elf';
import { ProductRepository } from 'declic-product/state';
import { SnackbarService } from './snackbar';

@Injectable({
  providedIn: 'root',
})
export class ErrorAnnouncer {
  constructor(
    private readonly produkto: ProductRepository,
    private readonly snacky: SnackbarService,
  ) {
    this.subscribeToProductRepoForErrAnnouncement();
  }

  private subscribeToProductRepoForErrAnnouncement(): void {
    this.produkto.error$
      .pipe(filterNil())
      .subscribe((error) => this.snacky.openNOK(error));
  }
}

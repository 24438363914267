import { Observable } from 'rxjs';
import {
  LoggedInProps,
  ProductAddedProps,
  ProjectCreatedProps,
  ProjectRanProps,
} from './analytics.tokens';

export interface AnalyticsService {
  trackProjectCreated(properties: ProjectCreatedProps): Observable<void>;
  trackProjectRan(properties: ProjectRanProps): Observable<void>;
  trackProductAdded(properties: ProductAddedProps): Observable<void>;
  trackProjectAccessed(properties: LoggedInProps): Observable<void>;
}

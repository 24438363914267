import { EndpointProvider } from 'declic-app/services/endpoints/endpoint.provider';
import { GlobalProduct } from 'declic-project/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Produkto } from './product.state';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(
    private readonly http: HttpClient,
    private readonly endpoints: EndpointProvider,
  ) {}

  addOne(projectId: string, product: GlobalProduct): Observable<Produkto> {
    return this.http.post<Produkto>(
      this.endpoints.forProducts(projectId),
      product,
    );
  }

  getAll(projectId: string): Observable<Produkto[]> {
    return this.http.get<Produkto[]>(this.endpoints.forProducts(projectId));
  }

  getOne(project: string, id: string): Observable<Produkto> {
    return this.http
      .get<Produkto>(this.endpoints.forProduct(project, id), {})
      .pipe(map((prod) => this.toProdukto(prod)));
  }

  deleteOne(project: string, id: string): Observable<unknown> {
    return this.http.delete(this.endpoints.forProduct(project, id));
  }

  updateOne(
    project: string,
    id: string,
    prod: GlobalProduct,
  ): Observable<unknown> {
    return this.http.patch<Produkto>(
      this.endpoints.forProduct(project, id),
      prod,
    );
  }

  private toProdukto(prod: GlobalProduct): Produkto {
    return { ...prod, loaded: true } as Produkto;
  }
}

import { DeclicUtils, OAndMApproach } from 'declic-app/common';
import { EnergySourceUtils } from 'declic-app/models';
import { OandmEmissionsFormModel } from 'declic-oandm/components/oandm-emissions';
import { OAndM, OAndMEmission } from 'declic-oandm/models';
import { ProductEditorModel } from 'declic-product/components/product-editor';
import { ProductType } from 'declic-project/models';

import { ProductEditorMapper } from './product.mapper';

export class OAndMMapper extends ProductEditorMapper<OAndM> {
  getType(): ProductType {
    return ProductType.OANDM;
  }

  mapEditorModelToProduct(editor: ProductEditorModel): OAndM {
    return {
      ...editor,
      type: editor.type as ProductType,
      id: undefined,
      calcApproach: OAndMApproach.ENERGIES_CONSUMED,
      projectEmissions: this.coerceToOAndMEmission(editor.projectEmissions),
      baselineEmissions: this.coerceToOAndMEmission(editor.baselineEmissions),
      historicalEmissions: this.toHistoricalEmissions(
        editor.historicalEmissions,
      ),
    };
  }

  mapProductToEditorHistorical(product: OAndM): OandmEmissionsFormModel {
    return product.historicalEmissions;
  }

  mapProductToEditorProjectEmissions(product: OAndM): OandmEmissionsFormModel {
    return product.projectEmissions;
  }

  mapProductToEditorBaselineEmissions(product: OAndM): OandmEmissionsFormModel {
    return product.baselineEmissions;
  }

  private coerceToOAndMEmission(something: unknown): OAndMEmission {
    return something as OAndMEmission;
  }

  private toHistoricalEmissions(from: unknown): OAndMEmission {
    const historical = DeclicUtils.resolveToEmptyObj(
      this.coerceToOAndMEmission(from),
    );
    return {
      ...historical,
      energySources: EnergySourceUtils.coerceToAverageEFs(
        historical.energySources,
      ),
    };
  }
}

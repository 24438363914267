import { ProductAuxiliary } from 'declic-app/components/product-auxiliaries';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { Directive, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Coerce } from '../coercion.utils';
import { emissionApproach } from '../emissionApproach';
import { AuxiliariesPatcher } from './auxiliaries.patcher';

export type Aux = ProductAuxiliary;
@UntilDestroy()
@Directive()
export abstract class AuxiliariesRegulator<T>
  implements ControlValueAccessor, Validator, OnInit
{
  private onChange: (value: T[]) => void;
  private readonly existingAuxs = new BehaviorSubject<Aux[]>([]);
  readonly existingAuxs$: Observable<Aux[]> = this.existingAuxs.asObservable();

  abstract getEmissionsApproachStream(): Observable<emissionApproach>;
  abstract getAssumedAuxsStream(): Observable<ProductAuxiliary[]>;
  abstract mapAuxToLocalModel(aux: ProductAuxiliary): T;
  abstract mapLocalToAuxModel(local: T): ProductAuxiliary;
  abstract auxiliaryOnInit(): void;

  ngOnInit(): void {
    this.auxiliaryOnInit();
  }

  onAuxUpdate(auxs: ProductAuxiliary[]): void {
    Coerce.toVoidFn(this.onChange)(this.auxsToTees(auxs));
    this.existingAuxs.next(auxs);
  }

  writeValue(formVal: T[]): void {
    this.existingAuxs.next(this.teesToAuxs(formVal));
  }

  registerOnChange(fn: () => {}): void {
    this.onChange = fn;
  }

  validate(): ValidationErrors {
    return null;
  }

  subscribeToRefreshTriggersForAuxSyncing(): void {
    this.selectAuxResetTriggers()
      .pipe(
        mergeMap(() => this.getAssumedAuxsStream()),
        untilDestroyed(this),
      )
      .subscribe((assumed) => this.patchAuxs(assumed));
  }

  getAuxRefreshAdditionalTriggers(): Observable<unknown>[] {
    return [];
  }

  shouldDefault(): boolean {
    return true;
  }

  patchAuxs(assumed: ProductAuxiliary[]): void {
    const patched = AuxiliariesPatcher.patch(this.existingAuxs.value, assumed);
    Coerce.toVoidFn(this.onChange)(this.auxsToTees(patched));
    this.existingAuxs.next(patched);
  }

  reduceAuxs(auxs: Aux[]): Record<string, Aux> {
    return Coerce.toArr(auxs).reduce((acc, curr) => {
      acc[String(curr.type)] = curr;
      return acc;
    }, {});
  }

  getCurrentAuxs(): Aux[] {
    return this.existingAuxs.value;
  }

  private selectAuxResetTriggers(): Observable<unknown> {
    return combineLatest([
      this.getEmissionsApproachStream(),
      ...this.getAuxRefreshAdditionalTriggers(),
    ]);
  }

  private teesToAuxs(tees: T[]): ProductAuxiliary[] {
    return this.coerceList(tees).map((tee) => this.mapLocalToAuxModel(tee));
  }

  private auxsToTees(auxs: ProductAuxiliary[]): T[] {
    return this.coerceList(auxs).map((aux) => this.mapAuxToLocalModel(aux));
  }

  private coerceList<U>(alleged: U[]): U[] {
    const coerced = Coerce.toArr(alleged);
    return Array.isArray(coerced) ? coerced : [];
  }

  registerOnTouched(): void {
    // do nothing
  }
}

import { Coerce, DeclicUtils } from 'declic-app/common';
import { ApiConfig } from 'declic-app/models/api-config.model';
import { Owner, Project, ProjectShareInfo } from 'declic-project/models';

import { JSONifiedProject, JSONifiedShareInfo } from './project.state';

function jsonifiedToProject(jsonProject: JSONifiedProject): Project {
  const mapped = toProject(jsonProject);
  return {
    ...mapped,
    shareInfo: toShareInfo(
      DeclicUtils.resolveToEmptyArray(jsonProject.shareInfo),
    ),
  };
}

function toProject(json: JSONifiedProject): Project {
  json = Coerce.toObj(json);
  return {
    ...json,
    id: json.id,
    name: json.name,
    globalBusinessUnit: json.globalBusinessUnit,
    location: json.location,
    period: json.period,
    shareInfo: toShareInfo(json.shareInfo),
    status: json.status,
    date: json.date,
    description: json.description,
    installation: json.installation,
    salesRef: json.salesRef,
    owner: {
      userId: json.owner_id,
      userName: json.owner_name,
    } as Owner,
  };
}

function toShareInfo(jsonShareInfo: JSONifiedShareInfo[]): ProjectShareInfo[] {
  if (jsonShareInfo) {
    return jsonShareInfo.map(
      (shareInfo) =>
        ({
          userId: shareInfo.user_id,
          permissions: shareInfo.permission,
          firstName: shareInfo.first_name,
          lastName: shareInfo.last_name,
          email: shareInfo.email,
        } as ProjectShareInfo),
    );
  }
}

function buildProjectsEndpoint(from: ApiConfig): string {
  return DeclicUtils.buildEndpoint(from.baseUrl, from.endpoints.project.list);
}

function buildProjectEndpoint(from: ApiConfig, id: string): string {
  return DeclicUtils.buildEndpoint(
    from.baseUrl,
    from.endpoints.project.single,
    id,
  );
}

function buildComputeEndpoint(from: ApiConfig, id: string): string {
  return DeclicUtils.buildEndpoint(
    from.baseUrl,
    from.endpoints.project.compute,
    id,
  );
}

function buildShareEndpoint(from: ApiConfig, id: string): string {
  return DeclicUtils.buildEndpoint(
    from.baseUrl,
    from.endpoints.project.share,
    id,
  );
}

function buildCountEndpoint(from: ApiConfig, id: string): string {
  return DeclicUtils.buildEndpoint(
    from.baseUrl,
    from.endpoints.project.count,
    id,
  );
}

function buildAccessStatusEndpoint(from: ApiConfig, id: string): string {
  return DeclicUtils.buildEndpoint(
    from.baseUrl,
    from.endpoints.project.accessStatus,
    id,
  );
}

export const ProjectUtils = {
  jsonifiedToProject,
  toShareInfo,
  buildProjectsEndpoint,
  buildProjectEndpoint,
  buildComputeEndpoint,
  buildShareEndpoint,
  buildCountEndpoint,
  buildAccessStatusEndpoint,
};

import { BaseProject, ProjectRepository } from 'declic-project/state';
import { take } from 'rxjs/operators';

import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ANALYTICS_SERVICE, AnalyticsService } from '@declic/analytics';
import { filterNil } from '@ngneat/elf';

@Injectable({ providedIn: 'root' })
export class ProjectAccessedResolver {
  constructor(
    private readonly proyektos: ProjectRepository,
    @Inject(ANALYTICS_SERVICE) private analytics: AnalyticsService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): void {
    this.proyektos.setActive(this.getIdFromSnapshot(route));
    this.proyektos
      .getOne(this.getIdFromSnapshot(route))
      .pipe(filterNil(), take(1))
      .subscribe((project: BaseProject) => {
        if (project) {
          this.analytics.trackProjectAccessed({
            businessUnit: project.globalBusinessUnit,
          });
        }
      });
  }

  private getIdFromSnapshot(snapshot: ActivatedRouteSnapshot): string {
    return snapshot.paramMap.get('id');
  }
}

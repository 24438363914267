import { Coerce } from 'declic-app/common/coercion.utils';

import {
  EquipmentFuel,
  HasType,
  ProjectElec,
  ProjectFuel,
  Refrigerant,
} from '@declic/types';
import { createStore, propsFactory } from '@ngneat/elf';

import { withError, withLoading } from './factories';

export const { withFuels, resetFuels, selectFuels, setFuels, getFuels } =
  propsFactory('fuels', {
    initialValue: [] as EquipmentFuel[],
  });

export const {
  withProjFuels,
  resetProjFuels,
  selectProjFuels,
  setProjFuels,
  getProjFuels,
} = propsFactory('projFuels', {
  initialValue: [] as ProjectFuel[],
});

export const {
  withRefrigerants,
  resetRefrigerants,
  selectRefrigerants,
  setRefrigerants,
  getRefrigerants,
} = propsFactory('refrigerants', {
  initialValue: [] as Refrigerant[],
});

export const {
  withProjElecs,
  resetProjElecs,
  selectProjElecs,
  setProjElecs,
  getProjElecs,
} = propsFactory('projElecs', {
  initialValue: [] as ProjectElec[],
});

export const efStore = createStore(
  { name: 'efs' },
  withFuels([]),
  withProjFuels([]),
  withProjElecs([]),
  withRefrigerants([]),
  withLoading(),
  withError(),
);

function reduceFuels(): Record<string, EquipmentFuel> {
  return reduceUsingType(efStore.query(getFuels));
}

function reduceProjFuels(): Record<string, ProjectFuel> {
  return reduceUsingType(efStore.query(getProjFuels));
}

function reduceProjElecs(): Record<string, ProjectElec> {
  return reduceUsingType(efStore.query(getProjElecs));
}

function reduceProjRefs(): Record<string, Refrigerant> {
  return reduceUsingType(efStore.query(getRefrigerants));
}

function reduceUsingType<T extends HasType>(hasTypes: T[]): Record<string, T> {
  return hasTypes.reduce((acc, curr) => {
    acc[curr.type] = curr;
    return acc;
  }, {});
}

function getLifecycleEF(type: string): number | undefined {
  return Coerce.toObj(reduceFuels()[type]).lifecycleEf;
}

function getName(type: string): string {
  return Coerce.toString(Coerce.toObj(reduceFuels()[type]).name);
}

function getProjectFuelEf(type: string): number {
  return getProjectFuel(type).ef;
}

function getProjectFuel(type: string): ProjectFuel {
  return Coerce.toObj(reduceProjFuels()[type]);
}

function getProjectElec(type: string): ProjectElec {
  return Coerce.toObj(reduceProjElecs()[type]);
}

function getProjectRef(type: string): Refrigerant {
  return Coerce.toObj(reduceProjRefs()[type]);
}

export const EFStoreQueries = {
  getLifecycleEF,
  getName,
  getProjectFuelEf,
  getProjectFuel,
  getProjectElec,
  getProjectRef,
};

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { CommafyModule } from '@declic-shared/directives/commafy';
import { DeclicTooltipModule } from '@declic/directives/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { SparklineModule } from '../sparkline';
import { YearlyValuesModule } from '../yearly-values';
import { YearlyInputComponent } from './yearly-input.component';
import { LabelMakerComponent } from 'declic-app/common/label-maker';
@NgModule({
  declarations: [YearlyInputComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    FlexLayoutModule,
    SparklineModule.forFeature(),
    MatButtonModule,
    MatIconModule,
    MatButtonModule,
    ReactiveFormsModule,
    CommafyModule,
    TranslocoModule,
    DeclicTooltipModule,
    YearlyValuesModule,
    LabelMakerComponent,
  ],
  exports: [YearlyInputComponent],
})
export class YearlyInputModule {}

import { Coerce } from './coercion.utils';

export type TokenData = Readonly<
  Partial<{
    exp: number;
    iat: number;
    auth_time: number;
    email: string;
    client_id: string;
    iss: string;
    jti: string;
    origin_jti: string;
    scope: string;
    sub: string;
    token_use: string;
    username: string;
  }>
>;

function parseJwt(token: string): TokenData {
  const base64Url = Coerce.toString(token).split('.')[1];
  const base64 = Coerce.toString(base64Url)
    .replace(/-/g, '+')
    .replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
}

export const DeclicParser = {
  parseJwt,
};

import { filterNil } from '@ngneat/elf';
import { selectActiveEntity } from '@ngneat/elf-entities';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { calculationStore } from './calculation.repository';
import { Result } from './calculation.state';

function selectActiveHasResults(): Observable<boolean> {
  return selectActiveResults().pipe(map((results) => results.length > 0));
}

function selectActiveResults(): Observable<Result[]> {
  return calculationStore.pipe(
    selectActiveEntity(),
    filterNil(),
    map((e) => e.results),
  );
}

export const CalcStoreQueries = {
  selectActiveHasResults,
  selectActiveResults,
};

import { ToggleOption } from 'declic-app/components/toggle';

export enum NatureHeatProductionType {
  HOT_WATER = 'Hot water',
  STEAM = 'Steam',
  DIRECT = 'Exhaust Gases',
}

export type NatureHeatProduction = {
  [productId: string]: NatureHeatProductionType[];
};

export type NatureHeatProductionWithID = {
  energySource: NatureHeatProductionType;
  id: string;
};

export const NATUREHEATPRODUCTION: NatureHeatProduction = {
  ogso: [
    NatureHeatProductionType.HOT_WATER,
    NatureHeatProductionType.STEAM,
    NatureHeatProductionType.DIRECT,
  ],
};

export const NATURE_HEAT_OPTIONS: ToggleOption[] = [
  {
    label: NatureHeatProductionType.HOT_WATER,
    source: '/assets/icons/heat_water.svg',
  },
  {
    label: NatureHeatProductionType.STEAM,
    source: '/assets/icons/heat_steam.svg',
  },
  {
    label: NatureHeatProductionType.DIRECT,
    source: '/assets/icons/heat_direct.svg',
  },
];

import {
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FourOhFourModule } from '@declic-shared/containers/four-oh-four';
import { LoginPageModule } from '@declic-shared/containers/login-page';
import {
  IS_ENV_PRODUCTION,
  WEB_SOCKET_URL,
  provideDecliConfig,
} from '@declic/env';
import { oktaAuth } from './app.tokens';

import { NgSelectModule } from '@ng-select/ng-select';
import { provideHttpCache, withHttpCacheInterceptor } from '@ngneat/cashew';
import { ElfNgRouterStoreModule } from '@ngneat/elf-ng-router-store';
import { OktaAuthModule } from '@okta/okta-angular';
import { environment } from '../environments';
import { DeclicSentry } from '../sentry';
import { AnalyticsModule } from './analytics';
import { AmplitudeAnalytics } from './analytics/analytics.adapters';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AssumptionsModule } from './assumptions';
import { DeclicUtils, tokenRefresher } from './common';
import { getDeclicInterceptorProviders } from './common/declic-interceptor.service';
import { InfoPanelModule } from './components/info-panel';
import { OmnibarLayoutModule } from './components/omnibar-layout';
import { NEW_TRANSLOCO_PROVIDER } from './configs/transloco.config';
import {
  ENV_CONFIG,
  HTTP_CONFIG,
} from './services/auth-config/auth-config.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatDialogModule,
    FlexLayoutModule,
    MatIconModule,
    AssumptionsModule,
    OmnibarLayoutModule,
    ElfNgRouterStoreModule,
    LoginPageModule,
    FourOhFourModule,
    MatSnackBarModule,
    AnalyticsModule.forRoot({
      amplitudeKey: environment.amplitudeAPIKey,
      serviceClass: AmplitudeAnalytics,
    }),
    InfoPanelModule,
    OktaAuthModule.forRoot({ oktaAuth }),
    NgSelectModule,
  ],
  providers: [
    ...DeclicUtils.provideBaseUrlAndEndpoints(),
    DeclicSentry.provideErrHandler(),
    DeclicSentry.provideTracing(),
    provideHttpClient(
      withInterceptorsFromDi(),
      withInterceptors([withHttpCacheInterceptor(), tokenRefresher()]),
    ),
    provideHttpCache(),
    provideDecliConfig(),
    { provide: ENV_CONFIG, useValue: environment },
    { provide: WEB_SOCKET_URL, useValue: environment.websocket?.url },
    { provide: IS_ENV_PRODUCTION, useValue: environment.production },
    { provide: HTTP_CONFIG, useValue: environment.http },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'fill' },
    },
    ...getDeclicInterceptorProviders(),
    NEW_TRANSLOCO_PROVIDER,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {}
}

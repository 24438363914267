import { EfficiencyAssumption } from 'declic-app/assumptions/common';
import { Efficiency } from 'declic-assumptions/models';

import { Injectable } from '@angular/core';
import { AssumptionStore } from 'declic-app/assumption/state';

@Injectable()
export class ElecConversionService extends EfficiencyAssumption<Efficiency> {
  /* istanbul ignore next */
  constructor(private assumptionStore: AssumptionStore) {
    super(assumptionStore);
  }
  getAssumptionsJSONFileName(): string {
    return 'energyRates';
  }
  getAssumptionsJSONKey(): string {
    return 'conversionEfficiencies';
  }

  reduceToManifest(assumptions): { [key: string]: Efficiency } {
    return assumptions.reduce((acc, assumption) => {
      acc[String(assumption.fuel).trim()] = {
        efficiency: assumption.productionOfElectricity,
        name: assumption.fuel,
      };
      return acc;
    }, {});
  }
}

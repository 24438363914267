import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  private readonly DISMISS_MSG = '';

  constructor(private snackbar: MatSnackBar) {}

  openNOK(message: string): void {
    this.snackbar.open(message, this.DISMISS_MSG, this.getErrorConfig());
  }

  openOK(message: string): void {
    this.snackbar.open(message, this.DISMISS_MSG, this.getInfoConfig());
  }

  private getInfoConfig(): MatSnackBarConfig {
    return { ...this.getDefaultConfig(), panelClass: 'declic-snack-ok' };
  }

  private getErrorConfig(): MatSnackBarConfig {
    return { ...this.getDefaultConfig(), panelClass: 'declic-snack-nok' };
  }

  private getDefaultConfig(): MatSnackBarConfig {
    return {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
    };
  }
}

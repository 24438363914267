import { HasGHGEmissionsApproach, PVSystem } from './equipment.types';

export type PVPlant = PVSystem & HasGHGEmissionsApproach;

export type PVPlantForm = Pick<
  PVPlant,
  | 'name'
  | 'capacity'
  | 'ghgEmissionsApproach'
  | 'productionType'
  | 'yearlyProduction'
  | 'firstYearValue'
  | 'efficiencyLoss'
>;

export type SolarPV = PVSystem;

export type SolarPVForm = Pick<
  SolarPV,
  | 'name'
  | 'capacity'
  | 'productionType'
  | 'firstYearValue'
  | 'yearlyProduction'
  | 'efficiencyLoss'
>;

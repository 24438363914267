export const ChartUtils = { getColors, pluckColor };

function getColors(): Record<NamedColor, string> {
  return {
    blue: 'rgb(54, 162, 235)',
    green: 'rgb(75, 192, 192)',
    red: 'rgb(255, 99, 132)',
    orange: 'rgb(255, 159, 64)',
    yellow: 'rgb(255, 205, 86)',
    purple: 'rgb(153,102,255)',
    grey: 'rgb(201, 203, 207)',
  };
}

function pluckColor(index: number): string {
  return Object.values(getColors())[index];
}

enum NamedColor {
  Red = 'red',
  Blue = 'blue',
  Green = 'green',
  Orange = 'orange',
  Yellow = 'yellow',
  Purple = 'purple',
  Grey = 'grey',
}

export enum ColorMapper {
  directEmissions = '#005AA0',
  carbonIntensity = '#005AA0',
  greenEnergyDelivered = '#005AA0',
  renewableAndRecoveryEnergyRatio = '#005AA0',
  avoidedEmissions = '#005AA0',
  reducedEmissions = '#005AA0',
  projectEmissions = '#FACA08',
  historicalEmissions = '#009DE9',
  baselineEmissions = '#552282',
}

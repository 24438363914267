import { ProductsType } from 'declic-project/models';

export interface NetworksProject {
  type: ProductsType;
  id: string;
  projectId?: string;
  valid?: boolean;
}

export enum NetworkProjectType {
  GI = 'Gas infrastructures',
  EI = 'Electricity infrastructures',
  BI = 'Biomethane production',
}

export enum NetworkProjectTypeWithAvoided {
  BI = 'Biomethane production',
}

export enum NetworkProjectAlias {
  GI = 'gi',
  EI = 'ei',
  BI = 'bi',
}

export const NetworkProjectAliases: Record<
  NetworkProjectType,
  NetworkProjectAlias
> = {
  [NetworkProjectType.BI]: NetworkProjectAlias.BI,
  [NetworkProjectType.GI]: NetworkProjectAlias.GI,
  [NetworkProjectType.EI]: NetworkProjectAlias.EI,
};

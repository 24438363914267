import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { filterNil } from '@ngneat/elf';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filterOutUndefined } from 'declic-app/common';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import tippy, { Instance } from 'tippy.js';

@UntilDestroy()
@Directive({
  selector: '[declicTooltip]',
})
export class TooltipDirective implements OnInit {
  private contentSubject = new BehaviorSubject<string>(undefined);
  private elemSubject = new BehaviorSubject<ElementRef>(undefined);
  private tippyInstance: unknown;
  @Input() set content(content: string) {
    if (content) {
      this.contentSubject.next(content);
    }
  }

  @Input() set huhu(content: ElementRef) {
    if (content) {
      this.elemSubject.next(content);
    }
  }
  @Input() showOnCreate = false;
  @Input() shouldShow = true;

  constructor(private el: ElementRef) {
    this.el = el;
  }

  ngOnInit() {
    this.subscribeToContentForTippyInstantiation();
    this.susbcribeToElementForTippyInstantiation();
  }

  private susbcribeToElementForTippyInstantiation(): void {
    this.elemSubject
      .pipe(
        filter(() => this.shouldShow),
        filterOutUndefined,
        untilDestroyed(this),
      )
      .subscribe((hahah) => {
        if (hahah) {
          this.destroyTippyInstanceIfTruthy();
          this.el = hahah;
          this.tippyInstance = tippy(this.el.nativeElement, {
            delay: [500, 0],
            showOnCreate: this.showOnCreate,
            allowHTML: true,
          });
        }
      });
  }

  private subscribeToContentForTippyInstantiation(): void {
    this.contentSubject
      .pipe(
        filter(() => this.shouldShow),
        filterNil(),
        untilDestroyed(this),
      )
      .subscribe((content) => {
        if (content) {
          this.destroyTippyInstanceIfTruthy();
          this.tippyInstance = tippy(this.el.nativeElement, {
            content,
            delay: [500, 0],
            showOnCreate: this.showOnCreate,
            allowHTML: true,
          });
        }
      });
  }

  private destroyTippyInstanceIfTruthy(): void {
    if (this.tippyInstance) {
      (this.tippyInstance as Instance).destroy();
    }
  }
}

/* eslint-disable */
import { AssumptionService } from 'declic-app/assumptions/common';
import { EmissionByYear, GasMix } from 'declic-app/assumptions/models';
import { Coerce, DeclicUtils } from 'declic-app/common';
import { YearlyEmission } from 'declic-app/models';

import { Injectable } from '@angular/core';
import { AssumptionStore } from 'declic-app/assumption/state';

import { CountriesService } from '../countries/countries.service';

@Injectable()
export class GasMixService extends AssumptionService<GasMix> {
  constructor(
    private countryService: CountriesService,
    private assumptionStore: AssumptionStore,
  ) {
    super(assumptionStore);
  }
  getAssumptionsJSONFileName(): string {
    return 'gasMixes';
  }
  getAssumptionsJSONKey(): string {
    return 'gas-mix';
  }

  reduceToManifest(assumptions): GasMix {
    const assumptionObj = {} as GasMix;
    assumptions.forEach((assumption) => {
      if (!assumptionObj[assumption.country]) {
        assumptionObj[assumption.country] = {};
      }
      assumptionObj[assumption.country][assumption.emissionType.toLowerCase()] =
        [assumption.values];
      assumptionObj[assumption.country].name = assumption.country;
    });
    return assumptionObj;
  }

  getDirectEmissionAverage(country: string, period: [number, number]): number {
    const [startYear, endYear] = period;
    const assumption = this.getGasMixAssumption(country);
    const directEmissions = assumption.direct[0];
    const directEmissionsYears = Object.keys(directEmissions);

    const sumDirect = directEmissionsYears.reduce((total, value) => {
      const emYear = Number(value);
      const emVal =
        emYear >= startYear && emYear <= endYear ? directEmissions[value] : 0;
      return total + emVal;
    }, 0);

    return sumDirect / (endYear - startYear + 1);
  }

  getDirectEmissionForYears(
    country: string,
    period: [number, number],
    toFixed: boolean = true,
  ): YearlyEmission[] {
    return this.buildEmissions(period, this.getDirectGasMix(country), toFixed);
  }

  getDirectEmissionForSpecificYear(country: string, year: number): number {
    const assumption = Coerce.toObj(this.getGasMixAssumption(country));
    const directEmissions = Coerce.toObj(Coerce.toArr(assumption.direct)[0]);
    const directEmissionsYears = Object.keys(directEmissions);
    let emissionsForYear = 0;

    directEmissionsYears.forEach((value) => {
      const emYear = Number(value);
      if (emYear === year) {
        emissionsForYear = directEmissions[value];
      }
    });

    return emissionsForYear;
  }

  getIndirectEmissionAverage(
    country: string,
    period: [number, number],
  ): number {
    const [startYear, endYear] = period;
    const assumption = this.getGasMixAssumption(country);
    const indirectEmissions = assumption.indirect[0];
    const indirectEmissionsYears = Object.keys(indirectEmissions);

    const sumInindirect = indirectEmissionsYears.reduce((total, value) => {
      const emYear = Number(value);
      const emVal =
        emYear >= startYear && emYear <= endYear ? indirectEmissions[value] : 0;
      return total + emVal;
    }, 0);

    return sumInindirect / (endYear - startYear + 1);
  }

  getIndirectEmissionForYears(
    country: string,
    period: [number, number],
  ): YearlyEmission[] {
    return this.buildEmissions(period, this.getIndirectGasMix(country), false);
  }

  getIndirectEmissionForSpecificYear(country: string, year: number): number {
    const assumption = Coerce.toObj(this.getGasMixAssumption(country));
    const indirectEmissions = Coerce.toObj(
      Coerce.toArr(assumption.indirect)[0],
    );
    const indirectEmissionsYears = Object.keys(indirectEmissions);
    let emissionsForYear = 0;

    indirectEmissionsYears.forEach((value) => {
      const emYear = Number(value);
      if (emYear === year) {
        emissionsForYear = indirectEmissions[value];
      }
    });

    return emissionsForYear;
  }

  getTotalEmissionAverage(country: string, period: [number, number]): number {
    const [startYear, endYear] = period;
    const duration = endYear - startYear + 1;
    const directEmissions = this.getDirectEmissionForYears(
      country,
      period,
      false,
    );
    const indirectEmissions = this.getIndirectEmissionForYears(country, period);
    const totalDirectEmissions = directEmissions.reduce(
      (total, current) => total + current.emissions,
      0,
    );
    const totalIndirectEmissions = indirectEmissions.reduce(
      (total, current) => total + current.emissions,
      0,
    );
    return DeclicUtils.fixTo3Places(
      (totalDirectEmissions + totalIndirectEmissions) / duration,
    );
  }

  getTotalEmissionForYears(
    country: string,
    period: [number, number],
  ): YearlyEmission[] {
    const directEmissions = this.getDirectEmissionForYears(
      country,
      period,
      false,
    );
    const indirectEmissions = this.getIndirectEmissionForYears(country, period);
    const totalEmissionsForYears: YearlyEmission[] = [];
    directEmissions.forEach(({ year, emissions }, index) => {
      totalEmissionsForYears.push({
        year,
        emissions: DeclicUtils.fixTo3Places(
          emissions + indirectEmissions[index].emissions,
        ),
      });
    });
    return totalEmissionsForYears;
  }

  getDirectEmission(country: string, year: number): number {
    return this.getDirectEmissionForSpecificYear(country, year);
  }

  getTotalEmission(country: string, year: number): number {
    return DeclicUtils.fixTo3Places(
      this.getDirectEmissionForSpecificYear(country, year) +
        this.getIndirectEmissionForSpecificYear(country, year),
    );
  }

  private getDirectGasMix(country: string): EmissionByYear {
    const assumption = this.getGasMixAssumption(country);
    const direct = Coerce.toArr(Coerce.toObj(assumption).direct);
    return Coerce.toObj(direct[0]);
  }

  private getIndirectGasMix(country: string): EmissionByYear {
    const assumption = this.getGasMixAssumption(country);
    const indirect = Coerce.toArr(Coerce.toObj(assumption).indirect);
    return Coerce.toObj(indirect[0]);
  }

  private buildEmissions(
    period: [number, number],
    bag: EmissionByYear,
    toFixed: boolean,
  ): YearlyEmission[] {
    const [start, end] = period;
    bag = Coerce.toObj(bag);
    return DeclicUtils.arrayFromRange(start, end).map((year) => ({
      year,
      emissions: toFixed
        ? Number(DeclicUtils.fixTo3Places(bag[year]))
        : bag[year],
    }));
  }

  private getGasMixAssumption(country: string) {
    const gasMix = Coerce.toObj(
      this.countryService.getAssumption(country),
    ).gasMix;
    return this.getAssumption(gasMix);
  }
}

import { AssumptionService } from 'declic-app/assumptions/common';
import { PowerGrid } from 'declic-app/assumptions/models';
import { arrayFromRange, Coerce, resolveToZero } from 'declic-app/common';
import { YearlyEmission } from 'declic-app/models';

import { Injectable } from '@angular/core';
import { AssumptionStore, PowerGridBE } from 'declic-app/assumption/state';

import { CountriesService } from '../countries/countries.service';

@Injectable()
export class PowerGridService extends AssumptionService<PowerGrid> {
  constructor(
    private countryService: CountriesService,
    private assumptionStore: AssumptionStore,
  ) {
    super(assumptionStore);
  }
  getAssumptionsJSONFileName(): string {
    return 'powerGrids';
  }
  getAssumptionsJSONKey(): string {
    return 'emissionFactors';
  }

  reduceToManifest(assumptions: unknown[]): PowerGrid {
    return assumptions.reduce((acc: PowerGrid, assumption: PowerGridBE) => {
      acc[assumption.country] = {
        year: [assumption.values],
        name: assumption.country,
      };
      return acc;
    }, {});
  }

  getAverage(year: [number, number], country: string): number {
    const assumption = this.getPowerGridAssumption(country);
    if (year[1] > 2050) {
      return -1;
    }

    const duration = year[1] - year[0] + 1;
    const contractYears = arrayFromRange(year[0], year[1]);
    const sum = contractYears.reduce(
      (runningSum, runningYear) =>
        runningSum + resolveToZero(assumption.year[0][runningYear]),
      0,
    );
    const average = sum / duration;
    return Number(average.toFixed(3));
  }

  getForSpecificYears(country: string, years: number[]): YearlyEmission[] {
    const allYears = this.getPerYear(country);
    return allYears.filter((emission) => years.includes(emission.year));
  }

  getEmissionsForYear(country: string, year: number): number {
    return Coerce.toObj(this.getForSpecificYears(country, [year])[0]).emissions;
  }

  private getPerYear(country: string): YearlyEmission[] {
    const yearDataOfPowerGrid =
      Coerce.toArr(
        Coerce.toObj(this.getPowerGridAssumption(country)).year,
      )[0] || {};
    return Object.entries(yearDataOfPowerGrid).map(([year, emissions]) => ({
      year: Number(year),
      emissions: Number(Number(emissions).toFixed(3)),
    }));
  }

  private getPowerGridAssumption(country: string): PowerGrid {
    const powerGridKey = Coerce.toObj(
      this.countryService.getAssumption(country),
    ).powerGrid;
    return this.getAssumption(powerGridKey);
  }
}
